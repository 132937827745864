import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createSlots as _createSlots } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "tw3-flex tw3-gap-4 tw3-items-center"
}
const _hoisted_2 = { key: 2 }
const _hoisted_3 = {
  key: 1,
  class: "tw3-flex tw3-items-start tw3-gap-4"
}
const _hoisted_4 = { class: "tw3-flex tw3-flex-wrap tw3-items-center tw3-gap-2" }
const _hoisted_5 = { class: "tw3-flex tw3-items-center tw3-gap-2 tw3-capitalize" }
const _hoisted_6 = { style: {"font-size":"0.7rem"} }
const _hoisted_7 = {
  key: 0,
  class: "pi pi-spin pi-spinner text-sm",
  style: {"cursor":"pointer","font-size":"0.7rem"}
}
const _hoisted_8 = ["onClickOnce"]
const _hoisted_9 = { class: "tw3-w-full tw3-h-[476px] tw3-flex tw3-justify-center tw3-items-center" }
const _hoisted_10 = { class: "tw3-text-center tw3-text-3xl" }
const _hoisted_11 = { class: "tw3-flex tw3-items-center tw3-justify-between tw3-gap-3" }
const _hoisted_12 = { class: "tw3-flex tw3-items-center tw3-gap-0.5 tw3-gap-x-2" }

import Button from 'primevue/button';
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import InputText from 'primevue/inputtext';
import Select from 'primevue/select';
import Skeleton from 'primevue/skeleton';
import Tag from 'primevue/tag';
import ToggleSwitch from 'primevue/toggleswitch';
import { useToast } from 'primevue/usetoast';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { RouterLink } from 'vue-router';

import { jobStatusConfig } from '@/entities/job/config';
import { JobTag } from '@/entities/job/model/types';
import { isEmptyValue } from '@/shared/helpers';
import { TableFiltersWithMetadata } from '@/shared/types/tables';
import { RefreshButton } from '@/shared/ui';
import StatusBadge from '@/shared/ui/table/StatusBadge.vue';

import { rejectedInvoicesHeader } from '../../model/constants';
import useRejectedInvoicesStore from '../../model/store';

import TagDialogWithButton from './TagDialogWithButton.vue';

import type {
	RejectedInvoice,
	RejectedInvoicesFilters
} from '@/widgets/rejectedInvoicesDialog/model/types';
import type {
	DataTablePageEvent,
	DataTableSortEvent
} from 'primevue/datatable';


export default /*@__PURE__*/_defineComponent({
  __name: 'RejectedInvoicesTable',
  setup(__props) {

const toast = useToast();

const rejectedInvoicesStore = useRejectedInvoicesStore();
const { t } = useI18n();

const handlePage = (state: DataTablePageEvent) => {
	rejectedInvoicesStore.pagination.page = state.page + 1;
};

const handleSort = (state: DataTableSortEvent) => {
	rejectedInvoicesStore.pagination.page = 1;

	rejectedInvoicesStore.handleSort({
		desc: state.sortOrder === -1 ? true : false,
		field: state.sortField as string
	});
};

const getFilterIconClass = (field: string) => {
	const filter =
		rejectedInvoicesStore.filters[
			field as keyof TableFiltersWithMetadata<RejectedInvoicesFilters>
		];

	return isEmptyValue(filter.value) || filter.value === 'all'
		? 'pi pi-filter'
		: 'pi pi-filter-fill';
};

const openInvoiceDetail = (id: number) => {
	const invoice = rejectedInvoicesStore.reactiveInvoicesData.find(
		item => item.id === id
	);
	rejectedInvoicesStore.setInvoiceDetailsData({
		message: invoice?.edi_message,
		status: invoice?.edi_status
	});
};

const newTagsIsLoading = ref({
	jobID: 0,
	loading: false
});
async function addNewTags(tags: JobTag[], data: RejectedInvoice) {
	newTagsIsLoading.value = {
		jobID: data.id,
		loading: true
	};

	const response = await rejectedInvoicesStore.addJobTagHandler({
		jobID: data?.id,
		tags
	});

	if (response !== 'error') {
		toast.add({
			detail: t('rejectedInvoices.tagAddedSuccess'),
			life: 3000,
			severity: 'success',
			summary: t('defaultToast.successSummary')
		});
	} else {
		toast.add({
			detail: t('defaultToast.errorDetails'),
			life: 3000,
			severity: 'error',
			summary: t('defaultToast.errorSummary')
		});
	}
	newTagsIsLoading.value = {
		jobID: 0,
		loading: false
	};
	return response;
}

const deleteTagIsLoading = ref({
	tagID: 0,
	jobID: 0,
	loading: false
});
async function deleteTag(params: { jobID: number; jobTagID: number }) {
	deleteTagIsLoading.value = {
		tagID: params.jobTagID,
		jobID: params.jobID,
		loading: true
	};

	const response = await rejectedInvoicesStore.deleteJobTagHandler({
		jobID: params.jobID,
		jobTagID: params.jobTagID
	});

	if (response !== 'error') {
		toast.add({
			severity: 'success',
			summary: t('defaultToast.successSummary'),
			life: 3000,
			detail: t('rejectedInvoices.tagDeletedSuccess')
		});
	} else {
		toast.add({
			severity: 'error',
			summary: t('defaultToast.errorSummary'),
			life: 3000,
			detail: t('rejectedInvoices.tagDeletedFailed')
		});
	}
	deleteTagIsLoading.value = {
		tagID: 0,
		jobID: 0,
		loading: false
	};
	return response;
}

const toggleSwitch = ref();
function toggleSwitchHandler() {
	if (toggleSwitch.value) {
		toggleSwitch.value.checked = !rejectedInvoicesStore.includeVoided;
		rejectedInvoicesStore.includeVoided = !rejectedInvoicesStore.includeVoided;
	}
}

const statusObject = (status: keyof typeof jobStatusConfig) => {
	return jobStatusConfig[status] || '';
};

const skeletonArray = Array(10).fill({});

return (_ctx: any,_cache: any) => {
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createBlock(_unref(DataTable), {
    filters: _unref(rejectedInvoicesStore).filters,
    "onUpdate:filters": _cache[3] || (_cache[3] = ($event: any) => ((_unref(rejectedInvoicesStore).filters) = $event)),
    dataKey: "id",
    filterDisplay: "menu",
    first: 
			(_unref(rejectedInvoicesStore).pagination.page - 1) *
			_unref(rejectedInvoicesStore).pagination.limit
		,
    lazy: "",
    paginator: "",
    rows: _unref(rejectedInvoicesStore).pagination.limit,
    scrollable: "",
    scrollHeight: "flex",
    sortField: _unref(rejectedInvoicesStore).sort.sortBy,
    sortOrder: _unref(rejectedInvoicesStore).sort.sortDesc === false ? 1 : -1,
    style: {"height":"calc(100% - 42px)"},
    tableClass: "tw3-max-w-full tw3-max-h-full",
    totalRecords: _unref(rejectedInvoicesStore).rejectedInvoicesData.totalCount,
    value: 
			_unref(rejectedInvoicesStore).rejectedInvoicesDataLoading
				? _unref(skeletonArray)
				: _unref(rejectedInvoicesStore).reactiveInvoicesData
		,
    onPage: handlePage,
    onSort: handleSort
  }, _createSlots({
    header: _withCtx(() => [
      _createElementVNode("div", _hoisted_11, [
        (_unref(rejectedInvoicesStore).isFilteredByShop)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createVNode(_unref(Select), {
                modelValue: _unref(rejectedInvoicesStore).selectedShop,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(rejectedInvoicesStore).selectedShop) = $event)),
                optionLabel: "name",
                options: _unref(rejectedInvoicesStore).shops,
                placeholder: `${_unref(t)('selectShop')}`
              }, null, 8, ["modelValue", "options", "placeholder"]),
              _createElementVNode("div", _hoisted_12, [
                _createVNode(_unref(ToggleSwitch), {
                  ref_key: "toggleSwitch",
                  ref: toggleSwitch,
                  modelValue: _unref(rejectedInvoicesStore).includeVoided,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(rejectedInvoicesStore).includeVoided) = $event))
                }, null, 8, ["modelValue"]),
                _createElementVNode("span", {
                  class: "tw3-cursor-pointer",
                  style: {"line-height":"100%"},
                  onClick: toggleSwitchHandler
                }, _toDisplayString(_unref(t)('rejectedInvoices.voidedJobs')), 1)
              ])
            ], 64))
          : _createCommentVNode("", true),
        _createVNode(_unref(RefreshButton), {
          class: "tw3-ml-auto tw3-mr-0",
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_unref(rejectedInvoicesStore).refreshData()))
        })
      ])
    ]),
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(rejectedInvoicesHeader), (column) => {
        return (_openBlock(), _createBlock(_unref(Column), {
          key: column.key,
          bodyClass: "tw3-align-top",
          field: column.key,
          filterField: column.key,
          header: column.title,
          headerClass: "tw3-text-right tw3-justify-end",
          showFilterMatchModes: false,
          showFilterMenu: "",
          sortable: "",
          style: _normalizeStyle({
				width:
					column.key === 'customer_name'
						? 'auto'
						: column.key === 'amount' || column.key === 'referral'
							? '12%'
							: column.key === 'id' || column.key === 'trading_partner'
								? '16%'
								: 'auto'
			})
        }, {
          body: _withCtx(({ data }) => [
            _createElementVNode("span", {
              class: _normalizeClass([
						'cell-container',
						{
							'tw3-justify-end':
								column.key === 'amount' ||
								column.key === 'referral' ||
								column.key === 'id',
							'tw3-justify-center': column.key === 'trading_partner'
						}
					])
            }, [
              (_unref(rejectedInvoicesStore).rejectedInvoicesDataLoading)
                ? (_openBlock(), _createBlock(_unref(Skeleton), { key: 0 }))
                : (column.key === 'id')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                      _createVNode(_unref(RouterLink), {
                        target: "_blank",
                        to: `/jobs/${data[column.key]}`
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(data[column.key]), 1)
                        ]),
                        _: 2
                      }, 1032, ["to"]),
                      (statusObject(data.job_status))
                        ? (_openBlock(), _createBlock(StatusBadge, {
                            key: 0,
                            style: _normalizeStyle({
									display: 'inline-block',
									background: statusObject(data.job_status).color
								}),
                            text: statusObject(data.job_status).label
                          }, null, 8, ["style", "text"]))
                        : _createCommentVNode("", true)
                    ]))
                  : (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(data[column.key]), 1))
            ], 2)
          ]),
          filtericon: _withCtx(() => [
            _createElementVNode("i", {
              class: _normalizeClass(getFilterIconClass(column.key))
            }, null, 2)
          ]),
          filter: _withCtx(({ filterModel }) => [
            _createVNode(_unref(InputText), {
              modelValue: filterModel.value,
              "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
              class: "p-column-filter",
              placeholder: `${_unref(t)('searchBy')} ${column.title}`,
              type: "text"
            }, null, 8, ["modelValue", "onUpdate:modelValue", "placeholder"])
          ]),
          _: 2
        }, 1032, ["field", "filterField", "header", "style"]))
      }), 128)),
      (_unref(rejectedInvoicesStore).shopTagsData.length)
        ? (_openBlock(), _createBlock(_unref(Column), {
            key: 0,
            bodyClass: "tw3-align-top text-center",
            dataType: "array",
            field: "tags",
            header: _unref(t)('rejectedInvoices.tags'),
            style: {
				width: '20%',
				'min-width': '200px'
			}
          }, {
            body: _withCtx(({ data }) => [
              (_unref(rejectedInvoicesStore).rejectedInvoicesDataLoading)
                ? (_openBlock(), _createBlock(_unref(Skeleton), { key: 0 }))
                : (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    data
                      ? (_openBlock(), _createBlock(TagDialogWithButton, {
                          key: 0,
                          currentTagsList: data.tags,
                          isLoading: 
							newTagsIsLoading.value.jobID === data.id
								? newTagsIsLoading.value.loading
								: false
						,
                          jobID: data.id,
                          tagsList: _unref(rejectedInvoicesStore).shopTagsData,
                          onUpdateTags: tags => addNewTags(tags, data)
                        }, null, 8, ["currentTagsList", "isLoading", "jobID", "tagsList", "onUpdateTags"]))
                      : _createCommentVNode("", true),
                    _createElementVNode("div", _hoisted_4, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(data.tags, (tag, index) => {
                        return (_openBlock(), _createBlock(_unref(Tag), {
                          key: tag?.id || index,
                          severity: "secondary",
                          style: _normalizeStyle({
									'border-left': '10px solid black',
									'border-color': tag.color
								})
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_5, [
                              _createElementVNode("span", _hoisted_6, _toDisplayString(tag.text.toLowerCase()), 1),
                              (
											deleteTagIsLoading.value.jobID === data.id &&
											deleteTagIsLoading.value.tagID === tag.jobtag.id &&
											deleteTagIsLoading.value.loading
										)
                                ? (_openBlock(), _createElementBlock("i", _hoisted_7))
                                : (_openBlock(), _createElementBlock("i", {
                                    key: 1,
                                    class: "pi pi-times-circle text-sm",
                                    style: {"cursor":"pointer","font-size":"0.7rem"},
                                    onClickOnce: ($event: any) => (
											deleteTag({ jobID: data.id, jobTagID: tag.jobtag.id })
										)
                                  }, null, 40, _hoisted_8))
                            ])
                          ]),
                          _: 2
                        }, 1032, ["style"]))
                      }), 128))
                    ])
                  ]))
            ]),
            _: 1
          }, 8, ["header"]))
        : _createCommentVNode("", true),
      _createVNode(_unref(Column), {
        bodyClass: "tw3-align-top",
        style: {
				width: '80px'
			}
      }, {
        body: _withCtx(({ data }) => [
          (_unref(rejectedInvoicesStore).rejectedInvoicesDataLoading)
            ? (_openBlock(), _createBlock(_unref(Skeleton), { key: 0 }))
            : (data?.edi_message || data?.edi_status)
              ? _withDirectives((_openBlock(), _createBlock(_unref(Button), {
                  key: 1,
                  icon: "pi pi-info-circle",
                  severity: "info",
                  text: "",
                  onClick: ($event: any) => (openInvoiceDetail(data.id))
                }, null, 8, ["onClick"])), [
                  [
                    _directive_tooltip,
                    _unref(t)('rejectedInvoices.showDetails'),
                    void 0,
                    { top: true }
                  ]
                ])
              : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 2
  }, [
    (!_unref(rejectedInvoicesStore).rejectedInvoicesDataLoading)
      ? {
          name: "empty",
          fn: _withCtx(() => [
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("p", _hoisted_10, _toDisplayString(_unref(t)('noData')), 1)
            ])
          ]),
          key: "0"
        }
      : undefined
  ]), 1032, ["filters", "first", "rows", "sortField", "sortOrder", "totalRecords", "value"]))
}
}

})