import { coreApi } from '@/shared/api';
import { convertToDateTime } from '@/shared/helpers/converters';
import { Exact } from '@/shared/types';

import {
	AccountTransaction,
	ListAccountTransactionRequest,
	ListAccountTransactionResponse
} from './types';

export const URL = '/unum/accounting';

export const listAccountTransaction = async <T>(
	request: Exact<T, ListAccountTransactionRequest>
): Promise<AccountTransaction[]> => {
	// * this is "GET with BODY" request
	const response = await coreApi().post<ListAccountTransactionResponse>(
		URL + '/account/transaction/search',
		request
	);
	return convertToDateTime(response.data.transaction);
};
