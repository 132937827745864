import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "tw3-flex tw3-items-center tw3-justify-between tw3-gap-3 tw3-overflow-hidden tw3-max-w-full xl:tw3-max-w-[464px]" }
const _hoisted_2 = { class: "tw3-flex tw3-items-center tw3-gap-3 tw3-overflow-hidden tw3-w-full" }
const _hoisted_3 = {
  key: 0,
  class: "tw3-text-sm tw3-pt-[3px] tw3-whitespace-nowrap",
  style: {"color":"var(--p-button-text-warn-color)"}
}
const _hoisted_4 = { class: "tw3-flex tw3-items-center tw3-flex-col sm:tw3-flex-row tw3-gap-2 tw3-mt-5 tw3-max-w-full xl:tw3-max-w-[464px]" }
const _hoisted_5 = { class: "tw3-relative tw3-w-full tw3-pt-2 tw3-overflow-x-auto" }
const _hoisted_6 = { class: "tw3-absolute tw3-text-xs tw3-left-2 tw3-bg-white tw3-top-0 tw3-px-[0.25rem] tw3-rounded" }
const _hoisted_7 = { class: "tw3-relative tw3-w-full tw3-pt-2 tw3-overflow-x-auto" }
const _hoisted_8 = { class: "tw3-absolute tw3-text-xs tw3-left-2 tw3-bg-white tw3-top-0 tw3-px-[0.25rem] tw3-rounded" }
const _hoisted_9 = { class: "tw3-pt-2 tw3-min-w-full sm:tw3-min-w-[7.75rem]" }
const _hoisted_10 = { class: "md:tw3-whitespace-nowrap" }
const _hoisted_11 = { class: "tw3-flex tw3-items-center tw3-gap-2" }
const _hoisted_12 = { class: "tw3-flex tw3-items-center tw3-gap-2 tw3-ml-auto" }

import { storeToRefs } from 'pinia';
import Button from 'primevue/button';
import Card from 'primevue/card';
import Checkbox from 'primevue/checkbox';
import Column from 'primevue/column';
import DataTable, { DataTableRowReorderEvent } from 'primevue/datatable';
import InputText from 'primevue/inputtext';
import Select from 'primevue/select';
import ToggleButton from 'primevue/togglebutton';
import { useConfirm } from 'primevue/useconfirm';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import { useMessages } from '@/shared/composables';
import { isEmptyValue } from '@/shared/helpers';

import {
	allColumnsTableConf,
	defaultTableConf,
	JOB_TABLE_CONFIG,
	JOBS_FILTERS
} from '../../config';
import { useJobsStore } from '../../model/store';
import JobColumnBgPopup from '../popups/JobColumnBgPopup.vue';
import JobColumnFilterPopup from '../popups/JobColumnFilterPopup.vue';

type FilterKey = keyof typeof JOBS_FILTERS;


export default /*@__PURE__*/_defineComponent({
  __name: 'JobEditTable',
  emits: ["on-close"],
  setup(__props, { emit: __emit }) {

const { t } = useI18n();

const sortOptions = [
	{ name: t('ascending'), id: false },
	{ name: t('descending'), id: true }
];

const emit = __emit;

const store = useJobsStore();
const {
	viewChanges,
	view,
	updateViewLoading,
	updateViewApiLoading,
	loading,
	jobFiltersData
} = storeToRefs(store);

const message = useMessages();
const confirm = useConfirm();

const createDisabled = computed(() => {
	if (!view.value.selectedViewName) {
		return true;
	}
	const index = jobFiltersData.value.views.findIndex(
		v => v.name === view.value.selectedViewName
	);
	if (
		index !== -1 ||
		view.value.selectedViewName === defaultTableConf.name ||
		view.value.selectedViewName === allColumnsTableConf.name
	) {
		return true;
	}
	return false;
});

const onRowReorder = (event: DataTableRowReorderEvent) => {
	updateViewLoading.value = true;

	setTimeout(() => {
		view.value.selectedColumns = event.value;
		updateViewLoading.value = false;
	}, 100);
};

const getFilterIconClass = (field: string) => {
	const filter = view.value.filters[field as FilterKey];

	return filter && isEmptyValue(filter.val)
		? 'pi pi-filter'
		: 'pi pi-filter-fill';
};

const checkNameNotEmpty = () => {
	if (!view.value.selectedViewName) {
		message.showError(t('viewNotEmptyMsg'));
		return;
	}
};

const createButtonPressed = () => {
	checkNameNotEmpty();
	const index = jobFiltersData.value.views.findIndex(
		v => v.name === view.value.selectedViewName
	);
	if (
		index !== -1 ||
		view.value.selectedViewName === defaultTableConf.name ||
		view.value.selectedViewName === allColumnsTableConf.name
	) {
		message.showError(t('viewUniqueMsg'));
		return;
	}
	store.createOrUpdateView(-1);
};

const removeButtonPressed = async (v: number) => {
	confirm.require({
		accept: async () => {
			await store.deleteView(v);
		},
		acceptLabel: t('yes'),
		acceptProps: {
			severity: 'success'
		},
		header: t('removeView'),
		message: t('removeViewConfirm'),
		rejectProps: {
			severity: 'secondary'
		}
	});
};

return (_ctx: any,_cache: any) => {
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createBlock(_unref(Card), {
    "pt:body:class": "tw3-overflow-y-auto tw3-h-full",
    "pt:content:class": " tw3-overflow-y-auto tw3-h-full",
    "pt:root:class": "tw3-z-50 tw3-w-full xl:tw3-w-auto tw3-h-full tw3-absolute tw3-right-0 tw3-overflow-y-auto"
  }, {
    title: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("span", null, _toDisplayString(_ctx.$t('edit')), 1),
          _createVNode(_unref(InputText), {
            modelValue: _unref(view).selectedViewName,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(view).selectedViewName) = $event)),
            class: "p-column-filter tw3-w-full !tw3-text-gray-700 !tw3-text-2xl !tw3-font-semibold !tw3-underline",
            disabled: _unref(loading) || _unref(updateViewLoading) || _unref(updateViewApiLoading),
            placeholder: "",
            style: {"padding":"0","border-width":"0","box-shadow":"none"},
            type: "text"
          }, null, 8, ["modelValue", "disabled"]),
          (_unref(viewChanges))
            ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.$t('unsavedChanges')), 1))
            : _createCommentVNode("", true)
        ]),
        _createVNode(_unref(Button), {
          icon: "pi pi-times",
          rounded: "",
          severity: "secondary",
          style: {"flex-shrink":"0"},
          text: "",
          type: "button",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (emit('on-close')))
        })
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_unref(Select), {
            class: "p-column-filter tw3-w-full",
            disabled: _unref(loading) || _unref(updateViewLoading) || _unref(updateViewApiLoading),
            loading: _unref(loading) || _unref(updateViewLoading) || _unref(updateViewApiLoading),
            modelValue: _unref(view).sort.field,
            optionLabel: "label",
            options: _unref(JOB_TABLE_CONFIG),
            optionValue: "key",
            "onUpdate:modelValue": _cache[2] || (_cache[2] = 
							v => _unref(store).handleSort({ field: v, desc: _unref(view).sort.desc })
						)
          }, null, 8, ["disabled", "loading", "modelValue", "options"]),
          _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t('sortColumn')), 1)
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createVNode(_unref(Select), {
            class: "p-column-filter tw3-w-full",
            disabled: _unref(loading) || _unref(updateViewLoading) || _unref(updateViewApiLoading),
            loading: _unref(loading) || _unref(updateViewLoading) || _unref(updateViewApiLoading),
            modelValue: _unref(view).sort.desc,
            optionLabel: "name",
            options: sortOptions,
            optionValue: "id",
            "onUpdate:modelValue": _cache[3] || (_cache[3] = 
							v => _unref(store).handleSort({ field: _unref(view).sort.field, desc: v })
						)
          }, null, 8, ["disabled", "loading", "modelValue"]),
          _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.$t('sortDirection')), 1)
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createVNode(_unref(ToggleButton), {
            class: "tw3-w-full",
            disabled: 
							_unref(loading) ||
							_unref(updateViewLoading) ||
							_unref(updateViewApiLoading) ||
							_unref(view).selectedView === _unref(defaultTableConf).id ||
							_unref(view).selectedView === _unref(allColumnsTableConf).id
						,
            modelValue: _unref(view).defaultView === _unref(view).selectedView,
            offLabel: "Set as Default",
            onLabel: "Default",
            "onUpdate:modelValue": _cache[4] || (_cache[4] = () => (_unref(view).defaultView = _unref(view).selectedView))
          }, null, 8, ["disabled", "modelValue"])
        ])
      ])
    ]),
    content: _withCtx(() => [
      _createVNode(_unref(DataTable), {
        class: "p-datatable",
        "pt:tablecontainer:style": "scrollbar-width: none",
        scrollable: "",
        scrollHeight: "flex",
        value: _unref(view).selectedColumns,
        onRowReorder: onRowReorder
      }, {
        default: _withCtx(() => [
          _createVNode(_unref(Column), {
            headerStyle: "width: 3rem",
            rowReorder: ""
          }),
          _createVNode(_unref(Column), {
            field: "label",
            header: "Column"
          }, {
            body: _withCtx(({ data }) => [
              _createElementVNode("p", _hoisted_10, _toDisplayString(data.label), 1)
            ]),
            _: 1
          }),
          _createVNode(_unref(Column), {
            field: "hidden",
            header: "Visible"
          }, {
            body: _withCtx(({ data, index }) => [
              _createVNode(_unref(Checkbox), {
                binary: "",
                disabled: 
								data.key === 'job_id' ||
								_unref(updateViewLoading) ||
								_unref(updateViewApiLoading)
							,
                modelValue: !data.hidden,
                "onUpdate:modelValue": 
								v => (_unref(view).selectedColumns[index].hidden = !v)
							
              }, null, 8, ["disabled", "modelValue", "onUpdate:modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_unref(Column), {
            field: "backgroundColor",
            header: "Color"
          }, {
            body: _withCtx(({ index }) => [
              _createVNode(JobColumnBgPopup, {
                id: _unref(view).selectedColumns[index].key,
                modelValue: _unref(view).selectedColumns[index].backgroundColor,
                "onUpdate:modelValue": ($event: any) => ((_unref(view).selectedColumns[index].backgroundColor) = $event),
                loading: _unref(updateViewLoading) || _unref(updateViewApiLoading)
              }, null, 8, ["id", "modelValue", "onUpdate:modelValue", "loading"])
            ]),
            _: 1
          }),
          _createVNode(_unref(Column), {
            field: "filter",
            header: "Filter"
          }, {
            body: _withCtx(({ data }) => [
              _createVNode(JobColumnFilterPopup, {
                id: data.key,
                icon: getFilterIconClass(data.key),
                loading: _unref(loading) || _unref(updateViewLoading) || _unref(updateViewApiLoading),
                modelValue: _unref(view).filters[data.key as FilterKey],
                name: data.label,
                onClear: 
								() =>
									_unref(store).updateFilters({
										..._unref(view).filters,
										[data.key as FilterKey]: _unref(JOBS_FILTERS)[data.key as FilterKey]
									})
							,
                "onUpdate:modelValue": 
								v =>
									_unref(store).updateFilters({
										..._unref(view).filters,
										[data.key as FilterKey]: v
									})
							
              }, null, 8, ["id", "icon", "loading", "modelValue", "name", "onClear", "onUpdate:modelValue"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["value"])
    ]),
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_11, [
        (
						_unref(view).selectedView !== _unref(defaultTableConf).id &&
						_unref(view).selectedView !== _unref(allColumnsTableConf).id
					)
          ? (_openBlock(), _createBlock(_unref(Button), {
              key: 0,
              disabled: _unref(loading) || _unref(updateViewLoading) || _unref(updateViewApiLoading),
              label: _ctx.$t('delete'),
              loading: _unref(loading) || _unref(updateViewLoading) || _unref(updateViewApiLoading),
              outlined: _unref(loading) || _unref(updateViewLoading) || _unref(updateViewApiLoading),
              severity: "danger",
              type: "button",
              onClick: _cache[5] || (_cache[5] = ($event: any) => (removeButtonPressed(_unref(view).selectedView)))
            }, null, 8, ["disabled", "label", "loading", "outlined"]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_12, [
          (
							_unref(view).selectedView !== _unref(defaultTableConf).id &&
							_unref(view).selectedView !== _unref(allColumnsTableConf).id
						)
            ? (_openBlock(), _createBlock(_unref(Button), {
                key: 0,
                disabled: 
							_unref(loading) ||
							_unref(updateViewLoading) ||
							_unref(updateViewApiLoading) ||
							!_unref(viewChanges)
						,
                label: _ctx.$t('update'),
                loading: _unref(loading) || _unref(updateViewLoading) || _unref(updateViewApiLoading),
                outlined: 
							_unref(loading) ||
							_unref(updateViewLoading) ||
							_unref(updateViewApiLoading) ||
							!_unref(viewChanges)
						,
                severity: "info",
                type: "button",
                onClick: _cache[6] || (_cache[6] = 
							() => {
								checkNameNotEmpty();
								_unref(store).createOrUpdateView(_unref(view).selectedView);
							}
						)
              }, null, 8, ["disabled", "label", "loading", "outlined"]))
            : _createCommentVNode("", true),
          _withDirectives(_createVNode(_unref(Button), {
            disabled: 
							_unref(loading) ||
							_unref(updateViewLoading) ||
							_unref(updateViewApiLoading) ||
							createDisabled.value
						,
            label: _ctx.$t('create'),
            loading: _unref(loading) || _unref(updateViewLoading) || _unref(updateViewApiLoading),
            outlined: _unref(loading) || _unref(updateViewLoading) || _unref(updateViewApiLoading),
            type: "button",
            onClick: createButtonPressed
          }, null, 8, ["disabled", "label", "loading", "outlined"]), [
            [_directive_tooltip, createDisabled.value ? _ctx.$t('uniqueViewName') : undefined]
          ])
        ])
      ])
    ]),
    _: 1
  }))
}
}

})