import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "install-report-table" }
const _hoisted_2 = { class: "table-group-subhead" }
const _hoisted_3 = ["colspan"]
const _hoisted_4 = { class: "table-head" }
const _hoisted_5 = ["id", "href"]
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = ["id", "innerHTML"]
const _hoisted_8 = {
  key: 3,
  class: "greyItalics tw3-ml-2"
}

import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import useReportsStore from '@/widgets/reportsElements/model/store';
import {
	convertGroupByToVendor,
	reportsGetters,
	splitReportDataByAttr
} from '@/widgets/reportsElements/model/utils/getters';

import TableRowSpacer from '../reportsHelpers/TableRowSpacer.vue';
import TableSubhead from '../reportsHelpers/TableSubhead.vue';
import TableTotals from '../reportsHelpers/TableTotals.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'InstallReportTable',
  props: {
    data: {}
  },
  setup(__props: any) {

const { t } = useI18n();

const props = __props;

const reportStore = useReportsStore();

const { currentColumns, currentReportType } = storeToRefs(reportStore);

const dataSplitedByShop = computed(() => {
	return splitReportDataByAttr('shop_name')(props.data);
});

const paidStatus = (status: string) => {
	if (status === 'PP') {
		return t('reportsPage.table.pp');
	} else if (status === 'FP') {
		return t('reportsPage.table.fp');
	} else {
		return t('reportsPage.table.unpaid');
	}
};

const splitByShopForTotals = (data: any) => {
	const typeName = convertGroupByToVendor(
		currentReportType.value.groupBy ?? ''
	);
	const returnArray: any = [];
	data?.map((d: any) => {
		d[typeName].forEach((jt: any) => {
			let totalJobCommission = 0;
			jt.job_gb_vendor_gb_vendor_types.forEach((jgv: any) => {
				totalJobCommission += jgv.commission_total || 0;
			});
			returnArray.push({
				...d,
				total_commission: totalJobCommission,
				[currentReportType.value.total_key as string]: totalJobCommission
			});
		});
	}) || [];
	return returnArray;
};

const curatedData = computed(() => {
	const newData: any = [];
	if (!props.data || !props.data?.length) return newData;
	const typeName = convertGroupByToVendor(
		currentReportType.value.groupBy ?? ''
	);
	for (let i = 0; i < props.data.length; i++) {
		if (props.data[i][typeName]?.length) {
			props.data[i][typeName].map((t: any) => {
				let totalJobCommission = 0;
				t.job_gb_vendor_gb_vendor_types.forEach((jgv: any) => {
					totalJobCommission += jgv.commission_total || 0;
				});
				newData.push({
					...props.data[i],
					[currentReportType.value.groupBy as string]: t.name,
					total_commission: totalJobCommission,
					[currentReportType.value.total_key as string]: totalJobCommission
				});
			});
		} else {
			newData.push({
				...props.data[i],
				[currentReportType.value.groupBy as string]: 'unassigned'
			});
		}
	}
	return newData;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("table", _hoisted_1, [
    _createElementVNode("tbody", null, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(dataSplitedByShop.value, (singleData, shop) => {
        return (_openBlock(), _createElementBlock(_Fragment, { key: shop }, [
          _createVNode(TableSubhead, {
            columns: _unref(currentColumns),
            data: splitByShopForTotals(singleData),
            label: shop,
            reportType: _unref(currentReportType),
            type: "shop"
          }, null, 8, ["columns", "data", "label", "reportType"]),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(splitReportDataByAttr)(
						_unref(currentReportType).groupBy ?? '',
						_unref(currentReportType).total_key
					)(singleData), (group_data, group) => {
            return (_openBlock(), _createElementBlock(_Fragment, {
              key: group + 'title'
            }, [
              _createElementVNode("tr", _hoisted_2, [
                _cache[0] || (_cache[0] = _createElementVNode("td", { class: "fake-cell" }, null, -1)),
                _createElementVNode("td", {
                  class: "cell",
                  colspan: _unref(currentColumns).length + 1
                }, _toDisplayString(group), 9, _hoisted_3)
              ]),
              _createElementVNode("tr", _hoisted_4, [
                _cache[1] || (_cache[1] = _createElementVNode("td", { class: "fake-cell" }, null, -1)),
                _cache[2] || (_cache[2] = _createElementVNode("td", { class: "fake-cell" }, null, -1)),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(currentColumns), (col) => {
                  return (_openBlock(), _createElementBlock("td", {
                    key: col.prop,
                    class: _normalizeClass(["cell", { dollar: col.dollar }])
                  }, _toDisplayString(col.label), 3))
                }), 128))
              ]),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(group_data, (row) => {
                return (_openBlock(), _createElementBlock("tr", {
                  key: group + row.id,
                  class: "table-row"
                }, [
                  _cache[3] || (_cache[3] = _createElementVNode("td", { class: "fake-cell" }, null, -1)),
                  _cache[4] || (_cache[4] = _createElementVNode("td", { class: "fake-cell" }, null, -1)),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(currentColumns), (col) => {
                    return (_openBlock(), _createElementBlock("td", {
                      key: col.prop,
                      class: _normalizeClass(["cell", { dollar: col.dollar, strong: col.strong }])
                    }, [
                      (col.link)
                        ? (_openBlock(), _createElementBlock("a", {
                            key: 0,
                            id: row.id,
                            class: "tw3-whitespace-nowrap",
                            href: `/jobs/${row.id}`
                          }, _toDisplayString(row[col.prop]), 9, _hoisted_5))
                        : (col.dollar)
                          ? (_openBlock(), _createElementBlock("div", {
                              key: 1,
                              class: "tw3-text-right",
                              innerHTML: 
									_unref(reportsGetters).formatNumber(
										row[col.prop] !== null ? row[col.prop] : 0,
										{
											style: 'dollar'
										}
									)
								
                            }, null, 8, _hoisted_6))
                          : (_openBlock(), _createElementBlock("div", {
                              key: 2,
                              id: row.id,
                              class: _normalizeClass({
									'tw3-whitespace-nowrap': col.prop === 'time'
								}),
                              innerHTML: row[col.prop]
                            }, null, 10, _hoisted_7)),
                      (col.prop === 'invoice_num')
                        ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(paidStatus(row.paid)), 1))
                        : _createCommentVNode("", true)
                    ], 2))
                  }), 128))
                ]))
              }), 128)),
              _createVNode(TableTotals, {
                columns: _unref(currentColumns),
                data: group_data,
                fake: 2
              }, null, 8, ["columns", "data"]),
              _createVNode(TableRowSpacer, {
                columns: _unref(currentColumns),
                fake: 2
              }, null, 8, ["columns"])
            ], 64))
          }), 128)),
          _createVNode(TableTotals, {
            columns: _unref(currentColumns),
            data: splitByShopForTotals(singleData),
            fake: 2,
            label: "Shop"
          }, null, 8, ["columns", "data"]),
          _createVNode(TableRowSpacer, {
            columns: _unref(currentColumns),
            fake: 2,
            shop: ""
          }, null, 8, ["columns"])
        ], 64))
      }), 128)),
      _createVNode(TableTotals, {
        columns: _unref(currentColumns),
        data: curatedData.value,
        fake: 2,
        label: "Grand"
      }, null, 8, ["columns", "data"])
    ])
  ]))
}
}

})