import { Address } from '@/entities/address/lib/types';
import { CustomerTable } from '@/entities/customer/lib/types';
import { coreApi } from '@/shared/api';

import type {
	Customer,
	CustomerJobs,
	CustomerRequestParams,
	CustomerResponse,
	EdiPartners,
	Option
} from './types';

const CUSTOMER_URL = '/unum/consumer';
const EDI_PARTNERS_URL = '/unum/job-details/edi/partners';
export const EXPORT_CUSTOMER_URL = '/unum/consumer/list/export';

export const getMany = async (
	params: CustomerRequestParams
): Promise<CustomerResponse<CustomerTable>> => {
	try {
		const response = await coreApi().post<CustomerResponse<CustomerTable>>(
			`${CUSTOMER_URL}/list`,
			params
		);

		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
};

export const exportMany = async (
	params: CustomerRequestParams
): Promise<{
	csvExport: string;
	csvUrl: string[];
}> => {
	try {
		const response = await coreApi().post<{
			csvExport: string;
			csvUrl: string[];
		}>(EXPORT_CUSTOMER_URL, params);

		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
};

export const getOne = async (id: number): Promise<Customer> => {
	try {
		const response = await coreApi().get<Customer>(
			`${CUSTOMER_URL}/list/${id}`
		);

		return {
			...response.data,
			emails: response.data.emails.filter(v => !!v.email),
			phones: response.data.phones.filter(v => !!v.number),
			addresses: response.data.addresses.filter(v => !!v.full_address),
			names: response.data.names.filter(v => !!v.first)
		};
	} catch (error) {
		console.error(error);
		throw error;
	}
};

export const getAddress = async (
	id: number,
	addressId: number
): Promise<Address> => {
	try {
		const response = await coreApi().get<Address>(
			`${CUSTOMER_URL}/list/${id}/detail/g_address/${addressId}`
		);

		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
};

export const getPreviousJobs = async (id: number): Promise<CustomerJobs[]> => {
	try {
		const response = await coreApi().get<CustomerJobs[]>(
			`${CUSTOMER_URL}/job/${id}`
		);

		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
};

export const getEdiPartners = async (v: string): Promise<EdiPartners[]> => {
	try {
		const response = await coreApi().get<EdiPartners[]>(
			`${EDI_PARTNERS_URL}/${v}`
		);

		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
};

export const getBillTo = async (
	organization_id: number,
	offset: number,
	term?: string
): Promise<{
	rows: (Option & { trading_partner: string })[];
	count: number;
}> => {
	try {
		const response = await coreApi().get<{
			rows: (Option & { trading_partner: string })[];
			count: number;
		}>(
			`${CUSTOMER_URL}/search/q/?term=${term}&type=insurance&organization_id=${organization_id}&offset=${offset}`
		);

		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
};
