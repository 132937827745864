import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, createTextVNode as _createTextVNode, isRef as _isRef, normalizeClass as _normalizeClass, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "tw3-flex tw3-flex-col tw3-gap-4 tw3-justify-between tw3-h-full" }
const _hoisted_2 = { class: "tw3-flex tw3-h-full tw3-flex-col tw3-justify-start tw3-items-start tw3-flex-wrap tw3-gap-3" }
const _hoisted_3 = { class: "tw3-grid tw3-grid-cols-2 tw3-gap-4 tw3-w-full" }
const _hoisted_4 = { class: "tw3-flex-col tw3-flex tw3-gap-2" }
const _hoisted_5 = { class: "tw3-text-sm" }
const _hoisted_6 = { class: "tw3-flex tw3-flex-col tw3-gap-2" }
const _hoisted_7 = { class: "tw3-text-sm" }
const _hoisted_8 = { class: "tw3-grid tw3-grid-cols-2 tw3-gap-4 tw3-w-full" }
const _hoisted_9 = { class: "tw3-flex tw3-flex-col tw3-gap-2" }
const _hoisted_10 = { class: "tw3-text-sm" }
const _hoisted_11 = { class: "tw3-flex-col tw3-flex tw3-gap-2" }
const _hoisted_12 = { class: "tw3-text-sm" }
const _hoisted_13 = { class: "tw3-flex-col tw3-flex tw3-gap-2" }
const _hoisted_14 = { class: "tw3-text-sm tw3-flex tw3-justify-between" }
const _hoisted_15 = { class: "tw3-font-medium tw3-text-red-600" }
const _hoisted_16 = { class: "error-message" }
const _hoisted_17 = { class: "tw3-flex tw3-gap-2 tw3-items-center tw3-justify-end" }
const _hoisted_18 = { class: "tw3-flex tw3-gap-2 tw3-items-center tw3-justify-self-start tw3-mr-auto tw3-ml-0" }
const _hoisted_19 = { class: "tw3-leading-none" }

import { toTypedSchema } from '@vee-validate/zod';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import Editor from 'primevue/editor';
import InputText from 'primevue/inputtext';
import Select from 'primevue/select';
import Tab from 'primevue/tab';
import TabList from 'primevue/tablist';
import TabPanel from 'primevue/tabpanel';
import TabPanels from 'primevue/tabpanels';
import Tabs from 'primevue/tabs';
import ToggleSwitch from 'primevue/toggleswitch';
import { useToast } from 'primevue/usetoast';
import { ErrorMessage, useField, useForm } from 'vee-validate';
import { ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import * as zod from 'zod';

import { useUserStore } from '@/entities/user/store';
import { InputTextWithError } from '@/shared/ui';
import {
	createMessageTemplate,
	updateMessageTemplate
} from '@/widgets/reportsElements/api/api';
import { textMssageTemplatestabList } from '@/widgets/reportsElements/model/constants';


export default /*@__PURE__*/_defineComponent({
  __name: 'TemplateEditor',
  props: /*@__PURE__*/_mergeModels({
    template: {},
    templatesTypes: {}
  }, {
    "show": { type: Boolean },
    "showModifiers": {},
  }),
  emits: /*@__PURE__*/_mergeModels(['updateTemplates'], ["update:show"]),
  setup(__props: any, { emit: __emit }) {

const { t } = useI18n();

const toast = useToast();
const userStore = useUserStore();
const shops = userStore.user.shops;

const props = __props;

const emit = __emit;

const templateModalVisible = _useModel<boolean>(__props, 'show');

const currentTab = ref<string>('customer');
const templateSubject = ref<any>(null);
const primeEditor = ref();
const textAreaFocus = ref(false);
const subjectFocus = ref(false);

const forceUpdate = ref(0);

const copyVariable = (event: Event) => {
	const target = event.target as HTMLElement;
	const variableText = target.innerText;

	navigator.clipboard
		.writeText(variableText)
		.then(() => {
			if (subjectFocus.value) {
				const subjectWrapper = templateSubject.value;

				if (!subjectWrapper) {
					return;
				}
				const subjectArea = subjectWrapper.$el;

				const cursorPos = subjectArea.selectionStart || 0;
				const tempText = subject.value || '';

				subject.value =
					tempText.substring(0, cursorPos) +
					variableText +
					tempText.substring(subjectArea.selectionEnd || 0, tempText.length);

				forceUpdate.value++;

				setTimeout(() => {
					subjectArea.focus();
					const newCursorPos = cursorPos + variableText.length;
					subjectArea.selectionStart = subjectArea.selectionEnd = newCursorPos;
				}, 10);
			} else {
				toast.add({
					life: 3000,
					severity: 'success',
					detail: t('copyTextToast.succesDetails'),
					summary: t('copyTextToast.succesSummary')
				});
			}
		})
		.catch(err => {
			toast.add({
				life: 3000,
				severity: 'error',
				summary: t('copyTextToast.errorSummary'),
				detail: `${t('copyTextToast.errorDetails')} ${err}`
			});
		});
};

let quillInstance: any = null;

const onEditorLoad = ({ instance }: { instance: any }) => {
	quillInstance = instance;
};

watch(props, async () => {
	if (props.template) {
		if (primeEditor.value) {
			updateEditorContent(props.template?.body || '');
		}
		name.value = props.template.name;
		type_id.value = props.template.type_id;
		shop_id.value = props.template.shop_id;
		subject.value = props.template.subject;
		defaultField.value = props.template.default === 1;
	}
});

const updateEditorContent = (value: string) => {
	if (quillInstance) {
		quillInstance.setContents(
			quillInstance.clipboard.convert({
				html: value
			})
		);
	}
};

const closeForm = () => {
	templateModalVisible.value = false;

	name.value = '';
	type_id.value = 0;
	shop_id.value = 0;
	subject.value = '';
	defaultField.value = false;
	updateEditorContent('');
};

function focusTextarea() {
	textAreaFocus.value = true;
	subjectFocus.value = false;
}
function focusSubject() {
	textAreaFocus.value = false;
	subjectFocus.value = true;
}

const validationSchema = toTypedSchema(
	zod.object({
		name: zod
			.string()
			.min(3, t('reportsPage.textMessagesTemplate.errors.name')),
		body: zod
			.string()
			.min(10, t('reportsPage.textMessagesTemplate.errors.body')),
		type_id: zod
			.number()
			.int()
			.positive(t('reportsPage.textMessagesTemplate.errors.type_id')),
		shop_id: zod
			.number()
			.int()
			.positive(t('reportsPage.textMessagesTemplate.errors.shop_id')),
		subject: zod
			.string()
			.min(3, t('reportsPage.textMessagesTemplate.errors.subject')),
		defaultField: zod.boolean()
	})
);

const formObject = useForm({
	initialValues: {
		name: '',
		body: '',
		subject: '',
		type_id: 0,
		shop_id: 0,
		defaultField: false
	},
	validationSchema: validationSchema
});

const { value: name, errorMessage: nameError } = useField<string>('name');
const { value: type_id, errorMessage: typeError } = useField<number>('type_id');
const { value: shop_id, errorMessage: shopError } = useField<number>('shop_id');
const { value: subject, errorMessage: subjectError } =
	useField<string>('subject');
const { value: body, errorMessage: bodyError } = useField<string>('body');
const { value: defaultField } = useField<boolean>('default');

const isFormLoading = ref(false);
const submitForm = async () => {
	const result = await formObject.validate();

	if (result.valid) {
		isFormLoading.value = true;
		try {
			if (props.template) {
				await updateMessageTemplate(props.template?.id, {
					...result.values,
					default: 1
				});
			} else {
				await createMessageTemplate({
					...result.values,
					default: 1
				});
			}
			isFormLoading.value = false;
			emit('updateTemplates');
			closeForm();

			toast.add({
				life: 3000,
				severity: 'success',
				detail: !props.template
					? t('reportsPage.textMessagesTemplate.created')
					: t('reportsPage.textMessagesTemplate.updated'),
				summary: t('defaultToast.successSummary')
			});
		} catch (error) {
			toast.add({
				life: 3000,
				severity: 'error',
				detail: `${t('defaultToast.errorDetails')}: ${error}`,
				summary: t('defaultToast.errorSummary')
			});
			isFormLoading.value = false;
		}
		isFormLoading.value = false;
	} else {
		toast.add({
			life: 3000,
			severity: 'error',
			detail: t('reportsPage.textMessagesTemplate.fillRequired'),
			summary: t('defaultToast.errorSummary')
		});
	}
	forceUpdate.value++;
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(Dialog), {
    id: "TextTemplateSettings",
    visible: templateModalVisible.value,
    "onUpdate:visible": _cache[6] || (_cache[6] = ($event: any) => ((templateModalVisible).value = $event)),
    appendTo: "#vue3app",
    class: "tw3-max-w-[1200px] tw3-h-full tw3-w-full",
    header: 
			props.template
				? _unref(t)('reportsPage.textMessagesTemplate.updateTemplate')
				: _unref(t)('reportsPage.textMessagesTemplate.createTemplate')
		,
    modal: "",
    "pt:content": 'tw3-h-full tw3-flex tw3-flex-col'
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_unref(Tabs), {
          class: "!tw3-h-full",
          value: currentTab.value
        }, {
          default: _withCtx(() => [
            _createVNode(_unref(TabList), null, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(textMssageTemplatestabList), (item) => {
                  return (_openBlock(), _createBlock(_unref(Tab), {
                    key: item.key,
                    "pt:root:class": '!tw3-pt-[16px]',
                    value: item.key
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("span", null, _toDisplayString(item.title), 1)
                    ]),
                    _: 2
                  }, 1032, ["value"]))
                }), 128))
              ]),
              _: 1
            }),
            _createVNode(_unref(TabPanels), {
              class: "!tw3-p-0 tw3-h-full",
              style: {"height":"calc(100% - 42px)"}
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(textMssageTemplatestabList), (item) => {
                  return (_openBlock(), _createBlock(_unref(TabPanel), {
                    key: item.key,
                    class: "tw3-h-full tw3-p-6 tw3-flex tw3-flex-col tw3-gap-4",
                    value: item.key
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_2, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.data, (paragraph) => {
                          return (_openBlock(), _createElementBlock("p", {
                            key: paragraph.value,
                            class: "tw3-w-fil tw3-h-fit tw3-block"
                          }, [
                            _createTextVNode(_toDisplayString(paragraph.key) + ": ", 1),
                            _createElementVNode("span", {
                              class: "text-message-template-variable",
                              onClick: copyVariable
                            }, _toDisplayString(paragraph.value), 1)
                          ]))
                        }), 128))
                      ])
                    ]),
                    _: 2
                  }, 1032, ["value"]))
                }), 128))
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["value"]),
        _createElementVNode("form", {
          class: "tw3-flex tw3-flex-col tw3-gap-2",
          onSubmit: _withModifiers(submitForm, ["prevent"])
        }, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("span", _hoisted_5, _toDisplayString(_unref(t)('name')), 1),
              _createVNode(_unref(InputTextWithError), {
                modelValue: _unref(name),
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(name) ? (name).value = $event : null)),
                invalid: _unref(nameError),
                placeholder: `${_unref(t)('reportsPage.enterAName')}...`,
                onFocus: focusTextarea
              }, null, 8, ["modelValue", "invalid", "placeholder"])
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("span", _hoisted_7, _toDisplayString(_unref(t)('type')), 1),
              _createVNode(_unref(Select), {
                modelValue: _unref(type_id),
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_isRef(type_id) ? (type_id).value = $event : null)),
                class: "w-full md:w-56",
                invalid: _unref(typeError) ? true : false,
                optionLabel: "name",
                options: _ctx.templatesTypes,
                optionValue: "id",
                placeholder: `${_unref(t)('select')}...`
              }, null, 8, ["modelValue", "invalid", "options", "placeholder"])
            ])
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("span", _hoisted_10, _toDisplayString(_unref(t)('shop')), 1),
              _createVNode(_unref(Select), {
                modelValue: _unref(shop_id),
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_isRef(shop_id) ? (shop_id).value = $event : null)),
                class: "w-full md:w-56",
                invalid: _unref(shopError) ? true : false,
                optionLabel: "name",
                options: _unref(shops),
                optionValue: "id",
                placeholder: `${_unref(t)('select')} ${_unref(t)('shop')}...`
              }, null, 8, ["modelValue", "invalid", "options", "placeholder"])
            ]),
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("span", _hoisted_12, _toDisplayString(_unref(t)('subject')), 1),
              _createVNode(_unref(InputText), {
                ref_key: "templateSubject",
                ref: templateSubject,
                modelValue: _unref(subject),
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_isRef(subject) ? (subject).value = $event : null)),
                invalid: _unref(subjectError) ? true : false,
                placeholder: `${_unref(t)('reportsPage.textMessagesTemplate.subject')}...`,
                onFocus: focusSubject
              }, null, 8, ["modelValue", "invalid", "placeholder"])
            ])
          ]),
          _createElementVNode("div", _hoisted_13, [
            _createElementVNode("span", _hoisted_14, [
              _createTextVNode(_toDisplayString(_unref(t)('reportsPage.textMessagesTemplate.body')) + " ", 1),
              _createElementVNode("p", null, [
                _createElementVNode("span", _hoisted_15, _toDisplayString(_unref(t)('noteText')), 1),
                _createTextVNode(" " + _toDisplayString(_unref(t)('reportsPage.textMessagesTemplate.note')), 1)
              ])
            ]),
            _createVNode(_unref(Editor), {
              ref_key: "primeEditor",
              ref: primeEditor,
              modelValue: _unref(body),
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (_isRef(body) ? (body).value = $event : null)),
              class: _normalizeClass(["tw3-col-span-12", { 'input-error': _unref(bodyError) }]),
              defaultValue: _ctx.template?.body,
              editorStyle: "height: 250px",
              placeholder: `${_unref(t)('reportsPage.textMessagesTemplate.templateBody')}...`,
              onLoad: onEditorLoad,
              onSelectionChange: focusTextarea
            }, null, 8, ["modelValue", "class", "defaultValue", "placeholder"]),
            _createElementVNode("span", _hoisted_16, _toDisplayString(_unref(bodyError)), 1)
          ]),
          _createElementVNode("div", _hoisted_17, [
            _createElementVNode("div", _hoisted_18, [
              _createVNode(_unref(ToggleSwitch), {
                modelValue: _unref(defaultField),
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (_isRef(defaultField) ? (defaultField).value = $event : null))
              }, null, 8, ["modelValue"]),
              _createElementVNode("p", _hoisted_19, _toDisplayString(_unref(t)('reportsPage.textMessagesTemplate.default')), 1),
              _createVNode(_unref(ErrorMessage), {
                class: "error-message",
                name: "default"
              })
            ]),
            _createVNode(_unref(Button), {
              class: "tw3-w-fit",
              label: "Cancel",
              severity: "secondary",
              onClick: closeForm
            }),
            _createVNode(_unref(Button), {
              class: "tw3-w-fit",
              label: props.template ? _unref(t)('update') : _unref(t)('create'),
              loading: isFormLoading.value,
              type: "submit"
            }, null, 8, ["label", "loading"])
          ])
        ], 32)
      ])
    ]),
    _: 1
  }, 8, ["visible", "header"]))
}
}

})