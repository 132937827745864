<script setup lang="ts">
import { storeToRefs } from 'pinia';
import ProgressSpinner from 'primevue/progressspinner';
import { computed, onMounted, onUnmounted, ref } from 'vue';

import { useUserStore } from '@/entities/user/store';

import { CreateAccount } from '../createAccount';

const isMob = ref(false);
const contentWidth = ref(48);

const left = computed(() => {
	return isMob.value && contentWidth.value < 110
		? contentWidth.value / 2
		: contentWidth.value;
});

const updatePosition = (entries: ResizeObserverEntry[]) => {
	contentWidth.value =
		entries[0].contentRect.width > 0 ? entries[0].contentRect.width : 48;
};

const resizeObserver = new ResizeObserver(updatePosition);
const resizeHandle = () => {
	isMob.value = window.innerWidth < 600;
};

onMounted(() => {
	const interval = setInterval(() => {
		const el = document.getElementById('main-sidebar');
		if (el === null) {
			return;
		}
		resizeObserver.observe(el!);
		clearInterval(interval);
		resizeHandle();
		window.addEventListener('resize', resizeHandle);
	}, 500);
});

onUnmounted(() => {
	resizeObserver.disconnect();
	window.removeEventListener('resize', resizeHandle);
});

const { user } = storeToRefs(useUserStore());

const blocked = computed(() => {
	return !user.value.user.id;
});

const showAccountCreate = computed(() => {
	return (
		!blocked.value &&
		!user.value.user.account_id &&
		window.location.pathname !== '/sign-up/finish'
	);
});
</script>

<template>
	<div
		id="vue3-main-container"
		class="px-4 py-3"
		:style="{ left: `${left}px` }"
	>
		<div v-if="blocked" class="tw3-flex tw3-flex-grow tw3-items-center">
			<ProgressSpinner />
		</div>
		<CreateAccount v-else-if="showAccountCreate" />
		<router-view v-else />
	</div>
</template>

<style>
#vue3-main-container {
	display: flex;
	flex-direction: column;
	position: absolute;
	top: 56px;
	left: 48px;
	right: 0px;
	bottom: 0px;
	z-index: 10;
	overflow: auto;
	min-height: 400px;
}
</style>
