<script setup lang="ts">
import Tag from 'primevue/tag';
import { useI18n } from 'vue-i18n';

import { FaxStatus } from '../model/types';

const { t } = useI18n();

const faxStatuses: { [key in FaxStatus]: string } = {
	UNREAD: t('faxDialog.status.unread'),
	READ: t('faxDialog.status.read'),
	SENT: t('faxDialog.status.sent'),
	ERROR: t('faxDialog.status.error'),
	SENDING: t('faxDialog.status.sending'),
	QUEUED: t('faxDialog.status.queued'),
	BURST: t('faxDialog.status.burst')
};

const faxStatusesFullInfo: { [key in FaxStatus]: string } = {
	UNREAD: t('faxDialog.statusResponses.unread'),
	READ: t('faxDialog.statusResponses.read'),
	SENT: t('faxDialog.statusResponses.sent'),
	ERROR: t('faxDialog.statusResponses.error'),
	SENDING: t('faxDialog.status.sending'),
	QUEUED: t('faxDialog.status.queued'),
	BURST: t('faxDialog.status.burst')
};

const mappedStatus = {
	[FaxStatus.SENT]: 'success',
	[FaxStatus.UNREAD]: 'info',
	[FaxStatus.READ]: 'success',
	[FaxStatus.ERROR]: 'danger',
	[FaxStatus.SENDING]: 'info',
	[FaxStatus.QUEUED]: 'warn',
	[FaxStatus.BURST]: 'warn'
};

defineProps<{
	status: FaxStatus;
}>();
</script>
<template>
	<Tag
		v-tooltip="faxStatusesFullInfo[status]"
		class="tw3-capitalize"
		:severity="mappedStatus[status]"
		:value="faxStatuses[status]"
	></Tag>
</template>
