import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, Suspense as _Suspense, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 0,
  class: "image"
}
const _hoisted_3 = ["src"]
const _hoisted_4 = {
  key: 1,
  class: "content"
}
const _hoisted_5 = ["data", "type"]
const _hoisted_6 = { key: 1 }
const _hoisted_7 = {
  key: 2,
  class: "details tw3-mt-5"
}
const _hoisted_8 = {
  key: 0,
  class: "tw3-flex tw3-gap-3 tw3-justify-between tw3-py-2 tw3-border-b tw3-border-gray-300 tw3-w-full"
}
const _hoisted_9 = { class: "tw3-text-lg tw3-font-bold tw3-text-gray-500 tw3-whitespace-nowrap" }
const _hoisted_10 = { class: "tw3-text-lg tw3-max-w-9/12 tw3-text-right" }
const _hoisted_11 = {
  key: 1,
  class: "tw3-flex tw3-gap-3 tw3-justify-between tw3-py-2 tw3-border-b tw3-border-gray-300 tw3-w-full"
}
const _hoisted_12 = { class: "tw3-text-lg tw3-font-bold tw3-text-gray-500 tw3-whitespace-nowrap" }
const _hoisted_13 = { class: "tw3-text-lg tw3-max-w-9/12 tw3-text-right" }
const _hoisted_14 = {
  key: 2,
  class: "tw3-flex tw3-gap-3 tw3-justify-between tw3-py-2 tw3-border-b tw3-border-gray-300 tw3-w-full"
}
const _hoisted_15 = { class: "tw3-text-lg tw3-font-bold tw3-text-gray-500 tw3-whitespace-nowrap" }
const _hoisted_16 = { class: "tw3-text-lg tw3-max-w-9/12 tw3-text-right" }
const _hoisted_17 = {
  key: 3,
  class: "tw3-flex tw3-gap-3 tw3-justify-between tw3-py-2 tw3-border-b tw3-border-gray-300 tw3-w-full"
}
const _hoisted_18 = { class: "tw3-text-lg tw3-font-bold tw3-text-gray-500 tw3-whitespace-nowrap" }
const _hoisted_19 = { class: "tw3-text-lg tw3-max-w-9/12 tw3-text-right" }
const _hoisted_20 = {
  key: 4,
  class: "tw3-flex tw3-gap-3 tw3-justify-between tw3-py-2 tw3-border-b tw3-border-gray-300 tw3-w-full"
}
const _hoisted_21 = { class: "tw3-text-lg tw3-font-bold tw3-text-gray-500 tw3-whitespace-nowrap" }
const _hoisted_22 = { class: "tw3-text-lg tw3-max-w-9/12 tw3-text-right" }
const _hoisted_23 = { class: "tw3-flex tw3-flex-wrap tw3-mt-5 tw3-gap-3 tw3-justify-end tw3-w-full" }

import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import { computed, onMounted, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';

import { formatFileSize } from '@/shared/helpers';
import { FileInfoPreview, getFilePreviewInfo } from '@/shared/helpers/file';

import { documentsConfig } from '../constants/file';


export default /*@__PURE__*/_defineComponent({
  __name: 'FilePreview',
  props: /*@__PURE__*/_mergeModels({
    fileUrl: {},
    fileSettingsKey: {}
  }, {
    "show": { type: Boolean },
    "showModifiers": {},
  }),
  emits: ["update:show"],
  setup(__props: any) {

const { t } = useI18n();
const showModal = _useModel<boolean>(__props, 'show');
const props = __props;
const fileInfo = ref<FileInfoPreview>();

onMounted(async () => {
	fileInfo.value = await getFilePreviewInfo(
		props.fileUrl,
		props.fileSettingsKey
	);
});
const isImage = computed(() => {
	if (fileInfo.value) {
		return /image/.test(fileInfo.value.type);
	} else {
		return false;
	}
});
const documentConfig = computed(() =>
	fileInfo.value
		? documentsConfig[fileInfo.value.fileKey as keyof typeof documentsConfig]
		: {
				color: 'grey',
				tag: 'UNDEFINED',
				id: 'undefined',
				label: 'Document was not defined'
			}
);
watch(props, async () => {
	fileInfo.value = await getFilePreviewInfo(
		props.fileUrl,
		props.fileSettingsKey
	);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_Suspense, null, {
    default: _withCtx(() => [
      _createVNode(_unref(Dialog), {
        id: "vue3FilePreview",
        visible: showModal.value,
        "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((showModal).value = $event)),
        appendTo: "#vue3app",
        class: "tw3-max-w-[900px] tw3-w-full",
        header: `${_unref(t)('filePreview.preview')} - ${documentConfig.value.label}`,
        modal: "",
        "pt:content": 'tw3-h-full'
      }, {
        default: _withCtx(() => [
          (fileInfo.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                (isImage.value)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                      _createElementVNode("img", { src: _ctx.fileUrl }, null, 8, _hoisted_3)
                    ]))
                  : (_openBlock(), _createElementBlock("div", _hoisted_4, [
                      _createElementVNode("object", {
                        class: "tw3-w-full tw3-h-6/12 tw3-min-h-[450px]",
                        data: _ctx.fileUrl,
                        type: fileInfo.value.type
                      }, null, 8, _hoisted_5)
                    ]))
              ]))
            : (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_unref(t)('filePreview.notAvailable')), 1)),
          (fileInfo.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                (fileInfo.value.name)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                      _createElementVNode("div", _hoisted_9, _toDisplayString(_unref(t)('filePreview.fileName')) + ": ", 1),
                      _createElementVNode("div", _hoisted_10, _toDisplayString(fileInfo.value.name), 1)
                    ]))
                  : _createCommentVNode("", true),
                (fileInfo.value.type)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                      _createElementVNode("div", _hoisted_12, _toDisplayString(_unref(t)('filePreview.fileType')) + ": ", 1),
                      _createElementVNode("div", _hoisted_13, _toDisplayString(fileInfo.value.type), 1)
                    ]))
                  : _createCommentVNode("", true),
                (documentConfig.value.label)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                      _createElementVNode("div", _hoisted_15, _toDisplayString(_unref(t)('filePreview.docType')) + ": ", 1),
                      _createElementVNode("div", _hoisted_16, _toDisplayString(documentConfig.value.label), 1)
                    ]))
                  : _createCommentVNode("", true),
                (fileInfo.value.size)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                      _createElementVNode("div", _hoisted_18, _toDisplayString(_unref(t)('filePreview.fileSize')) + ": ", 1),
                      _createElementVNode("div", _hoisted_19, _toDisplayString(_unref(formatFileSize)(fileInfo.value.size)), 1)
                    ]))
                  : _createCommentVNode("", true),
                (fileInfo.value.date)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                      _createElementVNode("div", _hoisted_21, _toDisplayString(_unref(t)('filePreview.created')) + ": ", 1),
                      _createElementVNode("div", _hoisted_22, _toDisplayString(fileInfo.value.date), 1)
                    ]))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_23, [
                  _createVNode(_unref(Button), {
                    as: "a",
                    class: "!tw3-no-underline",
                    href: _ctx.fileUrl,
                    rel: "noopener",
                    severity: "secondary",
                    target: "_blank"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_unref(t)('filePreview.openInNewTab')), 1)
                    ]),
                    _: 1
                  }, 8, ["href"])
                ])
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["visible", "header"])
    ]),
    _: 1
  }))
}
}

})