export interface AuthService {
	getAuth(): Auth;
	getIdentity(): Identity;
	getVisitorFingerprintId(): string;
	getAccessToken(): string;
	getAuthorizationHeader(): string;
	isPublicRoute(): boolean;
	logout(): void;
	handleSuspendedFingerprint(): void;
}

type Auth = {
	access_token: string;
	refresh_token: string;
	expires_in: number;
	id_token: string;
	scope: string;
	token_type: string;
};

type Identity = {
	avatar: string;
	bio: string;
	displayName: string;
	email: string;
	emailVerified: boolean;
	exp: number;
	firstName: string;
	iat: number;
	id: string;
	isAdmin: boolean;
	isDeleted: boolean;
	isForbidden: boolean;
	iss: string;
	jti: string;
	lastName: string;
	location: string;
	name: string;
	nbf: number;
	nonce: string;
	phone: string;
	scope: string;
	sub: string;
	tag: string;
	tokenType: string;
};

let service: AuthService;

export function setAuthService(newService: AuthService): AuthService {
	service = newService;

	return service;
}

export function authService(): AuthService {
	if (!service) {
		throw new Error('AuthService has not been initialized.');
	}

	return service;
}
