import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  id: "vue3app"
}

import ConfirmDialog from 'primevue/confirmdialog';
import Toast from 'primevue/toast';
import { useRoute } from 'vue-router';

import { authService } from '@/shared/auth';
import DialogContainer from '@/widgets/dialogContainer/DialogContainer.vue';
import MainContainer from '@/widgets/mainContainer/MainContainer.vue';

import { routes } from './router';


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const route = useRoute();

return (_ctx: any,_cache: any) => {
  return (!_unref(authService)().isPublicRoute())
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (
				_unref(routes)
					.filter(v => v.path !== '/')
					.some(v => _unref(route).path.startsWith(v.path)) || _unref(route).path === '/'
			)
          ? (_openBlock(), _createBlock(MainContainer, { key: 0 }))
          : _createCommentVNode("", true),
        _createVNode(_unref(Toast), {
          position: "top-center",
          pt: { root: { style: 'top: 62px' } }
        }),
        _createVNode(_unref(ConfirmDialog)),
        _createVNode(DialogContainer)
      ]))
    : _createCommentVNode("", true)
}
}

})