import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "tw3-flex tw3-items-center tw3-gap-2 tw3-capitalize" }
const _hoisted_2 = { style: {"white-space":"nowrap"} }
const _hoisted_3 = { class: "tw3-flex tw3-items-center tw3-gap-2 tw3-capitalize" }
const _hoisted_4 = { style: {"white-space":"nowrap"} }

import { FilterMatchMode } from '@primevue/core';
import MultiSelect from 'primevue/multiselect';
import Select from 'primevue/select';
import Tag from 'primevue/tag';
import { computed, ref } from 'vue';

import {
	JobFilterTag,
	JobFilterTax
} from '../../model/types/job-filters.interface';

type DateFilter = {
	val?: { val_1?: string[] | null; val_2?: string[] | null } | null;
	type: string;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'JobTagTaxFilter',
  props: {
    selectedShopsById: {},
    selectedShopsByName: {},
    multiselectOptions: {},
    modelValue: {},
    name: {}
  },
  emits: ["update:modelValue"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emit = __emit;

const options = ref([
	{ name: 'All', id: FilterMatchMode.EQUALS },
	{ name: 'All Tags', id: FilterMatchMode.BETWEEN },
	{ name: 'Selected Shops Tags', id: FilterMatchMode.IN }
]);

const multiselectOptions = computed(() => {
	if (props.modelValue.type !== FilterMatchMode.IN) {
		return props.multiselectOptions;
	}

	if (!props.selectedShopsById?.length && !props.selectedShopsByName?.length) {
		return [];
	}

	const shopIds = new Set([
		...(props.selectedShopsById ? props.selectedShopsById : []),
		...(props.selectedShopsByName ? props.selectedShopsByName : [])
	]);

	const filteredTags = [];
	for (const option of props.multiselectOptions) {
		const tagShopIds = option.shop_ids.split(',');
		for (const shopId of shopIds) {
			for (const tagShopId of tagShopIds) {
				if (tagShopId + '' === shopId + '') {
					filteredTags.push(option);
					continue;
				}
			}
		}
	}

	return filteredTags;
});

const updateMatchMode = (v: string) => {
	emit('update:modelValue', { type: v, val: null });
};

const updateHas = (v: string[]) => {
	emit('update:modelValue', {
		type: props.modelValue.type,
		val:
			v || props.modelValue.val?.val_2
				? {
						val_1: v ? v : null,
						val_2: props.modelValue.val?.val_2
					}
				: null
	});
};

const updateDoesNotHave = (v: string[]) => {
	emit('update:modelValue', {
		type: props.modelValue.type,
		val:
			v || props.modelValue.val?.val_1
				? {
						val_1: props.modelValue.val?.val_1,
						val_2: v ? v : null
					}
				: null
	});
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_unref(Select), {
      appendTo: "self",
      class: "p-column-filter",
      modelValue: _ctx.modelValue.type,
      optionLabel: "name",
      options: options.value,
      optionValue: "id",
      "onUpdate:modelValue": updateMatchMode
    }, null, 8, ["modelValue", "options"]),
    (_ctx.modelValue.type !== _unref(FilterMatchMode).EQUALS)
      ? (_openBlock(), _createBlock(_unref(MultiSelect), {
          key: 0,
          appendTo: "self",
          class: "p-column-filter",
          filter: "",
          maxSelectedLabels: 1,
          modelValue: props.modelValue.val?.val_1,
          optionLabel: "name",
          options: multiselectOptions.value,
          optionValue: "id",
          placeholder: `Has ${_ctx.name}`,
          showClear: "",
          "onUpdate:modelValue": updateHas
        }, {
          option: _withCtx(({ option }) => [
            _createVNode(_unref(Tag), {
              severity: "secondary",
              style: _normalizeStyle({
					'border-left': option.color ? '10px solid black' : '',
					'border-color': option.color ?? 'var(--p-tag-secondary-background)'
				})
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, [
                  _createElementVNode("span", _hoisted_2, _toDisplayString(option.name), 1)
                ])
              ]),
              _: 2
            }, 1032, ["style"])
          ]),
          _: 1
        }, 8, ["modelValue", "options", "placeholder"]))
      : _createCommentVNode("", true),
    (_ctx.modelValue.type !== _unref(FilterMatchMode).EQUALS)
      ? (_openBlock(), _createBlock(_unref(MultiSelect), {
          key: 1,
          appendTo: "self",
          class: "p-column-filter",
          filter: "",
          maxSelectedLabels: 1,
          modelValue: props.modelValue.val?.val_2,
          optionLabel: "name",
          options: multiselectOptions.value,
          optionValue: "id",
          placeholder: `Does Not Have ${_ctx.name}`,
          showClear: "",
          "onUpdate:modelValue": updateDoesNotHave
        }, {
          option: _withCtx(({ option }) => [
            _createVNode(_unref(Tag), {
              severity: "secondary",
              style: _normalizeStyle({
					'border-left': option.color ? '10px solid black' : '',
					'border-color': option.color ?? 'var(--p-tag-secondary-background)'
				})
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("span", _hoisted_4, _toDisplayString(option.name), 1)
                ])
              ]),
              _: 2
            }, 1032, ["style"])
          ]),
          _: 1
        }, 8, ["modelValue", "options", "placeholder"]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})