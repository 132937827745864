import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "tw3-flex tw3-justify-center tw3-h-full" }
const _hoisted_2 = {
  key: 0,
  class: "tw3-h-full tw3-flex tw3-items-center"
}
const _hoisted_3 = {
  key: 1,
  class: "tw3-w-96 tw3-text-center"
}
const _hoisted_4 = {
  key: 0,
  class: "tw3-flex tw3-flex-col tw3-items-center"
}
const _hoisted_5 = { class: "tw3-text-gray-500 tw3-mb-8" }
const _hoisted_6 = {
  key: 1,
  class: "tw3-flex tw3-flex-col tw3-items-center"
}

import Button from 'primevue/button';
import ProgressSpinner from 'primevue/progressspinner';
import { onMounted } from 'vue';

import {
	AccountCreationStepValues,
	useCreateAccountStore
} from '@/widgets/createAccount';


export default /*@__PURE__*/_defineComponent({
  __name: 'FinishSignUpPage',
  setup(__props) {

const store = useCreateAccountStore();

onMounted(async () => {
	await store.submit();
});

const startOver = () => {
	store.changeStep(AccountCreationStepValues.organization);
	window.location.href = '/';
};

const startUsing = async () => {
	window.location.href = '/';
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_unref(store).isSubmitting)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_unref(ProgressSpinner))
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_3, [
          (_unref(store).error)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _cache[0] || (_cache[0] = _createElementVNode("div", { class: "tw3-w-12 tw3-h-12 tw3-rounded-full tw3-flex tw3-items-center tw3-justify-center tw3-bg-red-200 tw3-text-red-600 tw3-mb-8" }, [
                  _createElementVNode("i", { class: "pi pi-times" })
                ], -1)),
                _cache[1] || (_cache[1] = _createElementVNode("div", { class: "tw3-text-xl tw3-font-semibold tw3-mb-2" }, " Something went wrong ", -1)),
                _createElementVNode("div", _hoisted_5, _toDisplayString(_unref(store).error), 1),
                _createVNode(_unref(Button), {
                  fluid: "",
                  label: "Go back and try again",
                  severity: "info",
                  onClick: startOver
                })
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _cache[2] || (_cache[2] = _createElementVNode("div", { class: "tw3-w-12 tw3-h-12 tw3-rounded-full tw3-flex tw3-items-center tw3-justify-center tw3-bg-green-200 tw3-text-green-600 tw3-mb-8" }, [
                  _createElementVNode("i", { class: "pi pi-check" })
                ], -1)),
                _cache[3] || (_cache[3] = _createElementVNode("div", { class: "tw3-text-xl tw3-font-semibold tw3-mb-2" }, "All set!", -1)),
                _cache[4] || (_cache[4] = _createElementVNode("div", { class: "tw3-text-gray-500 tw3-mb-8" }, " Your Glassbiller account is ready to use. ", -1)),
                _createVNode(_unref(Button), {
                  fluid: "",
                  label: "Start using Glassbiller",
                  severity: "info",
                  onClick: startUsing
                })
              ]))
        ]))
  ]))
}
}

})