<script setup lang="ts">
import Button from 'primevue/button';
import ProgressSpinner from 'primevue/progressspinner';
import { onMounted } from 'vue';

import {
	AccountCreationStepValues,
	useCreateAccountStore
} from '@/widgets/createAccount';

const store = useCreateAccountStore();

onMounted(async () => {
	await store.submit();
});

const startOver = () => {
	store.changeStep(AccountCreationStepValues.organization);
	window.location.href = '/';
};

const startUsing = async () => {
	window.location.href = '/';
};
</script>

<template>
	<div class="tw3-flex tw3-justify-center tw3-h-full">
		<div v-if="store.isSubmitting" class="tw3-h-full tw3-flex tw3-items-center">
			<ProgressSpinner />
		</div>
		<div v-else class="tw3-w-96 tw3-text-center">
			<div v-if="store.error" class="tw3-flex tw3-flex-col tw3-items-center">
				<div
					class="tw3-w-12 tw3-h-12 tw3-rounded-full tw3-flex tw3-items-center tw3-justify-center tw3-bg-red-200 tw3-text-red-600 tw3-mb-8"
				>
					<i class="pi pi-times" />
				</div>
				<div class="tw3-text-xl tw3-font-semibold tw3-mb-2">
					Something went wrong
				</div>
				<div class="tw3-text-gray-500 tw3-mb-8">{{ store.error }}</div>
				<Button
					fluid
					label="Go back and try again"
					severity="info"
					@click="startOver"
				/>
			</div>
			<div v-else class="tw3-flex tw3-flex-col tw3-items-center">
				<div
					class="tw3-w-12 tw3-h-12 tw3-rounded-full tw3-flex tw3-items-center tw3-justify-center tw3-bg-green-200 tw3-text-green-600 tw3-mb-8"
				>
					<i class="pi pi-check" />
				</div>
				<div class="tw3-text-xl tw3-font-semibold tw3-mb-2">All set!</div>
				<div class="tw3-text-gray-500 tw3-mb-8">
					Your Glassbiller account is ready to use.
				</div>
				<Button
					fluid
					label="Start using Glassbiller"
					severity="info"
					@click="startUsing"
				/>
			</div>
		</div>
	</div>
</template>
