import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "tw3-w-full" }
const _hoisted_2 = { key: 0 }

import MultiSelect from 'primevue/multiselect';
import Select from 'primevue/select';
import { ref, watch } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'ReportsSelectFilter',
  props: {
    modelValue: { default: null },
    placeholder: { default: undefined },
    errMsg: { default: undefined },
    disabled: { type: Boolean },
    optionLabel: { default: '' },
    optionValue: { default: '' },
    dropdownOptions: {},
    required: { type: Boolean, default: false },
    filter: { type: Boolean, default: true },
    singleSelection: { type: Boolean, default: false },
    optionGroupChildren: { default: undefined },
    optionGroupLabel: { default: undefined },
    invalid: { type: Boolean }
  },
  emits: ["update:modelValue", "before-show"],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emit = __emit;

const internalValue = ref(props.modelValue);

const internalSingleValue = ref(props.modelValue?.[0] || null);

// const readableValue = computed(() => {
// 	let preparedString = '';
// 	if (
// 		!props.dropdownOptions ||
// 		props.singleSelection ||
// 		!Array.isArray(internalValue.value)
// 	)
// 		return;

// 	let isFirst = true;
// 	let count = 0;
// 	props.dropdownOptions.forEach(item => {
// 		if (props.optionGroupChildren && item[props.optionGroupChildren]) {
// 			item[props.optionGroupChildren].forEach(element => {
// 				if (count >= 25) {
// 					if (count == 25) {
// 						preparedString += `

// 						---- More ----

// 						`;
// 						count++;
// 					}

// 					return;
// 				}
// 				if (
// 					internalValue.value &&
// 					internalValue.value.includes(element[props.optionValue])
// 				) {
// 					preparedString += isFirst
// 						? ` ${element[props.optionLabel]}`
// 						: `, ${element[props.optionLabel]} `;
// 					isFirst = false;
// 					count++;
// 				}
// 			});
// 		} else {
// 			if (count >= 25) {
// 				if (count == 25) {
// 					preparedString += ', And more...';
// 					count++;
// 				}

// 				return;
// 			}
// 			if (
// 				internalValue.value &&
// 				internalValue.value.includes(item[props.optionValue])
// 			) {
// 				preparedString += isFirst
// 					? `${item[props.optionLabel]}`
// 					: `, ${item[props.optionLabel]}`;
// 				isFirst = false;
// 				count++;
// 			}
// 		}
// 	});

// 	return preparedString;
// });

watch(
	() => props.modelValue,
	newValue => {
		internalValue.value = newValue;
		internalSingleValue.value = newValue?.[0] || null;
	}
);

watch(
	() => props.singleSelection,
	single => {
		if (single) {
			internalSingleValue.value = internalValue.value?.[0] || null;
			if (props.modelValue && props.modelValue.length) {
				handleUpdate([internalSingleValue.value]);
			}
		} else {
			internalValue.value = internalSingleValue.value
				? [internalSingleValue.value]
				: [];
		}
	}
);

// Update handler for MultiSelect
const handleUpdate = (value: any[]) => {
	internalValue.value = value;
	emit('update:modelValue', value);
};

// Update handler for Select
const handleSingleUpdate = (value: any) => {
	internalSingleValue.value = value;
	emit('update:modelValue', value ? [value] : []);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.singleSelection && _ctx.dropdownOptions)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_unref(MultiSelect), {
            modelValue: internalValue.value,
            "onUpdate:modelValue": [
              _cache[0] || (_cache[0] = ($event: any) => ((internalValue).value = $event)),
              handleUpdate
            ],
            class: "tw3-w-full",
            disabled: _ctx.disabled,
            display: "chip",
            filter: _ctx.filter,
            invalid: !!_ctx.errMsg && _ctx.required,
            optionGroupChildren: _ctx.optionGroupChildren,
            optionGroupLabel: _ctx.optionGroupLabel,
            optionLabel: _ctx.optionLabel,
            optionValue: _ctx.optionValue,
            options: _ctx.dropdownOptions,
            placeholder: _ctx.placeholder,
            onBeforeShow: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('before-show')))
          }, null, 8, ["modelValue", "disabled", "filter", "invalid", "optionGroupChildren", "optionGroupLabel", "optionLabel", "optionValue", "options", "placeholder"])
        ]))
      : (_ctx.dropdownOptions)
        ? (_openBlock(), _createBlock(_unref(Select), {
            key: 1,
            modelValue: internalSingleValue.value,
            "onUpdate:modelValue": [
              _cache[2] || (_cache[2] = ($event: any) => ((internalSingleValue).value = $event)),
              handleSingleUpdate
            ],
            class: "tw3-w-full",
            disabled: _ctx.disabled,
            display: "chip",
            filter: _ctx.filter,
            invalid: !!_ctx.errMsg && _ctx.required,
            optionLabel: _ctx.optionLabel,
            optionValue: _ctx.optionValue,
            options: _ctx.dropdownOptions,
            placeholder: _ctx.placeholder,
            onBeforeShow: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('before-show')))
          }, null, 8, ["modelValue", "disabled", "filter", "invalid", "optionLabel", "optionValue", "options", "placeholder"]))
        : _createCommentVNode("", true)
  ]))
}
}

})