import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

import TableHead from './TableHead.vue';
import TableRow from './TableRow.vue';
import TableTotals from './TableTotals.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'TableDefaultBody',
  props: {
    showTotal: { type: Boolean },
    data: {},
    columns: {},
    fake: {},
    totalLabel: {}
  },
  setup(__props: any) {



const displayFortyPercentHeader = (
	data: {
		forty_percent?: boolean;
	}[]
) => {
	if (data && data.length) {
		if (data[0].forty_percent) {
			return true;
		}
	}
	return false;
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.columns && _ctx.data)
      ? (_openBlock(), _createBlock(TableHead, {
          key: 0,
          columns: _ctx.columns,
          displayFortyPercentHeader: displayFortyPercentHeader(_ctx.data),
          fake: _ctx.fake
        }, null, 8, ["columns", "displayFortyPercentHeader", "fake"]))
      : _createCommentVNode("", true),
    (_ctx.columns && _ctx.data)
      ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.data, (row) => {
          return (_openBlock(), _createBlock(TableRow, {
            key: row.id,
            columns: _ctx.columns,
            data: row,
            fake: _ctx.fake
          }, null, 8, ["columns", "data", "fake"]))
        }), 128))
      : _createCommentVNode("", true),
    (_ctx.showTotal && _ctx.columns && _ctx.data)
      ? (_openBlock(), _createBlock(TableTotals, {
          key: 2,
          columns: _ctx.columns,
          data: _ctx.data,
          fake: _ctx.fake,
          label: _ctx.totalLabel
        }, null, 8, ["columns", "data", "fake", "label"]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})