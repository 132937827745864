import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "tw3-relative" }
const _hoisted_2 = { class: "tw3-w-[195px] tw3-px-[0.75rem]" }
const _hoisted_3 = { class: "tw3-relative" }
const _hoisted_4 = { class: "tw3-w-[55px]" }
const _hoisted_5 = { class: "tw3-w-[55px]" }
const _hoisted_6 = { class: "tw3-w-[102px] tw3-px-[0.75rem]" }
const _hoisted_7 = { class: "tw3-w-[111px] tw3-px-[0.75rem]" }
const _hoisted_8 = { class: "tw3-w-[106px] tw3-px-[0.75rem]" }
const _hoisted_9 = { class: "tw3-relative" }
const _hoisted_10 = { class: "tw3-w-[65px]" }
const _hoisted_11 = { class: "tw3-w-[65px] tw3-text-right" }
const _hoisted_12 = { class: "tw3-flex" }
const _hoisted_13 = { class: "tw3-flex tw3-flex-col tw3-items-center tw3-justify-center" }

import Badge from 'primevue/badge';
import Button from 'primevue/button';
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import Fieldset from 'primevue/fieldset';
import InputNumber from 'primevue/inputnumber';
import Select from 'primevue/select';
import Tag from 'primevue/tag';
import ToggleButton from 'primevue/togglebutton';
import { useConfirm } from 'primevue/useconfirm';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';

import { DamageInfo } from '@/entities/claim/lib/types';

import {
	locationOfDamage,
	locationOfDamageLables,
	nagsPositionCode,
	nagsPositionCodeLabels,
	nagsRelativeLocationCode,
	nagsRelativeLocationCodeLabels,
	nagsSideCode,
	nagsSideCodeLabels
} from './lib/constants';

import type { DataTableRowEditSaveEvent } from 'primevue/datatable';


export default /*@__PURE__*/_defineComponent({
  __name: 'DamageInfo',
  props: /*@__PURE__*/_mergeModels({
    addClaimDamageInfo: { type: Function },
    removeClaimDamageInfo: { type: Function },
    status: { type: Boolean }
  }, {
    "damageInfoData": {
	required: true
},
    "damageInfoDataModifiers": {},
  }),
  emits: ["update:damageInfoData"],
  setup(__props: any) {

const { t } = useI18n();

const confirm = useConfirm();

const damageInfoData = _useModel<DamageInfo[]>(__props, 'damageInfoData');



const editingRows = ref<any[]>([]);

const add = () => {
	__props.addClaimDamageInfo();
	editingRows.value = [
		...editingRows.value,
		damageInfoData.value[damageInfoData.value.length - 1]
	];
};

const onRowEditSave = (event: DataTableRowEditSaveEvent) => {
	const { newData, index } = event;
	damageInfoData.value[index] = newData;
};

const handleRowDelete = (id: number) => {
	confirm.require({
		accept: () => {
			__props.removeClaimDamageInfo(id);
		},
		acceptLabel: t('yes'),
		acceptProps: {
			severity: 'success'
		},
		header: t('removeDamageInfoHeader'),
		message: t('removeDamageInfoMsg'),
		rejectLabel: t('no'),
		rejectProps: {
			severity: 'secondary'
		}
	});
};

return (_ctx: any,_cache: any) => {
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createBlock(_unref(Fieldset), {
    class: "relative !tw3-mb-0",
    legend: _unref(t)('damage'),
    pt: { content: 'p-1' },
    toggleable: ""
  }, {
    default: _withCtx(() => [
      _createVNode(_unref(DataTable), {
        editingRows: editingRows.value,
        "onUpdate:editingRows": _cache[0] || (_cache[0] = ($event: any) => ((editingRows).value = $event)),
        dataKey: "id",
        editMode: "row",
        size: "small",
        tableStyle: "tw3-w-full",
        value: damageInfoData.value,
        onRowEditSave: onRowEditSave
      }, {
        empty: _withCtx(() => _cache[1] || (_cache[1] = [
          _createElementVNode("div", { class: "tw3-p-6" }, [
            _createElementVNode("p", { class: "tw3-text-2xl tw3-text-center" }, " Please add damage information. ")
          ], -1)
        ])),
        default: _withCtx(() => [
          _createVNode(_unref(Column), {
            bodyStyle: "border: 0",
            field: "openingTypeCode",
            style: {"width":"25%"}
          }, {
            header: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _withDirectives((_openBlock(), _createElementBlock("span", null, [
                  _createTextVNode(_toDisplayString(_unref(t)('location')), 1)
                ])), [
                  [
                    _directive_tooltip,
                    _unref(t)('damageTitles.location'),
                    void 0,
                    { top: true }
                  ]
                ]),
                _withDirectives(_createVNode(_unref(Badge), {
                  class: "tw3-absolute !tw3-h-[0.3rem] !tw3-min-w-[0.3rem] !tw3-w-[0.3rem]",
                  severity: "danger"
                }, null, 512), [
                  [
                    _directive_tooltip,
                    _unref(t)('required'),
                    void 0,
                    { top: true }
                  ]
                ])
              ])
            ]),
            editor: _withCtx(({ data, field }) => [
              _createVNode(_unref(Select), {
                modelValue: data[field],
                "onUpdate:modelValue": ($event: any) => ((data[field]) = $event),
                class: "tw3-w-[195px]",
                optionLabel: "label",
                options: _unref(locationOfDamage),
                optionValue: "value",
                placeholder: "Select a type code"
              }, null, 8, ["modelValue", "onUpdate:modelValue", "options"])
            ]),
            body: _withCtx(({ data, field }) => [
              _createElementVNode("div", _hoisted_2, _toDisplayString(_unref(locationOfDamageLables)[data[field]]), 1)
            ]),
            _: 1
          }),
          _createVNode(_unref(Column), {
            bodyStyle: "border: 0",
            field: "repairQualification",
            style: {"width":"10%"}
          }, {
            header: _withCtx(() => [
              _createElementVNode("div", _hoisted_3, [
                _withDirectives((_openBlock(), _createElementBlock("span", null, [
                  _createTextVNode(_toDisplayString(_unref(t)('repairable')), 1)
                ])), [
                  [
                    _directive_tooltip,
                    _unref(t)('damageTitles.repairable'),
                    void 0,
                    { top: true }
                  ]
                ]),
                _withDirectives(_createVNode(_unref(Badge), {
                  class: "tw3-absolute !tw3-h-[0.3rem] !tw3-min-w-[0.3rem] !tw3-w-[0.3rem]",
                  severity: "danger"
                }, null, 512), [
                  [
                    _directive_tooltip,
                    _unref(t)('required'),
                    void 0,
                    { top: true }
                  ]
                ])
              ])
            ]),
            editor: _withCtx(({ data, field }) => [
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_unref(ToggleButton), {
                  modelValue: data[field],
                  "onUpdate:modelValue": ($event: any) => ((data[field]) = $event),
                  offLabel: "No",
                  onLabel: "Yes"
                }, null, 8, ["modelValue", "onUpdate:modelValue"])
              ])
            ]),
            body: _withCtx(({ data, field }) => [
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_unref(Tag), {
                  severity: data[field] ? 'primary' : 'danger',
                  value: "Primary"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(data[field] === true ? 'Yes' : 'No'), 1)
                  ]),
                  _: 2
                }, 1032, ["severity"])
              ])
            ]),
            _: 1
          }),
          _createVNode(_unref(Column), {
            bodyStyle: "border: 0",
            field: "positionCode",
            style: {"width":"10%"}
          }, {
            header: _withCtx(() => [
              _withDirectives((_openBlock(), _createElementBlock("span", null, [
                _createTextVNode(_toDisplayString(_unref(t)('position')), 1)
              ])), [
                [
                  _directive_tooltip,
                  _unref(t)('damageTitles.position'),
                  void 0,
                  { top: true }
                ]
              ])
            ]),
            editor: _withCtx(({ data, field }) => [
              _createVNode(_unref(Select), {
                modelValue: data[field],
                "onUpdate:modelValue": ($event: any) => ((data[field]) = $event),
                class: "tw3-w-[102px]",
                optionLabel: "label",
                options: _unref(nagsPositionCode),
                optionValue: "value",
                placeholder: "Select a nags position code"
              }, null, 8, ["modelValue", "onUpdate:modelValue", "options"])
            ]),
            body: _withCtx(({ data, field }) => [
              _createElementVNode("div", _hoisted_6, _toDisplayString(_unref(nagsPositionCodeLabels)[data[field]]), 1)
            ]),
            _: 1
          }),
          _createVNode(_unref(Column), {
            bodyStyle: "border: 0",
            field: "relativeLocationCode",
            style: {"width":"20%"}
          }, {
            header: _withCtx(() => [
              _withDirectives((_openBlock(), _createElementBlock("span", null, [
                _createTextVNode(_toDisplayString(_unref(t)('relLocation')), 1)
              ])), [
                [
                  _directive_tooltip,
                  _unref(t)('damageTitles.relLocation'),
                  void 0,
                  { top: true }
                ]
              ])
            ]),
            editor: _withCtx(({ data, field }) => [
              _createVNode(_unref(Select), {
                modelValue: data[field],
                "onUpdate:modelValue": ($event: any) => ((data[field]) = $event),
                class: "tw3-w-[111px]",
                optionLabel: "label",
                options: _unref(nagsRelativeLocationCode),
                optionValue: "value",
                placeholder: "Select a nags relative location code"
              }, null, 8, ["modelValue", "onUpdate:modelValue", "options"])
            ]),
            body: _withCtx(({ data, field }) => [
              _createElementVNode("div", _hoisted_7, _toDisplayString(_unref(nagsRelativeLocationCodeLabels)[data[field]]), 1)
            ]),
            _: 1
          }),
          _createVNode(_unref(Column), {
            bodyStyle: "border: 0",
            field: "sideCode",
            style: {"width":"10%"}
          }, {
            header: _withCtx(() => [
              _withDirectives((_openBlock(), _createElementBlock("span", null, [
                _createTextVNode(_toDisplayString(_unref(t)('side')), 1)
              ])), [
                [
                  _directive_tooltip,
                  _unref(t)('damageTitles.side'),
                  void 0,
                  { top: true }
                ]
              ])
            ]),
            editor: _withCtx(({ data, field }) => [
              _createVNode(_unref(Select), {
                modelValue: data[field],
                "onUpdate:modelValue": ($event: any) => ((data[field]) = $event),
                class: "tw3-w-[106px]",
                optionLabel: "label",
                options: _unref(nagsSideCode),
                optionValue: "value",
                placeholder: "Select a nags position code"
              }, null, 8, ["modelValue", "onUpdate:modelValue", "options"])
            ]),
            body: _withCtx(({ data, field }) => [
              _createElementVNode("div", _hoisted_8, _toDisplayString(_unref(nagsSideCodeLabels)[data[field]]), 1)
            ]),
            _: 1
          }),
          _createVNode(_unref(Column), {
            bodyStyle: "border: 0",
            field: "glassDamageQuantity",
            style: {"width":"10%"}
          }, {
            header: _withCtx(() => [
              _createElementVNode("div", _hoisted_9, [
                _withDirectives((_openBlock(), _createElementBlock("span", null, [
                  _createTextVNode(_toDisplayString(_unref(t)('qty')), 1)
                ])), [
                  [
                    _directive_tooltip,
                    _unref(t)('damageTitles.qty'),
                    void 0,
                    { top: true }
                  ]
                ]),
                _withDirectives(_createVNode(_unref(Badge), {
                  class: "tw3-absolute !tw3-h-[0.3rem] !tw3-min-w-[0.3rem] !tw3-w-[0.3rem]",
                  severity: "danger"
                }, null, 512), [
                  [
                    _directive_tooltip,
                    _unref(t)('required'),
                    void 0,
                    { top: true }
                  ]
                ])
              ])
            ]),
            editor: _withCtx(({ data, field }) => [
              _createElementVNode("div", _hoisted_10, [
                _createVNode(_unref(InputNumber), {
                  modelValue: data[field],
                  "onUpdate:modelValue": ($event: any) => ((data[field]) = $event),
                  inputStyle: { width: '65px' },
                  inputId: "minmax-buttons",
                  max: 9,
                  min: 1,
                  mode: "decimal",
                  showButtons: ""
                }, null, 8, ["modelValue", "onUpdate:modelValue"])
              ])
            ]),
            body: _withCtx(({ data, field }) => [
              _createElementVNode("div", _hoisted_11, _toDisplayString(data[field]), 1)
            ]),
            _: 1
          }),
          (!_ctx.status)
            ? (_openBlock(), _createBlock(_unref(Column), {
                key: 0,
                bodyStyle: "display:flex; border: 0; width: 80px",
                rowEditor: ""
              }))
            : _createCommentVNode("", true),
          _createVNode(_unref(Column), {
            bodyStyle: "border: 0",
            field: "actions",
            header: "",
            style: {"width":"5%"}
          }, {
            header: _withCtx(() => [
              _createElementVNode("div", _hoisted_12, [
                (!_ctx.status)
                  ? (_openBlock(), _createBlock(_unref(Button), {
                      key: 0,
                      "aria-label": "Settings",
                      icon: "pi pi-plus",
                      rounded: "",
                      size: "small",
                      text: "",
                      type: "button",
                      onClick: add
                    }))
                  : _createCommentVNode("", true)
              ])
            ]),
            body: _withCtx(({ index }) => [
              _createElementVNode("div", _hoisted_13, [
                (index !== 0 && !_ctx.status)
                  ? (_openBlock(), _createBlock(_unref(Button), {
                      key: 0,
                      "aria-label": "Delete",
                      class: "tw3-w-8 tw3-h-8",
                      disabled: _ctx.status,
                      icon: "pi pi-trash",
                      rounded: "",
                      severity: "danger",
                      text: "",
                      onClick: ($event: any) => (handleRowDelete(index))
                    }, null, 8, ["disabled", "onClick"]))
                  : _createCommentVNode("", true)
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["editingRows", "value"])
    ]),
    _: 1
  }, 8, ["legend"]))
}
}

})