import { capitalize } from 'lodash-es';

const EN = {
	active: 'Active',
	agencyName: 'Agency Name',
	agentFirstName: 'Agent First Name',
	agentLastName: 'Agent Last Name',
	all: 'All',
	allShops: 'All Shops',
	allReports: 'All Reports',
	amount: 'Amount',
	appointmentDate: 'Appointment Date',
	archive: 'Archive',
	archiveDialog: {
		archiveTitle: 'Are you sure you want to unarchive this Claim?',
		unarchiveTitle: 'Are you sure you want to archive this Claim?'
	},
	autoAccept: 'Auto Accept',
	avatar: 'Avatar',
	averageInvoice: 'Average invoice',
	averageInvoiceAmount: 'Average invoice amount',
	averageRating: 'Average rating',
	averageStars: 'Average Stars',
	averageWarrantyRate: 'Average warranty rate',
	averageCostRatio: 'Average cost ratio',
	billTo: 'bill to',
	bodyInjury: 'Bodily Injury',
	bodyInjuryTooltip: 'Was there an injury as it relates to this claim',
	//@ts-ignore
	capitalize: function (ctx) {
		//@ts-ignore
		return capitalize(ctx.named('key'));
	},
	cancel: 'Cancel',
	callBack: 'Call back',
	causeOfLoss: 'Cause of Loss',
	causeOfLossTooltip: 'Determine how loss occurred',
	changeFollowupDate: {
		message: 'Are you sure you want to update this information?',
		title: 'Change Follow Up date'
	},
	costRatio: 'Cost Ratio',
	costRatioExplaining:
		'Technician {name} is a measure indicating how much of the operating revenue goes to technicians.  Jobs with zero revenue or zero commission are omitted from the calculation.',
	comm: 'Comm',
	commission: 'Commission',
	commissionAmount: 'Commission Amount',
	completedJobs: 'Completed Jobs',
	completedJobsCount: 'Completed Jobs Count',
	completionPercentage: 'Completion (%)',
	city: 'City',
	clear: 'Clear',
	claimForJob: 'Claim for Job',
	claims: 'Claims',
	claimsDialog: {
		claimsSended: 'Claim successfully sended'
	},
	contactPhone: 'Contact Phone',
	coverage: 'coverage',
	csr: 'CSR',
	customer: 'Customer',
	customers: 'Customers',
	customerCount: 'Customer count',
	dataUpdatedOn: 'Data updated as of {date}',
	revenueData: 'Revenue data',
	dashboardTooltipTitle:
		'{name} is tabulated from invoice totals within your GlassBiller account, it is exclusive of sales tax.',
	dashboardTooltipDescription:
		'Data displayed should not be used for accounting or tax reporting purposes. \nData shown is meant to give users a graphically zoomed out overview of the entire business as well as allow for comparisons to previous time periods. \nThese totals may, and in many cases will, deviate from professionally prepared accounting reports.',
	dashboard: {
		index: 'Dashboard',
		defaultChanged: 'Default dashboard changed successfully',
		permissionMsg:
			'Sorry, you do not have the necessary permissions to access this page. If you think you should have access, please reach out to your manager.',
		titles: {
			numberOfCustomers: 'Number of customers',
			totalNumberOfCustomers: 'Total number of customers',
			numberOfInvoices: 'Number of invoices',
			totalAmountOfInvoices: 'Total amount of invoices',
			totalNumberOfInvoices: 'Total number of invoices',
			singleInvoiceAmount: 'Single invoice amount',
			revenueAndNumberOfInvoices: 'Revenue and Number of Invoices',
			compareRevenue: 'Compare revenue trends over time',
			customerGrow: 'See your customer count grow over time',
			invoiceGrow: 'See your invoice count grow over time',
			averageInvoice: 'Monitor average invoice size over time'
		}
	},
	damage: 'Damage',
	damageTitles: {
		location: 'Capture what glass will be repaired or replaced',
		position: 'Nags Position Code',
		qty: 'Capture the pieces of glass\nthat will be used to service the vehicle',
		relLocation: 'Nags Relative Location Code',
		repairable:
			'If Glass Damage Opening is Windshield, \nthe Retailer must certify that the windshield\nwas not repairable',
		side: 'Nags Side Code'
	},
	date: 'Date',
	deductable: 'Deductable',
	details: 'Details',
	delivery: 'Delivery',
	driverName: `Driver's Name (if available)`,
	dropOff: `Drop off`,
	error: 'error',
	errorNumber: 'Please enter a valid 10 digit number.',
	faxDialog: {
		fax: 'Fax',
		actions: {
			actions: 'Actions',
			attachFax: {
				attach: 'Attach to job',
				error: 'This fax could not be attached.',
				success: 'The document was successfully attached.'
			},
			burst: {
				burst: 'Burst',
				plural: 'Do you want to burst these faxes?',
				pluralTooltip: 'Burst selected faxes',
				single: 'Do you want to burst this fax?',
				error: 'Failed to burst fax. Please try again.',
				success: 'Fax burst complete'
			},
			cancel: 'Cancel',
			changeReferral: {
				error: 'An error occurred during the update Refferal/Notes',
				placeholder: 'Enter referral/Notes',
				success: 'Refferal/Notes successffully updated!'
			},
			changeStatus: {
				error: 'An error occurred while changing the status',
				success: 'Status successfully changed!'
			},
			delete: {
				delete: 'Delete',
				error: 'There was an error deleting a fax.',
				plural: 'Do you want to delete these faxes?',
				pluralTooltip: 'Delete selected faxes',
				single: 'Do you want to delete this fax?',
				success: 'Faxes succesfully deleted!'
			},
			download: 'Download fax',
			downloadConfirmation: 'Download Transmittal Page',
			noConfirmationAvailable: 'No Transmittal Page Available',
			confirmationError:
				'Something went wrong retreiving the copy of your previous fax. Please try again.',
			confirmationSuccess: 'Transmittal page successfully downloaded',
			pluralActions: 'Plural actions:',
			pluralActionsTooltip: 'Actions with selected faxes',
			upload: {
				upload: 'Upload fax'
			}
		},
		inbox: {
			dateTime: 'Date',
			pages: 'Pages',
			referral: 'Referral/Notes',
			sentFrom: 'Sent From',
			sentTo: 'Sent To',
			status: 'Status',
			title: 'Fax Inbox'
		},
		outbox: {
			dateTime: 'Date',
			file: 'File Name',
			pages: 'Pages',
			sentFrom: 'Sent From',
			sentTo: 'Sent To',
			status: 'Status',
			title: 'Fax Outbox'
		},
		status: {
			burst: 'Burst',
			error: 'Error',
			queued: 'Queued',
			read: 'Read',
			sending: 'Sending',
			sent: 'Sent',
			unread: 'Unread'
		},
		statusResponses: {
			error: 'Error when sending a fax',
			queued: 'The fax is in the queue',
			read: 'Fax is readed',
			sending: 'Fax is sending',
			sent: 'Fax in sended',
			unread: 'Fax is unread'
		},
		searchForJob: 'Search For Job...',
		type: 'Type',
		attachFaxJob: 'Attach Fax to Job',
		selectType: 'Select a type',
		selectStatus: 'Select status',
		attachJob: 'Attach job',
		uploadFax: {
			fileName: 'File Name:',
			dateTime: 'Date/Time:',
			enterFaxNumber: 'Enter fax number',
			yourFile: 'Your file:',
			uploadFaxHeader: 'Send/Burst new fax',
			burstNewFax: {
				burstFax: 'Burst Fax',
				error: 'This fax could not be burst.',
				success: 'The fax was successfully burst.'
			},
			sendFax: {
				sendFax: 'Send Fax',
				error: 'An error occurred while sending a fax',
				success: 'Fax is successfully sended'
			},
			chooseFile: 'Choose file'
		}
	},
	job: 'Job',
	confirmation: 'Confirmation',
	defaultToast: {
		successSummary: 'Success Message',
		errorSummary: 'Error Message',
		errorDetails: 'Something went wrong'
	},
	searchBy: 'Search by ',
	followUp: 'Follow up',
	followUpJobs: 'Follow-up jobs',
	glassOnlyLoss: 'Glass Only Loss',
	glassOnlyLossTooltip: 'Determine if this is a Glass Only claim',
	id: 'Id',
	inactive: 'Inactive',
	inbox: 'Inbox',
	incident: 'Incident on',
	insurance: 'Insurance',
	invoices: 'Invoices',
	invoiceCount: 'Invoice Count',
	inShop: 'In Shop',
	jobId: 'Job ID',
	location: 'Location',
	ltlRevenue: 'LTL Rev',
	ltlJobs: 'LTL Jobs',
	ltlCommission: 'LTL Comm',
	lifetimeRevenue: 'Lifetime Revenue',
	lifetimeInvoices: 'Lifetime invoices',
	lifetimeCustomers: 'Lifetime customers',
	lifetimeCommission: 'Lifetime Commission',
	lifetimeCostRatio: 'Lifetime cost ratio',
	lifetimeRatings: 'Lifetime ratings',
	lifetimeWarrantyRate: 'Lifetime warranty rate',
	lifetimeCompletedJobs: 'Lifetime completed jobs',
	lifetimeJobs: 'Lifetime Jobs',
	lifetimeJobsCompleted: `{n}% of jobs are complete`,
	mobileIndicator: 'Mobile Indicator',
	name: 'Name',
	new: 'new',
	no: 'No',
	noData: 'No Data Found',
	newestInvoices: 'Newest Invoices',
	noInvoices: 'No invoices yet',
	home: 'Home',
	openClaim: 'Open Claim',
	pending: 'pending',
	phone: 'Phone',
	plate: 'Plate',
	policy: 'Policy',
	position: 'Position',
	permission: 'Permission Required',
	pickUp: 'Pick Up',
	mobile: 'Mobile',
	mobileAbout: 'Mobile jobs account for about {n}% of your installs',
	mobileVsInShop: 'Mobile vs In Shop',
	qty: 'Qty',
	quote: 'Quote#',
	rating: 'Rating',
	revenue: 'Revenue',
	refNumber: 'Reference Number',
	reference: 'Reference',
	rejectedInvoices: {
		tabs: {
			rejected: 'Rejected',
			queued: 'Queued',
			preparing: 'Preparing',
			pending: 'Pending',
			failed: 'Failed',
			unsubmitted: 'Unsubmitted'
		},
		columnNames: {
			amount: 'Amount',
			customer: 'Customer',
			invoice: 'Invoice',
			referral: 'Refferal',
			tradingPartner: 'Trading partner'
		},
		addTagsTitle: 'Add tags',
		title: 'Rejected invoices',
		applyTags: 'Apply tags',
		tagDeletedSuccess: 'Tag successfully deleted!',
		tagDeletedFailed: 'Failed to delete tag.',
		tagAddedSuccess: 'New tags added',
		tags: 'Tags',
		showDetails: 'Show Details',
		voidedJobs: 'Include voided jobs'
	},
	addNewTag: 'Add new tag',
	relLocation: 'Rel Location',
	removeDamageInfoHeader: 'Remove Damage Information',
	removeDamageInfoMsg:
		'Are you sure you want to delete this damage information?',
	repairable: 'Repairable',
	required: 'Required',
	result: 'Result',
	retail: 'Retail',
	recal: 'Recal',
	reports: 'Reports',
	reportCategory: 'Report Category',
	rAndI: 'R & I',
	save: 'Save',
	selectShop: 'Select Shop',
	selectDefault: 'Select Default',
	shopContactPerson: 'Shop Contact Person',
	shopContactPersonTooltip:
		'Allows Insurance Company or TPA to contact the originator.',
	showActive: 'Show Active',
	showArchive: 'Show Archive',
	side: 'Side',
	since: 'Since',
	statePolicty: 'State Policy was Written',
	status: 'Status',
	statusResponses: {
		coverage: 'coverage response received',
		error: 'error occured',
		new: 'claim created',
		pending: 'loss notice sent',
		success: 'acknowledge sent'
	},
	street: 'Street',
	submitClaim: 'Submit Claim',
	submitClaimMsg: 'Are you sure you would like to submit this claim?',
	subrogationContactName: {
		CAUSE_OF_LOSS_105: "Driver's Name (if available)",
		CAUSE_OF_LOSS_111: 'Details',
		CAUSE_OF_LOSS_199: "Person's Name (if available/applicable)",
		CAUSE_OF_LOSS_201: "Driver's Name (if available)",
		CAUSE_OF_LOSS_301: "Person's Name (if available)",
		CAUSE_OF_LOSS_341: "Person's Name (if available)",
		CAUSE_OF_LOSS_499: 'Details'
	},
	subrogationData: 'Subrogation Data',
	subrogationDataKeys: {
		CAUSE_OF_LOSS_105: 'License Plate Number',
		CAUSE_OF_LOSS_111: 'Type of Animal',
		CAUSE_OF_LOSS_199: 'Description of Object',
		CAUSE_OF_LOSS_201: 'Claim Number (if available) and Contact Info',
		CAUSE_OF_LOSS_301: 'Contact Info',
		CAUSE_OF_LOSS_341: 'Contact Info (e.g. policy report)',
		CAUSE_OF_LOSS_499: 'Type of Storm (e.g. lightning, wind, sand, etc)'
	},
	success: 'success',
	type: 'type',
	tech: {
		costRatioLifetime: 'Lifetime (LTL) Cost ratio',
		commissionPerJob: 'Commission per job',
		totalCommissions: 'Total commissions',
		titles: {
			numberOfCJobs: 'Number of completed jobs',
			totalNumberOfCJobs: 'Total number of completed jobs',
			numberOfWJobs: 'Number of warranty jobs',
			amountOfCommission: 'Amount of commission',
			commissionGrow: 'See your commission amount grow over time',
			completedGrow: 'See your completed jobs count over time',
			warrantyGrow: 'See your warranty jobs count over time',
			averageCostRatio: 'Monitor average cost ratio over time'
		}
	},
	techside: 'Techside',
	techsideJobsCompleted: '{n}% of jobs completed on Techside',
	technicians: 'Technicians',
	time: {
		day: 'Day',
		week: 'Week',
		month: 'Month',
		year: 'Year',
		thisMonth: 'this month',
		last90days: 'last 90 days',
		dayShort: 'TTD',
		dayLong: 'for trailing thirty days (ttd)',
		weekShort: 'TTW',
		weekLong: 'for trailing twenty four weeks (ttw)',
		monthShort: 'TTM',
		monthLong: 'for trailing twelve months (ttm)',
		yearShort: 'TSY',
		yearLong: 'for trailing seven years (tsy)'
	},
	tint: 'Tint',
	unarchive: 'Unarchive',
	vehicle: 'Vehicle',
	vehicleNumber: 'Vehicle Number',
	vehicleOwnership: 'Vehicle Ownership',
	vehicleType: 'Vehicle Type',
	vin: 'VIN',
	vinTooltip:
		'A policyholder often has more than one vehicle with the same make/model/yr on a policy, therefore, if available, the VIN may be sent to filter coverage confirmation information.',
	voidJobs: {
		message: 'Are you sure you want to void these jobs?',
		title: 'Void Jobs'
	},
	workLocation: 'Work Location Postal Code',
	warrCount: 'Warr',
	warrRate: 'Warr (%)',
	warrantyJobs: 'Warranty Jobs',
	warrantyRate: 'Warranty Rate',
	willCall: 'Will call',
	yes: 'Yes'
};

export default EN;
