import { useModel as _useModel, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "tw3-flex tw3-flex-col tw3-p-6 tw3-gap-4"
}
const _hoisted_2 = { class: "tw3-flex tw3-gap-3 tw3-justify-between tw3-pb-3 tw3-border-b tw3-border-gray-300 tw3-w-full" }
const _hoisted_3 = { class: "tw3-text-lg tw3-font-bold tw3-text-gray-500 tw3-whitespace-nowrap" }
const _hoisted_4 = { class: "tw3-text-lg" }
const _hoisted_5 = { class: "tw3-flex tw3-gap-3 tw3-justify-between tw3-pb-3 tw3-border-b tw3-border-gray-300 tw3-w-full" }
const _hoisted_6 = { class: "tw3-text-lg tw3-font-bold tw3-text-gray-500 tw3-whitespace-nowrap" }
const _hoisted_7 = { class: "tw3-text-lg" }
const _hoisted_8 = { class: "tw3-flex tw3-gap-3 tw3-justify-between tw3-pb-3 tw3-border-b tw3-border-gray-300 tw3-w-full" }
const _hoisted_9 = { class: "tw3-text-lg tw3-font-bold tw3-text-gray-500 tw3-whitespace-nowrap" }
const _hoisted_10 = { class: "tw3-text-lg" }
const _hoisted_11 = {
  key: 0,
  class: "tw3-flex tw3-gap-3 tw3-justify-between tw3-pb-3 tw3-border-b tw3-border-gray-300 tw3-w-full"
}
const _hoisted_12 = { class: "tw3-text-lg tw3-font-bold tw3-text-gray-500 tw3-whitespace-nowrap" }
const _hoisted_13 = { class: "tw3-text-lg" }
const _hoisted_14 = {
  key: 1,
  class: "tw3-flex tw3-gap-3 tw3-justify-between tw3-pb-3 tw3-border-b tw3-border-gray-300 tw3-w-full"
}
const _hoisted_15 = { class: "tw3-text-lg tw3-font-bold tw3-text-gray-500 tw3-whitespace-nowrap" }
const _hoisted_16 = { class: "tw3-text-lg" }
const _hoisted_17 = { class: "tw3-flex tw3-gap-3 tw3-justify-between tw3-pb-3 tw3-border-b tw3-border-gray-300 tw3-w-full" }
const _hoisted_18 = { class: "tw3-text-lg tw3-font-bold tw3-text-gray-500 tw3-whitespace-nowrap" }
const _hoisted_19 = { class: "tw3-text-lg" }
const _hoisted_20 = { class: "tw3-flex tw3-gap-3 tw3-justify-between tw3-pb-3 tw3-border-b tw3-border-gray-300 tw3-w-full" }
const _hoisted_21 = { class: "tw3-text-lg tw3-font-bold tw3-text-gray-500 tw3-whitespace-nowrap" }
const _hoisted_22 = { class: "tw3-text-lg" }
const _hoisted_23 = { class: "tw3-flex tw3-gap-3 tw3-justify-between tw3-pb-3 tw3-border-b tw3-border-gray-300 tw3-w-full" }
const _hoisted_24 = { class: "tw3-text-lg tw3-font-bold tw3-text-gray-500 tw3-whitespace-nowrap" }
const _hoisted_25 = { class: "tw3-text-lg" }
const _hoisted_26 = { class: "tw3-flex tw3-gap-3 tw3-justify-between tw3-pb-3 tw3-border-b tw3-border-gray-300 tw3-w-full" }
const _hoisted_27 = { class: "tw3-text-lg tw3-font-bold tw3-text-gray-500 tw3-whitespace-nowrap" }
const _hoisted_28 = { class: "tw3-text-lg" }
const _hoisted_29 = { class: "tw3-flex tw3-gap-3 tw3-justify-between tw3-pb-3 tw3-border-b tw3-border-gray-300 tw3-w-full" }
const _hoisted_30 = { class: "tw3-text-lg tw3-font-bold tw3-text-gray-500 tw3-whitespace-nowrap" }
const _hoisted_31 = { class: "tw3-text-lg" }
const _hoisted_32 = { class: "tw3-flex tw3-gap-3 tw3-justify-between tw3-pb-3 tw3-border-b tw3-border-gray-300 tw3-w-full" }
const _hoisted_33 = { class: "tw3-text-lg tw3-font-bold tw3-text-gray-500 tw3-whitespace-nowrap" }
const _hoisted_34 = { class: "tw3-flex tw3-justify-end tw3-gap-3 tw3-flex-wrap" }
const _hoisted_35 = {
  key: 0,
  class: "tw3-flex tw3-gap-3 tw3-justify-between tw3-pb-3 tw3-border-b tw3-border-gray-300 tw3-w-full"
}
const _hoisted_36 = { class: "tw3-text-lg tw3-font-bold tw3-text-gray-500 tw3-whitespace-nowrap" }
const _hoisted_37 = { class: "tw3-flex tw3-justify-end tw3-gap-3 tw3-flex-wrap" }
const _hoisted_38 = {
  class: "details-recal-tag",
  style: {
										background: '#805ad5',
										color: '#fff'
									}
}
const _hoisted_39 = { class: "tw3-flex tw3-gap-3 tw3-justify-between tw3-pb-3 tw3-border-b tw3-border-gray-300 tw3-w-full" }
const _hoisted_40 = { class: "tw3-text-lg tw3-font-bold tw3-text-gray-500 tw3-whitespace-nowrap" }
const _hoisted_41 = { class: "tw3-flex tw3-justify-end tw3-gap-3 tw3-flex-wrap" }
const _hoisted_42 = {
  class: "details-recal-tag",
  style: {
										background: '#299fd7',
										color: '#fff'
									}
}
const _hoisted_43 = {
  key: 0,
  class: "tw3-flex tw3-gap-3 tw3-justify-center tw3-pb-3 tw3-border-b tw3-font-bold tw3-text-center tw3-border-gray-300 tw3-w-full"
}
const _hoisted_44 = {
  key: 1,
  class: "tw3-flex tw3-flex-col tw3-gap-3"
}

import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { storeToRefs } from 'pinia';
import Dialog from 'primevue/dialog';
import Skeleton from 'primevue/skeleton';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import useReportsStore from '@/widgets/reportsElements/model/store';


export default /*@__PURE__*/_defineComponent({
  __name: 'ReportsRequestDetails',
  props: {
    "show": { type: Boolean },
    "showModifiers": {},
  },
  emits: ["update:show"],
  setup(__props) {

const { t } = useI18n();

const showModal = _useModel<boolean>(__props, 'show');

const reportStore = useReportsStore();
const { rightPartDetails: details, rightPartDetailsLoading } =
	storeToRefs(reportStore);

dayjs.extend(localizedFormat);
const formatDate = (date: string) => {
	return dayjs(date).format('lll');
};

const glass = computed(() => {
	return details.value?.result?.windshields || [];
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(Dialog), {
    id: "RightPartDetails",
    visible: showModal.value,
    "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((showModal).value = $event)),
    appendTo: "#vue3app",
    class: "tw3-max-w-[800px] tw3-w-full",
    header: _unref(t)('reportsPage.requestDetails.title'),
    modal: ""
  }, {
    default: _withCtx(() => [
      (!_unref(rightPartDetailsLoading) && _unref(details))
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, _toDisplayString(_unref(t)('user')) + ": ", 1),
              _createElementVNode("div", _hoisted_4, _toDisplayString(_unref(details).username), 1)
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, _toDisplayString(_unref(t)('reportsPage.requestDetails.reqTime')) + ": ", 1),
              _createElementVNode("div", _hoisted_7, _toDisplayString(formatDate(_unref(details).createdAt)), 1)
            ]),
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("div", _hoisted_9, _toDisplayString(_unref(t)('vin')) + ": ", 1),
              _createElementVNode("div", _hoisted_10, _toDisplayString(_unref(details).vin), 1)
            ]),
            (_unref(details).status != 'success')
              ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                  _createElementVNode("div", _hoisted_12, _toDisplayString(_unref(t)('message')) + ": ", 1),
                  _createElementVNode("div", _hoisted_13, _toDisplayString(_unref(t)('reportsPage.requestDetails.vinInvalid')), 1)
                ]))
              : _createCommentVNode("", true),
            (_unref(details).help_request_status === 'sent')
              ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                  _createElementVNode("div", _hoisted_15, _toDisplayString(_unref(t)('message')) + ": ", 1),
                  _createElementVNode("div", _hoisted_16, _toDisplayString(_unref(t)('reportsPage.requestDetails.helpReqSend')), 1)
                ]))
              : _createCommentVNode("", true),
            (_unref(details).result)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                  _createElementVNode("div", _hoisted_17, [
                    _createElementVNode("div", _hoisted_18, _toDisplayString(_unref(t)('time.year')) + ": ", 1),
                    _createElementVNode("div", _hoisted_19, _toDisplayString(_unref(details).result.year), 1)
                  ]),
                  _createElementVNode("div", _hoisted_20, [
                    _createElementVNode("div", _hoisted_21, _toDisplayString(_unref(t)('make')) + ": ", 1),
                    _createElementVNode("div", _hoisted_22, _toDisplayString(_unref(details).result.make), 1)
                  ]),
                  _createElementVNode("div", _hoisted_23, [
                    _createElementVNode("div", _hoisted_24, _toDisplayString(_unref(t)('model')) + ": ", 1),
                    _createElementVNode("div", _hoisted_25, _toDisplayString(_unref(details).result.model), 1)
                  ]),
                  _createElementVNode("div", _hoisted_26, [
                    _createElementVNode("div", _hoisted_27, _toDisplayString(_unref(t)('style')) + ": ", 1),
                    _createElementVNode("div", _hoisted_28, _toDisplayString(_unref(details).result.body), 1)
                  ]),
                  _createElementVNode("div", _hoisted_29, [
                    _createElementVNode("div", _hoisted_30, _toDisplayString(_unref(t)('reportsPage.requestDetails.glassType')) + ": ", 1),
                    _createElementVNode("div", _hoisted_31, _toDisplayString(_unref(t)('reportsPage.requestDetails.windshields')), 1)
                  ]),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(glass.value, (g) => {
                    return (_openBlock(), _createElementBlock(_Fragment, {
                      key: g.id
                    }, [
                      _createElementVNode("div", _hoisted_32, [
                        _createElementVNode("div", _hoisted_33, _toDisplayString(_unref(t)('reportsPage.requestDetails.glassType')) + ": ", 1),
                        _createElementVNode("div", _hoisted_34, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList([g.partNumber.slice(0, -1)], (value) => {
                            return (_openBlock(), _createElementBlock("span", {
                              key: value,
                              class: "detail-tag",
                              style: {
									background: '#337ab7',
									color: '#fff'
								}
                            }, _toDisplayString(value), 1))
                          }), 128))
                        ])
                      ]),
                      (g.calibrations.length)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_35, [
                            _createElementVNode("div", _hoisted_36, _toDisplayString(_unref(t)('reportsPage.requestDetails.calibrations')) + ": ", 1),
                            _createElementVNode("div", _hoisted_37, [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(g.calibrations, (cal) => {
                                return (_openBlock(), _createElementBlock("div", { key: cal }, [
                                  _createTextVNode(_toDisplayString(cal.sensor) + " ", 1),
                                  _createElementVNode("span", _hoisted_38, _toDisplayString(cal.calibrationType), 1)
                                ]))
                              }), 128))
                            ])
                          ]))
                        : _createCommentVNode("", true),
                      _createElementVNode("div", _hoisted_39, [
                        _createElementVNode("div", _hoisted_40, _toDisplayString(_unref(t)('reportsPage.requestDetails.dealerNum')) + ": ", 1),
                        _createElementVNode("div", _hoisted_41, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(g.oemPartNumbers, (value) => {
                            return (_openBlock(), _createElementBlock("div", { key: value }, [
                              _createElementVNode("span", _hoisted_42, _toDisplayString(value), 1)
                            ]))
                          }), 128))
                        ])
                      ])
                    ], 64))
                  }), 128)),
                  (glass.value.length == 0)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_43, _toDisplayString(_unref(t)('reportsPage.requestDetails.noGlassFound')) + "... ", 1))
                    : _createCommentVNode("", true)
                ], 64))
              : _createCommentVNode("", true)
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_44, [
            (_openBlock(), _createElementBlock(_Fragment, null, _renderList(8, (item) => {
              return _createVNode(_unref(Skeleton), {
                key: item,
                class: "mb-2"
              })
            }), 64))
          ]))
    ]),
    _: 1
  }, 8, ["visible", "header"]))
}
}

})