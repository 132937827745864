<script setup lang="ts">
import { toTypedSchema } from '@vee-validate/zod';
import Button from 'primevue/button';
import IconField from 'primevue/iconfield';
import InputIcon from 'primevue/inputicon';
import InputMask from 'primevue/inputmask';
import InputText from 'primevue/inputtext';
import { useForm } from 'vee-validate';
import { watch } from 'vue';
import { z } from 'zod';

import InputAddress from '@/features/address/InputAddress.vue';

import { useCreateAccountStore } from '../../model/createAccount';
import {
	AccountCreationSteps,
	AccountCreationStepValues,
	OrganizationForm
} from '../../model/types';
import StepBody from '../steps/StepBody.vue';
import StepInputGroup from '../steps/StepInputGroup.vue';

const createAccountStore = useCreateAccountStore();

const validationSchema = toTypedSchema(
	z.object({
		name: z
			.string({ message: 'Company name is required' })
			.min(1, { message: 'Company name is required' }),
		contact: z
			.string({ message: 'Business contact is required' })
			.min(1, { message: 'Business contact is required' }),
		email: z
			.string({ message: 'Contact email is required' })
			.email({ message: 'Invalid email' }),
		phone: z.string({ message: 'Phone is required' }).length(14, {
			message: 'Invalid phone number'
		}),
		fax: z.string().optional(),
		g_address: z.object({
			address: z.string(),
			city: z.string().optional(),
			extra: z.string().nullish(),
			full_address: z.string({ message: 'Mailing address is required' }),
			google_place_id: z.string(),
			lat: z.number(),
			lng: z.number(),
			po_box: z.number().optional(),
			state: z.string().optional(),
			unit: z.string().nullish(),
			zip: z.string().nullish()
		})
	})
);

const { handleSubmit, errors, defineField, values } = useForm<OrganizationForm>(
	{
		validationSchema,
		initialValues: createAccountStore.form[AccountCreationSteps.organization]
	}
);

watch(
	() => values,
	v => {
		createAccountStore.form[AccountCreationSteps.organization] = v;
	},
	{ deep: true }
);

const [name] = defineField('name');
const [contact] = defineField('contact');
const [email] = defineField('email');
const [phone] = defineField('phone');
const [fax] = defineField('fax');
const [g_address] = defineField('g_address');

const next = handleSubmit(async () => {
	createAccountStore.changeStep(AccountCreationStepValues.shopInfo);
});
</script>

<template>
	<div class="tw3-mb-8 tw3-text-slate-500">
		Let's start with some basic organization info.
	</div>

	<StepBody>
		<StepInputGroup
			class="tw3-col-span-2"
			:error="errors.name"
			label="Company name"
		>
			<InputText
				v-model="name"
				fluid
				:invalid="!!errors.name"
				placeholder="Company name"
			/>
		</StepInputGroup>

		<StepInputGroup
			:error="errors.contact"
			label="Business contact"
			subLabel="First and last name"
		>
			<InputText
				v-model="contact"
				fluid
				:invalid="!!errors.contact"
				placeholder="Business contact"
			/>
		</StepInputGroup>

		<StepInputGroup :error="errors.email" label="Contact email">
			<InputText
				v-model="email"
				fluid
				:invalid="!!errors.email"
				placeholder="Contact email"
			/>
		</StepInputGroup>

		<StepInputGroup :error="errors.phone" label="Phone">
			<IconField>
				<InputIcon class="pi pi-phone" />
				<InputMask
					v-model="phone"
					fluid
					:invalid="!!errors.phone"
					mask="(999) 999-9999"
					placeholder="(999) 999-9999"
				/>
			</IconField>
		</StepInputGroup>

		<StepInputGroup :error="errors.fax" label="Fax">
			<IconField>
				<InputIcon class="pi pi-phone" />
				<InputMask
					v-model="fax"
					fluid
					:invalid="!!errors.fax"
					mask="(999) 999-9999"
					placeholder="(999) 999-9999"
				/>
			</IconField>
		</StepInputGroup>

		<StepInputGroup
			class="tw3-col-span-2"
			:error="errors.g_address"
			label="Mailing address"
		>
			<InputAddress
				:errMsg="errors.g_address"
				:modelValue="g_address.full_address ? g_address : null"
				withExtra
				withPoBox
				@update:model-value="v => (g_address = v)"
			/>
		</StepInputGroup>
	</StepBody>

	<div class="tw3-flex tw3-pt-8">
		<Button label="Next" size="small" @click="next" />
	</div>
</template>
