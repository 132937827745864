import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "cell-container" }

import Skeleton from 'primevue/skeleton';


export default /*@__PURE__*/_defineComponent({
  __name: 'GbDefaultCell',
  props: {
    loading: { type: Boolean }
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_unref(Skeleton), { key: 0 }))
      : _renderSlot(_ctx.$slots, "default", { key: 1 })
  ]))
}
}

})