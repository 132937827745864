import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "dropdown-container" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = {
  key: 2,
  class: "tw3-flex tw3-gap-4 tw3-w-full"
}

import { vInfiniteScroll } from '@vueuse/components';
import { useDebounceFn } from '@vueuse/core'; // for debounce
import IconField from 'primevue/iconfield';
import InputIcon from 'primevue/inputicon';
import InputText from 'primevue/inputtext';
import { onBeforeUnmount, onMounted, ref, watch } from 'vue';

// Props

export default /*@__PURE__*/_defineComponent({
  __name: 'SearchDropdown',
  props: {
    modelValue: { default: null },
    dropdownOptions: { default: null },
    placeholder: { default: 'Search...' },
    optionLabel: { default: 'name' },
    optionValue: { default: undefined },
    loading: { type: Boolean, default: false },
    dropdownContainerClass: { default: '' }
  },
  emits: ['update:modelValue', 'search', 'loadMore'],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emit = __emit;

const inputText = ref('');
const dropdownVisible = ref(false);
const dropdownRef = ref();
const selectedVariant = ref();

const debouncedSearch = useDebounceFn(() => {
	emit('search', inputText.value);
}, 500);

const closeDropdown = () => {
	dropdownVisible.value = false;
};

const selectVariant = (variant: any) => {
	let emittedValue = variant;
	if (props.optionValue && variant[props.optionValue]) {
		emittedValue = variant[props.optionValue];
	}
	emit('update:modelValue', emittedValue);
	selectedVariant.value = emittedValue;
	closeDropdown();
};
const clearSelection = () => {
	selectedVariant.value = null;
	emit('update:modelValue', null);
};

const infiniteScrollHandler = () => {
	if (props.loading) return;

	emit('loadMore');
};

const handleClick = (event: MouseEvent) => {
	const target = event.target as HTMLElement;

	if (dropdownRef.value && !dropdownRef.value.contains(target)) {
		closeDropdown();
	}
};

onMounted(() => {
	document.addEventListener('click', handleClick);
});

onBeforeUnmount(() => {
	document.removeEventListener('click', handleClick);
});

watch(inputText, newValue => {
	if (newValue) {
		debouncedSearch();
		dropdownVisible.value = true;
	} else {
		closeDropdown();
	}
});

return (_ctx: any,_cache: any) => {
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", {
    ref_key: "dropdownRef",
    ref: dropdownRef,
    class: "tw3-flex tw3-flex-col tw3-w-full tw3-relative"
  }, [
    (!selectedVariant.value)
      ? (_openBlock(), _createBlock(_unref(IconField), { key: 0 }, {
          default: _withCtx(() => [
            _createVNode(_unref(InputText), {
              modelValue: inputText.value,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((inputText).value = $event)),
              class: "tw3-w-full",
              placeholder: _ctx.placeholder,
              onFocus: _cache[1] || (_cache[1] = ($event: any) => (dropdownVisible.value = true))
            }, null, 8, ["modelValue", "placeholder"]),
            _withDirectives(_createVNode(_unref(InputIcon), { class: "pi pi-spin pi-spinner" }, null, 512), [
              [_vShow, _ctx.loading]
            ])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_ctx.dropdownOptions && _ctx.dropdownOptions?.length)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass([
				'overflow-container',
				{
					'-active': dropdownVisible.value
				},
				_ctx.dropdownContainerClass
			])
        }, [
          _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dropdownOptions, (variant, index) => {
              return (_openBlock(), _createElementBlock("div", {
                key: index,
                class: "dropdown-item",
                "infinite-scroll-distance": "10",
                onClick: ($event: any) => (selectVariant(variant))
              }, [
                _renderSlot(_ctx.$slots, "dropdownItem", { item: variant }, () => [
                  _createTextVNode(_toDisplayString(variant.name), 1)
                ])
              ], 8, _hoisted_2))
            }), 128))
          ])), [
            [_unref(vInfiniteScroll), [infiniteScrollHandler, { distance: 10 }]]
          ])
        ], 2))
      : _createCommentVNode("", true),
    (selectedVariant.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _renderSlot(_ctx.$slots, "selected", { item: selectedVariant.value }, () => [
            _createTextVNode(_toDisplayString(selectedVariant.value?.[_ctx.optionLabel] || selectedVariant.value.name), 1)
          ]),
          _withDirectives(_createElementVNode("i", {
            class: "pi pi-times-circle tw3-ml-auto tw3-mr-0 tw3-cursor-pointer tw3-flex tw3-items-center",
            style: {"font-size":"1.1rem","color":"var(--p-tag-secondary-color)"},
            onClick: clearSelection
          }, null, 512), [
            [
              _directive_tooltip,
              'Clear selection',
              void 0,
              { top: true }
            ]
          ])
        ]))
      : _createCommentVNode("", true)
  ], 512))
}
}

})