<script setup lang="ts">
import Divider from 'primevue/divider';
import { computed } from 'vue';

import { useCreateAccountStore } from '../../model/createAccount';
import { AccountCreationSteps } from '../../model/types';

const createAccountStore = useCreateAccountStore();

const taxes = computed(
	() => createAccountStore.form[AccountCreationSteps.shopTaxes].tax
);
</script>

<template>
	<Divider />

	<div class="tw3-text-xl tw3-my-4">Taxes</div>

	<div v-if="!createAccountStore.hasTaxes" class="tw3-py-4 tw3-text-center">
		<i class="pi pi-file-plus !tw3-text-5xl tw3-text-slate-300" />
		<div class="tw3-text-slate-400 tw3-mt-4">Add tax from the form above</div>
	</div>

	<div v-else class="tw3-border tw3-rounded-md tw3-divide-y">
		<div
			v-for="(tax, index) in taxes"
			:key="index"
			class="tw3-flex tw3-items-center tw3-p-4"
		>
			<div class="tw3-flex-grow tw3-flex tw3-items-center tw3-space-x-3">
				<div class="tw3-text-slate-800">{{ tax.name }}</div>

				<div class="tw3-text-slate-500">{{ tax.percentage }}%</div>

				<div
					v-if="tax.for_labor"
					class="tw3-bg-green-100 tw3-text-green-800 tw3-rounded-full tw3-px-3 tw3-py-1 tw3-text-sm"
				>
					Labor
				</div>

				<div
					v-if="tax.for_materials"
					class="tw3-bg-blue-100 tw3-text-blue-800 tw3-rounded-full tw3-px-3 tw3-py-1 tw3-text-sm"
				>
					Materials
				</div>

				<div
					v-if="tax.default_tax"
					class="tw3-bg-purple-100 tw3-text-purple-800 tw3-rounded-full tw3-px-3 tw3-py-1 tw3-text-sm"
				>
					Default
				</div>
			</div>
			<div
				class="tw3-flex-shrink-0 tw3-text-red-500 tw3-cursor-pointer"
				@click="createAccountStore.removeShopTax(index)"
			>
				Remove
			</div>
		</div>
	</div>
</template>
