import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, unref as _unref, createBlock as _createBlock, createElementVNode as _createElementVNode, withKeys as _withKeys, createVNode as _createVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "tw3-flex tw3-flex-col tw3-w-[500px] tw3-h-[313px]" }
const _hoisted_2 = { class: "tw3-relative tw3-flex tw3-flex-col tw3-gap-2 tw3-pb-[0.75rem] tw3-px-[0.25rem] tw3-pt-[0.25rem] tw3-flex-1 tw3-overflow-y-auto notes-container" }
const _hoisted_3 = {
  key: 0,
  class: "tw3-m-auto"
}
const _hoisted_4 = { class: "tw3-flex tw3-flex-col sm:tw3-flex-row tw3-items-center tw3-pt-[0.75rem] tw3-border-t tw3-border-surface-200 tw3-gap-4" }

import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import InputText from 'primevue/inputtext';
import { useConfirm } from 'primevue/useconfirm';
import { ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';

import { useUserStore } from '@/entities/user/store';
import { useMessages } from '@/shared/composables';

import { JobNote, JobNoteResponse } from '../../../model/types';
import JobNoteCell from '../../cells/JobNoteCell.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'JobEditNotes',
  props: {
    data: {},
    show: { type: Boolean },
    loading: { type: Boolean }
  },
  emits: ["on-close", "on-create", "on-delete"],
  setup(__props: any, { emit: __emit }) {

const { t } = useI18n();

const props = __props;
const emit = __emit;

const message = useMessages();
const userStore = useUserStore();

const noteInput = ref('');

const confirm = useConfirm();

const deletePressed = (data: JobNote) => {
	confirm.require({
		accept: () => {
			emit('on-delete', data);
		},
		acceptLabel: t('yes'),
		acceptProps: {
			severity: 'success'
		},
		header: t('note.delete'),
		message: t('note.deleteConfirm'),
		rejectLabel: t('no'),
		rejectProps: {
			severity: 'secondary'
		}
	});
};

const save = async () => {
	if (!props.data) return;
	if (!noteInput.value || !noteInput.value.length) {
		message.showError(t('note.emptyError'));
		return;
	}
	emit('on-create', {
		id: -1,
		created: -1,
		modified: -1,
		note_date: getCurrentDate(),
		note_text: noteInput.value,
		user_id: +userStore.user.user.id,
		note_sequence: props.data.notes.length
			? props.data.notes[0].note_sequence + 1
			: 0
	});
	noteInput.value = '';
};

const getCurrentDate = () => {
	const now = new Date();

	return now
		.toLocaleString('en-US', {
			year: 'numeric',
			month: '2-digit',
			day: '2-digit',
			hour: '2-digit',
			minute: '2-digit',
			hour12: true
		})
		.replace(',', '');
};

const getUser = (userId: number) => {
	return props.data.users.find(v => v.id === userId);
};

watch(
	() => props.show,
	() => {
		noteInput.value = '';
	}
);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(Dialog), {
    id: "vue3JobEditNotesDialog",
    header: _ctx.$t('jobNotes'),
    modal: "",
    visible: _ctx.show,
    "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => (emit('on-close')))
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (!_ctx.data.notes.length)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.$t('note.empty')), 1))
            : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.data.notes, (item) => {
                return (_openBlock(), _createBlock(JobNoteCell, {
                  key: item.id,
                  class: "tw3-w-fit",
                  note: item,
                  showDelete: item.user_id === +_unref(userStore).user.user.id,
                  user: getUser(item.user_id),
                  onDelete: ($event: any) => (deletePressed(item))
                }, null, 8, ["note", "showDelete", "user", "onDelete"]))
              }), 128))
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_unref(InputText), {
            modelValue: noteInput.value,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((noteInput).value = $event)),
            autofocus: "",
            class: "tw3-w-full tw3-rounded",
            disabled: _ctx.loading,
            placeholder: _ctx.$t('note.placeholder'),
            type: "text",
            onKeyup: _withKeys(save, ["enter"])
          }, null, 8, ["modelValue", "disabled", "placeholder"]),
          _createVNode(_unref(Button), {
            class: "tw3-w-full sm:tw3-w-auto",
            icon: "pi pi-send",
            loading: _ctx.loading,
            "pt:root:class": "tw3-rounded-t-none",
            size: "small",
            onClick: save
          }, null, 8, ["loading"])
        ])
      ])
    ]),
    _: 1
  }, 8, ["header", "visible"]))
}
}

})