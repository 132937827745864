import { coreApi } from '@/shared/api';
import { DefaultApiResponse } from '@/shared/types/default-api-response';

import {
	Job,
	JobExportRequest,
	JobExportResponse,
	JobRequestParams
} from '../model/types';
import { JobFilters } from '../model/types/job-filters.interface';
import { JobListView } from '../model/types/job-list-view.interface';

const JOB_LIST_URL = '/unum/job-details/jobslist';
const JOB_FILTERS_URL = '/api/jobs';
const JOB_LIST_VIEW_URL = JOB_FILTERS_URL + '/jobslist_view';

export const getMany = async (
	params: JobRequestParams
): Promise<DefaultApiResponse<Job>> => {
	try {
		const response = await coreApi().post<DefaultApiResponse<Job>>(
			JOB_LIST_URL + '/search-v2',
			params
		);

		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
};

export const getOne = async (id: number): Promise<DefaultApiResponse<Job>> => {
	try {
		const response = await coreApi().post<DefaultApiResponse<Job>>(
			JOB_LIST_URL + '/row-v2',
			{ filters: { job_id: id.toString() } }
		);

		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
};

export const getFiltersForJobs = async (): Promise<JobFilters> => {
	try {
		const response = await coreApi().post<JobFilters>(JOB_FILTERS_URL, {
			request: 'filterData'
		});

		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
};

export const exportJobs = async (
	params: JobExportRequest
): Promise<JobExportResponse> => {
	try {
		const response = await coreApi().post<JobExportResponse>(
			JOB_LIST_URL + '/export',
			params
		);

		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
};

export const getJobslistViews = async (): Promise<JobListView[]> => {
	try {
		const response = await coreApi().get<JobListView[]>(JOB_LIST_VIEW_URL);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
};

export const createOrUpdateJobListView = async (
	id: number,
	isDefault: boolean,
	params?: Partial<JobListView> & { jobslistViewColumns: any[] }
): Promise<Exclude<JobListView, '_jobslistViewColumns'>> => {
	try {
		const response = await coreApi().post<
			Exclude<JobListView, '_jobslistViewColumns'>
		>(JOB_LIST_VIEW_URL + `/${id}?default_view=${isDefault}`, params);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
};

export const deleteJobListView = async (id: number) => {
	try {
		await coreApi().delete(JOB_LIST_VIEW_URL + `/${id}`);
		return true;
	} catch (error) {
		console.error(error);
		throw error;
	}
};
