import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withModifiers as _withModifiers, withKeys as _withKeys, createBlock as _createBlock, createCommentVNode as _createCommentVNode, isRef as _isRef } from "vue"

const _hoisted_1 = { class: "tw3-grid tw3-grid-cols-12 tw3-gap-4 tw3-items-start" }
const _hoisted_2 = { class: "tw3-flex tw3-col-span-6 tw3-flex-col tw3-gap-2" }
const _hoisted_3 = { class: "tw3-text-sm" }
const _hoisted_4 = { class: "tw3-flex tw3-flex-wrap tw3-items-center tw3-gap-2" }
const _hoisted_5 = { class: "tw3-flex tw3-col-span-6 tw3-flex-col tw3-gap-2" }
const _hoisted_6 = { class: "tw3-text-sm" }
const _hoisted_7 = { class: "tw3-flex tw3-w-full tw3-p-1" }
const _hoisted_8 = { class: "tw3-col-span-4 tw3-flex-col tw3-flex tw3-gap-2" }
const _hoisted_9 = { class: "tw3-text-sm" }
const _hoisted_10 = {
  key: 0,
  class: "tags tw3-flex tw3-flex tw3-gap-2 tw3-flex-wrap"
}
const _hoisted_11 = { class: "tw3-flex tw3-items-center tw3-gap-2 tw3-text-sm" }
const _hoisted_12 = ["onClick"]
const _hoisted_13 = {
  key: 1,
  class: "p-error"
}
const _hoisted_14 = { class: "tw3-col-span-4 tw3-flex-col tw3-flex tw3-gap-2" }
const _hoisted_15 = { class: "tw3-text-sm" }
const _hoisted_16 = {
  key: 0,
  class: "p-error"
}
const _hoisted_17 = { class: "tw3-col-span-4 tw3-flex tw3-gap-2 tw3-items-center tw3-pt-[30px]" }
const _hoisted_18 = {
  class: "tw3-select-none",
  for: "ccReplyTo"
}
const _hoisted_19 = { class: "tw3-col-span-12 tw3-flex-col tw3-flex tw3-gap-2" }
const _hoisted_20 = { class: "tw3-text-sm" }
const _hoisted_21 = {
  key: 0,
  class: "p-error"
}
const _hoisted_22 = { class: "tw3-col-span-12 tw3-flex-col tw3-flex tw3-gap-2" }
const _hoisted_23 = {
  key: 0,
  class: "p-error"
}
const _hoisted_24 = { class: "tw3-flex-col tw3-flex tw3-gap-4 tw3-col-span-12 md:tw3-col-span-4" }
const _hoisted_25 = { class: "tw3-flex tw3-flex-col tw3-gap-2" }
const _hoisted_26 = { class: "tw3-text-sm" }
const _hoisted_27 = { class: "tw3-flex tw3-items-center tw3-gap-2" }
const _hoisted_28 = { class: "tw3-flex tw3-flex-col tw3-flex tw3-gap-2" }
const _hoisted_29 = { class: "tw3-text-sm" }
const _hoisted_30 = { class: "tw3-col-span-12 md:tw3-col-span-8 tw3-gap-4 tw3-flex tw3-flex-col tw3-justify-between tw3-items-end tw3-h-full" }
const _hoisted_31 = { class: "tw3-flex tw3-gap-2 tw3-flex-wrap tw3-items-end tw3-justify-end" }
const _hoisted_32 = { class: "tw3-max-w-full tw3-overflow-hidden tw3-text-ellipsis tw3-whitespace-nowrap tw3-justify-start" }
const _hoisted_33 = ["onClick"]
const _hoisted_34 = { class: "tw3-flex tw3-gap-4 tw3-items-end tw3-w-8/12" }

import { toTypedSchema } from '@vee-validate/zod';
import * as filestack from 'filestack-js';
import { PickerFileMetadata, PickerResponse } from 'filestack-js';
import Button from 'primevue/button';
import Checkbox from 'primevue/checkbox';
import Dialog from 'primevue/dialog';
import Editor from 'primevue/editor';
import InputText from 'primevue/inputtext';
import Select from 'primevue/select';
import Tab from 'primevue/tab';
import TabList from 'primevue/tablist';
import TabPanel from 'primevue/tabpanel';
import TabPanels from 'primevue/tabpanels';
import Tabs from 'primevue/tabs';
import Tag from 'primevue/tag';
import ToggleSwitch from 'primevue/toggleswitch';
import { useToast } from 'primevue/usetoast';
import { useField, useFieldArray, useForm } from 'vee-validate';
import { onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import * as zod from 'zod';

import useAppStore from '@/entities/app/lib/store';
import { sendEmailReport } from '@/widgets/reportsElements/api/api';
import useEmailStore from '@/widgets/reportShareDialog/model/store';

import { EmailTemplate, TextMessageTemplate } from '../model/types';
import TextMessagesTemplates from '../textMessagesTemplates/TextMessagesTemplates.vue';

import EmailHistory from './EmailHistory.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'ReportShareDialog',
  props: /*@__PURE__*/_mergeModels({
    data: {}
  }, {
    "visible": { type: Boolean },
    "visibleModifiers": {},
  }),
  emits: ["update:visible"],
  setup(__props: any) {

const { t } = useI18n();

const props = __props;

const appStore = useAppStore();
const emailStore = useEmailStore();
const emailTemplates = ref<EmailTemplate[]>([]);
const showModal = _useModel<boolean>(__props, 'visible');
const currentTab = ref<string>('email');
const bodyEditor = ref();
const emailShortcuts = [
	{
		label: 'SGC',
		address: 'invoicingfax@safelite.com'
	},
	{
		label: 'SGC-POP',
		address: 'mailbox.sgcpop@safelite.com'
	},
	{
		label: 'SGC-Shop Care',
		address: 'shopcare@sgcnetwork.com'
	},
	{
		label: 'SGC Research',
		address: 'researchfax@safelite.com'
	},
	{
		label: 'Lynx',
		address: 'fieldsupport@lynxservices.com'
	}
];
const selectedTemplate = ref<EmailTemplate>();
const toast = useToast();

const refreshEmailTemplates = async () => {
	emailTemplates.value = await emailStore.getEmailTemplatesHandler(8);
};

const updateDataInTab = (tabValue: string | number) => {
	if (tabValue === 'history') {
		emailStore.emailHistory.execute(0, props.data.fileType);
	}
};

onMounted(() => {
	refreshEmailTemplates();
});

const openFilePicker = () => {
	const client = filestack.init(appStore.appConfig.state.filePickerKey);
	const options = {
		accept: ['application/pdf'],
		maxFiles: 1,
		onUploadDone: addFile
	};
	client.picker(options).open();
};

let quillInstance: any = null;
const onEditorLoad = ({ instance }: { instance: any }) => {
	quillInstance = instance;
};
const fillFormWithTemplate = (value: TextMessageTemplate | null) => {
	if (value) {
		subject.value = value.subject;
		updateEditorContent(value.body);
	} else {
		clearPreparedTemplate();
	}
};

const updateEditorContent = (value: string) => {
	if (quillInstance) {
		quillInstance.setContents(
			quillInstance.clipboard.convert({
				html: value
			})
		);
	}
};

const clearPreparedTemplate = () => {
	subject.value = '';
	updateEditorContent('');
};

const showTemplatesDialog = ref(false);

const validationSchema = toTypedSchema(
	zod.object({
		destination: zod
			.array(
				zod
					.string()
					.email(t('reportsPage.textMessagesTemplate.errors.emailInvalid'))
			)
			.min(1, t('reportsPage.textMessagesTemplate.errors.emailReq')),
		replyTo: zod
			.string()
			.email(t('reportsPage.textMessagesTemplate.errors.emailInvalid'))
			.optional()
			.or(zod.literal('')),
		ccReplyTo: zod.boolean(),

		subject: zod
			.string()
			.min(3, t('reportsPage.textMessagesTemplate.errors.subject')),
		body: zod
			.string()
			.min(10, t('reportsPage.textMessagesTemplate.errors.body')),
		attachmentUrls: zod.array(zod.object({})).optional().default([])
	})
);

const formObject = useForm({
	validationSchema,
	initialValues: {
		destination: [],
		replyTo: '',
		ccReplyTo: false,
		subject: '',
		body: '',
		attachmentUrls: []
	}
});

const {
	fields: destination,
	push: addDestination,
	remove: removeDestination
} = useFieldArray<string>('destination');
const { value: replyTo, errorMessage: replyToError } =
	useField<string>('replyTo');
const { value: ccReplyTo } = useField<boolean>('ccReplyTo');
const { value: subject, errorMessage: subjectError } =
	useField<string>('subject');
const { value: body, errorMessage: bodyError } = useField<string>('body');
const {
	fields: attachmentUrls,
	push: addAttachment,
	remove: removeAttachment
} = useFieldArray<{ url: string; name: string }>('attachmentUrls');

const destinationInput = ref();
const addEmail = (email: string) => {
	if (!email.trim() || destination.value.some(field => field.value === email))
		return;
	destinationError.value = null;

	addDestination(email);
	destinationInput.value = '';
};

const deleteEmail = (email: string) => {
	const index = destination.value.findIndex(field => field.value === email);

	destinationError.value = null;
	if (index !== -1) {
		removeDestination(index);
	}
};

const customFiles = ref<PickerFileMetadata[]>([]);
const addFile = (result: PickerResponse) => {
	const uploadedFile = {
		name: result.filesUploaded[0].filename,
		url: result.filesUploaded[0].url
	};
	addAttachment(uploadedFile);
};

const deleteFile = (url: string) => {
	const index = attachmentUrls.value.findIndex(item => item.value.url === url);

	if (index !== -1) {
		customFiles.value.splice(index, 1);
		removeAttachment(index);
	}
};

const reportIsAttaching = ref(true);
const togglePreparedAttachment = (value: boolean) => {
	if (value) {
		addAttachment(props.data.fileObj);
	} else {
		deleteFile(props.data.fileObj.url);
	}
};

onMounted(() => {
	togglePreparedAttachment(reportIsAttaching.value);
});

const destinationError = ref<string | null>();

const isFormLoading = ref(false);
const submitForm = async () => {
	const result = await formObject.validate();

	if (result.valid) {
		const files = formObject.values.attachmentUrls?.map(item => item.url);

		try {
			await sendEmailReport({
				data: {
					to: formObject.values.destination,
					body: formObject.values.body,
					subject: formObject.values.subject,
					replyTo: formObject.values.replyTo,
					ccReplyTo: formObject.values.ccReplyTo ? 1 : null
				},
				fileType: 'report',
				shops: props.data.shops,
				attachmentUrls: files
			});
			isFormLoading.value = false;
			closeModal();
			toast.add({
				life: 3000,
				severity: 'success',
				detail: t('reportsPage.textMessagesTemplate.emailSended'),
				summary: t('copyTextToast.succesSummary')
			});
		} catch (error) {
			toast.add({
				life: 3000,
				severity: 'error',
				detail: t('defaultToast.errorDetails'),
				summary: `${t('defaultToast.errorSummary')} ${error}`
			});
			isFormLoading.value = false;
		}
	} else {
		const pattern = /^destination(\[\d+\])?$/;

		const firstMatchingKey = Object.keys(result.errors).find(key =>
			pattern.test(key)
		);
		destinationError.value = result.errors[firstMatchingKey as string];
	}
};

const closeModal = () => {
	showModal.value = false;
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(Dialog), {
    id: "EmailGenericWindow",
    visible: showModal.value,
    "onUpdate:visible": _cache[12] || (_cache[12] = ($event: any) => ((showModal).value = $event)),
    appendTo: "#vue3app",
    class: "tw3-max-w-[1200px] tw3-h-full tw3-w-full tw3-pb-4",
    header: _unref(t)('email'),
    modal: "",
    "pt:content": 'tw3-flex tw3-flex-col'
  }, {
    default: _withCtx(() => [
      _createVNode(_unref(Tabs), {
        value: currentTab.value,
        "onUpdate:value": updateDataInTab
      }, {
        default: _withCtx(() => [
          _createVNode(_unref(TabList), null, {
            default: _withCtx(() => [
              _createVNode(_unref(Tab), {
                "pt:root:class": '!tw3-pt-[16px]',
                value: "email"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("span", null, _toDisplayString(_unref(t)('email')), 1)
                ]),
                _: 1
              }),
              _createVNode(_unref(Tab), {
                "pt:root:class": '!tw3-pt-[16px]',
                value: "history"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("span", null, _toDisplayString(_unref(t)('History')), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_unref(TabPanels), {
            class: "!tw3-p-0",
            style: {"height":"calc(100% - 42px)"}
          }, {
            default: _withCtx(() => [
              _createVNode(_unref(TabPanel), {
                class: "tw3-min-h-full tw3-p-6 tw3-flex tw3-flex-col tw3-gap-4",
                value: "email"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_1, [
                    _createElementVNode("div", _hoisted_2, [
                      _createElementVNode("div", _hoisted_3, _toDisplayString(_unref(t)('reportsPage.textMessagesTemplate.quickAddresses')), 1),
                      _createElementVNode("div", _hoisted_4, [
                        (_openBlock(), _createElementBlock(_Fragment, null, _renderList(emailShortcuts, (shortcut) => {
                          return _createVNode(_unref(Button), {
                            key: shortcut.label,
                            severity: "secondary",
                            size: "small",
                            onClick: ($event: any) => (addEmail(shortcut.address))
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(shortcut.label), 1)
                            ]),
                            _: 2
                          }, 1032, ["onClick"])
                        }), 64))
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_5, [
                      _createElementVNode("span", _hoisted_6, _toDisplayString(_unref(t)('reportsPage.textMessagesTemplate.templates')), 1),
                      _createVNode(_unref(Select), {
                        modelValue: selectedTemplate.value,
                        "onUpdate:modelValue": [
                          _cache[1] || (_cache[1] = ($event: any) => ((selectedTemplate).value = $event)),
                          fillFormWithTemplate
                        ],
                        class: "w-full md:w-56",
                        optionLabel: "name",
                        options: emailTemplates.value,
                        placeholder: `${_unref(t)('reportsPage.textMessagesTemplate.selectTemplate')}...`,
                        showClear: ""
                      }, {
                        footer: _withCtx(() => [
                          _createElementVNode("div", _hoisted_7, [
                            _createVNode(_unref(Button), {
                              class: "tw3-w-full",
                              label: 
												_unref(t)('reportsPage.textMessagesTemplate.manageTemplates')
											,
                              severity: "secondary",
                              onClick: _cache[0] || (_cache[0] = ($event: any) => (showTemplatesDialog.value = true))
                            }, null, 8, ["label"])
                          ])
                        ]),
                        _: 1
                      }, 8, ["modelValue", "options", "placeholder"])
                    ])
                  ]),
                  _createElementVNode("form", {
                    class: "tw3-grid tw3-grid-cols-12 tw3-gap-4 tw3-items-start",
                    onSubmit: _withModifiers(submitForm, ["prevent"])
                  }, [
                    _createElementVNode("div", _hoisted_8, [
                      _createElementVNode("span", _hoisted_9, _toDisplayString(_unref(t)('to')) + " *", 1),
                      _createVNode(_unref(InputText), {
                        modelValue: destinationInput.value,
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((destinationInput).value = $event)),
                        invalid: destinationError.value ? true : false,
                        placeholder: `${_unref(t)('reportsPage.textMessagesTemplate.typeAndEnter')}...`,
                        onKeydown: _cache[3] || (_cache[3] = _withKeys(_withModifiers(($event: any) => (addEmail(destinationInput.value)), ["prevent"]), ["enter"]))
                      }, null, 8, ["modelValue", "invalid", "placeholder"]),
                      (_unref(destination).length)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(destination), (tag) => {
                              return (_openBlock(), _createBlock(_unref(Tag), {
                                key: tag,
                                severity: "secondary"
                              }, {
                                default: _withCtx(() => [
                                  _createElementVNode("div", _hoisted_11, [
                                    _createElementVNode("span", null, _toDisplayString(tag.value), 1),
                                    _createElementVNode("i", {
                                      class: "pi pi-times-circle tw3-cursor-pointer",
                                      style: {"font-size":"0.7rem"},
                                      onClick: ($event: any) => (deleteEmail(tag.value))
                                    }, null, 8, _hoisted_12)
                                  ])
                                ]),
                                _: 2
                              }, 1024))
                            }), 128))
                          ]))
                        : _createCommentVNode("", true),
                      (destinationError.value)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_13, _toDisplayString(destinationError.value), 1))
                        : _createCommentVNode("", true)
                    ]),
                    _createElementVNode("div", _hoisted_14, [
                      _createElementVNode("span", _hoisted_15, _toDisplayString(_unref(t)('replyTo')), 1),
                      _createVNode(_unref(InputText), {
                        modelValue: _unref(replyTo),
                        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (_isRef(replyTo) ? (replyTo).value = $event : null)),
                        invalid: _unref(replyToError) ? true : false,
                        placeholder: `${_unref(t)('replyTo')}...`
                      }, null, 8, ["modelValue", "invalid", "placeholder"]),
                      (_unref(replyToError))
                        ? (_openBlock(), _createElementBlock("span", _hoisted_16, _toDisplayString(_unref(replyToError)), 1))
                        : _createCommentVNode("", true)
                    ]),
                    _createElementVNode("div", _hoisted_17, [
                      _createVNode(_unref(Checkbox), {
                        modelValue: _unref(ccReplyTo),
                        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (_isRef(ccReplyTo) ? (ccReplyTo).value = $event : null)),
                        binary: "",
                        disabled: !_unref(replyTo),
                        inputId: "ccReplyTo"
                      }, null, 8, ["modelValue", "disabled"]),
                      _createElementVNode("label", _hoisted_18, " CC " + _toDisplayString(_unref(t)('replyTo')), 1)
                    ]),
                    _createElementVNode("div", _hoisted_19, [
                      _createElementVNode("span", _hoisted_20, _toDisplayString(_unref(t)('subject')) + " *", 1),
                      _createVNode(_unref(InputText), {
                        modelValue: _unref(subject),
                        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => (_isRef(subject) ? (subject).value = $event : null)),
                        invalid: _unref(subjectError) ? true : false,
                        placeholder: `${_unref(t)('subject')}...`
                      }, null, 8, ["modelValue", "invalid", "placeholder"]),
                      (_unref(subjectError))
                        ? (_openBlock(), _createElementBlock("span", _hoisted_21, _toDisplayString(_unref(subjectError)), 1))
                        : _createCommentVNode("", true)
                    ]),
                    _createElementVNode("div", _hoisted_22, [
                      _createVNode(_unref(Editor), {
                        ref_key: "bodyEditor",
                        ref: bodyEditor,
                        modelValue: _unref(body),
                        "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => (_isRef(body) ? (body).value = $event : null)),
                        class: "tw3-col-span-12",
                        editorStyle: "height: 250px",
                        onLoad: onEditorLoad
                      }, null, 8, ["modelValue"]),
                      (_unref(bodyError))
                        ? (_openBlock(), _createElementBlock("span", _hoisted_23, _toDisplayString(_unref(bodyError)), 1))
                        : _createCommentVNode("", true)
                    ]),
                    _createElementVNode("div", _hoisted_24, [
                      _createElementVNode("div", _hoisted_25, [
                        _createElementVNode("span", _hoisted_26, _toDisplayString(_unref(t)('attachments')), 1),
                        _createElementVNode("div", _hoisted_27, [
                          _createVNode(_unref(ToggleSwitch), {
                            modelValue: reportIsAttaching.value,
                            "onUpdate:modelValue": [
                              _cache[8] || (_cache[8] = ($event: any) => ((reportIsAttaching).value = $event)),
                              togglePreparedAttachment
                            ]
                          }, null, 8, ["modelValue"]),
                          _createElementVNode("p", {
                            class: "tw3-select-none tw3-cursor-pointer",
                            onClick: _cache[9] || (_cache[9] = 
											() => {
												togglePreparedAttachment(!reportIsAttaching.value);
												reportIsAttaching.value = !reportIsAttaching.value;
											}
										)
                          }, _toDisplayString(_ctx.data.fileObj.name), 1)
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_28, [
                        _createElementVNode("span", _hoisted_29, _toDisplayString(_unref(t)('additionalAttachments')), 1),
                        _createVNode(_unref(Button), {
                          label: _unref(t)('faxDialog.uploadFax.chooseFile'),
                          severity: "secondary",
                          type: "button",
                          onClick: openFilePicker
                        }, null, 8, ["label"])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_30, [
                      _createElementVNode("div", _hoisted_31, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(attachmentUrls), (item) => {
                          return (_openBlock(), _createElementBlock(_Fragment, {
                            key: item.value.url
                          }, [
                            (item.value.url !== _ctx.data.fileObj.url)
                              ? (_openBlock(), _createBlock(_unref(Tag), {
                                  key: 0,
                                  class: "tw3-flex tw3-gap-2 tw3-items-center tw3-max-w-full",
                                  severity: "secondary"
                                }, {
                                  default: _withCtx(() => [
                                    _createElementVNode("span", _hoisted_32, _toDisplayString(item.value.name), 1),
                                    _createElementVNode("i", {
                                      class: "pi pi-times-circle tw3-cursor-pointer",
                                      style: {"font-size":"0.7rem"},
                                      onClick: ($event: any) => (deleteFile(item.value.url))
                                    }, null, 8, _hoisted_33)
                                  ]),
                                  _: 2
                                }, 1024))
                              : _createCommentVNode("", true)
                          ], 64))
                        }), 128))
                      ]),
                      _createElementVNode("div", _hoisted_34, [
                        _createVNode(_unref(Button), {
                          class: "tw3-w-6/12",
                          label: _unref(t)('cancel'),
                          severity: "secondary",
                          onClick: _cache[10] || (_cache[10] = ($event: any) => (showModal.value = false))
                        }, null, 8, ["label"]),
                        _createVNode(_unref(Button), {
                          class: "tw3-w-6/12",
                          label: "Send",
                          type: "submit"
                        })
                      ])
                    ])
                  ], 32)
                ]),
                _: 1
              }),
              _createVNode(_unref(TabPanel), {
                class: "tw3-min-h-full",
                value: "history"
              }, {
                default: _withCtx(() => [
                  _createVNode(EmailHistory)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["value"]),
      _createVNode(TextMessagesTemplates, {
        visible: showTemplatesDialog.value,
        "onUpdate:visible": _cache[11] || (_cache[11] = ($event: any) => ((showTemplatesDialog).value = $event)),
        onHide: refreshEmailTemplates
      }, null, 8, ["visible"])
    ]),
    _: 1
  }, 8, ["visible", "header"]))
}
}

})