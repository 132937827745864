import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "table-container" }
const _hoisted_2 = { key: 0 }

import { useI18n } from 'vue-i18n';


export default /*@__PURE__*/_defineComponent({
  __name: 'NagsLicenseReportTable',
  props: {
    data: {}
  },
  setup(__props: any) {



const { t } = useI18n();

const currentColumns = [
	{
		label: `${t('reportsPage.table.license')} #`,
		prop: 'id'
	},
	{
		label: t('reportsPage.table.account'),
		prop: 'account'
	},
	{
		label: t('reportsPage.table.address'),
		prop: 'address'
	},
	{
		label: t('reportsPage.table.anniversaryDate'),
		prop: 'anniversary_date'
	},
	{
		label: t('reportsPage.table.renewalDate'),
		prop: 'date'
	},
	{
		label: `# ${t('reportsPage.table.ofUsers')}`,
		prop: 'users'
	},
	{
		label: t('reportsPage.table.fee'),
		prop: 'fee'
	}
];

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.data)
      ? (_openBlock(), _createElementBlock("table", _hoisted_2, [
          _createElementVNode("tbody", null, [
            _createElementVNode("tr", null, [
              (_openBlock(), _createElementBlock(_Fragment, null, _renderList(currentColumns, (col) => {
                return _createElementVNode("th", {
                  key: col.prop,
                  class: "cell",
                  scope: "col"
                }, _toDisplayString(col.label), 1)
              }), 64))
            ]),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data, (row) => {
              return (_openBlock(), _createElementBlock("tr", {
                key: row.id
              }, [
                (_openBlock(), _createElementBlock(_Fragment, null, _renderList(currentColumns, (col) => {
                  return _createElementVNode("td", {
                    key: col.prop,
                    class: "cell"
                  }, _toDisplayString(row[col.prop]), 1)
                }), 64))
              ]))
            }), 128))
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})