import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { id: "royalty-report-franchisee-data" }
const _hoisted_3 = { id: "royalty-report-summary-table" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = {
  key: 1,
  class: "cell"
}
const _hoisted_6 = {
  key: 2,
  class: "cell"
}
const _hoisted_7 = {
  key: 3,
  class: "cell"
}
const _hoisted_8 = {
  key: 4,
  class: "cell"
}
const _hoisted_9 = {
  key: 5,
  class: "cell"
}

import { storeToRefs } from 'pinia';
import { useI18n } from 'vue-i18n';

import useReportsStore from '@/widgets/reportsElements/model/store';
import { reportsGetters } from '@/widgets/reportsElements/model/utils/getters';


export default /*@__PURE__*/_defineComponent({
  __name: 'RoyaltySummaryReportTable',
  props: {
    data: {}
  },
  setup(__props: any) {



const { t } = useI18n();

const reportStore = useReportsStore();
const { currentColumns } = storeToRefs(reportStore);

return (_ctx: any,_cache: any) => {
  return (_ctx.data)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", null, _toDisplayString(_unref(t)('reportsPage.table.franchiseId')) + ": 237", 1),
          _createElementVNode("div", null, _toDisplayString(_unref(t)('reportsPage.table.account')) + ": 950217", 1)
        ]),
        _createElementVNode("table", _hoisted_3, [
          _createElementVNode("tbody", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(currentColumns), (col) => {
              return (_openBlock(), _createElementBlock("tr", {
                key: col.id
              }, [
                (col.prop)
                  ? (_openBlock(), _createElementBlock("td", {
                      key: 0,
                      class: "cell",
                      style: _normalizeStyle({ 'font-weight': col.bold ? 'bold' : 'normal' }),
                      innerHTML: col.label
                    }, null, 12, _hoisted_4))
                  : (_openBlock(), _createElementBlock("td", _hoisted_5, " ")),
                (col.dollar)
                  ? (_openBlock(), _createElementBlock("td", _hoisted_6, _toDisplayString(_unref(reportsGetters).formatNumber(_ctx.data[col.prop], {
								style: 'dollar'
							})), 1))
                  : (col.percent)
                    ? (_openBlock(), _createElementBlock("td", _hoisted_7, _toDisplayString(`${_ctx.data[col.prop]}%`), 1))
                    : (col.prop)
                      ? (_openBlock(), _createElementBlock("td", _hoisted_8, _toDisplayString(_ctx.data[col.prop]), 1))
                      : (_openBlock(), _createElementBlock("td", _hoisted_9, " "))
              ]))
            }), 128))
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}
}

})