import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, unref as _unref, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import {
	reportsGetters,
	splitByContractor,
	splitReportDataByAttr
} from '@/widgets/reportsElements/model/utils/getters';

import { ReportType } from '../../api/types';
import { dateFilterMap } from '../../model/constants';
import useReportsStore from '../../model/store';

import TableDefaultBody from './TableDefaultBody.vue';
import TableRowSpacer from './TableRowSpacer.vue';
import TableSubhead from './TableSubhead.vue';
import TableTotals from './TableTotals.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'TableVariableBody',
  props: {
    data: {},
    splitBy: {},
    reportType: {},
    columns: {}
  },
  setup(__props: any) {

const { t } = useI18n();

const props = __props;

const displayFortyPercentHeader = (
	data: {
		forty_percent?: boolean;
	}[]
) => {
	if (data && data.length) {
		if (data[0].forty_percent) {
			return true;
		}
	}
	return false;
};

const splitedData = computed(() => {
	if (props.splitBy == 'name') {
		return splitByContractor(props.data);
	} else {
		return splitReportDataByAttr(props.splitBy)(props.data);
	}
});

const prepareLabel = (value: string): string => {
	let label = t('reportsPage.grand');
	if (value == 'shop_name') {
		label = t('shop');
	} else if (value == 'customerName') {
		label = t('reportsPage.customer');
	} else if (value == 'name') {
		label = t('reportsPage.contractor');
	}
	return label;
};

const reportsStore = useReportsStore();
const { currentReportType, dateType } = storeToRefs(reportsStore);

const currentDateType = computed(() => {
	if (currentReportType.value.date) {
		return currentReportType.value.date;
	} else {
		return reportsGetters.dateColumnLabel(
			dateType.value as keyof typeof dateFilterMap
		);
	}
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(splitedData.value, (singleData, splitedLabel) => {
      return (_openBlock(), _createElementBlock(_Fragment, { key: splitedLabel }, [
        _createVNode(TableSubhead, {
          columns: _ctx.columns,
          data: singleData,
          displayFortyPercentHeader: displayFortyPercentHeader(singleData),
          label: splitedLabel !== 'undefined' ? splitedLabel : 'Multiple Selected',
          reportType: _ctx.reportType,
          type: _ctx.splitBy
        }, null, 8, ["columns", "data", "displayFortyPercentHeader", "label", "reportType", "type"]),
        (_ctx.splitBy !== 'date')
          ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_unref(splitReportDataByAttr)('date')(singleData), (date_data, date) => {
              return (_openBlock(), _createElementBlock(_Fragment, {
                key: date + splitedLabel
              }, [
                _createVNode(TableSubhead, {
                  columns: _ctx.columns,
                  data: date_data,
                  displayFortyPercentHeader: displayFortyPercentHeader(date_data),
                  fake: 1,
                  label: `${currentDateType.value}: ${date}`,
                  reportType: _ctx.reportType,
                  type: "date"
                }, null, 8, ["columns", "data", "displayFortyPercentHeader", "label", "reportType"]),
                _createVNode(TableDefaultBody, {
                  columns: _ctx.columns,
                  data: date_data,
                  fake: 2
                }, null, 8, ["columns", "data"]),
                _createVNode(TableTotals, {
                  columns: _ctx.columns,
                  data: date_data,
                  fake: 2,
                  label: _unref(t)('reportsPage.daily')
                }, null, 8, ["columns", "data", "label"]),
                _createVNode(TableRowSpacer, {
                  columns: _ctx.columns,
                  fake: 2
                }, null, 8, ["columns"])
              ], 64))
            }), 128))
          : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              (_ctx.columns && singleData)
                ? (_openBlock(), _createBlock(TableDefaultBody, {
                    key: 0,
                    columns: _ctx.columns,
                    data: singleData,
                    fake: 1,
                    showTotal: ""
                  }, null, 8, ["columns", "data"]))
                : _createCommentVNode("", true)
            ], 64)),
        (_ctx.splitBy !== 'date')
          ? (_openBlock(), _createBlock(TableTotals, {
              key: 2,
              columns: _ctx.columns,
              data: singleData,
              fake: 2,
              label: prepareLabel(_ctx.splitBy)
            }, null, 8, ["columns", "data", "label"]))
          : _createCommentVNode("", true),
        _createVNode(TableRowSpacer, {
          columns: _ctx.columns,
          fake: _ctx.splitBy !== 'date' ? 2 : 1,
          shop: _ctx.splitBy == 'shop_name'
        }, null, 8, ["columns", "fake", "shop"])
      ], 64))
    }), 128)),
    (_ctx.splitBy !== 'date')
      ? (_openBlock(), _createBlock(TableTotals, {
          key: 0,
          columns: _ctx.columns,
          data: _ctx.data,
          fake: 2,
          label: _unref(t)('reportsPage.grand')
        }, null, 8, ["columns", "data", "label"]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})