import axios, { AxiosInstance } from 'axios';

import { authService } from '../auth';
import { config } from '../config';

import { applyApiHostRewrite } from './dev';

let api: AxiosInstance;

export function coreApi(): AxiosInstance {
	if (api) {
		return api;
	}

	api = axios.create({
		baseURL: config().getBaseURL()
	});

	if (process.env.NODE_ENV === 'development') {
		applyApiHostRewrite(api);
	}

	api.interceptors.request.use(request => {
		request.headers.authorization = authService().getAuthorizationHeader();
		request.headers['Visitor-Fingerprint-Id'] =
			authService().getVisitorFingerprintId();

		return request;
	});

	api.interceptors.response.use(
		async config => config,
		async error => {
			if (error.response?.status === 401) {
				if (error.response?.data?.type === 'suspended_visitor_fingerprint_id') {
					authService().handleSuspendedFingerprint();
				} else {
					authService().logout();
				}
			}
			return Promise.reject(error);
		}
	);

	return api;
}
