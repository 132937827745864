<script setup lang="ts">
import Step from 'primevue/step';
import StepItem from 'primevue/stepitem';
import StepPanel from 'primevue/steppanel';
import Stepper from 'primevue/stepper';

import { useCreateAccountStore } from '../../model/createAccount';
import { AccountCreationStepValues } from '../../model/types';
import BillingFinancingStep from '../billingFinancing/BillingFinancingStep.vue';
import BillingPlanStep from '../billingPlan/BillingPlanStep.vue';
import OrganizationStep from '../organization/OrganizationStep.vue';
import ShopInfoStep from '../shopInfo/ShopInfoStep.vue';
import ShopIntegrationsStep from '../shopIntegrations/ShopIntegrationsStep.vue';
import ShopLogoStep from '../shopLogo/ShopLogoStep.vue';
import ShopTaxesStep from '../shopTaxes/ShopTaxesStep.vue';

const createAccountStore = useCreateAccountStore();
</script>

<template>
	<Stepper linear :value="createAccountStore.step">
		<StepItem :value="AccountCreationStepValues.organization">
			<Step>Company info</Step>
			<StepPanel class="tw3-py-2">
				<OrganizationStep />
			</StepPanel>
		</StepItem>

		<StepItem :value="AccountCreationStepValues.shopInfo">
			<Step>Shop details</Step>
			<StepPanel class="tw3-py-2">
				<ShopInfoStep />
			</StepPanel>
		</StepItem>

		<StepItem :value="AccountCreationStepValues.shopLogo">
			<Step>Shop logo</Step>
			<StepPanel class="tw3-py-2">
				<ShopLogoStep />
			</StepPanel>
		</StepItem>

		<StepItem :value="AccountCreationStepValues.shopTaxes">
			<Step>Sales taxes</Step>
			<StepPanel class="tw3-py-2">
				<ShopTaxesStep />
			</StepPanel>
		</StepItem>

		<StepItem :value="AccountCreationStepValues.shopIntegrations">
			<Step>Integrations</Step>
			<StepPanel class="tw3-py-2">
				<ShopIntegrationsStep />
			</StepPanel>
		</StepItem>

		<StepItem :value="AccountCreationStepValues.billingPlan">
			<Step>Pricing plan</Step>
			<StepPanel class="tw3-py-2">
				<BillingPlanStep />
			</StepPanel>
		</StepItem>

		<StepItem :value="AccountCreationStepValues.billingFinancing">
			<Step>Financing</Step>
			<StepPanel class="tw3-py-2">
				<BillingFinancingStep />
			</StepPanel>
		</StepItem>
	</Stepper>
</template>
