import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, unref as _unref, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "table-row" }
const _hoisted_2 = ["href"]
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { key: 2 }
const _hoisted_5 = { key: 3 }
const _hoisted_6 = {
  key: 0,
  style: {"padding":"5px","font-size":"12px"}
}

import { reportsGetters } from '@/widgets/reportsElements/model/utils/getters';

import { TableColumn } from '../../api/types';

const baseUrl = 'https://pro.glassbiller.com/jobs';

export default /*@__PURE__*/_defineComponent({
  __name: 'TableRow',
  props: {
    data: {},
    columns: {},
    fake: {}
  },
  setup(__props: any) {




return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("tr", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fake, (n) => {
      return (_openBlock(), _createElementBlock("td", {
        key: n,
        class: "fake-cell"
      }))
    }), 128)),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.columns, (col) => {
      return (_openBlock(), _createElementBlock("td", {
        key: col.prop,
        class: _normalizeClass(["cell table-cell", {
				dollar: col.dollar,
				strong: col.strong,
				wrapped: col.prop === 'customer_name' || col.wrap,
				xAction: col.prop === 'x_action_number',
				memo: col.prop === 'memo'
			}])
      }, [
        (col.link)
          ? (_openBlock(), _createElementBlock("a", {
              key: 0,
              href: 
					_ctx.data[col.prop].status === 'draft'
						? `${baseUrl}/new/${_ctx.data.id}`
						: `${baseUrl}/${_ctx.data.id}`
				,
              target: "_blank"
            }, _toDisplayString(_ctx.data[col.prop]), 9, _hoisted_2))
          : (col.dollar)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, " $" + _toDisplayString(_unref(reportsGetters).formatNumber(_ctx.data[col.prop])), 1))
            : (col.isArray)
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data[col.prop], (item, index) => {
                    return (_openBlock(), _createElementBlock("span", {
                      key: item.name
                    }, _toDisplayString(item.name) + _toDisplayString(index + 1 === _ctx.data[col.prop].length ? null : ','), 1))
                  }), 128))
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.data[col.prop]), 1))
      ], 2))
    }), 128)),
    (_ctx.data['forty_percent'])
      ? (_openBlock(), _createElementBlock("td", _hoisted_6, " $" + _toDisplayString(_ctx.data['forty_percent']), 1))
      : _createCommentVNode("", true),
    (_ctx.data['margin_percentage'])
      ? (_openBlock(), _createElementBlock("td", {
          key: 1,
          style: _normalizeStyle({
				color: _ctx.data['margin_percentage_color'],
				fontWeight: 'bold',
				fontSize: '12px',
				padding: '5px'
			})
        }, _toDisplayString(_ctx.data['margin_percentage']), 5))
      : _createCommentVNode("", true)
  ]))
}
}

})