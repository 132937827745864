import { useAsyncState } from '@vueuse/core';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import { useMessages } from '@/shared/composables';
import { checkFieldsEmpty } from '@/shared/helpers';

import { create, getOne } from '../api';
import { defaultClaimDetailsState, fieldsToCheck } from '../constants';
import {
	ClaimStatus,
	CreateClaimRequest,
	OpeningTypeCode,
	PositionCode,
	RelativeLocationCode,
	SideCode
} from '../types';

export const useClaimDetailsStore = defineStore('entitiesClaimDetails', () => {
	const { t } = useI18n();
	const toastMessages = useMessages();

	const claim = useAsyncState(
		async (id: number) => {
			return getOne({
				id: id
			}).then(data => {
				//@ts-ignore
				data.claim.acknowledgementDetails.requestedAppointmentDt = new Date(
					data.claim.acknowledgementDetails.requestedAppointmentDt
				);
				data.claim.damageInfo.forEach(damageInfo => {
					//@ts-ignore
					damageInfo.id = crypto.randomUUID();
				});
				return data.claim;
			});
		},
		defaultClaimDetailsState,
		{
			immediate: false,
			shallow: false
		}
	);

	const hasEmptyFields = computed(() => {
		return checkFieldsEmpty(
			fieldsToCheck,
			claim.state.value.additionalInfo,
			claim.state.value.acknowledgementDetails,
			claim.state.value.insurance,
			...claim.state.value.damageInfo
		);
	});

	const addClaimDamageInfo = () => {
		claim.state.value.damageInfo.push({
			glassDamageQuantity: 1,
			//@ts-ignore
			id: crypto.randomUUID(),
			openingTypeCode: OpeningTypeCode.OPENING_TYPE_CODE_WR,
			positionCode: PositionCode.POSITION_CODE_B,
			relativeLocationCode: RelativeLocationCode.RELATIVE_LOCATION_CODE_IN,
			repairQualification: false,
			sideCode: SideCode.SIDE_CODE_C
		});
	};

	const removeClaimDamageInfo = (index: number) => {
		claim.state.value.damageInfo.splice(index, 1);
	};

	const restartClaim = () => {
		claim.state.value.result.status = ClaimStatus.CLAIM_STATUS_NEW;
	};

	const createIsLoading = ref(false);
	const createClaim = async (params: CreateClaimRequest) => {
		const unlinkedParams = JSON.parse(JSON.stringify(params));
		createIsLoading.value = true;
		try {
			createIsLoading.value = false;
			unlinkedParams.acknowledgementDetails.requestedAppointmentDt = new Date(
				unlinkedParams.acknowledgementDetails.requestedAppointmentDt
			).toISOString();
			unlinkedParams.insurance.dateOfLoss = new Date(
				unlinkedParams.insurance.dateOfLoss
			).toISOString();
			//@ts-ignore
			unlinkedParams.damageInfo.forEach(damageInfo => {
				//@ts-ignore
				delete damageInfo.id;
			});
			const response = await create(unlinkedParams);

			await claim.execute(0, response.id);

			if (claim.state.value.result.status === 'CLAIM_STATUS_ERROR') {
				toastMessages.showError(claim.state.value.result.errorMessage);
			} else {
				toastMessages.showSuccess(t('claimsDialog.claimsSended'));
			}
		} catch (error) {
			toastMessages.showError(error);
			createIsLoading.value = false;
			throw error;
		}
	};

	const claimIsUpdating = computed(
		() => claim.isLoading || createIsLoading.value
	);

	return {
		addClaimDamageInfo,
		claim,
		claimIsUpdating,
		createIsLoading,
		createClaim,
		hasEmptyFields,
		removeClaimDamageInfo,
		restartClaim
	};
});
