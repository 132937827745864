import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, mergeProps as _mergeProps, resolveDirective as _resolveDirective, withDirectives as _withDirectives, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "tw3-max-w-[1440px] tw3-mx-auto tw3-w-full tw3-flex tw3-justify-between tw3-mb-[1rem]" }
const _hoisted_2 = { class: "tw3-flex tw3-justify-center tw3-mt-[-8px]" }
const _hoisted_3 = {
  key: 1,
  class: "tw3-ml-auto tw3-text-xs tw3-py-1 tw3-px-2 tw3-bg-gray-200 tw3-rounded-xl"
}

import { useStorage } from '@vueuse/core';
import { storeToRefs } from 'pinia';
import Button from 'primevue/button';
import Menu from 'primevue/menu';
import { computed, onMounted, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';

import { permissionCheck, permissions } from '@/entities/user/lib/util';
import { useUserStore } from '@/entities/user/store';
import { useMessages } from '@/shared/composables';
import GbDefaultBreadcrumb from '@/shared/ui/GbDefaultBreadcrumb.vue';

import { DEFAULT_DASHBOARD_KEY } from './config/constants';
import { DashboardType } from './model/enum';
import DefaultDashboardDialog from './ui/DefaultDashboardDialog.vue';
import RevenueDashboard from './ui/RevenueDashboard.vue';
import TechDashboard from './ui/TechDashboard.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'DashboardPage',
  setup(__props) {

const { t } = useI18n();

const { showSuccess } = useMessages();
const userStore = useUserStore();
const { user } = storeToRefs(userStore);

const graphPermission = computed(() =>
	permissionCheck(permissions.GRAPH, user.value)
);

const selectDialog = ref(false);
const defaultDashboard = useStorage<DashboardType>(
	DEFAULT_DASHBOARD_KEY + '_' + user.value.user.id,
	DashboardType.revenue
);
const selectedDashboard = ref(
	graphPermission.value ? defaultDashboard.value : DashboardType.tech
);
const updateDashboardByPermission = () => {
	if (
		!graphPermission.value &&
		defaultDashboard.value !== DashboardType.revenue
	) {
		defaultDashboard.value = DashboardType.revenue;
	}
};

const tabs = computed(() => [
	{ label: t('dashboard.index') },
	{
		label:
			selectedDashboard.value === DashboardType.revenue
				? t('revenue')
				: t('technicians')
	}
]);
const menu = ref();
const menuItems = computed(() => [
	{
		label: t('dashboard.index'),
		items: [
			{
				label: t('technicians'),
				icon: 'pi pi-user',
				active: defaultDashboard.value === DashboardType.tech,
				command: () => (selectedDashboard.value = DashboardType.tech)
			},
			...(graphPermission.value
				? [
						{
							label: t('revenue'),
							icon: 'pi pi-dollar',
							active: defaultDashboard.value === DashboardType.revenue,
							command: () => (selectedDashboard.value = DashboardType.revenue)
						},
						{
							label: t('selectDefault'),
							command: () => (selectDialog.value = true)
						}
					]
				: [])
		]
	}
]);

const toggle = (event: MouseEvent) => {
	menu.value.toggle(event);
};

const changeDefault = (v: DashboardType) => {
	defaultDashboard.value = v;
	showSuccess(t('dashboard.defaultChanged'));
};

onMounted(updateDashboardByPermission);
watch(() => graphPermission.value, updateDashboardByPermission);

return (_ctx: any,_cache: any) => {
  const _directive_ripple = _resolveDirective("ripple")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(DefaultDashboardDialog, {
      show: selectDialog.value,
      "onUpdate:show": _cache[0] || (_cache[0] = ($event: any) => ((selectDialog).value = $event)),
      selected: _unref(defaultDashboard),
      onChangeDefault: changeDefault
    }, null, 8, ["show", "selected"]),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(GbDefaultBreadcrumb, { tabs: tabs.value }, null, 8, ["tabs"]),
      _createElementVNode("div", _hoisted_2, [
        (graphPermission.value)
          ? (_openBlock(), _createBlock(_unref(Button), {
              key: 0,
              "aria-controls": "dashboard_overlay_menu",
              "aria-haspopup": "true",
              icon: "pi pi-cog",
              type: "button",
              onClick: toggle
            }))
          : _createCommentVNode("", true),
        (graphPermission.value)
          ? (_openBlock(), _createBlock(_unref(Menu), {
              key: 1,
              id: "dashboard_overlay_menu",
              ref_key: "menu",
              ref: menu,
              model: menuItems.value,
              popup: ""
            }, {
              item: _withCtx(({ item, props }) => [
                _withDirectives((_openBlock(), _createElementBlock("div", _mergeProps({ class: "tw3-flex tw3-items-center" }, props.action), [
                  (item.icon)
                    ? (_openBlock(), _createElementBlock("span", {
                        key: 0,
                        class: _normalizeClass(item.icon)
                      }, null, 2))
                    : _createCommentVNode("", true),
                  _createElementVNode("span", null, _toDisplayString(item.label), 1),
                  (item.active)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_3, " Default "))
                    : _createCommentVNode("", true)
                ], 16)), [
                  [_directive_ripple]
                ])
              ]),
              _: 1
            }, 8, ["model"]))
          : _createCommentVNode("", true)
      ])
    ]),
    (selectedDashboard.value === _unref(DashboardType).revenue)
      ? (_openBlock(), _createBlock(RevenueDashboard, { key: 0 }))
      : _createCommentVNode("", true),
    (selectedDashboard.value === _unref(DashboardType).tech)
      ? (_openBlock(), _createBlock(TechDashboard, { key: 1 }))
      : _createCommentVNode("", true)
  ]))
}
}

})