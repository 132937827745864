import { coreApi } from '@/shared/api';

import { User, UserInfo } from './types';

const USER_URL = '/api/user';

export const getOne = async (): Promise<UserInfo> => {
	try {
		const response = await coreApi().get(`${USER_URL}`);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
};

export const getAccountPackages = async (): Promise<User[]> => {
	try {
		const response = await coreApi().get(`/api/accounting_packages/user`);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
};
