<script setup lang="ts">
import { toTypedSchema } from '@vee-validate/zod';
import Button from 'primevue/button';
import IconField from 'primevue/iconfield';
import InputIcon from 'primevue/inputicon';
import InputMask from 'primevue/inputmask';
import InputText from 'primevue/inputtext';
import { useForm } from 'vee-validate';
import { watch } from 'vue';
import { z } from 'zod';

import InputAddress from '@/features/address/InputAddress.vue';

import { useCreateAccountStore } from '../../model/createAccount';
import {
	AccountCreationSteps,
	AccountCreationStepValues,
	ShopInfoForm
} from '../../model/types';
import StepBody from '../steps/StepBody.vue';
import StepInputGroup from '../steps/StepInputGroup.vue';

const createAccountStore = useCreateAccountStore();

const validationSchema = toTypedSchema(
	z.object({
		name: z
			.string({ message: 'Shop name is required' })
			.min(1, { message: 'Shop name is required' }),
		tax_id: z.string().optional(),
		g_address: z.object({
			address: z.string(),
			city: z.string().optional(),
			extra: z.string().nullish(),
			full_address: z.string({ message: 'Mailing address is required' }),
			google_place_id: z.string(),
			lat: z.number(),
			lng: z.number(),
			po_box: z.number().optional(),
			state: z.string().optional(),
			unit: z.string().nullish(),
			zip: z.string().nullish()
		}),
		phone: z.string({ message: 'Phone is required' }).length(14, {
			message: 'Invalid phone number'
		}),
		fax: z.string().optional(),
		email: z
			.string({ message: 'Contact email is required' })
			.email({ message: 'Invalid email' }),
		website: z.string().url().optional()
	})
);

const { handleSubmit, errors, defineField, values } = useForm<ShopInfoForm>({
	validationSchema,
	initialValues: createAccountStore.form[AccountCreationSteps.shopInfo]
});

watch(
	() => values,
	v => {
		createAccountStore.form[AccountCreationSteps.shopInfo] = v;
	},
	{ deep: true }
);

const [name] = defineField('name');
const [tax_id] = defineField('tax_id');
const [g_address] = defineField('g_address');
const [phone] = defineField('phone');
const [fax] = defineField('fax');
const [email] = defineField('email');
const [website] = defineField('website');

const next = handleSubmit(async () => {
	createAccountStore.changeStep(AccountCreationStepValues.shopLogo);
});

const copyCompanyInfo = () => {
	const organization =
		createAccountStore.form[AccountCreationSteps.organization];
	name.value = organization.name;
	g_address.value = organization.g_address;
	phone.value = organization.phone;
	fax.value = organization.fax;
	email.value = organization.email;
};
</script>

<template>
	<div class="tw3-flex tw3-mb-8 tw3-items-center">
		<div class="tw3-flex-grow tw3-mr-24 tw3-text-slate-500">
			Fill in main organization shop data.
		</div>
		<Button
			class="tw3-shrink-0"
			label="Use company info"
			outlined
			severity="info"
			size="small"
			@click="copyCompanyInfo"
		/>
	</div>

	<StepBody>
		<StepInputGroup :error="errors.name" label="Shop name">
			<InputText
				v-model="name"
				fluid
				:invalid="!!errors.name"
				placeholder="Shop name"
			/>
		</StepInputGroup>

		<StepInputGroup :error="errors.tax_id" label="Tax ID" subLabel="(EIN)">
			<IconField>
				<InputIcon class="pi pi-phone" />
				<InputMask
					v-model="tax_id"
					fluid
					:invalid="!!errors.tax_id"
					mask="99-9999999"
					placeholder="99-9999999"
				/>
			</IconField>
		</StepInputGroup>

		<StepInputGroup
			class="tw3-col-span-2"
			:error="errors.g_address"
			label="Address"
		>
			<InputAddress
				:errMsg="errors.g_address"
				:modelValue="g_address.full_address ? g_address : null"
				withExtra
				withPoBox
				@update:model-value="v => (g_address = v)"
			/>
		</StepInputGroup>

		<StepInputGroup :error="errors.phone" label="Phone">
			<IconField>
				<InputIcon class="pi pi-phone" />
				<InputMask
					v-model="phone"
					fluid
					:invalid="!!errors.phone"
					mask="(999) 999-9999"
					placeholder="(999) 999-9999"
				/>
			</IconField>
		</StepInputGroup>

		<StepInputGroup :error="errors.fax" label="Fax">
			<IconField>
				<InputIcon class="pi pi-phone" />
				<InputMask
					v-model="fax"
					fluid
					:invalid="!!errors.fax"
					mask="(999) 999-9999"
					placeholder="(999) 999-9999"
				/>
			</IconField>
		</StepInputGroup>

		<StepInputGroup :error="errors.email" label="Email">
			<InputText
				v-model="email"
				fluid
				:invalid="!!errors.email"
				placeholder="Contact email"
			/>
		</StepInputGroup>

		<StepInputGroup :error="errors.website" label="Website">
			<InputText
				v-model="website"
				fluid
				:invalid="!!errors.website"
				placeholder="Email"
			/>
		</StepInputGroup>
	</StepBody>

	<div class="tw3-flex tw3-pt-8 tw3-space-x-4">
		<Button
			label="Back"
			severity="secondary"
			size="small"
			@click="
				createAccountStore.changeStep(AccountCreationStepValues.organization)
			"
		/>
		<Button label="Next" size="small" @click="next" />
	</div>
</template>
