import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "tw3-font-bold tw3-mb-2" }
const _hoisted_2 = { class: "tw3-text-slate-500 tw3-font-normal tw3-ml-2" }
const _hoisted_3 = { class: "tw3-text-sm tw3-text-red-400" }


export default /*@__PURE__*/_defineComponent({
  __name: 'StepInputGroup',
  props: {
    label: {},
    subLabel: {},
    error: {}
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createTextVNode(_toDisplayString(_ctx.label) + " ", 1),
      _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.subLabel), 1)
    ]),
    _renderSlot(_ctx.$slots, "default"),
    _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.error), 1)
  ]))
}
}

})