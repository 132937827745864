import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "statement-report-table",
  style: {"page-break-after":"always"}
}
const _hoisted_2 = {
  key: 0,
  class: "statement-row"
}
const _hoisted_3 = { class: "statement-title" }
const _hoisted_4 = { class: "bold" }
const _hoisted_5 = { class: "statement-info" }
const _hoisted_6 = { class: "imp bold" }
const _hoisted_7 = { class: "bold" }
const _hoisted_8 = { class: "bold" }
const _hoisted_9 = {
  key: 1,
  class: "statement-row"
}
const _hoisted_10 = { class: "statement-bill-to" }
const _hoisted_11 = { class: "box-header" }
const _hoisted_12 = { class: "content" }
const _hoisted_13 = { class: "bold" }
const _hoisted_14 = { class: "statement-account-summary" }
const _hoisted_15 = { class: "box-header" }
const _hoisted_16 = { class: "content" }
const _hoisted_17 = { class: "bold" }
const _hoisted_18 = { class: "bold" }
const _hoisted_19 = { class: "bold" }
const _hoisted_20 = { class: "bold" }
const _hoisted_21 = { class: "imp bold" }
const _hoisted_22 = { class: "statement-row" }
const _hoisted_23 = { class: "head-table" }
const _hoisted_24 = { class: "cell" }
const _hoisted_25 = { class: "cell" }
const _hoisted_26 = { class: "cell" }
const _hoisted_27 = {
  key: 0,
  class: "cell"
}
const _hoisted_28 = {
  key: 1,
  class: "cell dollar"
}
const _hoisted_29 = {
  key: 2,
  class: "cell dollar"
}
const _hoisted_30 = { class: "cell dollar" }
const _hoisted_31 = { class: "cell dollar strong" }
const _hoisted_32 = { key: 0 }
const _hoisted_33 = { class: "cell date-cell" }
const _hoisted_34 = { class: "cell" }
const _hoisted_35 = { class: "cell dollar strong" }
const _hoisted_36 = { class: "cell date-cell" }
const _hoisted_37 = { class: "cell" }
const _hoisted_38 = ["href"]
const _hoisted_39 = {
  key: 0,
  class: "paid-label"
}
const _hoisted_40 = {
  key: 0,
  class: "cell"
}
const _hoisted_41 = {
  key: 1,
  class: "cell"
}
const _hoisted_42 = {
  key: 2,
  class: "cell"
}
const _hoisted_43 = {
  key: 3,
  class: "cell"
}
const _hoisted_44 = { class: "cell" }
const _hoisted_45 = {
  key: 4,
  class: "cell"
}
const _hoisted_46 = {
  key: 5,
  class: "cell dollar"
}
const _hoisted_47 = {
  key: 6,
  class: "cell dollar"
}
const _hoisted_48 = {
  key: 7,
  class: "cell dollar"
}
const _hoisted_49 = { class: "cell dollar" }
const _hoisted_50 = {
  key: 8,
  class: "cell dollar strong"
}
const _hoisted_51 = {
  key: 9,
  class: "cell dollar strong"
}
const _hoisted_52 = { key: 1 }
const _hoisted_53 = {
  class: "text-center",
  colspan: "6"
}
const _hoisted_54 = { class: "footer-table" }
const _hoisted_55 = ["colspan"]
const _hoisted_56 = {
  key: 0,
  class: "cell dollar",
  colspan: "2"
}
const _hoisted_57 = {
  key: 1,
  class: "cell dollar",
  colspan: "2"
}
const _hoisted_58 = {
  key: 2,
  class: "cell dollar strong"
}
const _hoisted_59 = {
  key: 3,
  class: "cell dollar strong"
}
const _hoisted_60 = { class: "footer-table" }
const _hoisted_61 = { class: "cell" }
const _hoisted_62 = ["colspan"]

import dayjs from 'dayjs';
import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import { statementAgingColumns } from '@/widgets/reportsElements/model/constants';
import useReportsStore from '@/widgets/reportsElements/model/store';
import { reportsGetters } from '@/widgets/reportsElements/model/utils/getters';


export default /*@__PURE__*/_defineComponent({
  __name: 'StatementReportTable',
  props: {
    data: {}
  },
  setup(__props: any) {

const { t } = useI18n();

const props = __props;

const reportStore = useReportsStore();

const { staticDate, currentReportType } = storeToRefs(reportStore);
const today = dayjs().format('MM/DD/YYYY');
const statementData = computed(() => props.data.consumer?.[0] ?? null);

const formatDate = (date: string) => {
	return dayjs(date).format('MM/DD/YYYY');
};

const translateCreditMemoPaidStatus = (val: string) => {
	if (val === 'UP') return 'UF';
	if (val === 'FP') return 'FF';
	if (val === 'PP') return 'PF';
};

const validateVehicle = (row: { vehicle: (number | string)[] }) => {
	// payment rows do not have a vehicle property
	if (Object.prototype.hasOwnProperty.call(row, 'vehicle')) {
		if (row.vehicle !== null && !row.vehicle.includes('null')) {
			return row.vehicle;
		} else {
			return t('invoice');
		}
	} else {
		return `${t('invoice')} ${t('payment')}`;
	}
};

return (_ctx: any,_cache: any) => {
  return (_ctx.data)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (statementData.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.data.name), 1),
                _createElementVNode("p", null, _toDisplayString(_ctx.data.address) + _toDisplayString(' '), 1),
                _createElementVNode("p", null, _toDisplayString(_ctx.data.address_2) + _toDisplayString(' '), 1),
                _createElementVNode("p", null, _toDisplayString(_ctx.data.phones) + _toDisplayString(' '), 1)
              ]),
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("p", _hoisted_6, _toDisplayString(_unref(t)('reportsPage.table.account')) + " " + _toDisplayString(_unref(t)('reportsPage.table.statement')), 1),
                _createElementVNode("p", null, [
                  _createElementVNode("span", _hoisted_7, _toDisplayString(_unref(t)('date')) + ":", 1),
                  _cache[0] || (_cache[0] = _createTextVNode()),
                  _createElementVNode("span", null, _toDisplayString(_unref(today)), 1)
                ]),
                _createElementVNode("p", null, [
                  _createElementVNode("span", _hoisted_8, _toDisplayString(_unref(t)('reportsPage.customer')) + " ID:", 1),
                  _createElementVNode("span", null, _toDisplayString(statementData.value.id), 1)
                ])
              ])
            ]))
          : _createCommentVNode("", true),
        (statementData.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
              _createElementVNode("div", _hoisted_10, [
                _createElementVNode("div", _hoisted_11, _toDisplayString(_unref(t)('billTo')), 1),
                _createElementVNode("div", _hoisted_12, [
                  _createElementVNode("p", _hoisted_13, _toDisplayString(statementData.value.name), 1),
                  _createElementVNode("p", null, _toDisplayString(statementData.value.address), 1),
                  _createElementVNode("p", null, _toDisplayString(statementData.value.address_2), 1),
                  _createElementVNode("p", null, _toDisplayString(_unref(t)('phone')) + ": " + _toDisplayString(statementData.value.phone), 1),
                  _createElementVNode("p", null, _toDisplayString(_unref(t)('email')) + ": " + _toDisplayString(statementData.value.default_email), 1)
                ])
              ]),
              _createElementVNode("div", _hoisted_14, [
                _createElementVNode("div", _hoisted_15, _toDisplayString(_unref(t)('reportsPage.table.account')) + " " + _toDisplayString(_unref(t)('reportsPage.table.summary')), 1),
                _createElementVNode("div", _hoisted_16, [
                  _createElementVNode("p", null, [
                    _createElementVNode("span", _hoisted_17, _toDisplayString(_unref(t)('reportsPage.table.statement')) + " " + _toDisplayString(_unref(t)('period')) + ": ", 1),
                    _createElementVNode("span", null, _toDisplayString(formatDate(_unref(staticDate).startDate)) + " - " + _toDisplayString(formatDate(_unref(staticDate).endDate)), 1)
                  ]),
                  _createElementVNode("p", null, [
                    _createElementVNode("span", _hoisted_18, _toDisplayString(_unref(t)('balance')) + " " + _toDisplayString(_unref(t)('forward')) + ":", 1),
                    _createElementVNode("span", null, _toDisplayString(_unref(reportsGetters).formatNumber(_ctx.data.balance_forward, {
								style: 'dollar'
							})), 1)
                  ]),
                  _createElementVNode("p", null, [
                    _createElementVNode("span", _hoisted_19, _toDisplayString(_unref(t)('payments')) + ":", 1),
                    _createElementVNode("span", null, _toDisplayString(_unref(reportsGetters).formatNumber(_ctx.data.total_payments, {
								style: 'dollar'
							})), 1)
                  ]),
                  _createElementVNode("p", null, [
                    _createElementVNode("span", _hoisted_20, _toDisplayString(_unref(t)('invoices')) + ":", 1),
                    _createElementVNode("span", null, _toDisplayString(_unref(reportsGetters).formatNumber(_ctx.data.total_invoices, {
								style: 'dollar'
							})), 1)
                  ]),
                  _createElementVNode("p", null, [
                    _createElementVNode("span", _hoisted_21, _toDisplayString(_unref(t)('reportsPage.table.statement')) + " " + _toDisplayString(_unref(t)('balance')) + ": ", 1),
                    _createElementVNode("span", null, _toDisplayString(_unref(reportsGetters).formatNumber(_ctx.data.total_balance, {
								style: 'dollar'
							})), 1)
                  ])
                ])
              ])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_22, [
          _createElementVNode("table", null, [
            _createElementVNode("tbody", null, [
              _createElementVNode("tr", _hoisted_23, [
                _createElementVNode("td", _hoisted_24, _toDisplayString(_unref(t)('date')), 1),
                _createElementVNode("td", _hoisted_25, _toDisplayString(_unref(t)('invoice')) + " #", 1),
                _cache[1] || (_cache[1] = _createElementVNode("td", { class: "cell" }, "RO # / PO #", -1)),
                _createElementVNode("td", _hoisted_26, _toDisplayString(_unref(t)('description')), 1),
                (_unref(currentReportType).id === 'open-invoice')
                  ? (_openBlock(), _createElementBlock("td", _hoisted_27, _toDisplayString(_unref(t)('vin')), 1))
                  : _createCommentVNode("", true),
                (_unref(currentReportType).id === 'open-invoice')
                  ? (_openBlock(), _createElementBlock("td", _hoisted_28, _toDisplayString(_unref(t)('amount')) + " " + _toDisplayString(_unref(t)('due')), 1))
                  : (_openBlock(), _createElementBlock("td", _hoisted_29, _toDisplayString(_unref(t)('charge')), 1)),
                _createElementVNode("td", _hoisted_30, _toDisplayString(_unref(t)('credit')), 1),
                _createElementVNode("td", _hoisted_31, _toDisplayString(_unref(t)('total')), 1)
              ]),
              (
							_ctx.data.balance_forward && _unref(currentReportType).id !== 'open-invoice'
						)
                ? (_openBlock(), _createElementBlock("tr", _hoisted_32, [
                    _createElementVNode("td", _hoisted_33, _toDisplayString(formatDate(_unref(staticDate).startDate)), 1),
                    _cache[2] || (_cache[2] = _createElementVNode("td", { class: "cell" }, null, -1)),
                    _cache[3] || (_cache[3] = _createElementVNode("td", { class: "cell" }, null, -1)),
                    _createElementVNode("td", _hoisted_34, "* " + _toDisplayString(_unref(t)('balance')) + " " + _toDisplayString(_unref(t)('forward')) + " *", 1),
                    _cache[4] || (_cache[4] = _createElementVNode("td", { class: "cell" }, null, -1)),
                    _cache[5] || (_cache[5] = _createElementVNode("td", { class: "cell" }, null, -1)),
                    _createElementVNode("td", _hoisted_35, _toDisplayString(_unref(reportsGetters).formatNumber(_ctx.data.balance_forward, {
									style: 'dollar'
								})), 1)
                  ]))
                : _createCommentVNode("", true),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data.list_data, (row) => {
                return (_openBlock(), _createElementBlock("tr", {
                  key: row.id
                }, [
                  _createElementVNode("td", _hoisted_36, _toDisplayString(formatDate(row.date)), 1),
                  _createElementVNode("td", _hoisted_37, [
                    _createElementVNode("a", {
                      href: `/jobs/${row.job_id || row.id}`,
                      target: "_blank"
                    }, _toDisplayString(row.shop_id) + "-" + _toDisplayString(row.job_id || row.id) + " " + _toDisplayString(row.is_supplemental ? '(SUP)' : ''), 9, _hoisted_38),
                    (row.paid)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_39, "(" + _toDisplayString(row.is_credit_memo === 1
										? translateCreditMemoPaidStatus(row.paid)
										: row.paid) + ")", 1))
                      : _createCommentVNode("", true)
                  ]),
                  (row.requisition_order_number && row.purchase_order_number)
                    ? (_openBlock(), _createElementBlock("td", _hoisted_40, _toDisplayString(row.requisition_order_number) + " / " + _toDisplayString(row.purchase_order_number), 1))
                    : (
								row.requisition_order_number && !row.purchase_order_number
							)
                      ? (_openBlock(), _createElementBlock("td", _hoisted_41, _toDisplayString(row.requisition_order_number), 1))
                      : (
								!row.requisition_order_number && row.purchase_order_number
							)
                        ? (_openBlock(), _createElementBlock("td", _hoisted_42, " / " + _toDisplayString(row.purchase_order_number), 1))
                        : (_openBlock(), _createElementBlock("td", _hoisted_43)),
                  _createElementVNode("td", _hoisted_44, _toDisplayString(row.is_credit_memo === 1 ? 'Credit Memo' : validateVehicle(row)), 1),
                  (_unref(currentReportType).id === 'open-invoice')
                    ? (_openBlock(), _createElementBlock("td", _hoisted_45, _toDisplayString(row.vin), 1))
                    : _createCommentVNode("", true),
                  (
								_unref(currentReportType).id === 'open-invoice' &&
								statementData.value &&
								statementData.value.id === row.consumer_id
							)
                    ? (_openBlock(), _createElementBlock("td", _hoisted_46, _toDisplayString(row.is_credit_memo !== 1
									? row.total_deductible_after_payments &&
										_unref(reportsGetters).formatNumber(
											row.total_deductible_after_payments,
											{ style: 'dollar' }
										)
									: ''), 1))
                    : (
								_unref(currentReportType).id === 'open-invoice' &&
								statementData.value &&
								statementData.value.id === row.bill_to_id
							)
                      ? (_openBlock(), _createElementBlock("td", _hoisted_47, _toDisplayString(row.is_credit_memo !== 1
									? row.total_balance_after_payments &&
										_unref(reportsGetters).formatNumber(
											row.total_balance_after_payments,
											{ style: 'dollar' }
										)
									: ''), 1))
                      : (_openBlock(), _createElementBlock("td", _hoisted_48, _toDisplayString(row.is_credit_memo !== 1
									? row.total_after_taxes &&
										_unref(reportsGetters).formatNumber(row.total_after_taxes, {
											style: 'dollar'
										})
									: ''), 1)),
                  _createElementVNode("td", _hoisted_49, _toDisplayString(row.is_credit_memo === 1
									? row.total_after_taxes &&
										_unref(reportsGetters).formatNumber(row.total_after_taxes, {
											style: 'dollar'
										})
									: row.amount &&
										_unref(reportsGetters).formatNumber(row.amount, { style: 'dollar' })), 1),
                  (_unref(currentReportType).id === 'open-invoice')
                    ? (_openBlock(), _createElementBlock("td", _hoisted_50, _toDisplayString(_unref(reportsGetters).formatNumber(row.total_outstanding, {
									style: 'dollar'
								})), 1))
                    : (_openBlock(), _createElementBlock("td", _hoisted_51, _toDisplayString(_unref(reportsGetters).formatNumber(row.total, { style: 'dollar' })), 1))
                ]))
              }), 128)),
              (!_ctx.data.list_data.length)
                ? (_openBlock(), _createElementBlock("tr", _hoisted_52, [
                    _createElementVNode("td", _hoisted_53, _toDisplayString(_unref(t)('noData')), 1)
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("tr", _hoisted_54, [
                _createElementVNode("td", {
                  class: "cell statement-memo",
                  colspan: _unref(currentReportType).id === 'open-invoice' ? '5' : '4'
                }, " * (FP) - " + _toDisplayString(_unref(t)('reportsPage.table.fp')) + ", (PP) - " + _toDisplayString(_unref(t)('reportsPage.table.pp')) + ", (UP) - " + _toDisplayString(_unref(t)('reportsPage.table.unpaid')), 9, _hoisted_55),
                (_unref(currentReportType).id === 'open-invoice')
                  ? (_openBlock(), _createElementBlock("td", _hoisted_56, _toDisplayString(_unref(t)('total')) + " " + _toDisplayString(_unref(t)('reportsPage.table.outstanding')) + ": ", 1))
                  : (_openBlock(), _createElementBlock("td", _hoisted_57, "Grand Total:")),
                (_unref(currentReportType).id === 'open-invoice')
                  ? (_openBlock(), _createElementBlock("td", _hoisted_58, _toDisplayString(_unref(reportsGetters).formatNumber(_ctx.data.total_outstanding_balance, {
									style: 'dollar'
								})), 1))
                  : (_openBlock(), _createElementBlock("td", _hoisted_59, _toDisplayString(_unref(reportsGetters).formatNumber(_ctx.data.total_balance, {
									style: 'dollar'
								})), 1))
              ]),
              _createElementVNode("tr", _hoisted_60, [
                _createElementVNode("td", _hoisted_61, _toDisplayString(_unref(t)('aging')) + ":", 1),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(statementAgingColumns), (age) => {
                  return (_openBlock(), _createElementBlock("td", {
                    key: age,
                    class: "cell"
                  }, _toDisplayString(age) + ": " + _toDisplayString(_unref(reportsGetters).formatNumber(_ctx.data.aging[age] || 0, {
									style: 'dollar'
								})), 1))
                }), 128)),
                _createElementVNode("td", {
                  class: "cell",
                  colspan: _unref(currentReportType).id === 'open-invoice' ? '2' : '1'
                }, null, 8, _hoisted_62)
              ])
            ])
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}
}

})