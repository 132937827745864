export const TNC: string = `
<p>The following terms will govern the terms under which Glassbiller, LLC, an Arizona limited liability company (referred to as “Glassbiller,” “We,” “Our,” or “Us”) will provide services to you, our customer (referred to as “Customer,” “You,” “Your” or “User”), and your authorized agents. Please read the following terms setting forth Our relationship with You and Your authorized agents, because, upon Your approval, these terms become a binding agreement (“Agreement”) between the two parties. By You agreeing to use our Services, You are accepting the terms of this Agreement on behalf of You individually or as an entity that owns the business utilizing Our Services; alternatively, by installing, accessing or using the Software and Our Services, You agree to the Agreement terms on behalf of Customer. If You do not agree to all of the terms of this Agreement, then You may not use the Software or Our Services.</p>
<p>“Services" means the online, web-based applications and platform provided by Us via http://www.glassbiller.com and/or other designated websites as described by Us through Our our web site and training sessions, that are ordered by You as part of a 30-day free trial or under an order form for paid services, including associated offline components but excluding Third Party Applications. </p>
<p>By agreeing to the terms of this Agreement, You are representing that You have the authority to bind the Customer (whether You, Your business, or the business entity You claim to represent) to the terms of this Agreement and that any agents of the Customer who utilize the Software and Services are bound by its terms, whether or not they have specifically reviewed this agreement or individually agreed to its terms. You represent that Your company, as a Customer, will be responsible for its agent’s compliance with this Agreement. </p>
<p>“Access Site” refers to our website that provides the portal through which we allow you to enter data for transmission to a third-party and/or access third party software. </p>
<p>“Customer” refers to the business, its authorized employees and agents who utilize the Software and Services, whether it be the thirty-day (30) free trial or any subsequent paid subscriptions services or product that we provide to You. The person who seeks services under this Agreement represents that they have the authority to bind Customer to the Agreement terms. </p>
<p><b>1.1 Types of Users.</b> The Services allow the following types of access and user rights: When You initially register for and create an account for a Service, You, or a party that You authorize, is the administrator ("Administrator" or “User”). In addition, “Users" means individuals who are authorized by Customer to use the Services, for whom subscriptions to a Service have been purchased, and who have been supplied user identifications and passwords by You, or through Us. Users under your subscription are limited to Your employees, contractors and agents. Administrators may authorize additional individuals to access the Services through the same account, with Our written approval ("Additional Users"). The number of Additional Users may be limited based upon the subscription You purchase. Additional Users may include, for example, your employees, accountant, contractors, agents, and clients. All Users will be required to accept this Agreement before accessing the Services. As any User of the Services, unless otherwise explicitly stated in this Agreement, all of these terms apply to You each time you access the Services. You agree that You are solely responsible for assuring that the Administrator and any Additional Users are aware that they are bound by the terms of this Agreement as Your agent(s). </p>
<p><b>1.2 User Subscriptions.</b> Unless otherwise specified in the applicable Service Order or other written communication from Us, Services may be accessed by no more than the specified number of Customer users as requested and approved in the Service Order. Additional Users may be added during the subscription term at the same pricing as offered at the beginning of the subscription period, prorated for the remainder of the subscription period in effect at the time the Additional User subscriptions are added. This modified subscription shall terminate on the same date as the pre-existing subscription ends. Subscriptions are for the designated Customer who identifies a User and cannot be shared or used by more than one Customer, through its authorized users. </p>
<p><b>1.3 Software Licensed.</b> You acknowledge and agree that the software and services (“Software”) accessed through the Access Site is licensed, not sold. You agree not to use, nor permit any third party to use, the Software in a manner that violates any applicable law, regulation or this Agreement. You agree You will not: i) Provide access to or give the Software or any part of the Software to any third party; ii) Reproduce, duplicate, modify, copy, deconstruct, reverse-engineer, sell, trade or resell the Software; iii) Transfer Your license to the Software to any other party; iv) Attempt unauthorized access to any other online services that are not part of the Software; v) Permit any third party to benefit from the use or functionality of the Software via a rental, lease, timesharing, service bureau, hosting service, or other arrangement; or vi) Make the Software available on any file-sharing or application hosting service. </p>
<p>You are only granted the right to use the Services and only for the purposes described by Us. We reserve all other rights in the Services. Until termination of this Agreement and as long as You meet any applicable payment obligations, service terms, and otherwise comply with this Agreement, We grant to you a personal, limited, nonexclusive, nontransferable right and license to use the Services. </p>
<p><b>1.4 Intellectual Property Protected.</b> The Services are protected by copyright, trade secret, and other intellectual property laws. You are only granted the right to use the Services and only for the purposes described by Us. We reserve all other rights in the Services. Until termination of this Agreement and as long as you meet any applicable payment obligations, data limits, and otherwise comply with this Agreement, We grant to you a personal, limited, nonexclusive, nontransferable right and license to use the Services. </p>
<p><b>2.1 Free Trial Period.</b> This Agreement will govern the terms of Your thirty-day (30) “Free Trial Period” and any subsequent paid subscription services and products that You purchase from Us. The terms, conditions, pricing, features, and support options are subject to change without notice. We will attempt to communicate changes to You, at which time your continued use of the Service will constitute your acceptance of that change in the Agreement. If You do not accept any changes to the Agreement that You receive notice of, you have ten days from such notice to advise Us of Your non-acceptance in writing. </p>
<p><b>2.2 Trial Period Runs Thirty Days.</b> The thirty-day free trial, starting from the date of the subscription services become available to You, are provided free of cost to you (“Free Trial Period”). You will be contacted by Us prior to the end of the trial period to inquire about Your intentions to continue with our paid subscription service. The free trial period is an opportunity for You to explore Our Services, become familiar with Our Software and its functions, and ask questions about using Our Software and its features, and review Our online training videos. Upon deciding to proceed with a paid subscription, it will be assumed by Us that You understand and have evaluated how to utilize the services that We offer and how to integrate those services into Your particular business needs. </p>
<p>You must decide to purchase a license to the Services within the Free Trial Period in order to retain access to any content or data provided or created during the Free Trial Period. If You do not purchase a license to the Services by the end of the Free Trial Period, Your content will no longer be available to You. To be very clear, after using the Services during the Free Trial Period, if You decide not to purchase the Services, You will not be able to access or retrieve any of the data or content You added to or created with the Services during the trial and We will not be responsible for damages You incur as a result of losing access to that data or content. </p>
<p><b>3.1 Ongoing Paid Services.</b> If You choose to subscribe to the Free Trial Period or our paid subscription Services, You will be required to submit, on our website, a valid credit card for authorization, to be charged on a monthly basis at the then-current fee for any paid subscription Services You have selected until cancellation of the Services. It is Your responsibility to assure that the credit card information You provided is current and that We can charge Our fees to the card. Failure to do so will result in, among other things, the immediate termination of Your ability to access Services should a charge by Us be denied by Your credit card company. </p>
<p>If You do not properly advise Us of Your intention to cancel Our services before the end of the Free Trial Period, then We will presume that You wish to continue with paid subscription Services, and You will be charged a monthly fee. </p>
<p><b>3.2 Training.</b> Upon signing up for Our Services, you will have access to online training through Our website and access to Our support department through Our website. Upon Your decision to proceed with Our paid subscription services, We will provide live training sessions as determined necessary by Us. </p>
<p><b>3.3 Cancellation of Services.</b> To cancel your subscription at any time, email support@glassbiller.com and inform GlassBiller of your intent to cancel the service. Your cancellation will become effective at the end of the monthly billing period after we have received a written confirmation of Your intent to cancel. You will not receive a prorated refund; Your access and subscription benefits will continue for the remainder of the billing period. To reinstate service, email support@glassbiller.com to inform GlassBiller of your intent to reinstate your account. Customer support and access to previous account data will not be available for accounts that are currently inactive due to a prior requested cancellation, or due to the account being inactive due to non-payment. Reinstating a previously inactive account will not result in a free 30 day trial period. You will be charged immediately for any past due amounts plus the monthly subscription amount. </p>
<p><b>3.4 No Assignments.</b> You shall not assign, sublease, extend or otherwise transfer in whole or in part any interest otherwise granted to You through this Agreement. </p>
<p><b>3.5 User Limitation.</b> Services may be subject to other limitations, as we may determine, that will become effective at the end of Your current monthly subscription period. </p>
<p><b>3.6 Support.</b> We shall provide support for the Services through our website, particularly through our posted online videos and our support department that can be reached by email. You can also reach us by telephone. However, we are typically available for response only during our normal business operation hours. We are not responsible for any unavailability caused by circumstances beyond Our reasonable control, including without limitation, acts of God, acts of government, flood, fire, earthquakes, civil unrest, acts of terror, strikes or other labor problems; or for internet service provider failures or delays. </p>
<p><b>4.1 Your Responsibilities.</b> You shall be responsible for Your Users’ compliance with this Agreement, and for preventing the use of the Services by unauthorized users. You are required, at a minimum, to use commercially reasonable efforts to prevent unauthorized access to or use of the Services. You agree to notify Us promptly of any such unauthorized access or use. You shall be solely responsible for the accuracy, quality, integrity and legality of Your data and of the means by which You acquired Your data. </p>
<p>You shall not make the Services available to anyone other than Users; sell, resell, rent or lease the Services; use the Services to store or transmit infringing, libelous, or otherwise unlawful or tortuous material, or to store or transmit material in violation of third-party privacy rights; use the Services to store or transmit malicious code; interfere with or disrupt the integrity or performance of the Services or third-party data contained therein; or attempt to gain unauthorized access to the Services or their related systems or networks. </p>
<p>You shall use the Services only in accordance with Our instructions and applicable laws and government regulations. </p>
<p><b>4.2 Service Termination.</b> Your privilege to use or access the Access Site may be terminated by us immediately and without notice if you fail to comply with any of the terms or conditions of this Agreement. Upon such termination, You must immediately cease accessing or using the Access Site and agree not to make further use of the Access Site. Furthermore, You acknowledge that We reserve the right to take any action to block or further deny Your ability to access the Access Site, whether it be through technical, legal or other means. You understand that we may exercise this right in Our sole discretion. </p>
<p><b>4.3 You will Manage Your Passwords and Accept Updates.</b> You are responsible for securely managing Your password(s) and user name for the Services and to contact Us if You become aware of any unauthorized access to Your account. The Services may periodically be updated with tools, utilities, improvements, third party applications, or general updates to improve the Services. You agree to receive these updates. </p>
<p><b>4.4 Accurate Information from You.</b> In consideration of Your use of the Site, You agree to: (a) provide true, accurate, current and complete information about yourself as prompted by the registration form, if applicable, and (b) to maintain and update this information to keep it true, accurate, current and complete. If any information provided by You is untrue, inaccurate, not current or incomplete, We have the right to terminate your account, collect applicable charges, and refuse any and all current or future use of the Site. </p>
<p><b>4.4 General Representations.</b> You must be at least 18 years of age to use our Services. By accessing or using our Services you agree that: </p>
<ul style="list-style: disc; margin-left: 2rem;">
<li>You can form a binding contract with Us; </li>
<li>You are not a person who is prohibited from receiving the Services under the laws of the United States, or any other applicable jurisdiction; and </li>
<li>You will comply with this Agreement and all applicable local, state, national, and international laws, rules, and regulations, including applicable trade regulations. </li>
</ul>
<p><b>5.1 Payment And Taxes.</b> For Services offered on a payment or subscription basis, the following terms apply if You are the User paying for the Services, unless We or our third party affiliate notifies you otherwise in writing. This Agreement also incorporates by reference and includes program ordering and payment terms provided to You on the website for the Services: </p>
<ol style="list-style: lower-alpha; margin-left: 2rem;">
<li>Payments will be billed to You in U.S. dollars or other currencies which may be made available (plus any and all applicable taxes), and Your account will be debited (including any applicable taxes) when You subscribe and provide Your payment information, unless stated otherwise in the program ordering or payment terms on the website for the Services. </li>
<li>You must pay with one of the following, at Our option:
<ol style="list-style: decimal; margin-left: 2rem;">
  <li>A valid credit card acceptable to Us;</li>
  <li>A valid debit card acceptable to Us;</li>
  <li>Sufficient funds in a checking or savings account to cover an electronic debit ofthe payment due; or</li>
  <li>By another payment option We provide to you in writing</li>
</ol>
</li>
<li>If Your payment and registration information is not accurate, current, and complete and You do not notify us promptly when such information changes, We may suspend or terminate your account and refuse any use of the Services. </li>
<li>If You do not notify us of updates to Your payment method (e.g., credit card expiration date), to avoid interruption of the Services, We may participate in programs supported by Your card provider (e.g., updater services, recurring billing programs, etc.) to try to update Your payment information, and You authorize us to continue billing Your account with the updated information that We obtain. </li>
<li>We will automatically renew Your monthly Services at the then-current rates, unless the Services subscription are cancelled or terminated under this Agreement. User subscription fees are based on monthly periods that begin on the subscription start date and each monthly period (the same day next month); therefore, fees for User subscriptions added in the middle of a monthly period will be charged for that full monthly period and the monthly periods remaining in the subscription term. </li>
<li>Additional cancellation or renewal terms may be provided to You on the website for the Services. </li>
<li>charges shall be made in advance, either annually or in accordance with any different billing frequency stated in the applicable Order Form. </li>
<li>Unless otherwise stated in the Order Form, invoiced charges are due upon receipt. You are responsible for maintaining complete and accurate billing and contact information in the Services. </li>
</ol>
<p><b>5.2 Overdue Charges.</b> If any charges are not received from You by the due date, then at Our discretion, (a) such charges may accrue late interest at the rate of 2.0% of the outstanding balance per month, or the maximum rate permitted by law, whichever is lower, from the date such payment was due until the date paid, and/or (b) We may condition future subscription renewals and Order Forms on payment terms shorter than monthly periods. </p>
<p><b>5.3 Nonpayment Remedies.</b> If any amount owing by You under this or any other agreement for Our services is thirty or more days overdue (or 10 or more days overdue in the case of amounts You have authorized Us to charge to Your credit card), We may, without limiting Our other rights and remedies, accelerate Your unpaid fee obligations under such agreements so that all such obligations become immediately due and payable, and suspend Our services to You until such amounts are paid in full. </p>
<p><b>5.4 Payment Disputes.</b> We shall not exercise Our rights under the prior two sections (Overdue Charges) or (Suspension of Service and Acceleration) if the applicable charges are under reasonable and good-faith dispute and You are cooperating diligently to resolve the dispute. </p>
<p><b>5.5 Tax Responsibility.</b> Unless otherwise stated, Our fees do not include any taxes, levies, duties or similar governmental assessments of any nature, including but not limited to value-added, sales, use or withholding taxes, assessable by any local, state, provincial, federal or foreign jurisdiction (collectively, " Taxes"). You are responsible for paying all Taxes associated with Your purchases hereunder. If We have the legal obligation to pay or collect Taxes for which You are responsible under this paragraph, the appropriate amount shall be invoiced to and paid by You, unless You provide Us with a valid tax exemption certificate authorized by the appropriate taxing authority. For clarity, We are solely responsible for taxes assessable against it based on Our income, property and employees. </p>
<insolvent,><b>5.6 Default or Bankruptcy.</b> If You: (i) default in the payment of any sum of money hereunder, (ii) default in the performance of any other obligations under this Agreement, or (iii) commit an act of bankruptcy or become the subject of any proceeding under the Bankruptcy Act or become insolvent, or if any substantial portion of Your property becomes subject to levy, seizure, assignment, application for sale for or by any creditor or governmental agency, then, in any such event, We, at our option, may, upon written notice thereof, (a) terminate the Agreement, (b) declare all amounts due become immediately due and payable, and/or (c) require You to deposit with us an amount equal to the 12 month average monthly or annual processing charges to prepay for any future processing. </p>
<p><b>5.7 Indemnification.</b> You agree to defend, indemnify and hold Us harmless from any and all claims, liabilities, costs and expenses, including reasonable attorneys’ fees, arising in any way from Your use of the Service or the placement or transmission of any message, information, software or other materials through the Service by You or users of Your account or related to any violation of this Agreement by You or users of Your account. </p>
<p><b>6.1 Warranties:</b> </p>
<p>We warrant that we are authorized to grant the Services offered herein. We warrant that Our Services will operate in close compliance with Our representations set forth in our other written materials. We do not warrant that the software provided will meet your needs or the needs of your customers. If You advise Us, in writing, of a perceived deficiency in the operation of the software we have made available to You under this Agreement within thirty days after Your discovery of the deficiency, We will investigate Your claim, and, if deemed accurate, we will offer to terminate this Agreement and refund any payments You have made to Us covering the time-period forward from the date you discover the software deficiency. </p>
<p>We do not warrant that the Services will meet Your or Your customers’ needs. You should make that determination during your free trial period. </p>
<p>THE FOREGOING WARRANTIES ARE IN LIEU OF ALL WARRANTIES EXPRESSED IMPLIED, OR STATUTORY, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF FITNESS FOR A PARTICULAR PURPOSE AND WARRANTIES OF MERCHANTABILITY, TO THE FULL EXTENT ALLOWED BY THE LAW. THE SERVICE IS PROVIDED “AS IS,” AND OUR DISCLAIMER INCLUDES ALL GUARANTEES AND WARRANTIES REGARDING THE SITE AND RELATED MATERIALS, INCLUDING ANY WARRANTY OF FITNESS FOR A PARTICULAR PURPOSE. WE DO NOT WARRANT THAT THE ANY SITE MADE AVAILABLE THROUGH OUR SERVICES IS SECURE, FREE FROM BUGS, VIRUSES, INTERRUPTION, ERRORS, THEFT OR DESTRUCTION OR THAT THE SITE WILL MEET YOUR REQUIREMENTS. YOU EXPRESSLY UNDERSTAND AND AGREE THAT YOU BEAR ALL RISKS ASSOCIATED WITH USING OR RELYING ON THAT CONTENT. </p>
<p><b>6.2 Limitation of Liability:</b> </p>
<p>OUR CUMULATIVE LIABILITY FOR DAMAGES FROM ANY CAUSE OF ACTION WHATSOEVER, INCLUDING LIABILITY FOR ANY CLAIM THAT THE SERVICES WERE NOT AVAILABLE, SHALL NOT EXCEED THE AMOUNT THAT YOU HAVE PAID US, IN TOTAL, FOR THE SERVICES DURING THE PRECEDING TWELVE-MONTH PERIOD. IN NO EVENT SHALL WE BE LIABLE FOR LOST PROFITS OR OTHER REVENUE, OR FOR INCIDENTAL CONSEQUENTIAL DAMAGES UNDER ANY CIRCUMSTANCES WHATSOEVER, WHETHER CLAIMED IN CONTRACT OR TORT, OR ANY OTHER THEORY OF LIABILITY. NOR SHALL WE BE LIABLE FOR A DAMAGE CLAIM OF PUNITIVE DAMAGES. THIS DISCLAIMER IS OTHERWISE APPLICABLE TO THE FULL EXTENT ALLOWED BY LAW. </p>
<p>YOU AGREE THAT YOU AND US ARE EACH WAIVING THE RIGHT TO A TRIAL BY JURY. IN ADDITION, YOU AGREE TO WAIVE THE RIGHT TO PARTICIPATE IN A CLASS ACTION OR LITIGATE ON A CLASS-WIDE BASIS. YOU AGREE THAT YOU HAVE EXPRESSLY AND KNOWINGLY WAIVED THESE RIGHTS. </p>
<p>Our liability for damages from any cause of action whatsoever, including liability for any claim that the Services were not available, shall not exceed the amount that You have paid Us, in total, for the Services during the preceding twelve month period. In no event shall We be liable for lost profits or other revenue, or for incidental consequential damages under any circumstances whatsoever, whether claimed in contract or tort, or any other theory of liability. Nor shall we be liable for a claim of punitive damages. This disclaimer is otherwise applicable to the full extent allowed by law. </p>
<p><b>6.3 Protection of Data and Information:</b> We shall maintain appropriate safeguards for the protection of Your data. We shall not modify or change your data in any manner not contemplated by this Agreement. We shall not disclose your date except to the extent We are compelled to do so by law, or if expressly permitted by You. We will not collect Your raw data provided for Our Services except in the normal course of providing Our Services, or with your permission, or to address technical problems. </p>
<p><b>6.4 No Resale of Other Use of Our Services.</b> The license to use the Our services does not include any resale or commercial use of Our services or the content of Our site; or any derivative use of this Site or its contents; or any use of data mining, robots or similar data gathering and extraction tools or processes. You agree not to store in any form, distribute, transmit, display, reproduce, modify, create derivative works from, sell or otherwise exploit any of the content on this site for any commercial purpose. By using the Site, You warrant to Us that You will not use the Site, or any of the content obtained from the Site, for any purpose that is unlawful or prohibited by this Agreement. We do not grant any license or other authorization to any user of Our trademarks, registered trademarks, service marks, or other copyrightable material or other intellectual property, by placing them on Our website. If You violate any of the terms of this Agreement, Your permission to use the Site automatically terminates. </p>
<p><b>7.1 Notices.</b> You consent to receive communications from Us electronically. We will communicate with You by e-mail or by posting notices on our web site. You agree that all agreements, notices, disclosures and other communications that We provide to you electronically satisfy any legal requirement that such communication be in writing. The email address that You have provided us when purchasing Our Services will be presumed by Us to be Your preferred address for notices. You agree to notify us promptly of any change in your email address. </p>
<p><b>Miscellaneous.</b> </p>
<p><b>Website Monitoring.</b> We have no obligation to monitor Our website. However, You acknowledge and agree that We have the right to monitor the site electronically from time to time and to disclose any information as necessary or appropriate to satisfy any law, regulation or other governmental request, to operate Our services properly, or to protect Us or other subscribers. We will not intentionally monitor or disclose any private electronic-mail message unless required by law. We reserve the right to refuse to post or to remove any information or materials, in whole or in part, that, in Our sole discretion, are unacceptable, undesirable, inappropriate or in violation of this Agreement. </p>
<p><b>Third Party Web Sites.</b> The Site may contain or reference links to websites operated by a third party (“Third Party Websites”). These links are provided as a convenience only. Such Third Party </p>
<p><b>Websites are not under the control of Us.</b> We are not responsible for the content of any Third Party Website or any link contained in a Third Party Website. We do not review, approve, monitor, endorse, warrant, or make any representations with respect to Third Party Websites, and the inclusion of any link in the Software or Service is not and does not imply an affiliation, sponsorship, endorsement, approval, investigation, verification or monitoring by Us of any information contained in any Third Party Website. In no event will We be responsible for the information contained in such Third Party Website or for Your use of or inability to use such website. Access to any Third Party Website is at Your own risk, and You acknowledge and understand that linked Third Party Websites may contain terms and privacy policies that are different from Ours. We are not responsible for such provisions, and expressly disclaim any liability for them. </p>
<p><b>Third Party Liability Waiver.</b> In utilizing Our services, You will be dealing and/or communicating with third-party insurance companies or others. You agree not to hold Us liable for any loss or damage of any sort incurred as a result of any such dealings with any services provided by others. Your access may be limited from time to time by third-party service providers. You may be billed for services related to Ours by third-party service providers. We are not responsible for any diminutions in Your access to Our Services as a result. </p>
<p><b>Entire Agreement.</b> Except as expressly set forth in this Agreement, the terms of this Agreement are a complete statement of the agreement between You and Us, and set forth the limits of Our entire liability and your exclusive remedy with respect to your access to and use of Our Services. Our agents and employees are not authorized to make modifications to this Agreement, or to make any additional representations, commitments or warranties binding Us. Any waiver of the terms herein by Us must be in a writing signed by one of Our authorized officers, expressly referencing the applicable provisions of the Agreement. If any terms of the Agreement are invalid or unenforceable under applicable law, then it shall be changed and interpreted to accomplish the objectives of such provision to the greatest extent possible under applicable law, and the remaining provisions will continue in full force and effect. </p>
<p><b>Choice of Law and Jurisdiction.</b> This Agreement will be governed by Arizona law, as if the Agreement was entered into and to be performed entirely within Arizona, without regard to its choice of law or conflicts of law principles that would require application of law of a different jurisdiction, and applicable federal law. The parties hereby consent to the exclusive jurisdiction and venue in the state courts in Maricopa County, Arizona or federal court for the District of Arizona in Phoenix. Should We seek injunctive relief, You agree not to challenge such action on jurisdictional grounds. Headings are included for convenience only, and shall not be considered in interpreting the terms of this Agreement. You agree that regardless of any statute or law to the contrary, any claim or cause of action arising out of or related to use of Our Services or the terms of this Agreement must be filed within one (1) year after such claim or cause of action arose or be forever barred. </p>
<p><b>Waiver and Cumulative Remedies.</b> No failure or delay by either party in exercising any right under this Agreement shall constitute a waiver of that right. Other than as expressly stated herein, the remedies provided herein are in addition to, and not exclusive of, any other remedies of a party at law or in equity. </p>

<p><b>Unauthorized Services.</b> You shall not, and shall not permit any users of Our services or any other party to, engage in, solicit, or promote any activity that is objectionable or may be illegal, violates the rights of others, is likely to cause notoriety, harm or damage to the reputation to Us or could subject Us to liability to third parties, including: (i) unauthorized access, monitoring, interference with, or use of the services or third party accounts, data, computers, systems or networks; (ii) interference with others' use of Our services or any system or network, including mail bombing, broadcast or denial of service attacks; (iii) unauthorized collection or use of personal or confidential information, including phishing, pharming, spidering, and harvesting; (iv) viewing or other use of any content that, in Our opinion, is prohibited under this Agreement; (v) any other activity that places Us in the position of fostering, or having potential or actual liability for, illegal activity in any jurisdiction; or (vi) attempting to probe, scan, penetrate or test the vulnerability of an Our system or network or to breach Our security or authentication measures, whether by passive or intrusive techniques. We reserve the right to not authorize and may terminate Your use of Our services based on reasonable suspicion of Your or your User’s activities, business, products or services that are objectionable or promote, support or engage in any of the restricted uses described above. You acknowledge that the Service is not intended for permanent storage and agree not to use the Service for general archiving or back-up purposes. You also agree not to store "critical" data on the Service, including without limitation data pertaining to power generation, military or national security, or any function to sustain or rescue the health or well-being of any person. </p>
<p><b>Our Right to Modify.</b> We reserve the right to modify this Agreement, in Our sole discretion, at any time. Such modifications may be posted on Our website for Our services or when We notify you by other means. We may also change or discontinue Our services, in whole or in part. It is important that you review this Agreement whenever We modify it because Your continued use of Our services indicates Your agreement to the modifications. </p>
<p><b>Export Compliance.</b> Each party shall comply with the export laws and regulations of the United States and other applicable jurisdictions in providing and using the Services. Without limiting the foregoing, (i) each party represents that it is not named on any U.S. government list of persons or entities prohibited from receiving exports, and (ii) You shall not permit Users to access or use Services in violation of any U.S. export embargo, prohibition or restriction. </p>
<p><b>Relationship of the Parties.</b> The parties are independent contractors. This Agreement does not create a partnership, franchise, joint venture, agency, fiduciary or employment relationship between the parties. </p>
<p><b>No Third-Party Beneficiaries.</b> There are no third-party beneficiaries to this Agreement. </p>
`;
