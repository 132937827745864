<script setup lang="ts">
// Accounting profile selector that uses fearures/accounting/lib/store store
import { storeToRefs } from 'pinia';
import { computed } from 'vue';

import useOrganizationsStore from '@/entities/organization/lib/store';
import AutoCompleteSelect from '@/shared/ui/input/AutoCompleteSelect.vue';

import useAccountingStore from './lib/store';

const { profiles, selectedProfileId } = storeToRefs(useAccountingStore());
const { organizations } = storeToRefs(useOrganizationsStore());

const options = computed(() =>
	profiles.value.map(profile => ({
		id: profile.organizationId,
		name: organizations.value.find(o => o.id === profile.organizationId)?.name
	}))
);
</script>

<template>
	<AutoCompleteSelect
		v-model="selectedProfileId"
		fluid
		:options="options"
		optionValue="id"
	/>
</template>
