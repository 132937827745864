import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createSlots as _createSlots } from "vue"

const _hoisted_1 = {
  class: "tw3-flex tw3-justify-between tw3-gap-2",
  style: {"padding-right":"2.5px"}
}
const _hoisted_2 = { class: "cell-container" }
const _hoisted_3 = { key: 2 }
const _hoisted_4 = { class: "tw3-flex tw3-flex-col tw3-items-center" }
const _hoisted_5 = { class: "tw3-w-full tw3-h-[600px] tw3-flex tw3-justify-center tw3-items-center" }
const _hoisted_6 = { class: "tw3-text-center tw3-text-3xl" }

import Button from 'primevue/button';
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import DatePicker from 'primevue/datepicker';
import InputText from 'primevue/inputtext';
import Select from 'primevue/select';
import { useConfirm } from 'primevue/useconfirm';
import { useI18n } from 'vue-i18n';
import { RouterLink } from 'vue-router';

import ClaimStatus from '@/entities/claim/ClaimStatus.vue';
import { useClaimDetailsStore } from '@/entities/claim/lib/store';
import { dispatchEvent, isEmptyValue } from '@/shared/helpers';
import { RefreshButton } from '@/shared/ui';

import { archiveOptions, statusOptions, tableHeaders } from './lib/constants';
import { useClaimsStore } from './lib/store';

import type {
	DataTablePageEvent,
	DataTableSortEvent
} from 'primevue/datatable';


export default /*@__PURE__*/_defineComponent({
  __name: 'ClaimsTable',
  setup(__props) {

const { t } = useI18n();

const confirm = useConfirm();

const claimsStore = useClaimsStore();
const claimDetailsStore = useClaimDetailsStore();

const handlePage = (state: DataTablePageEvent) => {
	claimsStore.pagination.page = state.page + 1;
};
const handleSort = (state: DataTableSortEvent) => {
	claimsStore.pagination.page = 1;

	claimsStore.handleSort({
		desc: state.sortOrder === -1,
		field: state.sortField as string
	});
};
//TODO: re-check in the stable release of primevue
const getFilterIconClass = (field: string) => {
	const filter = claimsStore.filters[field];

	return isEmptyValue(filter.value) || filter.value === 'all'
		? 'pi pi-filter'
		: 'pi pi-filter-fill';
};

const handleArchive = async (id: number, archived: boolean) => {
	const isArchived = archived === true;

	confirm.require({
		accept: async () => {
			await claimsStore.updateClaimAndRefetchClaims(
				id,
				!archived,
				await claimsStore.composeGetClaimsArgs()
			);
		},
		acceptLabel: t('yes'),
		acceptProps: {
			severity: 'success'
		},
		header: isArchived ? t('unarchive') : t('archive'),
		message: isArchived
			? t('archiveDialog.unarchiveTitle')
			: t('archiveDialog.archiveTitle'),
		rejectLabel: t('no'),
		rejectProps: {
			severity: 'secondary'
		}
	});
};

const openClaimDialog = async (jobId: number) => {
	await claimDetailsStore.claim.execute(0, jobId);

	dispatchEvent('openClaimDetailsDialog');
};

const refreshClaims = async () => {
	claimsStore.claims.execute(0, await claimsStore.composeGetClaimsArgs());
};

return (_ctx: any,_cache: any) => {
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createBlock(_unref(DataTable), {
    filters: _unref(claimsStore).filters,
    "onUpdate:filters": _cache[2] || (_cache[2] = ($event: any) => ((_unref(claimsStore).filters) = $event)),
    dataKey: "id",
    filterDisplay: "menu",
    lazy: "",
    loading: _unref(claimsStore).claims.isLoading,
    paginator: "",
    rowHover: "",
    rows: _unref(claimsStore).pagination.pageSize,
    scrollable: "",
    scrollHeight: "flex",
    size: "small",
    sortField: _unref(claimsStore).sort.field,
    sortOrder: _unref(claimsStore).sort.desc === false ? 1 : -1,
    tableClass: "tw3-max-w-full",
    totalRecords: _unref(claimsStore).claims.state.totalCount,
    value: _unref(claimsStore).claims.state.claim,
    onPage: handlePage,
    onSort: handleSort
  }, _createSlots({
    header: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_unref(Select), {
          modelValue: _unref(claimsStore).selectedShop,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(claimsStore).selectedShop) = $event)),
          optionLabel: "name",
          options: _unref(claimsStore).shops,
          placeholder: `${_unref(t)('selectShop')}`
        }, null, 8, ["modelValue", "options", "placeholder"]),
        _createVNode(_unref(RefreshButton), {
          onClick: _cache[1] || (_cache[1] = ($event: any) => (refreshClaims()))
        })
      ])
    ]),
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(tableHeaders)[_unref(claimsStore).selectedChipNumber], (column) => {
        return (_openBlock(), _createBlock(_unref(Column), {
          key: column.key,
          field: column.key,
          filterField: column.key,
          header: column.title,
          showFilterMatchModes: false,
          showFilterMenu: column.key !== 'actions',
          sortable: column.key !== 'actions',
          style: _normalizeStyle({
				width: `${column.width}%`
			})
        }, {
          body: _withCtx(({ data }) => [
            _createElementVNode("div", _hoisted_2, [
              (column.key === 'status')
                ? (_openBlock(), _createBlock(ClaimStatus, {
                    key: 0,
                    status: data[column.key]
                  }, null, 8, ["status"]))
                : (column.key === 'jobId')
                  ? (_openBlock(), _createBlock(_unref(RouterLink), {
                      key: 1,
                      target: "_blank",
                      to: `/jobs/${data[column.key]}`
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(data[column.key]), 1)
                      ]),
                      _: 2
                    }, 1032, ["to"]))
                  : (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(data[column.key]), 1))
            ])
          ]),
          filtericon: _withCtx(() => [
            _createElementVNode("i", {
              class: _normalizeClass(getFilterIconClass(column.key))
            }, null, 2)
          ]),
          filter: _withCtx(({ filterModel }) => [
            (column.key === 'submittedDt')
              ? (_openBlock(), _createBlock(_unref(DatePicker), {
                  key: 0,
                  modelValue: filterModel.value,
                  "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
                  appendTo: "#vue3app",
                  dateFormat: "mm/dd/yy",
                  mask: "99/99/9999",
                  placeholder: "mm/dd/yyyy"
                }, null, 8, ["modelValue", "onUpdate:modelValue"]))
              : (column.key === 'status')
                ? (_openBlock(), _createBlock(_unref(Select), {
                    key: 1,
                    modelValue: filterModel.value,
                    "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
                    optionLabel: "label",
                    options: _unref(statusOptions),
                    optionValue: "value",
                    placeholder: "Select status"
                  }, null, 8, ["modelValue", "onUpdate:modelValue", "options"]))
                : (_openBlock(), _createBlock(_unref(InputText), {
                    key: 2,
                    modelValue: filterModel.value,
                    "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
                    class: "p-column-filter",
                    placeholder: `Search by ${column.title}`,
                    type: "text"
                  }, null, 8, ["modelValue", "onUpdate:modelValue", "placeholder"]))
          ]),
          _: 2
        }, 1032, ["field", "filterField", "header", "showFilterMenu", "sortable", "style"]))
      }), 128)),
      _createVNode(_unref(Column), {
        bodyClass: "text-center",
        dataType: "boolean",
        field: "archived",
        pt: {
				filter: {
					style: 'margin: 0 auto !important'
				}
			},
        showClearButton: false,
        style: {
				width: '4%'
			}
      }, {
        body: _withCtx(({ data }) => [
          _withDirectives((_openBlock(), _createBlock(_unref(Button), {
            severity: "secondary",
            text: "",
            onClick: ($event: any) => (handleArchive(data.id, data.archived))
          }, {
            icon: _withCtx(() => [
              _createElementVNode("i", {
                class: _normalizeClass(`material-icons ${data.archived ? 'md-unarchive' : 'md-archive'}`)
              }, null, 2)
            ]),
            _: 2
          }, 1032, ["onClick"])), [
            [
              _directive_tooltip,
              data.archived ? _unref(t)('unarchive') : _unref(t)('archive'),
              void 0,
              { top: true }
            ]
          ])
        ]),
        filtericon: _withCtx(() => [
          _createElementVNode("i", {
            class: _normalizeClass(getFilterIconClass('archived'))
          }, null, 2)
        ]),
        filter: _withCtx(({ filterModel }) => [
          _createVNode(_unref(Select), {
            modelValue: filterModel.value,
            "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
            class: "tw3-max-w-[300px] tw3-w-full",
            optionLabel: "label",
            options: _unref(archiveOptions),
            optionValue: "value"
          }, null, 8, ["modelValue", "onUpdate:modelValue", "options"])
        ]),
        _: 1
      }),
      _createVNode(_unref(Column), { style: {
				width: '4%'
			} }, {
        body: _withCtx(({ data }) => [
          _createElementVNode("div", _hoisted_4, [
            (data.jobId)
              ? _withDirectives((_openBlock(), _createBlock(_unref(Button), {
                  key: 0,
                  "aria-label": "Filter",
                  severity: "secondary",
                  text: "",
                  onClick: ($event: any) => (openClaimDialog(data.id))
                }, {
                  icon: _withCtx(() => _cache[3] || (_cache[3] = [
                    _createElementVNode("i", { class: "material-icons md-edit" }, null, -1)
                  ])),
                  _: 2
                }, 1032, ["onClick"])), [
                  [
                    _directive_tooltip,
                    _unref(t)('openClaim'),
                    void 0,
                    { top: true }
                  ]
                ])
              : _createCommentVNode("", true)
          ])
        ]),
        _: 1
      })
    ]),
    _: 2
  }, [
    (!_unref(claimsStore).claims.isLoading)
      ? {
          name: "empty",
          fn: _withCtx(() => [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("p", _hoisted_6, _toDisplayString(_unref(t)('noData')), 1)
            ])
          ]),
          key: "0"
        }
      : undefined
  ]), 1032, ["filters", "loading", "rows", "sortField", "sortOrder", "totalRecords", "value"]))
}
}

})