import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createVNode as _createVNode, unref as _unref, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import Dialog from 'primevue/dialog';

import StepsLayout from './steps/StepsLayout.vue';

export default /*@__PURE__*/_defineComponent({
  __name: 'CreateAccountDialog',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(Dialog), {
    appendTo: "#vue3app",
    class: "tw3-w-full tw3-max-w-[700px] tw3-absolute tw3-top-20",
    closable: false,
    contentClass: "tw3-p-0",
    modal: "",
    showHeader: false,
    visible: ""
  }, {
    default: _withCtx(() => [
      _cache[0] || (_cache[0] = _createElementVNode("div", { class: "tw3-text-center tw3-my-6" }, [
        _createElementVNode("div", { class: "tw3-text-4xl tw3-font-bold tw3-tracking-tight tw3-text-gray-900 tw3-pb-2" }, " Start using Glassbiller today "),
        _createElementVNode("div", { class: "tw3-text-slate-500 tw3-pb-2" }, " Follow the steps below to start your free trial ")
      ], -1)),
      _createVNode(StepsLayout)
    ]),
    _: 1
  }))
}
}

})