import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createBlock as _createBlock } from "vue"

const _hoisted_1 = { id: "right-part-report" }
const _hoisted_2 = { class: "link expand-collapse" }
const _hoisted_3 = { class: "header" }
const _hoisted_4 = { class: "font-size-xs" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { key: 0 }
const _hoisted_7 = ["href"]
const _hoisted_8 = { key: 1 }
const _hoisted_9 = ["href"]
const _hoisted_10 = {
  key: 0,
  class: "success-result"
}
const _hoisted_11 = { key: 1 }
const _hoisted_12 = {
  key: 0,
  class: "error-result"
}
const _hoisted_13 = { key: 1 }
const _hoisted_14 = { class: "total" }
const _hoisted_15 = { class: "success-total" }

import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import Accordion from 'primevue/accordion';
import AccordionContent from 'primevue/accordioncontent';
import AccordionHeader from 'primevue/accordionheader';
import AccordionPanel from 'primevue/accordionpanel';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import useReportsStore from '@/widgets/reportsElements/model/store';
import { splitReportDataByAttr } from '@/widgets/reportsElements/model/utils/getters';

import ReportsRequestDetails from './ReportsRequestDetails.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'RightPartReportTable',
  props: {
    data: {}
  },
  setup(__props: any) {

const { t } = useI18n();

const props = __props;

const splitByShop = computed(() =>
	splitReportDataByAttr('shopName')(props.data)
);

const countSuccessRequests = (requests: any[]) => {
	return requests.filter(
		request => request.partNumber !== null && request.partNumber !== ''
	).length;
};

dayjs.extend(advancedFormat);
const formatDate = (date: string) => dayjs(date).format('lll');

const capitalize = (value: string) => {
	return value.charAt(0).toUpperCase() + value.slice(1);
};

const activeAccordions = ref<string[]>([]);
const isAllOpen = ref(false);
const toggleAll = () => {
	if (isAllOpen.value) {
		activeAccordions.value = [];
		isAllOpen.value = false;
	} else {
		activeAccordions.value = Object.keys(splitByShop.value);
		isAllOpen.value = true;
	}
};

const reportStore = useReportsStore();
const showRequestModal = ref<boolean>(false);
const openRequestDetails = (id: number) => {
	reportStore.rightPartRequestDetails.execute(0, id);
	showRequestModal.value = true;
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", { onClick: toggleAll }, _toDisplayString(isAllOpen.value ? _unref(t)('collapse') : _unref(t)('expand')) + " " + _toDisplayString(_unref(t)('all')), 1)
    ]),
    _createVNode(_unref(Accordion), {
      multiple: "",
      value: activeAccordions.value
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(splitByShop.value, (requests, shopName) => {
          return (_openBlock(), _createBlock(_unref(AccordionPanel), {
            key: shopName,
            name: shopName,
            value: shopName
          }, {
            default: _withCtx(() => [
              _createVNode(_unref(AccordionHeader), null, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_3, [
                    _createElementVNode("span", null, [
                      _createElementVNode("b", null, _toDisplayString(shopName) + " " + _toDisplayString(requests.length ? `(${requests[0].shopId})` : ``), 1)
                    ]),
                    _createElementVNode("span", null, [
                      _createTextVNode(_toDisplayString(_unref(t)('reportsPage.total')) + " " + _toDisplayString(_unref(t)('reportsPage.requests')) + ": ", 1),
                      _createElementVNode("b", null, _toDisplayString(requests.length), 1)
                    ]),
                    _createElementVNode("span", null, [
                      _createTextVNode(_toDisplayString(_unref(t)('success')) + " " + _toDisplayString(_unref(t)('reportsPage.requests')) + ": ", 1),
                      _createElementVNode("b", null, _toDisplayString(countSuccessRequests(requests)), 1)
                    ])
                  ])
                ]),
                _: 2
              }, 1024),
              _createVNode(_unref(AccordionContent), null, {
                default: _withCtx(() => [
                  _createElementVNode("table", _hoisted_4, [
                    _createElementVNode("thead", null, [
                      _createElementVNode("tr", null, [
                        _createElementVNode("th", null, [
                          _createElementVNode("b", null, _toDisplayString(_unref(t)('vin')), 1)
                        ]),
                        _createElementVNode("th", null, [
                          _createElementVNode("b", null, _toDisplayString(_unref(t)('date')), 1)
                        ]),
                        _createElementVNode("th", null, [
                          _createElementVNode("b", null, _toDisplayString(_unref(t)('user')), 1)
                        ]),
                        _createElementVNode("th", null, [
                          _createElementVNode("b", null, _toDisplayString(_unref(t)('job')), 1)
                        ]),
                        _createElementVNode("th", null, [
                          _createElementVNode("b", null, _toDisplayString(_unref(t)('result')), 1)
                        ])
                      ])
                    ]),
                    _createElementVNode("tbody", null, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(requests, (request) => {
                        return (_openBlock(), _createElementBlock("tr", {
                          key: request.id
                        }, [
                          _createElementVNode("td", null, [
                            _createElementVNode("div", {
                              class: "link",
                              onClick: ($event: any) => (openRequestDetails(request.id))
                            }, _toDisplayString(request.vin), 9, _hoisted_5)
                          ]),
                          _createElementVNode("td", null, _toDisplayString(formatDate(request.createdAt)), 1),
                          _createElementVNode("td", null, _toDisplayString(request.username) + " (" + _toDisplayString(request.userId) + ")", 1),
                          _createElementVNode("td", null, [
                            (request.jobId)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                                  _cache[1] || (_cache[1] = _createTextVNode(" # ")),
                                  _createElementVNode("a", {
                                    class: "link",
                                    href: '/jobs/' + request.jobId,
                                    target: "_blank"
                                  }, _toDisplayString(request.jobId), 9, _hoisted_7)
                                ]))
                              : (request.jobDraftId)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                                    _cache[2] || (_cache[2] = _createTextVNode(" # ")),
                                    _createElementVNode("a", {
                                      class: "link",
                                      href: '/jobs/new/' + request.jobDraftId,
                                      target: "_blank"
                                    }, _toDisplayString(request.jobDraftId), 9, _hoisted_9)
                                  ]))
                                : _createCommentVNode("", true)
                          ]),
                          _createElementVNode("td", null, [
                            (request.partNumber)
                              ? (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString(request.partNumber.slice(0, -1)), 1))
                              : (_openBlock(), _createElementBlock("span", _hoisted_11, [
                                  (request.invalid_vin === 1)
                                    ? (_openBlock(), _createElementBlock("span", _hoisted_12, _toDisplayString(_unref(t)('invalid')) + " " + _toDisplayString(_unref(t)('vin')), 1))
                                    : (_openBlock(), _createElementBlock("span", _hoisted_13, [
                                        (
													request.help_request_status === 'error' ||
													request.help_request_status === 'sent'
												)
                                          ? (_openBlock(), _createElementBlock("span", {
                                              key: 0,
                                              class: _normalizeClass(
													request.help_request_status === 'error'
														? 'error-result'
														: 'warning-result'
												)
                                            }, _toDisplayString(capitalize(request.help_request_status)), 3))
                                          : _createCommentVNode("", true)
                                      ]))
                                ]))
                          ])
                        ]))
                      }), 128))
                    ])
                  ])
                ]),
                _: 2
              }, 1024)
            ]),
            _: 2
          }, 1032, ["name", "value"]))
        }), 128))
      ]),
      _: 1
    }, 8, ["value"]),
    _createElementVNode("div", _hoisted_14, [
      _createElementVNode("span", null, _toDisplayString(_unref(t)('reportsPage.total')) + " " + _toDisplayString(_unref(t)('requests')) + ": " + _toDisplayString(_ctx.data.length), 1),
      _createElementVNode("span", _hoisted_15, _toDisplayString(_unref(t)('reportsPage.total')) + " " + _toDisplayString(_unref(t)('success')) + " " + _toDisplayString(_unref(t)('requests')) + ": " + _toDisplayString(countSuccessRequests(_ctx.data)), 1)
    ]),
    _createVNode(ReportsRequestDetails, {
      visible: showRequestModal.value,
      "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((showRequestModal).value = $event))
    }, null, 8, ["visible"])
  ]))
}
}

})