import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["colspan"]
const _hoisted_2 = {
  class: "cell",
  colspan: "2"
}

import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import { ReportType } from '../../api/types';


export default /*@__PURE__*/_defineComponent({
  __name: 'TableSubhead',
  props: {
    label: {},
    data: {},
    columns: {},
    type: { default: 'none' },
    extraInfo: { default: '' },
    reportType: {},
    fake: { default: 0 }
  },
  setup(__props: any) {

const { t } = useI18n();

// Props definition
const props = __props;

// Computed properties
const total = computed(() =>
	getTotal(props.reportType?.total_key || '')(props.data)
);

const toDouble = (number: number) => (+number).toFixed(2);

const getTotal = (key: string) => (data: any[]) => {
	const sum = data.reduce((acc, item) => acc + (item[key] || 0), 0);
	return toDouble(sum);
};

const totalLabel = computed(() => {
	switch (props.type) {
		case 'customer_name':
			return `${t('reportsPage.customer')} ${t('reportsPage.total')}`;
		case 'customerName':
			return `${t('reportsPage.customer')} ${t('reportsPage.total')}`;
		case 'name':
			return `${t('reportsPage.contractor')} ${t('reportsPage.total')}`;
		case 'shop_name':
			return `${t('shop')} ${t('reportsPage.total')}`;
		case 'x_action_type':
			return `${t('reportsPage.xType')} ${t('reportsPage.total')}`;
		case 'date':
			return `${t('reportsPage.daily')} ${t('reportsPage.total')}`;
		default:
			return t('reportsPage.total');
	}
});

const subheadClass = computed(() => `table-${props.type}-subhead`);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("tr", {
    class: _normalizeClass(["subhead", subheadClass.value])
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fake, (item) => {
      return (_openBlock(), _createElementBlock("td", {
        key: item,
        class: "fake-cell"
      }))
    }), 128)),
    _createElementVNode("td", {
      class: "cell",
      colspan: _ctx.columns.length - _ctx.fake
    }, _toDisplayString(_ctx.label), 9, _hoisted_1),
    _createElementVNode("td", _hoisted_2, " (" + _toDisplayString(_ctx.data.length) + ") " + _toDisplayString(totalLabel.value) + ": $" + _toDisplayString(total.value), 1)
  ], 2))
}
}

})