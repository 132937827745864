import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex items-center gap-4 mb-4" }
const _hoisted_2 = { class: "flex justify-end gap-2" }

import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import { useI18n } from 'vue-i18n';


export default /*@__PURE__*/_defineComponent({
  __name: 'MessageDialog',
  props: /*@__PURE__*/_mergeModels({
    header: {},
    message: {}
  }, {
    "visible": { type: Boolean, ...{ required: true } },
    "visibleModifiers": {},
  }),
  emits: ["update:visible"],
  setup(__props: any) {

// General message dialog to show user messages that are too long or too important to be shown in a toast
const { t } = useI18n();

const visible = _useModel<boolean>(__props, 'visible');


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(Dialog), {
    visible: visible.value,
    "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => ((visible).value = $event)),
    appendTo: "#vue3app",
    header: _ctx.header,
    modal: "",
    style: { width: '25rem' }
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.message), 1),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_unref(Button), {
          label: _unref(t)('Close'),
          severity: "secondary",
          type: "button",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (visible.value = false))
        }, null, 8, ["label"])
      ])
    ]),
    _: 1
  }, 8, ["visible", "header"]))
}
}

})