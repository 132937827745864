import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "tw3-w-full tw3-flex tw3-flex-col tw3-col-span-1"
}
const _hoisted_2 = { class: "tw3-content-end tw3-text-slate-500 tw3-font-medium tw3-text-sm tw3-relative" }

import { storeToRefs } from 'pinia';
import Badge from 'primevue/badge';
import { useI18n } from 'vue-i18n';

import { capitalizeStr } from '@/shared/helpers';
import useReportsStore from '@/widgets/reportsElements/model/store';

// import { customFiltersTypes } from '../../api/types';

import ReportsSelectFilter from './ReportsSelectFilter.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'ReportsShopDataFilter',
  setup(__props) {

const { t } = useI18n();

const reportsStore = useReportsStore();

const {
	currentReportType,
	shopDataLabel,
	customShopDataFilterOptions,
	customShopDataFilterOptionsLoading,
	customShopDataFilterValue
} = storeToRefs(reportsStore);

return (_ctx: any,_cache: any) => {
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_unref(customShopDataFilterOptions))
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("p", _hoisted_2, [
          _createTextVNode(_toDisplayString(_unref(capitalizeStr)(_unref(shopDataLabel))) + " ", 1),
          (_unref(reportsStore).isRequired(_unref(customShopDataFilterValue).type))
            ? _withDirectives((_openBlock(), _createBlock(_unref(Badge), {
                key: 0,
                class: "tw3-absolute !tw3-h-[0.3rem] !tw3-min-w-[0.3rem] !tw3-w-[0.3rem]",
                severity: "danger"
              }, null, 512)), [
                [
                  _directive_tooltip,
                  _unref(t)('required'),
                  void 0,
                  { top: true }
                ]
              ])
            : _createCommentVNode("", true)
        ]),
        _withDirectives(_createVNode(ReportsSelectFilter, {
          modelValue: _unref(customShopDataFilterValue).value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(customShopDataFilterValue).value) = $event)),
          disabled: 
				!_unref(reportsStore).selectedShop || !_unref(reportsStore).selectedShop.length
			,
          dropdownOptions: _unref(customShopDataFilterOptions),
          filter: "",
          loading: _unref(customShopDataFilterOptionsLoading),
          optionGroupChildren: _unref(currentReportType).filter,
          optionGroupLabel: "name",
          optionLabel: "name",
          optionValue: "id",
          placeholder: `${_unref(t)('select')} ${_unref(t)('type').toLocaleLowerCase()}...`,
          onBeforeShow: _cache[1] || (_cache[1] = 
				() => {
					if (!_unref(customShopDataFilterOptions).length)
						_unref(reportsStore).refetchCustomShopFilter();
				}
			)
        }, null, 8, ["modelValue", "disabled", "dropdownOptions", "loading", "optionGroupChildren", "placeholder"]), [
          [
            _directive_tooltip,
            
				!_unref(reportsStore).selectedShop || !_unref(reportsStore).selectedShop.length
					? 'Shop is required'
					: null
			,
            void 0,
            { top: true }
          ]
        ])
      ]))
    : _createCommentVNode("", true)
}
}

})