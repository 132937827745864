import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { defineAsyncComponent } from 'vue';

import { useJobEditCellStore } from '../../../model/store';


export default /*@__PURE__*/_defineComponent({
  __name: 'JobEditCellDialogs',
  setup(__props) {

const JobEditEdi = defineAsyncComponent(() => import('./JobEditEdi.vue'));
const JobEditNotes = defineAsyncComponent(() => import('./JobEditNotes.vue'));
const TagsDialog = defineAsyncComponent(
	() => import('@/features/tagsDialog/TagsDialog.vue')
);
const JobEditDocuments = defineAsyncComponent(
	() => import('./JobEditDocuments.vue')
);

const dStore = useJobEditCellStore();

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_unref(JobEditEdi), {
      data: _unref(dStore).jobEdi.state,
      loading: _unref(dStore).updateJobEdiLoading || _unref(dStore).jobEdi.isLoading,
      show: _unref(dStore).editJobEdiDialog,
      onOnClose: _cache[0] || (_cache[0] = ($event: any) => (_unref(dStore).toggleJobEdiDialog())),
      onOnUpdate: _unref(dStore).updateJobEdi
    }, null, 8, ["data", "loading", "show", "onOnUpdate"]),
    _createVNode(_unref(JobEditNotes), {
      data: _unref(dStore).jobNotes.state,
      loading: _unref(dStore).updateJobNoteLoading || _unref(dStore).jobNotes.isLoading,
      show: _unref(dStore).editJobNotesDialog,
      onOnClose: _cache[1] || (_cache[1] = ($event: any) => (_unref(dStore).toggleJobNotesDialog())),
      onOnCreate: _unref(dStore).createJobNote,
      onOnDelete: _unref(dStore).deleteJobNote
    }, null, 8, ["data", "loading", "show", "onOnCreate", "onOnDelete"]),
    _createVNode(_unref(TagsDialog), {
      visible: _unref(dStore).editJobTagDialog,
      "onUpdate:visible": _cache[2] || (_cache[2] = ($event: any) => ((_unref(dStore).editJobTagDialog) = $event)),
      currentTagsList: _unref(dStore).jobTags.state,
      dialogTitle: _ctx.$t('jobTags'),
      isLoading: _unref(dStore).updateJobTagLoading || _unref(dStore).jobTags.isLoading,
      jobID: _unref(dStore).currentJobId,
      tagsList: _unref(dStore).shopTags,
      onUpdateTags: _unref(dStore).updateJobTag
    }, null, 8, ["visible", "currentTagsList", "dialogTitle", "isLoading", "jobID", "tagsList", "onUpdateTags"]),
    _createVNode(_unref(JobEditDocuments), {
      data: _unref(dStore).jobDocuments.state,
      loading: _unref(dStore).updateJobDocumentLoading || _unref(dStore).jobDocuments.isLoading,
      show: _unref(dStore).editJobDocumentsDialog,
      onOnClose: _cache[3] || (_cache[3] = ($event: any) => (_unref(dStore).toggleJobDocumentsDialog())),
      onOnCreate: _unref(dStore).createJobDocument,
      onOnDelete: _unref(dStore).deleteJobDocument
    }, null, 8, ["data", "loading", "show", "onOnCreate", "onOnDelete"])
  ], 64))
}
}

})