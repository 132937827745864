import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "tw3-flex tw3-h-full tw3-flex-col tw3-mx-auto tw3-gap-6 tw3-max-w-[1440px] tw3-w-full" }

import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { onBeforeRouteLeave } from 'vue-router';

import { useUserStore } from '@/entities/user/store';
import { useReportsStore } from '@/widgets/reportsElements';
import { ReportsBody } from '@/widgets/reportsElements';
import { ReportsHeader } from '@/widgets/reportsElements';


export default /*@__PURE__*/_defineComponent({
  __name: 'SingleReport',
  setup(__props) {

const userStore = useUserStore();

const reportsStore = useReportsStore();
const { currentReportType } = storeToRefs(reportsStore);

const isReportAllowed = computed(() => {
	if (currentReportType.value.permissionId) {
		return userStore.checkUserCan(currentReportType.value.permissionId);
	} else {
		return true;
	}
});

onBeforeRouteLeave(() => {
	reportsStore.resetStoreData();
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_unref(currentReportType).id === 'aging')
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createVNode(_unref(ReportsHeader), {
            hasPermission: _unref(userStore).checkUserCan('reportaging')
          }, null, 8, ["hasPermission"]),
          (_unref(userStore).checkUserCan('reportaging'))
            ? (_openBlock(), _createBlock(_unref(ReportsBody), { key: 0 }))
            : _createCommentVNode("", true)
        ], 64))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createVNode(_unref(ReportsHeader), { hasPermission: isReportAllowed.value }, null, 8, ["hasPermission"]),
          (isReportAllowed.value)
            ? (_openBlock(), _createBlock(_unref(ReportsBody), { key: 0 }))
            : _createCommentVNode("", true)
        ], 64))
  ]))
}
}

})