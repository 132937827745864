<script setup lang="ts">
import { toTypedSchema } from '@vee-validate/zod';
import Button from 'primevue/button';
import InputMask from 'primevue/inputmask';
import InputText from 'primevue/inputtext';
import ToggleSwitch from 'primevue/toggleswitch';
import { useForm } from 'vee-validate';
import { onMounted, ref, watch } from 'vue';
import { z } from 'zod';

import { useCreateAccountStore } from '../../model/createAccount';
import {
	AccountCreationSteps,
	AccountCreationStepValues,
	ShopIntegrationsForm
} from '../../model/types';
import StepBody from '../steps/StepBody.vue';
import StepInputGroup from '../steps/StepInputGroup.vue';

const createAccountStore = useCreateAccountStore();

const mygrantChecked = ref(false);
const pilkingtonChecked = ref(false);
const pgwChecked = ref(false);

const validationSchema = toTypedSchema(
	z
		.object({
			mygrant_account_id: z.string().optional(),
			pilkington_username: z.string().optional(),
			pilkington_password: z.string().optional(),
			pgw_customer_number: z.string().optional()
		})
		.superRefine((values, ctx) => {
			if (mygrantChecked.value && !values.mygrant_account_id) {
				ctx.addIssue({
					code: z.ZodIssueCode.custom,
					message: 'Account ID is required',
					path: ['mygrant_account_id']
				});
			}
			if (pilkingtonChecked.value) {
				if (!values.pilkington_username) {
					ctx.addIssue({
						code: z.ZodIssueCode.custom,
						message: 'Username is required',
						path: ['pilkington_username']
					});
				}
				if (!values.pilkington_password) {
					ctx.addIssue({
						code: z.ZodIssueCode.custom,
						message: 'Password is required',
						path: ['pilkington_password']
					});
				}
			}
			if (pgwChecked.value && !values.pgw_customer_number) {
				ctx.addIssue({
					code: z.ZodIssueCode.custom,
					message: 'Customer number is required',
					path: ['pgw_customer_number']
				});
			}
		})
);

const { handleSubmit, errors, defineField, values } =
	useForm<ShopIntegrationsForm>({
		validationSchema,
		initialValues:
			createAccountStore.form[AccountCreationSteps.shopIntegrations]
	});

onMounted(() => {
	if (!createAccountStore.form[AccountCreationSteps.shopIntegrations]) {
		createAccountStore.form[AccountCreationSteps.shopIntegrations] =
			{} as ShopIntegrationsForm;
	} else {
		const form = createAccountStore.form[AccountCreationSteps.shopIntegrations];
		mygrantChecked.value = !!form.mygrant_account_id;
		pilkingtonChecked.value =
			!!form.pilkington_username && !!form.pilkington_password;
		pgwChecked.value = !!form.pgw_customer_number;
	}
});

watch(
	() => values,
	v => {
		createAccountStore.form[AccountCreationSteps.shopIntegrations] = v;
	},
	{ deep: true }
);

const [mygrant_account_id] = defineField('mygrant_account_id');
const [pilkington_username] = defineField('pilkington_username');
const [pilkington_password] = defineField('pilkington_password');
const [pgw_customer_number] = defineField('pgw_customer_number');

const next = handleSubmit(async () => {
	if (!mygrantChecked.value) {
		createAccountStore.form[
			AccountCreationSteps.shopIntegrations
		].mygrant_account_id = '';
		mygrant_account_id.value = '';
	}
	if (!pilkingtonChecked.value) {
		createAccountStore.form[
			AccountCreationSteps.shopIntegrations
		].pilkington_username = '';
		createAccountStore.form[
			AccountCreationSteps.shopIntegrations
		].pilkington_password = '';
		pilkington_username.value = '';
		pilkington_password.value = '';
	}
	if (!pgwChecked.value) {
		createAccountStore.form[
			AccountCreationSteps.shopIntegrations
		].pgw_customer_number = '';
		pgw_customer_number.value = '';
	}
	createAccountStore.changeStep(AccountCreationStepValues.billingPlan);
});
</script>

<template>
	<div class="tw3-mb-4 tw3-mr-24 tw3-text-slate-700 tw3-text-lg">
		Does your shop work with any of these warehouses?
	</div>

	<div class="tw3-flex tw3-items-center tw3-justify-between tw3-py-4">
		<div class="tw3-flex tw3-items-center">
			<img
				alt="Mygrant Logo"
				class="tw3-w-12 tw3-h-12 tw3-rounded-full tw3-mr-4"
				src="../../images/mygrant.jpg"
			/>
			<div class="tw3-font-semibold">Mygrant Glass</div>
		</div>
		<ToggleSwitch v-model="mygrantChecked" />
	</div>

	<div v-if="mygrantChecked" class="tw3-py-4">
		<StepInputGroup :error="errors.mygrant_account_id" label="Account ID">
			<InputMask
				v-model="mygrant_account_id"
				fluid
				:invalid="!!errors.mygrant_account_id"
				mask="*******-***"
				placeholder="C123456-001"
			/>
		</StepInputGroup>
	</div>

	<div class="tw3-flex tw3-items-center tw3-justify-between tw3-py-4">
		<div class="tw3-flex tw3-items-center">
			<img
				alt="Pilkington Logo"
				class="tw3-w-12 tw3-h-12 tw3-rounded-full tw3-mr-4"
				src="../../images/pilkington.png"
			/>
			<div class="tw3-font-semibold">Pilkington</div>
		</div>
		<ToggleSwitch v-model="pilkingtonChecked" />
	</div>

	<StepBody v-if="pilkingtonChecked" class="tw3-py-4">
		<StepInputGroup :error="errors.pilkington_username" label="Username">
			<InputText
				v-model="pilkington_username"
				fluid
				:invalid="!!errors.pilkington_username"
				placeholder="Username"
			/>
		</StepInputGroup>
		<StepInputGroup :error="errors.pilkington_password" label="Password">
			<InputText
				v-model="pilkington_password"
				fluid
				:invalid="!!errors.pilkington_password"
				placeholder="Password"
			/>
		</StepInputGroup>
	</StepBody>

	<div class="tw3-flex tw3-items-center tw3-justify-between tw3-py-4">
		<div class="tw3-flex tw3-items-center">
			<img
				alt="PGW Logo"
				class="tw3-w-12 tw3-h-12 tw3-rounded-full tw3-mr-4"
				src="../../images/pgw.jpg"
			/>
			<div class="tw3-font-semibold">Pittsburg Glass Works</div>
		</div>
		<ToggleSwitch v-model="pgwChecked" />
	</div>

	<div v-if="pgwChecked" class="tw3-py-4">
		<StepInputGroup :error="errors.pgw_customer_number" label="Customer Number">
			<InputText
				v-model="pgw_customer_number"
				fluid
				:invalid="!!errors.pgw_customer_number"
				placeholder="12345"
			/>
		</StepInputGroup>
	</div>

	<div class="tw3-flex tw3-pt-8 tw3-space-x-4">
		<Button
			label="Back"
			severity="secondary"
			size="small"
			@click="
				createAccountStore.changeStep(AccountCreationStepValues.shopTaxes)
			"
		/>
		<Button label="Next" size="small" @click="next" />
	</div>
</template>
