import { coreApi } from '@/shared/api';
import { convertToDateTime } from '@/shared/helpers/converters';
import { Exact } from '@/shared/types';

import {
	Account,
	AccountSubtype,
	AccountType,
	CreateAccountRequest,
	CreateAccountResponse,
	GetAccountResponse,
	ListAccountResponse,
	ListAccountSubtypeResponse,
	ListAccountTypeResponse,
	UpdateAccountRequest
} from './types';

export const URL = '/unum/accounting';

export const listAccountType = async (): Promise<AccountType[]> => {
	const response = await coreApi().get<ListAccountTypeResponse>(
		URL + '/account/type'
	);
	return response.data.accountType;
};

export const listAccountSubtype = async (): Promise<AccountSubtype[]> => {
	const response = await coreApi().get<ListAccountSubtypeResponse>(
		URL + '/account/subtype'
	);
	return response.data.accountSubtype;
};

export const getAccount = async (id: number): Promise<Account> => {
	const response = await coreApi().get<GetAccountResponse>(
		URL + '/account/' + id
	);
	return convertToDateTime(response.data.account);
};

export const listAccount = async (
	organizationId: number
): Promise<Account[]> => {
	const response = await coreApi().get<ListAccountResponse>(URL + '/account', {
		params: { organizationId: organizationId }
	});
	return convertToDateTime(response.data.account);
};

export const createAccount = async <T>(
	request: Exact<T, CreateAccountRequest>
): Promise<number> => {
	const response = await coreApi().post<CreateAccountResponse>(
		URL + '/account',
		request
	);
	return response.data.id;
};

export const updateAccount = async <T>(
	request: Exact<T, UpdateAccountRequest>
): Promise<void> => {
	await coreApi().patch(URL + '/account', request);
};
