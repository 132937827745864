import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "tw3-py-4 tw3-text-center"
}
const _hoisted_2 = {
  key: 1,
  class: "tw3-border tw3-rounded-md tw3-divide-y"
}
const _hoisted_3 = { class: "tw3-flex-grow tw3-flex tw3-items-center tw3-space-x-3" }
const _hoisted_4 = { class: "tw3-text-slate-800" }
const _hoisted_5 = { class: "tw3-text-slate-500" }
const _hoisted_6 = {
  key: 0,
  class: "tw3-bg-green-100 tw3-text-green-800 tw3-rounded-full tw3-px-3 tw3-py-1 tw3-text-sm"
}
const _hoisted_7 = {
  key: 1,
  class: "tw3-bg-blue-100 tw3-text-blue-800 tw3-rounded-full tw3-px-3 tw3-py-1 tw3-text-sm"
}
const _hoisted_8 = {
  key: 2,
  class: "tw3-bg-purple-100 tw3-text-purple-800 tw3-rounded-full tw3-px-3 tw3-py-1 tw3-text-sm"
}
const _hoisted_9 = ["onClick"]

import Divider from 'primevue/divider';
import { computed } from 'vue';

import { useCreateAccountStore } from '../../model/createAccount';
import { AccountCreationSteps } from '../../model/types';


export default /*@__PURE__*/_defineComponent({
  __name: 'TaxesSection',
  setup(__props) {

const createAccountStore = useCreateAccountStore();

const taxes = computed(
	() => createAccountStore.form[AccountCreationSteps.shopTaxes].tax
);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_unref(Divider)),
    _cache[1] || (_cache[1] = _createElementVNode("div", { class: "tw3-text-xl tw3-my-4" }, "Taxes", -1)),
    (!_unref(createAccountStore).hasTaxes)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[0] || (_cache[0] = [
          _createElementVNode("i", { class: "pi pi-file-plus !tw3-text-5xl tw3-text-slate-300" }, null, -1),
          _createElementVNode("div", { class: "tw3-text-slate-400 tw3-mt-4" }, "Add tax from the form above", -1)
        ])))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(taxes.value, (tax, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: index,
              class: "tw3-flex tw3-items-center tw3-p-4"
            }, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, _toDisplayString(tax.name), 1),
                _createElementVNode("div", _hoisted_5, _toDisplayString(tax.percentage) + "%", 1),
                (tax.for_labor)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_6, " Labor "))
                  : _createCommentVNode("", true),
                (tax.for_materials)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_7, " Materials "))
                  : _createCommentVNode("", true),
                (tax.default_tax)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_8, " Default "))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", {
                class: "tw3-flex-shrink-0 tw3-text-red-500 tw3-cursor-pointer",
                onClick: ($event: any) => (_unref(createAccountStore).removeShopTax(index))
              }, " Remove ", 8, _hoisted_9)
            ]))
          }), 128))
        ]))
  ], 64))
}
}

})