import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createVNode as _createVNode, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "tw3-w-full tw3-flex tw3-flex-col tw3-gap-2" }
const _hoisted_2 = { class: "tw3-flex tw3-gap-2" }
const _hoisted_3 = { class: "tw3-text-slate-600 tw3-font-medium tw3-text-sm" }
const _hoisted_4 = { class: "tw3-text-slate-400 tw3-font-medium tw3-text-xs" }
const _hoisted_5 = {
  key: 0,
  class: "tw3-flex tw3-items-center tw3-gap-4"
}
const _hoisted_6 = {
  key: 0,
  class: "tw3-flex tw3-items-center"
}
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { key: 1 }

import { Loader } from '@googlemaps/js-api-loader';
import AutoComplete, {
	AutoCompleteCompleteEvent,
	AutoCompleteOptionSelectEvent
} from 'primevue/autocomplete';
import Checkbox from 'primevue/checkbox';
import InputText from 'primevue/inputtext';
import Textarea from 'primevue/textarea';
import { computed, onMounted, ref, watch } from 'vue';

import { Address } from '@/entities/address/lib/types';
import { config } from '@/shared/config';

import {
	parseAutocompleteResults,
	serializeAddressComponents
} from './lib/helpers';
import { GoogleAddressComponents, GoogleParsedResults } from './lib/types';


export default /*@__PURE__*/_defineComponent({
  __name: 'InputAddress',
  props: {
    modelValue: {},
    disabled: { type: Boolean },
    withPoBox: { type: Boolean },
    withExtra: { type: Boolean },
    errMsg: {},
    inline: { type: Boolean }
  },
  emits: ["update:modelValue"],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emit = __emit;

const token = ref<string>();
const Places = ref<any>();
const suggestions = ref<GoogleParsedResults[]>([]);

const loading = ref(false);
const addExtra = ref(!!props.modelValue?.extra);
const address = ref(props.modelValue?.full_address ?? '');

const showExtra = computed(() => addExtra.value || props.modelValue?.extra);

const search = async (event: AutoCompleteCompleteEvent) => {
	loading.value = true;
	try {
		const service = new Places.value.AutocompleteService();
		const data = await service.getPlacePredictions({
			componentRestrictions: { country: ['us', 'ca'] },
			language: 'en',
			fields: [
				'address_component',
				'adr_address',
				'formatted_address',
				'geometry,place_id'
			],
			input: event.query,
			sessionToken: token.value,
			types: props.modelValue?.po_box ? ['(regions)'] : ['address']
		});
		suggestions.value = parseAutocompleteResults(data.predictions);
	} catch (error) {
		suggestions.value = [];
	}
	loading.value = false;
};

const getPlaceDetails = (placeId: string) =>
	new Promise(resolve => {
		try {
			const service = new Places.value.PlacesService(
				document.createElement('div')
			);
			service.getDetails(
				{
					fields: [
						'address_component',
						'adr_address',
						'formatted_address',
						'geometry,place_id'
					],
					placeId,
					sessionToken: token.value
				},
				(results: any) => {
					resolve(serializeAddressComponents(results));
				}
			);
		} catch (error) {
			resolve(null);
		}
	});

const handleSelect = async (result: AutoCompleteOptionSelectEvent) => {
	loading.value = true;
	const { place_id } = result.value;

	const data = (await getPlaceDetails(place_id)) as GoogleAddressComponents;
	const value = {
		...props.modelValue,
		...data
	};
	emit('update:modelValue', value);
	address.value = value.full_address ?? '';
	loading.value = false;
};

const setToken = () => {
	token.value = new Places.value.AutocompleteSessionToken();
};

const updateValue = (field: keyof Address, value: any) => {
	emit('update:modelValue', { ...props.modelValue, [field]: value });
};

const invalidateInput = () => {
	emit('update:modelValue', {
		...props.modelValue,
		address: undefined,
		city: undefined,
		full_address: undefined,
		google_place_id: undefined,
		lat: 0,
		lng: 0,
		state: undefined,
		zip: null
	});
};

const toggleExtra = () => {
	if (showExtra.value) {
		updateValue('extra', null);
		addExtra.value = false;
	} else {
		addExtra.value = true;
	}
};

onMounted(async () => {
	const loader = new Loader({
		apiKey: config().googleMapsApiKey,
		version: config().googleMapsVersion
	});
	Places.value = await loader.importLibrary('places');
	address.value = props.modelValue?.full_address || '';
});

watch(
	() => props.modelValue,
	() => {
		address.value = props.modelValue?.full_address || '';
	},
	{ deep: true }
);

return (_ctx: any,_cache: any) => {
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["tw3-w-full tw3-flex tw3-gap-2", { 'tw3-flex-col': !_ctx.inline }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _withDirectives((_openBlock(), _createBlock(_unref(AutoComplete), {
          modelValue: address.value,
          "onUpdate:modelValue": [
            _cache[0] || (_cache[0] = ($event: any) => ((address).value = $event)),
            invalidateInput
          ],
          class: "tw3-min-w-64 tw3-w-full",
          disabled: _ctx.disabled,
          inputClass: "tw3-min-w-64 tw3-w-full",
          invalid: !!_ctx.errMsg,
          loading: loading.value,
          placeholder: "Select address",
          suggestions: suggestions.value,
          onComplete: search,
          onFocus: setToken,
          onOptionSelect: handleSelect
        }, {
          option: _withCtx(({ option }) => [
            _createElementVNode("div", null, [
              _createElementVNode("div", _hoisted_3, _toDisplayString(option.main_text), 1),
              _createElementVNode("div", _hoisted_4, _toDisplayString(option.secondary_text), 1)
            ])
          ]),
          _: 1
        }, 8, ["modelValue", "disabled", "invalid", "loading", "suggestions"])), [
          [
            _directive_tooltip,
            {
						value: _ctx.errMsg,
						pt: {
							text: {
								style: { backgroundColor: 'rgb(248 113 113)' }
							},
							arrow: {
								style: { borderTopColor: 'rgb(248 113 113)' }
							}
						}
					},
            void 0,
            { top: true }
          ]
        ]),
        _createVNode(_unref(InputText), {
          class: "tw3-w-28",
          disabled: _ctx.disabled,
          invalid: !!_ctx.errMsg,
          modelValue: _ctx.modelValue?.unit,
          placeholder: !!props.modelValue?.po_box ? 'PO BOX' : 'Unit/Apt',
          type: "text",
          "onUpdate:modelValue": _cache[1] || (_cache[1] = v => updateValue('unit', v ?? null))
        }, null, 8, ["disabled", "invalid", "modelValue", "placeholder"])
      ]),
      (_ctx.withPoBox || _ctx.withExtra)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            (_ctx.withPoBox)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _createVNode(_unref(Checkbox), {
                    binary: "",
                    modelValue: !!props.modelValue?.po_box,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = v => updateValue('po_box', +v))
                  }, null, 8, ["modelValue"]),
                  _cache[4] || (_cache[4] = _createElementVNode("div", { class: "tw3-text-slate-500 tw3-font-medium tw3-text-sm tw3-ml-2" }, " P.O. BOX ", -1))
                ]))
              : _createCommentVNode("", true),
            (_ctx.withExtra)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 1,
                  class: "tw3-text-slate-500 tw3-font-medium tw3-text-sm tw3-cursor-pointer hover:tw3-underline",
                  onClick: _withModifiers(toggleExtra, ["prevent"])
                }, [
                  (showExtra.value)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_7, "Remove instructions"))
                    : (_openBlock(), _createElementBlock("span", _hoisted_8, "Add delivery instructions"))
                ]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ]),
    (_ctx.withExtra && showExtra.value)
      ? (_openBlock(), _createBlock(_unref(Textarea), {
          key: 0,
          autoResize: "",
          class: "tw3-w-full",
          disabled: _ctx.disabled,
          inputClass: "tw3-w-full",
          modelValue: _ctx.modelValue?.extra,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = v => updateValue('extra', v))
        }, null, 8, ["disabled", "modelValue"]))
      : _createCommentVNode("", true)
  ], 2))
}
}

})