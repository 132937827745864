import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "tw3-w-full tw3-flex tw3-flex-col tw3-col-span-1" }
const _hoisted_2 = { class: "tw3-content-end tw3-text-slate-500 tw3-font-medium tw3-text-sm tw3-relative" }
const _hoisted_3 = {
  key: 0,
  class: "tw3-flex tw3-gap-2 tw3-items-center tw3-justify-between tw3-w-full"
}
const _hoisted_4 = {
  key: 0,
  class: "tw3-flex tw3-gap-2 tw3-items-center tw3-w-max"
}
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = {
  key: 0,
  class: "tw3-block tw3-w-1.5 tw3-h-1.5 tw3-bg-slate-800 tw3-rounded-full"
}
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { class: "tw3-ml-auto tw3-mr-0" }

import Badge from 'primevue/badge';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';

import { capitalizeStr } from '@/shared/helpers';
import SearchDropdown from '@/shared/ui/SearchDropdown.vue';
import useReportsStore from '@/widgets/reportsElements/model/store';

import { CustomerSearchItem } from '../../api/types';

import ReportsCustomerTag from './ReportsCustomerTag.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'ReportsCustomerFilter',
  setup(__props) {

const { t } = useI18n();

const reportsStore = useReportsStore();

const dropdownOptions = ref();
const currentOffset = ref<number>(0);
const previousSearchString = ref();

const loadMoreCustomer = async () => {
	if (currentOffset.value > reportsStore.getCustomerFilterOptions.state.count)
		return;
	currentOffset.value += 20;
	dropdownOptions.value = await reportsStore.getCustomerFilterOptions.execute(
		0,
		{
			s: previousSearchString.value,
			offset: currentOffset.value
		}
	);
};

const searchForCustomer = async (searchString: string) => {
	if (searchString === previousSearchString.value) return;

	previousSearchString.value = searchString;
	currentOffset.value = 0;

	dropdownOptions.value = await reportsStore.getCustomerFilterOptions.execute(
		0,
		{
			s: searchString,
			offset: currentOffset.value
		}
	);
};

return (_ctx: any,_cache: any) => {
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("p", _hoisted_2, [
      _createTextVNode(_toDisplayString(_unref(capitalizeStr)(_unref(t)('customer'))) + " ", 1),
      (_unref(reportsStore).isRequired('customer'))
        ? _withDirectives((_openBlock(), _createBlock(_unref(Badge), {
            key: 0,
            class: "tw3-absolute !tw3-h-[0.3rem] !tw3-min-w-[0.3rem] !tw3-w-[0.3rem]",
            severity: "danger"
          }, null, 512)), [
            [
              _directive_tooltip,
              _unref(t)('required'),
              void 0,
              { top: true }
            ]
          ])
        : _createCommentVNode("", true)
    ]),
    _createVNode(SearchDropdown, {
      modelValue: _unref(reportsStore).customerFilterValue,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(reportsStore).customerFilterValue) = $event)),
      dropdownOptions: _unref(reportsStore).customerFilterOptions,
      dropdownContainerClass: "customWidth",
      loading: _unref(reportsStore).getCustomerFilterOptions.isLoading,
      optionLabel: "full_name",
      placeholder: `${_unref(t)('searchBy')} ${_unref(t)('customers').toLocaleLowerCase()}...`,
      onLoadMore: loadMoreCustomer,
      onSearch: searchForCustomer
    }, {
      selected: _withCtx(({ item }) => [
        item
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createElementVNode("span", null, _toDisplayString(item.full_name), 1),
              _createVNode(ReportsCustomerTag, { item: item }, null, 8, ["item"])
            ]))
          : _createCommentVNode("", true)
      ]),
      dropdownItem: _withCtx(({ item }) => [
        item
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createElementVNode("span", {
                style: {"text-overflow":"ellipsis","max-width":"47%","overflow":"hidden"},
                innerHTML: item.full_name
              }, null, 8, _hoisted_5),
              (item.phone_number)
                ? (_openBlock(), _createElementBlock("span", _hoisted_6))
                : _createCommentVNode("", true),
              (item.phone_number)
                ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(item.phone_number), 1))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_8, [
                _createVNode(ReportsCustomerTag, {
                  item: item as CustomerSearchItem
                }, null, 8, ["item"])
              ])
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["modelValue", "dropdownOptions", "loading", "placeholder"])
  ]))
}
}

})