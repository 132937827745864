import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  id: "edi-report"
}
const _hoisted_2 = ["data"]
const _hoisted_3 = {
  class: "alignment",
  style: {"width":"100%","display":"flex","justify-content":"flex-start"}
}
const _hoisted_4 = {
  class: "ediTh",
  style: {"background":"rgb(208, 229, 255)","color":"rgb(62, 148, 255)","width":"100% !important"}
}
const _hoisted_5 = { class: "cell" }
const _hoisted_6 = {
  class: "el-table__cell smallerCell",
  style: {"width":"120px !important","min-width":"120px !important"}
}
const _hoisted_7 = { class: "cell" }
const _hoisted_8 = {
  class: "el-table__cell",
  style: {"width":"100% !important"}
}
const _hoisted_9 = { class: "cell" }
const _hoisted_10 = {
  class: "el-table__cell",
  style: {"width":"100% !important"}
}
const _hoisted_11 = { class: "cell" }


export default /*@__PURE__*/_defineComponent({
  __name: 'EdiReportTable',
  props: {
    data: {}
  },
  setup(__props: any) {



const groupTotal = (group: any) => {
	let total = 0;
	for (let i = 0; i < group.length; i++) {
		total += group[i].count;
	}
	return total;
};

return (_ctx: any,_cache: any) => {
  return (_ctx.data)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data, (group, index) => {
          return (_openBlock(), _createElementBlock("div", { key: index }, [
            _createElementVNode("table", {
              data: group,
              "header-cell-style": { background: '#d0e5ff', color: '#3e94ff' },
              style: {"width":"100%"}
            }, [
              _createElementVNode("thead", null, [
                _createElementVNode("tr", _hoisted_3, [
                  _cache[0] || (_cache[0] = _createElementVNode("th", {
                    class: "ediTh smallerCell",
                    style: {"background":"rgb(208, 229, 255)","color":"rgb(62, 148, 255)","width":"120px !important","min-width":"120px !important"}
                  }, [
                    _createElementVNode("div", { class: "cell" }, "Group")
                  ], -1)),
                  _cache[1] || (_cache[1] = _createElementVNode("th", {
                    class: "ediTh",
                    style: {"background":"rgb(208, 229, 255)","color":"rgb(62, 148, 255)","width":"100% !important"}
                  }, [
                    _createElementVNode("div", { class: "cell" }, "Shop")
                  ], -1)),
                  _createElementVNode("th", _hoisted_4, [
                    _createElementVNode("div", _hoisted_5, "EDI Count (" + _toDisplayString(groupTotal(group)) + ")", 1)
                  ])
                ])
              ]),
              _createElementVNode("tbody", null, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(group, (item) => {
                  return (_openBlock(), _createElementBlock("tr", {
                    key: item.id,
                    class: "alignment",
                    style: {"width":"100%","display":"flex","justify-content":"flex-start"}
                  }, [
                    _createElementVNode("td", _hoisted_6, [
                      _createElementVNode("div", _hoisted_7, _toDisplayString(item.edi_group_id), 1)
                    ]),
                    _createElementVNode("td", _hoisted_8, [
                      _createElementVNode("div", _hoisted_9, "(" + _toDisplayString(item.id) + ") " + _toDisplayString(item.name), 1)
                    ]),
                    _createElementVNode("td", _hoisted_10, [
                      _createElementVNode("div", _hoisted_11, [
                        _createElementVNode("span", null, _toDisplayString(item.count || 0), 1)
                      ])
                    ])
                  ]))
                }), 128))
              ])
            ], 8, _hoisted_2)
          ]))
        }), 128))
      ]))
    : _createCommentVNode("", true)
}
}

})