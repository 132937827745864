import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import Button from 'primevue/button';
import Checkbox from 'primevue/checkbox';
import Column from 'primevue/column';
import DataTable, { DataTableRowReorderEvent } from 'primevue/datatable';
import Dialog from 'primevue/dialog';
import { useToast } from 'primevue/usetoast';
import { ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';

import { TableHeader } from './model/types';


export default /*@__PURE__*/_defineComponent({
  __name: 'UpdateColumns',
  props: /*@__PURE__*/_mergeModels({
    modelValue: {},
    options: {}
  }, {
    "show": { type: Boolean },
    "showModifiers": {},
  }),
  emits: /*@__PURE__*/_mergeModels(["save"], ["update:show"]),
  setup(__props: any, { emit: __emit }) {

const { t } = useI18n();

const props = __props;
const emit = __emit;
const toast = useToast();
const showModal = _useModel<boolean>(__props, 'show');
const columns = ref<TableHeader[]>([]);
const selectedColumns = ref<TableHeader[]>([]);

const onRowReorder = (event: DataTableRowReorderEvent) => {
	columns.value = event.value;
};

const save = () => {
	const cols: TableHeader[] = [];
	for (const item of columns.value) {
		const index = selectedColumns.value.findIndex(v => v.key === item.key);
		if (index !== -1) cols.push(item);
	}
	emit('save', cols);
	showModal.value = false;
};

const update = (v: any[]) => {
	if (v.length && v.length > 1) {
		selectedColumns.value = v;
	} else {
		toast.add({
			detail: t('columnRequiredMsg'),
			life: 3000,
			severity: 'warn',
			summary: 'Warn Message'
		});
	}
};

watch(
	() => showModal.value,
	() => {
		if (showModal.value) {
			columns.value = [
				...props.modelValue,
				...props.options.filter(
					v => !props.modelValue.some(m => m.key === v.key)
				)
			];
			selectedColumns.value = props.modelValue;
		}
	}
);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(Dialog), {
    id: "vue3UpdateColumns",
    visible: showModal.value,
    "onUpdate:visible": _cache[2] || (_cache[2] = ($event: any) => ((showModal).value = $event)),
    appendTo: "#vue3app",
    class: "tw3-w-full tw3-max-w-lg",
    contentStyle: { padding: '0 0 1rem 0' },
    header: _ctx.$t('columnSettings'),
    modal: ""
  }, {
    footer: _withCtx(() => [
      _createVNode(_unref(Button), {
        label: _ctx.$t('cancel'),
        severity: "secondary",
        size: "small",
        type: "button",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (showModal.value = false))
      }, null, 8, ["label"]),
      _createVNode(_unref(Button), {
        label: _ctx.$t('save'),
        severity: "info",
        size: "small",
        type: "button",
        onClick: save
      }, null, 8, ["label"])
    ]),
    default: _withCtx(() => [
      _createVNode(_unref(DataTable), {
        scrollable: "",
        scrollHeight: "400px",
        selection: selectedColumns.value,
        value: columns.value,
        onRowReorder: onRowReorder,
        "onUpdate:selection": update
      }, {
        default: _withCtx(() => [
          _createVNode(_unref(Column), {
            headerStyle: "width: 3rem",
            rowReorder: ""
          }),
          _createVNode(_unref(Column), {
            headerStyle: "width: 3rem",
            selectionMode: "multiple"
          }, {
            body: _withCtx(({ data }) => [
              _createVNode(_unref(Checkbox), {
                modelValue: selectedColumns.value,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((selectedColumns).value = $event)),
                disabled: data.key === 'edit',
                value: data
              }, null, 8, ["modelValue", "disabled", "value"])
            ]),
            _: 1
          }),
          _createVNode(_unref(Column), {
            field: "title",
            header: _ctx.$t('column')
          }, null, 8, ["header"])
        ]),
        _: 1
      }, 8, ["selection", "value"])
    ]),
    _: 1
  }, 8, ["visible", "header"]))
}
}

})