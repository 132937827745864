import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["colspan"]
const _hoisted_2 = {
  key: 0,
  class: "cell"
}
const _hoisted_3 = ["innerHTML"]

import { useI18n } from 'vue-i18n';

import { reportsGetters } from '@/widgets/reportsElements/model/utils/getters';


export default /*@__PURE__*/_defineComponent({
  __name: 'TableTotals',
  props: {
    label: {},
    fake: {},
    columns: {},
    data: {}
  },
  setup(__props: any) {

const { t } = useI18n();



return (_ctx: any,_cache: any) => {
  return (_ctx.columns && _ctx.data)
    ? (_openBlock(), _createElementBlock("tr", {
        key: 0,
        class: _normalizeClass(["table-totals", {
			'grand-total': _ctx.label === _unref(t)('reportsPage.grand'),
			'shop-totals': _ctx.label === _unref(t)('shop'),
			'customer-totals': _ctx.label === _unref(t)('reportsPage.customer'),
			'contractor-totals': _ctx.label === _unref(t)('reportsPage.contractor')
		}])
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fake, (n) => {
          return (_openBlock(), _createElementBlock("td", {
            key: n,
            class: "fake-cell"
          }))
        }), 128)),
        _createElementVNode("td", {
          class: "totals-cell-text cell",
          colspan: 
				_ctx.columns.length - _unref(reportsGetters).getDollarColumns(_ctx.columns).length
			
        }, " (" + _toDisplayString(_ctx.data.length) + ") " + _toDisplayString(_ctx.label) + " " + _toDisplayString(_unref(t)('reportsPage.totals')) + ": ", 9, _hoisted_1),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(reportsGetters).getDollarColumns(_ctx.columns), (col) => {
          return (_openBlock(), _createElementBlock("td", {
            key: col.prop,
            class: "cell"
          }, _toDisplayString(_unref(reportsGetters).getColumnsSum(col, _ctx.data)), 1))
        }), 128)),
        (_ctx.data[0]['forty_percent'])
          ? (_openBlock(), _createElementBlock("td", _hoisted_2, _toDisplayString(_unref(reportsGetters).getFortyPercentSum(_ctx.data)), 1))
          : _createCommentVNode("", true),
        (_ctx.data[0]['margin_percentage'])
          ? (_openBlock(), _createElementBlock("td", {
              key: 1,
              style: _normalizeStyle({
				color: _ctx.data['margin_percentage_color'],
				fontWeight: 'bold',
				fontSize: '12px',
				padding: '5px',
				textAlign: 'left'
			}),
              innerHTML: _unref(reportsGetters).getTotalMarginPercentageWithColor(_ctx.data)
            }, null, 12, _hoisted_3))
          : _createCommentVNode("", true)
      ], 2))
    : _createCommentVNode("", true)
}
}

})