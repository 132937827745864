import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, isMemoSame as _isMemoSame, withMemo as _withMemo } from "vue"

const _hoisted_1 = { class: "tw3-flex tw3-flex-col tw3-gap-2" }
const _hoisted_2 = { class: "tw3-flex tw3-items-center tw3-gap-2" }
const _hoisted_3 = { class: "tw3-flex tw3-items-center tw3-gap-2" }
const _hoisted_4 = { class: "cell-container" }
const _hoisted_5 = ["href"]
const _hoisted_6 = {
  key: 2,
  class: "tw3-w-full tw3-flex tw3-items-center tw3-gap-4"
}
const _hoisted_7 = { class: "tw3-w-full tw3-flex tw3-gap-2" }
const _hoisted_8 = {
  key: 5,
  class: "tw3-w-full tw3-flex tw3-items-center tw3-gap-4"
}
const _hoisted_9 = { class: "tw3-w-full tw3-flex tw3-items-center tw3-gap-2" }
const _hoisted_10 = { class: "tw3-flex tw3-flex-wrap tw3-items-center tw3-gap-2" }
const _hoisted_11 = {
  key: 6,
  class: "tw3-w-full tw3-flex tw3-items-center tw3-gap-4"
}
const _hoisted_12 = { class: "tw3-w-full tw3-flex tw3-items-center tw3-justify-center tw3-gap-2" }
const _hoisted_13 = { class: "tw3-flex tw3-flex-wrap tw3-items-center tw3-gap-2" }
const _hoisted_14 = {
  key: 7,
  class: "tw3-flex tw3-items-center tw3-gap-2"
}
const _hoisted_15 = {
  key: 8,
  class: "tw3-whitespace-nowrap tw3-ml-auto"
}
const _hoisted_16 = {
  key: 11,
  class: "tw3-w-full tw3-flex tw3-items-center tw3-gap-4"
}
const _hoisted_17 = { class: "tw3-w-full tw3-flex tw3-items-center tw3-justify-center tw3-gap-2" }
const _hoisted_18 = {
  key: 12,
  class: "tw3-whitespace-nowrap tw3-max-w-64 tw3-truncate"
}
const _hoisted_19 = { class: "tw3-flex tw3-w-full" }
const _hoisted_20 = {
  key: 0,
  class: "tw3-sticky tw3-top-1/2 tw3-left-1/2 tw3-text-3xl tw3-translate-x-[-50%] tw3-translate-y-[-50%]"
}

import { storeToRefs } from 'pinia';
import Button from 'primevue/button';
import Checkbox from 'primevue/checkbox';
import Column from 'primevue/column';
import DataTable, { type DataTableSortEvent } from 'primevue/datatable';
import tinycolor from 'tinycolor2';
import { onMounted, onUnmounted } from 'vue';
import { useI18n } from 'vue-i18n';

import { jobStatusConfig } from '@/entities/job/config';
import { isEmptyValue } from '@/shared/helpers';
import GbDefaultCell from '@/shared/ui/table/GbDefaultCell.vue';
import StatusBadge from '@/shared/ui/table/StatusBadge.vue';

import { JOBS_FILTERS, TABLE_ID } from '../config';
import {
	JOB_DATE_COLUMNS,
	JOB_PRICE_COLUMNS,
	JOB_VENDOR_COLUMNS
} from '../config/jobs-table-columns';
import { EdiStatus } from '../model/enums/edi-status.enum';
import { useJobEditCellStore, useJobsStore } from '../model/store';
import {
	getPartsWithColors,
	jobDollarFormatter,
	jobInstallContextColor
} from '../utils';

import JobDocumentCell from './cells/JobDocumentCell.vue';
import JobEditCell from './cells/JobEditCell.vue';
import JobNoteCell from './cells/JobNoteCell.vue';
import JobTagsCell from './cells/JobTagsCell.vue';
import JobPopoverCell from './cells/layout/JobPopoverCell.vue';
import JobTableFilters from './filters/JobTableFilters.vue';
import JobsListHeader from './JobsListHeader.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'JobsList',
  setup(__props) {

const { t } = useI18n();
const store = useJobsStore();
const editStore = useJobEditCellStore();
const {
	exportMode,
	exportAllColumnsSelected,
	exportAllRowsSelected,
	exportRows,
	tableLoading,
	shownItems,
	loadingFiltersData,
	view,
	loading,
	loadingMore,
	preventFetchMore,
	updateViewLoading
} = storeToRefs(store);

const getFilterIconClass = (field: string) => {
	const filter = view.value.filters[field as keyof typeof JOBS_FILTERS];

	return filter && isEmptyValue(filter.val)
		? 'pi pi-filter'
		: 'pi pi-filter-fill';
};

const handleSort = (state: DataTableSortEvent) => {
	store.handleSort({
		desc: state.sortOrder === 1,
		field: state.sortField as string
	});
};

const transformColor = (color: string) => {
	const hsl = tinycolor(color).toHsl();
	hsl.l = 0.95;
	return tinycolor(hsl).toHslString();
};

const statusObject = (status: keyof typeof jobStatusConfig) => {
	return jobStatusConfig[status] || '';
};

onMounted(() => {
	if (store.jobFiltersDataEmpty) store.fetchFiltersData(0);
	store.fetchInit(0);

	const table = document.getElementById(TABLE_ID);

	if (table) {
		table.addEventListener('scroll', () => {
			if (
				table.scrollTop + table.clientHeight >= table.scrollHeight - 100 &&
				!tableLoading.value &&
				!loadingMore.value &&
				!preventFetchMore.value
			) {
				store.fetchMore();
			}
		});
	}
});

onUnmounted(() => {
	const table = document.getElementById(TABLE_ID);

	if (table) {
		table.removeEventListener('scroll', () => {
			if (
				table.scrollTop + table.clientHeight >= table.scrollHeight - 100 &&
				!tableLoading.value &&
				!loadingMore.value &&
				!preventFetchMore.value
			) {
				store.fetchMore();
			}
		});
	}
});

return (_ctx: any,_cache: any) => {
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createBlock(_unref(DataTable), {
    key: _unref(TABLE_ID) + '_vue',
    dataKey: "id",
    filterDisplay: "menu",
    filters: _unref(view).filters,
    lazy: "",
    loading: _unref(tableLoading),
    "pt:emptymessagecell:class": "tw3-h-full",
    "pt:mask:class": "!tw3-bg-transparent",
    "pt:tableContainer:id": _unref(TABLE_ID),
    scrollable: "",
    scrollHeight: "flex",
    size: "small",
    sortField: _unref(view).sort.field,
    sortOrder: _unref(view).sort.desc ? 1 : -1,
    tableClass: 
			'tw3-max-w-full ' +
			(_unref(tableLoading) ? 'p-datatable-gb-loading ' : '') +
			(_unref(shownItems).length ? '' : '!tw3-h-full')
		,
    value: _unref(shownItems),
    onSort: handleSort,
    "onUpdate:filters": _unref(store).updateFilters
  }, {
    header: _withCtx(() => [
      _createVNode(JobsListHeader)
    ]),
    loading: _withCtx(() => _cache[2] || (_cache[2] = [])),
    empty: _withCtx(() => [
      _createElementVNode("div", _hoisted_19, [
        (!(_unref(loading) || _unref(loadingFiltersData) || _unref(updateViewLoading)))
          ? (_openBlock(), _createElementBlock("p", _hoisted_20, _toDisplayString(_unref(t)('noData')), 1))
          : _createCommentVNode("", true)
      ])
    ]),
    default: _withCtx(() => [
      _createVNode(_unref(Column), {
        bodyStyle: "z-index: 5",
        field: "exportSelect",
        frozen: "",
        hidden: !_unref(exportMode)
      }, {
        header: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_unref(Checkbox), {
                binary: "",
                modelValue: _unref(exportAllColumnsSelected),
                "onUpdate:modelValue": _unref(store).exportSelectAllColumns
              }, null, 8, ["modelValue", "onUpdate:modelValue"]),
              _cache[3] || (_cache[3] = _createElementVNode("p", { class: "tw3-whitespace-nowrap" }, "All Columns", -1))
            ]),
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_unref(Checkbox), {
                binary: "",
                modelValue: _unref(exportAllRowsSelected),
                "onUpdate:modelValue": _unref(store).exportSelectAllRows
              }, null, 8, ["modelValue", "onUpdate:modelValue"]),
              _cache[4] || (_cache[4] = _createElementVNode("p", { class: "tw3-whitespace-nowrap" }, "All Rows", -1))
            ])
          ])
        ]),
        body: _withCtx(({ data }) => [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_unref(Checkbox), {
              binary: "",
              class: "tw3-m-auto",
              modelValue: _unref(exportRows).includes(data.job_id),
              "onUpdate:modelValue": 
							() => _unref(store).exportToggleRowSelection(data.job_id)
						
            }, null, 8, ["modelValue", "onUpdate:modelValue"])
          ])
        ]),
        _: 1
      }, 8, ["hidden"]),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(view).selectedColumns, (column, columnIndex, ___, _cached) => {
        const _memo = ([column.hidden, column.backgroundColor, columnIndex])
        if (_cached && _cached.key === column.key && _isMemoSame(_cached, _memo)) return _cached
        const _item = (_openBlock(), _createBlock(_unref(Column), {
          key: column.key,
          exportable: "",
          field: column.key,
          filterField: column.key,
          header: column.label,
          headerStyle: 
				column.backgroundColor
					? `background-color: ${transformColor(column.backgroundColor)}`
					: undefined
			,
          headerClass: "tw3-whitespace-nowrap tw3-border-b tw3-relative",
          hidden: column.hidden,
          "pt:headerCell:class": "tw3-border-b",
          showFilterMatchModes: false,
          showFilterMenu: column.key !== 'row_number',
          sortable: column.sortable
        }, {
          header: _withCtx(() => [
            (_unref(exportMode))
              ? (_openBlock(), _createBlock(_unref(Checkbox), {
                  key: 0,
                  binary: "",
                  modelValue: !column.dont_export,
                  "onUpdate:modelValue": v => (column.dont_export = !v)
                }, null, 8, ["modelValue", "onUpdate:modelValue"]))
              : _createCommentVNode("", true),
            _createElementVNode("div", {
              class: "tw3-h-[4px] tw3-absolute tw3-bottom-0 tw3-right-0 tw3-left-0",
              style: _normalizeStyle(`background-color: ${column.backgroundColor && column.backgroundColor !== '' && column.backgroundColor !== 'rgb(255,255,255)' && column.backgroundColor !== 'rgba(255,255,255,1)' ? column.backgroundColor : 'transparent'}`)
            }, null, 4)
          ]),
          filtericon: _withCtx(() => [
            (column.key !== 'row_number')
              ? (_openBlock(), _createElementBlock("i", {
                  key: 0,
                  class: _normalizeClass(getFilterIconClass(column.key))
                }, null, 2))
              : _createCommentVNode("", true)
          ]),
          filter: _withCtx(({ filterModel }) => [
            _createVNode(JobTableFilters, {
              id: column.key,
              modelValue: filterModel.val,
              "onUpdate:modelValue": ($event: any) => ((filterModel.val) = $event),
              type: filterModel.type,
              "onUpdate:type": ($event: any) => ((filterModel.type) = $event),
              name: column.label
            }, null, 8, ["id", "modelValue", "onUpdate:modelValue", "type", "onUpdate:type", "name"])
          ]),
          filterclear: _withCtx(({ filterModel, filterCallback }) => [
            _createVNode(_unref(Button), {
              label: "Clear",
              outlined: "",
              size: "small",
              onClick: 
						() => {
							filterModel.val =
								_unref(JOBS_FILTERS)[column.key as keyof typeof JOBS_FILTERS]?.val;
							filterModel.type =
								_unref(JOBS_FILTERS)[column.key as keyof typeof JOBS_FILTERS]?.type;
							filterCallback();
						}
					
            }, null, 8, ["onClick"])
          ]),
          body: _withCtx(({ data }) => [
            _createVNode(GbDefaultCell, {
              loading: !data
            }, {
              default: _withCtx(() => [
                (column.key === 'job_id')
                  ? (_openBlock(), _createElementBlock("a", {
                      key: 0,
                      class: "tw3-font-bold tw3-ml-auto",
                      href: '/jobs/' + data[column.key],
                      target: "_blank"
                    }, _toDisplayString(data[column.key]), 9, _hoisted_5))
                  : (
							column.key === 'status' && statusObject(data[column.key])
						)
                    ? (_openBlock(), _createBlock(StatusBadge, {
                        key: 1,
                        style: _normalizeStyle({
							margin: 'auto',
							background: statusObject(data[column.key]).color
						}),
                        text: statusObject(data[column.key]).label
                      }, null, 8, ["style", "text"]))
                    : (column.key === 'tags')
                      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                          _createElementVNode("div", _hoisted_7, [
                            _createVNode(JobTagsCell, {
                              data: data[column.key] ? data[column.key] : [],
                              name: column.key
                            }, null, 8, ["data", "name"])
                          ]),
                          (_unref(editStore).editPermission)
                            ? (_openBlock(), _createBlock(JobEditCell, {
                                key: 0,
                                class: "tw3-flex-shrink-0 tw3-mr-[0.5rem]",
                                loading: 
								_unref(editStore).jobTags.isLoading &&
								data.job_id === _unref(editStore).currentJobId
							,
                                onClick: ($event: any) => (_unref(editStore).toggleJobTagsDialog(data))
                              }, null, 8, ["loading", "onClick"]))
                            : _createCommentVNode("", true)
                        ]))
                      : (column.key === 'parts')
                        ? (_openBlock(), _createBlock(JobTagsCell, {
                            key: 3,
                            class: "tw3-mr-auto",
                            data: data[column.key] ? _unref(getPartsWithColors)(data[column.key]) : [],
                            name: column.key
                          }, null, 8, ["data", "name"]))
                        : (column.key === 'taxes')
                          ? (_openBlock(), _createBlock(JobTagsCell, {
                              key: 4,
                              class: "tw3-mr-auto",
                              data: 
							data[column.key]
								? data[column.key].map((v) => ({ text: v.name }))
								: []
						,
                              name: column.key
                            }, null, 8, ["data", "name"]))
                          : (column.key === 'documents')
                            ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                                _createElementVNode("div", _hoisted_9, [
                                  (data[column.key] && data[column.key][0])
                                    ? (_openBlock(), _createBlock(JobDocumentCell, {
                                        key: 0,
                                        data: data[column.key][0]
                                      }, null, 8, ["data"]))
                                    : _createCommentVNode("", true),
                                  (data[column.key]?.length && data[column.key].length > 1)
                                    ? (_openBlock(), _createBlock(JobPopoverCell, {
                                        key: 1,
                                        label: `+${data[column.key].length - 1}`,
                                        name: column.key
                                      }, {
                                        default: _withCtx(() => [
                                          _createElementVNode("div", _hoisted_10, [
                                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(data[column.key], (document, documentIndex) => {
                                              return (_openBlock(), _createElementBlock(_Fragment, {
                                                key: document?.id || documentIndex
                                              }, [
                                                (documentIndex !== 0)
                                                  ? (_openBlock(), _createBlock(JobDocumentCell, {
                                                      key: 0,
                                                      data: document
                                                    }, null, 8, ["data"]))
                                                  : _createCommentVNode("", true)
                                              ], 64))
                                            }), 128))
                                          ])
                                        ]),
                                        _: 2
                                      }, 1032, ["label", "name"]))
                                    : _createCommentVNode("", true)
                                ]),
                                (_unref(editStore).editPermission)
                                  ? (_openBlock(), _createBlock(JobEditCell, {
                                      key: 0,
                                      class: "tw3-flex-shrink-0 tw3-mr-[0.5rem]",
                                      loading: 
								_unref(editStore).jobDocuments.isLoading &&
								data.job_id === _unref(editStore).currentJobId
							,
                                      onClick: ($event: any) => (_unref(editStore).toggleJobDocumentsDialog(data))
                                    }, null, 8, ["loading", "onClick"]))
                                  : _createCommentVNode("", true)
                              ]))
                            : (column.key === 'notes')
                              ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                                  _createElementVNode("div", _hoisted_12, [
                                    (data[column.key]?.length)
                                      ? (_openBlock(), _createBlock(JobPopoverCell, {
                                          key: 0,
                                          label: "See All",
                                          name: column.key
                                        }, {
                                          default: _withCtx(() => [
                                            _createElementVNode("div", _hoisted_13, [
                                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(data[column.key], (note, noteIndex) => {
                                                return (_openBlock(), _createBlock(JobNoteCell, {
                                                  key: note?.id || noteIndex,
                                                  note: note,
                                                  showDelete: false,
                                                  user: note.user
                                                }, null, 8, ["note", "user"]))
                                              }), 128))
                                            ])
                                          ]),
                                          _: 2
                                        }, 1032, ["name"]))
                                      : _createCommentVNode("", true)
                                  ]),
                                  (_unref(editStore).editPermission)
                                    ? (_openBlock(), _createBlock(JobEditCell, {
                                        key: 0,
                                        class: "tw3-flex-shrink-0 tw3-mr-[0.5rem]",
                                        loading: 
								_unref(editStore).jobNotes.isLoading &&
								data.job_id === _unref(editStore).currentJobId
							,
                                        onClick: ($event: any) => (_unref(editStore).toggleJobNotesDialog(data))
                                      }, null, 8, ["loading", "onClick"]))
                                    : _createCommentVNode("", true)
                                ]))
                              : (_unref(JOB_VENDOR_COLUMNS).includes(column.key))
                                ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(data[column.key]?.split(', ') ||
							[], (vendor, vendorIndex) => {
                                      return (_openBlock(), _createBlock(StatusBadge, {
                                        key: vendorIndex,
                                        style: {"font-size":"1rem","line-height":"1rem","background-color":"var(--p-tag-secondary-background)","color":"rgb(71, 85, 105)"},
                                        text: vendor
                                      }, null, 8, ["text"]))
                                    }), 128))
                                  ]))
                                : (_unref(JOB_PRICE_COLUMNS).includes(column.key))
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_15, _toDisplayString(_unref(jobDollarFormatter)(data[column.key])), 1))
                                  : (column.key === 'install_context')
                                    ? (_openBlock(), _createBlock(StatusBadge, {
                                        key: 9,
                                        severity: "secondary",
                                        style: _normalizeStyle({
							margin: 'auto',
							backgroundColor: _unref(jobInstallContextColor)(data[column.key])
						}),
                                        text: data[column.key]
                                      }, null, 8, ["style", "text"]))
                                    : (column.key === 'consumer.is_commercial')
                                      ? (_openBlock(), _createBlock(StatusBadge, {
                                          key: 10,
                                          class: "tw3-m-auto",
                                          style: {"background-color":"var(--p-tag-secondary-background)","color":"rgb(71, 85, 105)"},
                                          text: data[column.key] === 1 ? _ctx.$t('commercial') : _ctx.$t('individual')
                                        }, null, 8, ["text"]))
                                      : (column.key === 'ediqueue.status')
                                        ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                                            _createElementVNode("div", _hoisted_17, [
                                              (
									data[column.key] && data[column.key] !== _unref(EdiStatus).Unsubmitted
								)
                                                ? (_openBlock(), _createBlock(StatusBadge, {
                                                    key: 0,
                                                    class: "tw3-m-auto",
                                                    style: {"background-color":"var(--p-tag-secondary-background)","color":"rgb(71, 85, 105)"},
                                                    text: data[column.key]
                                                  }, null, 8, ["text"]))
                                                : _createCommentVNode("", true)
                                            ]),
                                            (_unref(editStore).editPermission)
                                              ? (_openBlock(), _createBlock(JobEditCell, {
                                                  key: 0,
                                                  class: "tw3-flex-shrink-0 tw3-mr-[0.5rem]",
                                                  loading: 
								_unref(editStore).jobEdi.isLoading &&
								data.job_id === _unref(editStore).currentJobId
							,
                                                  onClick: ($event: any) => (_unref(editStore).toggleJobEdiDialog(data))
                                                }, null, 8, ["loading", "onClick"]))
                                              : _createCommentVNode("", true)
                                          ]))
                                        : (
							column.key === 'install_notes' ||
							column.key === 'technician_notes'
						)
                                          ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_18, [
                                              _createTextVNode(_toDisplayString(data[column.key]), 1)
                                            ])), [
                                              [
                                                _directive_tooltip,
                                                data[column.key],
                                                void 0,
                                                { bottom: true }
                                              ]
                                            ])
                                          : (_openBlock(), _createElementBlock("div", {
                                              key: 13,
                                              class: _normalizeClass(["tw3-whitespace-nowrap", {
							'tw3-m-auto':
								_unref(JOB_DATE_COLUMNS).includes(column.key) ||
								column.key === 'consumer.address.state' ||
								column.key === 'install_g_address.state' ||
								column.key === 'scheduled_time_start' ||
								column.key === 'scheduled_time_end',
							'tw3-ml-auto':
								column.key === 'internal_number' ||
								column.key === 'consumer.address.unit' ||
								column.key === 'consumer.address.zip' ||
								column.key === 'vehicle.year' ||
								column.key === 'vehicle.vin' ||
								column.key === 'install_g_address.zip' ||
								column.key === 'install_g_address.unit' ||
								column.key === 'vehicle.unit' ||
								column.key === 'dot_number' ||
								column.key === 'lot_number' ||
								column.key === 'purchase_order_number' ||
								column.key === 'requisition_order_number' ||
								column.key === 'vehicle.plate_number' ||
								column.key === 'referral_number' ||
								column.key === 'shop_id' ||
								column.key === 'policy_number' ||
								column.key === 'vehicle.unit_number' ||
								column.key === 'consumer_id' ||
								column.key === 'full_job_number' ||
								column.key === 'salesidejob_id'
						}])
                                            }, _toDisplayString(data[column.key]), 3))
              ]),
              _: 2
            }, 1032, ["loading"])
          ]),
          _: 2
        }, 1032, ["field", "filterField", "header", "headerStyle", "hidden", "showFilterMenu", "sortable"]))
        _item.memo = _memo
        return _item
      }, _cache, 0), 128))
    ]),
    _: 1
  }, 8, ["filters", "loading", "pt:tableContainer:id", "sortField", "sortOrder", "tableClass", "value", "onUpdate:filters"]))
}
}

})