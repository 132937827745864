import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref } from "vue"

const _hoisted_1 = { class: "report-table-title tw3-w-max tw3-min-w-full" }
const _hoisted_2 = { class: "title-row tw3-text-[14px] tw3-flex tw3-justify-between tw3-items-center tw3-px-0 tw3-p-3 tw3-pt-0 tw3-w-full tw3-text-center tw3-px-16 lg:tw3-px-32" }
const _hoisted_3 = { class: "table-shop-name tw3-py-8 tw3-w-4/12" }
const _hoisted_4 = {
  key: 0,
  class: "title-nested"
}
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = { key: 2 }
const _hoisted_7 = { class: "table-logo tw3-py-8 tw3-w-4/12" }
const _hoisted_8 = {
  key: 0,
  class: "shopLogo title-nested",
  src: "https://www.filepicker.io/api/file/HutESuxTn6flddjsNLqL"
}
const _hoisted_9 = ["src"]
const _hoisted_10 = { class: "table-date tw3-py-8 tw3-w-4/12" }
const _hoisted_11 = { class: "title-nested" }

import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { storeToRefs } from 'pinia';
import { computed, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';

import { useUserStore } from '@/entities/user/store';
import useReportsStore from '@/widgets/reportsElements/model/store';

export default /*@__PURE__*/_defineComponent({
  __name: 'ReportTableTitle',
  setup(__props) {

dayjs.extend(advancedFormat);

const { t } = useI18n();
const { user } = storeToRefs(useUserStore());

const allShops = computed(() => user.value.shops);

const reportStore = useReportsStore();
const { selectedShop, currentReportType, staticDate, reportData } =
	storeToRefs(reportStore);
const isShopSingle = ref(selectedShop.value?.length === 1);

const shopNames = ref(
	allShops.value
		.filter(({ id }) => selectedShop.value?.includes(id))
		.map(shop => shop.name)
		.join('<br/>')
);

const shopNameWithLogo = ref(
	allShops.value
		.filter(({ id }) => selectedShop.value?.includes(id))
		.map(({ name, logo }) => {
			return { name, logo };
		})[0]
);

const dates = computed(() => {
	if (currentReportType.value.id === 'balance-sheet') {
		return dayjs(staticDate.value?.endDate).format('MMM Do, YYYY');
	} else {
		return `${dayjs(staticDate.value?.startDate).format('MMM Do, YYYY')} - ${dayjs(staticDate.value?.endDate).format('MMM Do, YYYY')}`;
	}
});

watch(reportData, () => {
	isShopSingle.value = selectedShop.value?.length === 1;
	shopNames.value = allShops.value
		.filter(({ id }) => selectedShop.value?.includes(id))
		.map(shop => shop.name)
		.join('<br/>');
	shopNameWithLogo.value = allShops.value
		.filter(({ id }) => selectedShop.value?.includes(id))
		.map(({ name, logo }) => {
			return { name, logo };
		})[0];
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (isShopSingle.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createElementVNode("strong", null, _toDisplayString(shopNames.value), 1)
            ]))
          : (!isShopSingle.value && _unref(currentReportType).group !== 'right-part')
            ? (_openBlock(), _createElementBlock("div", {
                key: 1,
                innerHTML: shopNames.value
              }, null, 8, _hoisted_5))
            : (!isShopSingle.value && _unref(currentReportType).group === 'right-part')
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _createElementVNode("b", null, _toDisplayString(_unref(t)('reportsPage.multipleShops')), 1)
                ]))
              : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_7, [
        (_unref(currentReportType).id === 'nags-license')
          ? (_openBlock(), _createElementBlock("img", _hoisted_8))
          : _createCommentVNode("", true),
        (
						shopNameWithLogo.value.logo !== null &&
						isShopSingle.value &&
						_unref(currentReportType).id !== 'nags-license'
					)
          ? (_openBlock(), _createElementBlock("img", {
              key: 1,
              class: "shopLogo title-nested",
              src: shopNameWithLogo.value.logo
            }, null, 8, _hoisted_9))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("div", _hoisted_11, [
          _createElementVNode("strong", null, _toDisplayString(_unref(currentReportType).label), 1),
          _createElementVNode("div", null, _toDisplayString(dates.value), 1)
        ])
      ])
    ])
  ]))
}
}

})