import { coreApi } from '@/shared/api';
import { convertToDateTime } from '@/shared/helpers/converters';

import { VendorSuggestData, VendorSuggestResponse } from './model/types';

export const URL = '/unum/vendor-go';

export const suggestVendor = async (
	organizationId: number,
	name: string,
	ids: number[],
	limit: number
): Promise<VendorSuggestData[]> => {
	// * this is "GET with BODY" request
	const response = await coreApi().post<VendorSuggestResponse>(
		URL + '/vendor/suggest',
		{
			organizationId: organizationId,
			name: name,
			ids: ids,
			limit: limit
		}
	);
	return convertToDateTime(response.data.vendor);
};
