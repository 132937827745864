import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "table-head" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = {
  key: 0,
  class: "table-head-cell cell dollar"
}

import { TableColumn } from '../../api/types';


export default /*@__PURE__*/_defineComponent({
  __name: 'TableHead',
  props: {
    columns: {},
    fortyPercent: { type: Boolean },
    fake: {}
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("tr", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fake, (n) => {
      return (_openBlock(), _createElementBlock("td", {
        key: n,
        class: "fake-cell"
      }))
    }), 128)),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.columns, (col) => {
      return (_openBlock(), _createElementBlock("td", {
        key: col.prop,
        class: _normalizeClass(["table-head-cell cell", { dollar: col.dollar }]),
        innerHTML: col.label
      }, null, 10, _hoisted_2))
    }), 128)),
    (_ctx.fortyPercent)
      ? (_openBlock(), _createElementBlock("td", _hoisted_3, "40%"))
      : _createCommentVNode("", true)
  ]))
}
}

})