import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

import { FilterMatchMode } from '@primevue/core';
import InputText from 'primevue/inputtext';
import Select from 'primevue/select';
import { computed, ref } from 'vue';

type DateFilter = {
	val?: { val_1?: string | null; val_2?: string | null } | null;
	type: string;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'JobPriceFilter',
  props: {
    modelValue: {},
    name: {}
  },
  emits: ["update:modelValue"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emit = __emit;

const formattedPrice1 = computed(() =>
	props.modelValue.val?.val_1 ? +props.modelValue.val.val_1 : null
);
const formattedPrice2 = computed(() =>
	props.modelValue.val?.val_2 ? +props.modelValue.val.val_2 : null
);

const options = ref([
	{ name: 'Single Value', id: FilterMatchMode.EQUALS },
	{ name: 'Value Range', id: FilterMatchMode.BETWEEN }
]);

const updateMatchMode = (v: string) => {
	emit('update:modelValue', { type: v, val: null });
};

const updatePrice1 = (v: number) => {
	emit('update:modelValue', {
		type: props.modelValue.type,
		val:
			v || props.modelValue.val?.val_2
				? {
						val_1: v ? v.toString() : null,
						val_2: props.modelValue.val?.val_2
					}
				: null
	});
};

const updatePrice2 = (v: number) => {
	emit('update:modelValue', {
		type: props.modelValue.type,
		val:
			v || props.modelValue.val?.val_1
				? {
						val_1: props.modelValue.val?.val_1,
						val_2: v ? v.toString() : null
					}
				: null
	});
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_unref(Select), {
      appendTo: "self",
      class: "p-column-filter",
      modelValue: _ctx.modelValue.type,
      optionLabel: "name",
      options: options.value,
      optionValue: "id",
      "onUpdate:modelValue": updateMatchMode
    }, null, 8, ["modelValue", "options"]),
    _createVNode(_unref(InputText), {
      modelValue: formattedPrice1.value,
      placeholder: 
			_ctx.modelValue.type === _unref(FilterMatchMode).BETWEEN
				? `${_ctx.name} from`
				: `Search by ${_ctx.name}`
		,
      type: "number",
      "onUpdate:modelValue": updatePrice1
    }, null, 8, ["modelValue", "placeholder"]),
    (_ctx.modelValue.type === _unref(FilterMatchMode).BETWEEN)
      ? (_openBlock(), _createBlock(_unref(InputText), {
          key: 0,
          modelValue: formattedPrice2.value,
          placeholder: `${_ctx.name} to`,
          type: "number",
          "onUpdate:modelValue": updatePrice2
        }, null, 8, ["modelValue", "placeholder"]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})