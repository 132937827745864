import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, isRef as _isRef, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { storeToRefs } from 'pinia';
import { computed } from 'vue';

import useOrganizationsStore from '@/entities/organization/lib/store';
import AutoCompleteSelect from '@/shared/ui/input/AutoCompleteSelect.vue';

import useAccountingStore from './lib/store';


export default /*@__PURE__*/_defineComponent({
  __name: 'ProfileSelect',
  setup(__props) {

// Accounting profile selector that uses fearures/accounting/lib/store store
const { profiles, selectedProfileId } = storeToRefs(useAccountingStore());
const { organizations } = storeToRefs(useOrganizationsStore());

const options = computed(() =>
	profiles.value.map(profile => ({
		id: profile.organizationId,
		name: organizations.value.find(o => o.id === profile.organizationId)?.name
	}))
);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(AutoCompleteSelect, {
    modelValue: _unref(selectedProfileId),
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(selectedProfileId) ? (selectedProfileId).value = $event : null)),
    fluid: "",
    options: options.value,
    optionValue: "id"
  }, null, 8, ["modelValue", "options"]))
}
}

})