import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

import { ReportType } from '../../api/types';

import TableDefaultBody from './TableDefaultBody.vue';
import TableVariableBody from './TableVariableBody.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'TableGrid',
  props: {
    data: {},
    splitBy: {},
    reportType: {},
    columns: {}
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_ctx.data)
    ? (_openBlock(), _createElementBlock("table", {
        key: 0,
        class: _normalizeClass(['table-grid', `border-left-${_ctx.splitBy}`])
      }, [
        (_ctx.splitBy === 'none')
          ? (_openBlock(), _createBlock(TableDefaultBody, {
              key: 0,
              columns: _ctx.columns,
              data: _ctx.data,
              showTotal: ""
            }, null, 8, ["columns", "data"]))
          : (_openBlock(), _createBlock(TableVariableBody, {
              key: 1,
              columns: _ctx.columns,
              data: _ctx.data,
              reportType: _ctx.reportType,
              splitBy: _ctx.splitBy
            }, null, 8, ["columns", "data", "reportType", "splitBy"]))
      ], 2))
    : _createCommentVNode("", true)
}
}

})