import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { style: {"background-color":"#f3f4f6","padding-top":"1.5rem","padding-bottom":"1.5rem","padding-left":"1rem","padding-right":"1rem","margin-bottom":"2rem","width":"100%"} }
const _hoisted_2 = {
  key: 0,
  style: {"text-align":"center","font-size":"1.125rem","margin-bottom":"1.25rem"}
}
const _hoisted_3 = { style: {"display":"-webkit-box","-webkit-box-pack":"center","justify-content":"center","width":"100%"} }
const _hoisted_4 = { style: {"font-size":"1.25rem","color":"#15803d"} }
const _hoisted_5 = { style: {"font-size":"1.25rem","color":"#a16207"} }
const _hoisted_6 = { style: {"font-size":"1.25rem","color":"#c2410c"} }
const _hoisted_7 = { style: {"font-size":"1.25rem","color":"#b91c1c"} }
const _hoisted_8 = { style: {"font-size":"1.25rem","color":"#1e40af"} }


export default /*@__PURE__*/_defineComponent({
  __name: 'OutstandingBalances',
  props: {
    groupBy: {},
    totalBalance: {},
    ninetyPlusBalance: {},
    sixtyNinetyBalance: {},
    thirtySixtyBalance: {},
    zeroThirtyBalance: {},
    grandTotals: { type: Boolean }
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.grandTotals)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, " Aging Summary "))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", {
        style: _normalizeStyle([{"border-radius":"0.375rem","border":"1px solid #bbf7d0","-webkit-box-flex":"0.7","margin-right":"1rem","-webkit-flex":"0.7","flex":"0.7","display":"flex","flex-direction":"column","align-items":"center","justify-content":"center","background-color":"white","padding":"0.5rem","gap":"0.25rem","box-shadow":"0px 1px 3px rgba(0, 0, 0, 0.1)"}, { height: _ctx.groupBy !== 'consumer' ? '6rem' : 'auto' }])
      }, [
        _cache[0] || (_cache[0] = _createElementVNode("div", { style: {"font-size":"0.75rem","color":"#22c55e"} }, "Under 31 days", -1)),
        _createElementVNode("div", _hoisted_4, " $" + _toDisplayString(_ctx.zeroThirtyBalance), 1)
      ], 4),
      _createElementVNode("div", {
        style: _normalizeStyle([{"border-radius":"0.375rem","border":"1px solid #facc15","-webkit-box-flex":"0.7","margin-right":"1rem","-webkit-flex":"0.7","flex":"0.7","display":"flex","flex-direction":"column","align-items":"center","justify-content":"center","background-color":"white","padding":"0.5rem","gap":"0.25rem","box-shadow":"0px 1px 3px rgba(0, 0, 0, 0.1)"}, { height: _ctx.groupBy !== 'consumer' ? '6rem' : 'auto' }])
      }, [
        _cache[1] || (_cache[1] = _createElementVNode("div", { style: {"font-size":"0.75rem","color":"#ca8a04"} }, "31 - 60 days", -1)),
        _createElementVNode("div", _hoisted_5, " $" + _toDisplayString(_ctx.thirtySixtyBalance), 1)
      ], 4),
      _createElementVNode("div", {
        style: _normalizeStyle([{"border-radius":"0.375rem","border":"1px solid #fb923c","-webkit-box-flex":"0.7","margin-right":"1rem","-webkit-flex":"0.7","flex":"0.7","display":"flex","flex-direction":"column","align-items":"center","justify-content":"center","background-color":"white","padding":"0.5rem","gap":"0.25rem","box-shadow":"0px 1px 3px rgba(0, 0, 0, 0.1)"}, { height: _ctx.groupBy !== 'consumer' ? '6rem' : 'auto' }])
      }, [
        _cache[2] || (_cache[2] = _createElementVNode("div", { style: {"font-size":"0.75rem","color":"#ea580c"} }, "61 - 90 days", -1)),
        _createElementVNode("div", _hoisted_6, " $" + _toDisplayString(_ctx.sixtyNinetyBalance), 1)
      ], 4),
      _createElementVNode("div", {
        style: _normalizeStyle([{"border-radius":"0.375rem","border":"1px solid #f87171","-webkit-box-flex":"0.7","margin-right":"1rem","-webkit-flex":"0.7","flex":"0.7","display":"flex","flex-direction":"column","align-items":"center","justify-content":"center","background-color":"white","padding":"0.5rem","gap":"0.25rem","box-shadow":"0px 1px 3px rgba(0, 0, 0, 0.1)"}, { height: _ctx.groupBy !== 'consumer' ? '6rem' : 'auto' }])
      }, [
        _cache[3] || (_cache[3] = _createElementVNode("div", { style: {"font-size":"0.75rem","color":"#dc2626"} }, "91+ days", -1)),
        _createElementVNode("div", _hoisted_7, " $" + _toDisplayString(_ctx.ninetyPlusBalance), 1)
      ], 4),
      _createElementVNode("div", {
        style: _normalizeStyle([{"border-radius":"0.375rem","border":"1px solid #60a5fa","-webkit-box-flex":"0.7","-webkit-flex":"0.7","flex":"0.7","display":"flex","flex-direction":"column","align-items":"center","justify-content":"center","background-color":"white","padding":"0.5rem","gap":"0.25rem","box-shadow":"0px 1px 3px rgba(0, 0, 0, 0.1)"}, { height: _ctx.groupBy !== 'consumer' ? '6rem' : 'auto' }])
      }, [
        _cache[4] || (_cache[4] = _createElementVNode("div", { style: {"font-size":"0.75rem","color":"#2563eb"} }, "Total outstanding", -1)),
        _createElementVNode("div", _hoisted_8, " $" + _toDisplayString(_ctx.totalBalance), 1)
      ], 4)
    ])
  ]))
}
}

})