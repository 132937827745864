<script setup lang="ts">
import Button from 'primevue/button';
import Checkbox from 'primevue/checkbox';
import Message from 'primevue/message';
import RadioButton from 'primevue/radiobutton';
import ScrollPanel from 'primevue/scrollpanel';
import { onMounted, ref, watch } from 'vue';

import { getPlans, Plan } from '../../api';
import { TNC } from '../../constants/tnc';
import { useCreateAccountStore } from '../../model/createAccount';
import {
	AccountCreationSteps,
	AccountCreationStepValues
} from '../../model/types';

const store = useCreateAccountStore();

const plans = ref<Plan[]>([]);

const selectedPlanCode = ref('');
const termsAccepted = ref(false);

onMounted(() => init());

watch(
	() => store.step,
	async val => {
		if (val === AccountCreationStepValues.billingPlan) {
			await init();
		} else {
			termsAccepted.value = false;
		}
	}
);

watch(
	selectedPlanCode,
	val => {
		if (val) {
			const plan = plans.value.find(
				({ plan_code }) => plan_code === selectedPlanCode.value
			);
			if (plan) {
				store.form[AccountCreationSteps.billingPlan] = { plan };
			}
		}
	},
	{ deep: true }
);

const init = async () => {
	plans.value = await getPlans();
	if (store.form[AccountCreationSteps.billingPlan]) {
		selectedPlanCode.value =
			store.form[AccountCreationSteps.billingPlan].plan.plan_code;
	}
};
const next = () => {
	store.changeStep(AccountCreationStepValues.billingFinancing);
};
</script>

<template>
	<div class="tw3-mb-6 tw3-text-slate-500">
		Select a subscription plan based on your shop's requirements.
	</div>

	<div class="tw3-text-xl tw3-font-semibold tw3-mb-6">
		First 30 days completely FREE!
	</div>

	<div class="tw3-relative tw3-mb-6 -tw3-space-y-px">
		<div
			v-for="i in plans"
			:key="i.plan_id"
			class="tw3-relative tw3-flex tw3-items-center tw3-p-6 tw3-cursor-pointer tw3-border tw3-border-gray-200 first:tw3-rounded-t-lg last:tw3-rounded-b-lg"
			:class="{
				'tw3-border-green-200 tw3-z-10 tw3-bg-green-50':
					selectedPlanCode === i.plan_code
			}"
			@click="selectedPlanCode = i.plan_code"
		>
			<div class="tw3-flex-grow tw3-flex tw3-items-center tw3-gap-4">
				<RadioButton :modelValue="selectedPlanCode" :value="i.plan_code" />
				<div class="tw3-font-medium">{{ i.description }}</div>
			</div>
			<div class="tw3-font-medium">${{ i.recurring_price }}/mo *</div>
		</div>
	</div>

	<div class="tw3-text-orange-400 tw3-mb-6">
		* Plus $1 per Lynx Services EDI sent
	</div>

	<div class="tw3-mb-6">
		<Message icon="pi pi-info-circle" severity="info">
			NAGS© and GlassMate© are registered trademarks owned by Mitchell
			International, Inc. The NAGS Data and All Related OEM and Aftermarket
			Information are provided under License from Mitchell International, Inc.
		</Message>
	</div>

	<div
		class="tw3-mb-6 tw3-border tw3-border-gray-200 tw3-rounded-lg tw3-divide-y tw3-divide-gray-200"
	>
		<div class="tw3-p-4 tw3-font-medium">
			TERMS OF USE OF GLASSBILLER SERVICES
		</div>
		<div class="tw3-p-4">
			<ScrollPanel class="tw3-h-48 tw3-w-full">
				<div v-html="TNC"></div>
			</ScrollPanel>
		</div>
		<div class="tw3-flex tw3-items-center tw3-gap-3 tw3-p-4">
			<Checkbox v-model="termsAccepted" binary inputId="terms" size="small" />
			<label for="terms">Accept Terms of Use</label>
		</div>
	</div>

	<div class="tw3-pt-6 tw3-space-x-4">
		<Button
			label="Back"
			severity="secondary"
			size="small"
			@click="store.changeStep(AccountCreationStepValues.shopIntegrations)"
		/>
		<Button
			:disabled="!selectedPlanCode || !termsAccepted"
			label="Next"
			size="small"
			@click="next"
		/>
	</div>
</template>
