import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "layout-breadcrumb tw3-text-gray-500 tw3-overflow-auto" }
const _hoisted_2 = { class: "tw3-truncate" }
const _hoisted_3 = {
  key: 0,
  class: "layout-breadcrumb-chevron"
}

import { useRouter } from 'vue-router';


export default /*@__PURE__*/_defineComponent({
  __name: 'GbDefaultBreadcrumb',
  props: {
    tabs: {}
  },
  setup(__props: any) {



const router = useRouter();

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("nav", _hoisted_1, [
    _createElementVNode("ol", null, [
      _createElementVNode("i", {
        class: "pi pi-home tw3-cursor-pointer hover:tw3-opacity-60 tw3-transition-all",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(router).push('/')))
      }),
      _cache[1] || (_cache[1] = _createElementVNode("li", { class: "layout-breadcrumb-chevron" }, "/", -1)),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (breadcrumbRoute, i) => {
        return (_openBlock(), _createElementBlock(_Fragment, { key: breadcrumbRoute }, [
          _createElementVNode("li", _hoisted_2, _toDisplayString(breadcrumbRoute.label), 1),
          (i !== _ctx.tabs.length - 1)
            ? (_openBlock(), _createElementBlock("li", _hoisted_3, " / "))
            : _createCommentVNode("", true)
        ], 64))
      }), 128))
    ])
  ]))
}
}

})