import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { id: "accounting-reports" }
const _hoisted_2 = { class: "section-header" }
const _hoisted_3 = { class: "co-accounts-table" }
const _hoisted_4 = { class: "column-one" }
const _hoisted_5 = {
  align: "right",
  class: "column-three"
}
const _hoisted_6 = { class: "column-two" }
const _hoisted_7 = {
  align: "right",
  class: "column-three"
}
const _hoisted_8 = { class: "border-top-div black-text" }
const _hoisted_9 = { class: "section-header" }
const _hoisted_10 = { class: "co-accounts-table" }
const _hoisted_11 = { class: "column-one" }
const _hoisted_12 = {
  align: "right",
  class: "column-three"
}
const _hoisted_13 = { class: "column-two" }
const _hoisted_14 = {
  align: "right",
  class: "column-three"
}
const _hoisted_15 = { class: "border-top-div black-text" }
const _hoisted_16 = { class: "section-header" }
const _hoisted_17 = { class: "co-accounts-table" }
const _hoisted_18 = { class: "column-one" }
const _hoisted_19 = {
  align: "right",
  class: "column-three"
}
const _hoisted_20 = { class: "column-two" }
const _hoisted_21 = {
  align: "right",
  class: "column-three"
}
const _hoisted_22 = { class: "border-top-div black-text" }

import { useI18n } from 'vue-i18n';


export default /*@__PURE__*/_defineComponent({
  __name: 'ProfitAndLossTable',
  props: {
    data: {}
  },
  setup(__props: any) {



const { t } = useI18n();

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", _hoisted_2, _toDisplayString(_unref(t)('reportsPage.table.income')), 1),
    _createElementVNode("table", _hoisted_3, [
      _createElementVNode("tbody", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data.incomeAccountDetails, (accountDetail) => {
          return (_openBlock(), _createElementBlock("tr", {
            key: accountDetail.id
          }, [
            _createElementVNode("td", _hoisted_4, _toDisplayString(accountDetail.name), 1),
            _cache[0] || (_cache[0] = _createElementVNode("td", { class: "column-two" }, null, -1)),
            _createElementVNode("td", _hoisted_5, _toDisplayString(accountDetail.displayValue), 1)
          ]))
        }), 128)),
        _createElementVNode("tr", null, [
          _cache[1] || (_cache[1] = _createElementVNode("td", { class: "column-one" }, null, -1)),
          _createElementVNode("td", _hoisted_6, _toDisplayString(_unref(t)('reportsPage.total')) + " " + _toDisplayString(_unref(t)('reportsPage.table.income')), 1),
          _createElementVNode("td", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.data.totalIncome), 1)
          ])
        ])
      ])
    ]),
    _createElementVNode("span", _hoisted_9, _toDisplayString(_unref(t)('reportsPage.table.costOfGoodsSold')), 1),
    _createElementVNode("table", _hoisted_10, [
      _createElementVNode("tbody", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data.cogsAccountDetails, (accountDetail) => {
          return (_openBlock(), _createElementBlock("tr", {
            key: accountDetail.id
          }, [
            _createElementVNode("td", _hoisted_11, _toDisplayString(accountDetail.name), 1),
            _cache[2] || (_cache[2] = _createElementVNode("td", { class: "column-two" }, null, -1)),
            _createElementVNode("td", _hoisted_12, _toDisplayString(accountDetail.displayValue), 1)
          ]))
        }), 128)),
        _createElementVNode("tr", null, [
          _cache[3] || (_cache[3] = _createElementVNode("td", { class: "column-one" }, null, -1)),
          _createElementVNode("td", _hoisted_13, _toDisplayString(_unref(t)('reportsPage.total')) + " COGS", 1),
          _createElementVNode("td", _hoisted_14, [
            _createElementVNode("div", _hoisted_15, _toDisplayString(_ctx.data.totalCogs), 1)
          ])
        ]),
        _cache[5] || (_cache[5] = _createElementVNode("tr", null, [
          _createElementVNode("td", { class: "column-one" }, [
            _createElementVNode("br")
          ]),
          _createElementVNode("td", { class: "column-two" }),
          _createElementVNode("td", {
            align: "right",
            class: "column-three"
          })
        ], -1)),
        _createElementVNode("tr", null, [
          _cache[4] || (_cache[4] = _createElementVNode("td", { class: "column-one" }, null, -1)),
          _createElementVNode("td", {
            class: _normalizeClass({
							'column-two': true,
							'green-text': _ctx.data.profitMarginPositive,
							'red-text': !_ctx.data.profitMarginPositive
						})
          }, _toDisplayString(_unref(t)('reportsPage.table.profitMargin')) + " (" + _toDisplayString(_ctx.data.profitMarginPercent) + ") ", 3),
          _createElementVNode("td", {
            align: "right",
            class: _normalizeClass({
							'column-three': true,
							'green-text': _ctx.data.profitMarginPositive,
							'red-text': !_ctx.data.profitMarginPositive
						})
          }, _toDisplayString(_ctx.data.profitMarginValue), 3)
        ])
      ])
    ]),
    _createElementVNode("span", _hoisted_16, _toDisplayString(_unref(t)('reportsPage.table.expenses')), 1),
    _createElementVNode("table", _hoisted_17, [
      _createElementVNode("tbody", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data.expenseAccountDetails, (accountDetail) => {
          return (_openBlock(), _createElementBlock("tr", {
            key: accountDetail.id
          }, [
            _createElementVNode("td", _hoisted_18, _toDisplayString(accountDetail.name), 1),
            _cache[6] || (_cache[6] = _createElementVNode("td", { class: "column-two" }, null, -1)),
            _createElementVNode("td", _hoisted_19, _toDisplayString(accountDetail.displayValue), 1)
          ]))
        }), 128)),
        _createElementVNode("tr", null, [
          _cache[7] || (_cache[7] = _createElementVNode("td", { class: "column-one" }, null, -1)),
          _createElementVNode("td", _hoisted_20, _toDisplayString(_unref(t)('reportsPage.total')) + " " + _toDisplayString(_unref(t)('reportsPage.table.expenses')), 1),
          _createElementVNode("td", _hoisted_21, [
            _createElementVNode("div", _hoisted_22, _toDisplayString(_ctx.data.totalExpenses), 1)
          ])
        ]),
        _cache[9] || (_cache[9] = _createElementVNode("tr", null, [
          _createElementVNode("td", { class: "column-one" }, [
            _createElementVNode("br")
          ]),
          _createElementVNode("td", { class: "column-two" }),
          _createElementVNode("td", { class: "column-three" })
        ], -1)),
        _createElementVNode("tr", null, [
          _cache[8] || (_cache[8] = _createElementVNode("td", { class: "column-one" }, null, -1)),
          _createElementVNode("td", {
            class: _normalizeClass({
							'column-two': true,
							'green-text': _ctx.data.netProfitPositive,
							'red-text': !_ctx.data.netProfitPositive
						})
          }, _toDisplayString(_unref(t)('reportsPage.table.netProfit')), 3),
          _createElementVNode("td", {
            align: "right",
            class: _normalizeClass({
							'column-three': true,
							'green-text': _ctx.data.netProfitPositive,
							'red-text': !_ctx.data.netProfitPositive
						})
          }, _toDisplayString(_ctx.data.netProfit), 3)
        ])
      ])
    ])
  ]))
}
}

})