import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import Button from 'primevue/button';


export default /*@__PURE__*/_defineComponent({
  __name: 'JobEditCell',
  props: {
    loading: { type: Boolean }
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(Button), {
    "aria-label": "Edit",
    icon: "pi pi-pencil",
    iconClass: "!tw3-text-[0.8rem]",
    loading: _ctx.loading,
    rounded: "",
    severity: "secondary",
    small: "",
    style: {"height":"1.5rem","width":"1.5rem"},
    text: "",
    type: "button"
  }, null, 8, ["loading"]))
}
}

})