import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent } from "vue"

const _hoisted_1 = { class: "tw3-flex tw3-flex-col tw3-max-w-[1440px] tw3-w-full tw3-mx-auto tw3-h-full" }
const _hoisted_2 = { class: "tw3-flex tw3-flex-col tw3-gap-4 md:tw3-flex-row md:tw3-items-start md:tw3-justify-between tw3-pb-[1rem]" }
const _hoisted_3 = { class: "layout-breadcrumb tw3-text-gray-500 tw3-mr-4 tw3-" }
const _hoisted_4 = {
  key: 0,
  class: "layout-breadcrumb-chevron"
}
const _hoisted_5 = { class: "tw3-flex tw3-flex-col tw3-flex-1" }
const _hoisted_6 = { class: "tw3-text-xl tw3-font-extrabold tw3-bg-white tw3-px-4 tw3-py-6" }
const _hoisted_7 = {
  key: 0,
  class: "tw3-w-full tw3-h-full tw3-flex tw3-justify-center tw3-items-center tw3-border-t"
}
const _hoisted_8 = { class: "tw3-text-xl tw3-text-center tw3-py-7" }
const _hoisted_9 = ["id"]
const _hoisted_10 = { class: "tw3-w-full tw3-flex tw3-items-center tw3-gap-4 tw3-p-4 tw3-border-t tw3-border-b" }
const _hoisted_11 = { class: "icon-component" }
const _hoisted_12 = { class: "tw3-w-full tw3-flex tw3-flex-col tw3-gap-2" }
const _hoisted_13 = { class: "tw3-flex tw3-items-center tw3-gap-2" }
const _hoisted_14 = { class: "tw3-text-xl tw3-font-bold" }
const _hoisted_15 = { class: "tw3-text-gray-500 tw3-text-sm" }
const _hoisted_16 = { class: "tw3-flex tw3-flex-col" }
const _hoisted_17 = { class: "tw3-flex tw3-items-center tw3-gap-2" }
const _hoisted_18 = ["innerHTML"]
const _hoisted_19 = ["innerHTML"]

import Card from 'primevue/card';
import IconField from 'primevue/iconfield';
import InputIcon from 'primevue/inputicon';
import InputText from 'primevue/inputtext';
import Tag from 'primevue/tag';
import { computed, onBeforeMount, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';

import { useUserStore } from '@/entities/user/store';
import { useMessages } from '@/shared/composables';
import { capitalizeStr } from '@/shared/helpers';

import { reportsMenuConfig } from './lib/constants';


export default /*@__PURE__*/_defineComponent({
  __name: 'ChooseReportsPage',
  setup(__props) {

const router = useRouter();
const messages = useMessages();
const i18n = useI18n();
const breadcrumbs = [
	{ label: i18n.t('reports'), path: '' },
	{ label: `${i18n.t('all')} ${i18n.t('reports')}`, path: '' }
];

const userStore = useUserStore();
const searchQuery = ref('');

const config = computed(() => {
	const configArray = [];
	for (let i = 0; i < reportsMenuConfig.length; i++) {
		if (reportsMenuConfig[i].id === 'accounting-reports') {
			if (
				userStore.accountPackagesState.state &&
				userStore.accountPackagesState.state.length > 0
			) {
				configArray.push(reportsMenuConfig[i]);
			}
		} else {
			const filterRportsByPermission = reportsMenuConfig[i].items.filter(
				item => !item.permissionId || userStore.checkUserCan(item.permissionId)
			);
			reportsMenuConfig[i].items = filterRportsByPermission;
			if (reportsMenuConfig[i].items.length) {
				configArray.push(reportsMenuConfig[i]);
			}
		}
	}

	return configArray;
});

const filteredConfig = computed(() => {
	if (!searchQuery.value.trim()) return config.value;
	return config.value
		.map(group => {
			const filteredItems = group.items.filter(item => {
				const byTitle = item.label
					.toLowerCase()
					.includes(searchQuery.value.toLowerCase());
				const byDescription = item.desc
					.toLowerCase()
					.includes(searchQuery.value.toLowerCase());
				return byTitle || byDescription;
			});
			return { ...group, items: filteredItems };
		})
		.filter(group => group.items.length > 0);
});

const highlightSearchingText = (text: string, searchQuery: string): string => {
	if (!searchQuery.trim()) return text;
	const regex = new RegExp(`(${searchQuery.trim()})`, 'gi');
	return text.replace(regex, '<span class="highlight">$1</span>');
};

const showToast = (text: string) => {
	messages.showInfo(text);
};

onBeforeMount(() => {
	if (!userStore.checkUserCan('reports')) {
		router.push('/');
	}
});

return (_ctx: any,_cache: any) => {
  const _component_RouterLink = _resolveComponent("RouterLink")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("nav", _hoisted_3, [
        _createElementVNode("ol", null, [
          _createElementVNode("li", null, [
            _createVNode(_component_RouterLink, {
              class: "tw3-cursor-pointer tw3-transition-all",
              to: "/"
            }, {
              default: _withCtx(() => _cache[1] || (_cache[1] = [
                _createElementVNode("i", { class: "pi pi-home" }, null, -1)
              ])),
              _: 1
            })
          ]),
          _cache[2] || (_cache[2] = _createElementVNode("li", { class: "layout-breadcrumb-chevron" }, "/", -1)),
          (_openBlock(), _createElementBlock(_Fragment, null, _renderList(breadcrumbs, (breadcrumbRoute, i) => {
            return (_openBlock(), _createElementBlock(_Fragment, { key: breadcrumbRoute }, [
              _createElementVNode("li", null, [
                (breadcrumbRoute.path)
                  ? (_openBlock(), _createBlock(_component_RouterLink, {
                      key: 0,
                      class: "tw3-cursor-pointer tw3-transition-all",
                      to: breadcrumbRoute.path
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_unref(capitalizeStr)(breadcrumbRoute.label)), 1)
                      ]),
                      _: 2
                    }, 1032, ["to"]))
                  : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                      _createTextVNode(_toDisplayString(_unref(capitalizeStr)(breadcrumbRoute.label)), 1)
                    ], 64))
              ]),
              (i !== breadcrumbs.length - 1)
                ? (_openBlock(), _createElementBlock("li", _hoisted_4, " / "))
                : _createCommentVNode("", true)
            ], 64))
          }), 64))
        ])
      ]),
      _createVNode(_unref(IconField), { class: "tw3-w-full sm:tw3-w-4/12 md:tw3-mt-[-8px]" }, {
        default: _withCtx(() => [
          _createVNode(_unref(InputIcon), { class: "pi pi-search" }),
          _createVNode(_unref(InputText), {
            modelValue: searchQuery.value,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((searchQuery).value = $event)),
            class: "tw3-w-full",
            placeholder: "Search reports...",
            type: "text"
          }, null, 8, ["modelValue"])
        ]),
        _: 1
      })
    ]),
    _createVNode(_unref(Card), { class: "tw3-h-full tw3-overflow-auto" }, {
      content: _withCtx(() => [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("h2", _hoisted_6, _toDisplayString(_ctx.$t('allReports')), 1),
          (!filteredConfig.value.length)
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.$t('noData')), 1)
              ]))
            : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(filteredConfig.value, (group) => {
                return (_openBlock(), _createElementBlock("div", {
                  id: group.id,
                  key: group.id
                }, [
                  _createElementVNode("div", _hoisted_10, [
                    _createElementVNode("div", _hoisted_11, [
                      (_openBlock(), _createBlock(_resolveDynamicComponent(group.img)))
                    ]),
                    _createElementVNode("div", _hoisted_12, [
                      _createElementVNode("div", _hoisted_13, [
                        _createElementVNode("h2", _hoisted_14, _toDisplayString(group.label), 1),
                        _createVNode(_unref(Tag), null, {
                          default: _withCtx(() => [
                            _createTextVNode("Reports: " + _toDisplayString(group.items.length), 1)
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _createElementVNode("div", _hoisted_15, _toDisplayString(group.desc), 1)
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_16, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(group.items, (item) => {
                      return (_openBlock(), _createBlock(_resolveDynamicComponent(item.commingSoon ? 'div' : 'router-link'), {
                        key: item.id,
                        class: "tw3-flex tw3-flex-col tw3-gap-2 tw3-px-8 tw3-py-4 tw3-border-b tw3-border-slate-200 last:tw3-border-b-0 tw3-bg-white hover:tw3-bg-[#E2E8F0] !tw3-no-underline tw3-cursor-pointer tw3-transition-all",
                        to: item.commingSoon ? null : `/reports/${item.id}`,
                        onClick: ($event: any) => (
										item.commingSoon ? showToast(item.command as string) : null
									)
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_17, [
                            _createElementVNode("div", {
                              class: "tw3-text-blue-500 tw3-font-semibold tw3-text-lg",
                              innerHTML: highlightSearchingText(item.label, searchQuery.value)
                            }, null, 8, _hoisted_18),
                            (item.commingSoon)
                              ? (_openBlock(), _createBlock(_unref(Tag), {
                                  key: 0,
                                  severity: "warn"
                                }, {
                                  default: _withCtx(() => _cache[3] || (_cache[3] = [
                                    _createTextVNode("Comming soon")
                                  ])),
                                  _: 1
                                }))
                              : (_openBlock(), _createBlock(_unref(Tag), {
                                  key: 1,
                                  severity: "info"
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode("Filters: " + _toDisplayString(item.filters), 1)
                                  ]),
                                  _: 2
                                }, 1024))
                          ]),
                          _createElementVNode("div", {
                            class: "tw3-text-gray-500 tw3-text-sm",
                            innerHTML: highlightSearchingText(item.desc, searchQuery.value)
                          }, null, 8, _hoisted_19)
                        ]),
                        _: 2
                      }, 1032, ["to", "onClick"]))
                    }), 128))
                  ])
                ], 8, _hoisted_9))
              }), 128))
        ])
      ]),
      _: 1
    })
  ]))
}
}

})