import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "tw3-rounded tw3-whitespace-nowrap tw3-text-white tw3-text-sm tw3-leading-3 tw3-shadow-md tw3-p-2" }


export default /*@__PURE__*/_defineComponent({
  __name: 'StatusBadge',
  props: {
    text: {}
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.text), 1))
}
}

})