import { coreApi } from '@/shared/api';
import { convertToDateTime } from '@/shared/helpers/converters';
import { Exact } from '@/shared/types';

import {
	BalanceSheetRecord,
	BalanceSheetRequest,
	BalanceSheetResponse,
	IncomeStatementRecord,
	IncomeStatementRequest,
	IncomeStatementResponse,
	TransactionsByAccountRequest,
	TransactionsByAccountResponse
} from './types';

export const URL = '/unum/accounting';

export const getBalanceSheet = async <T>(
	params: Exact<T, BalanceSheetRequest>
): Promise<BalanceSheetRecord[]> => {
	// * this is "GET with BODY" request
	const response = await coreApi().post<BalanceSheetResponse>(
		URL + '/report/balance-sheet',
		params
	);
	return convertToDateTime(response.data.record);
};

export const getIncomeStatement = async <T>(
	params: Exact<T, IncomeStatementRequest>
): Promise<IncomeStatementRecord[]> => {
	// * this is "GET with BODY" request
	const response = await coreApi().post<IncomeStatementResponse>(
		URL + '/report/income-statement',
		params
	);
	return convertToDateTime(response.data.record);
};

export const getTransactionsByAccount = async <T>(
	params: Exact<T, TransactionsByAccountRequest>
): Promise<TransactionsByAccountResponse> => {
	// * this is "GET with BODY" request
	const response = await coreApi().post<TransactionsByAccountResponse>(
		URL + '/report/transactions-by-account',
		params
	);
	return convertToDateTime(response.data);
};
