import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, unref as _unref, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives, Transition as _Transition, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "tw3-w-full tw3-h-full tw3-max-w-[1440px] tw3-flex tw3-flex-col tw3-gap-[1rem] tw3-overflow-hidden tw3-mx-auto" }
const _hoisted_2 = { class: "tw3-h-full tw3-flex tw3-overflow-hidden tw3-relative tw3-gap-2" }

import Card from 'primevue/card';
import { defineAsyncComponent, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';

import GbDefaultBreadcrumb from '@/shared/ui/GbDefaultBreadcrumb.vue';

import { useJobEditCellStore } from './model/store';
import JobsList from './ui/JobsList.vue';
import JobEditCellDialogs from './ui/modals/edit/JobEditCellDialogs.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'JobsPage',
  setup(__props) {

const JobEditTable = defineAsyncComponent(
	() => import('./ui/modals/JobEditTable.vue')
);

const { t } = useI18n();

const tabs = [{ label: t('data') }, { label: t('jobs') }];

const dStore = useJobEditCellStore();

onMounted(() => (dStore.editJobViewDialog = false));

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(GbDefaultBreadcrumb, { tabs: tabs }),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(JobEditCellDialogs),
      _createVNode(_unref(Card), {
        "pt:body:class": "tw3-overflow-auto tw3-h-full",
        "pt:content:class": "tw3-h-full",
        "pt:root:class": 
					'tw3-w-full tw3-h-full tw3-overflow-auto table-transition' +
					(_unref(dStore).editJobViewDialog ? ' table-collapse' : '')
				
      }, {
        content: _withCtx(() => [
          _createVNode(JobsList)
        ]),
        _: 1
      }, 8, ["pt:root:class"]),
      _createVNode(_Transition, { name: "slide-menu" }, {
        default: _withCtx(() => [
          _withDirectives(_createVNode(_unref(JobEditTable), {
            onOnClose: _unref(dStore).toggleJobViewDialog
          }, null, 8, ["onOnClose"]), [
            [_vShow, _unref(dStore).editJobViewDialog]
          ])
        ]),
        _: 1
      })
    ])
  ]))
}
}

})