import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, normalizeStyle as _normalizeStyle, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "tw3-m-auto" }
const _hoisted_2 = { class: "tw3-flex tw3-flex-col tw3-gap-[0.5rem] tw3-min-w-[11rem] tw3-mb-[0.25rem] tw3-mx-[0.75rem] tw3-mt-[0.75rem]" }
const _hoisted_3 = { class: "tw3-flex tw3-items-center tw3-justify-between" }

import Button from 'primevue/button';
import ColorPicker from 'primevue/colorpicker';
import Menu from 'primevue/menu';
import { computed, ref } from 'vue';

import { blackOrWhiteFromRGBA } from '@/shared/helpers';

const ID_PREFIX = 'overlay_menu_color_picker_';
const WHITE_RGB = 'rgb(255,255,255)';


export default /*@__PURE__*/_defineComponent({
  __name: 'JobColumnBgPopup',
  props: {
    id: {},
    modelValue: {},
    loading: { type: Boolean }
  },
  emits: ["update:modelValue"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emit = __emit;

const localColor = ref();
const currentSelected = computed(() =>
	props.modelValue && props.modelValue !== '' ? props.modelValue : WHITE_RGB
);

const menu = ref<any>();

const openMenu = (e: MouseEvent) => {
	localColor.value = formatRgb(currentSelected.value);
	menu.value?.toggle(e);
};

const save = () => {
	menu.value?.toggle();
	emit(
		'update:modelValue',
		(formatRgb(localColor.value) as string) ?? WHITE_RGB
	);
};

const formatRgb = (v?: { r: string; g: string; b: string } | string) => {
	if (!v) return;

	if (typeof v === 'string') {
		const backgroundColorParts = v
			.replace('rgb(', '')
			.replace('rgba(', '')
			.replace(')', '')
			.split(',');

		return {
			r: backgroundColorParts[0],
			g: backgroundColorParts[1],
			b: backgroundColorParts[2]
		};
	} else {
		return `rgb(${v.r},${v.g},${v.b})`;
	}
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_unref(Button), {
      "aria-controls": ID_PREFIX + _ctx.id,
      "aria-haspopup": "true",
      disabled: _ctx.loading,
      icon: "pi pi-palette",
      loading: _ctx.loading,
      severity: "secondary",
      style: _normalizeStyle({
				color: _unref(blackOrWhiteFromRGBA)(currentSelected.value),
				backgroundColor: currentSelected.value,
				borderColor: _unref(blackOrWhiteFromRGBA)(currentSelected.value)
			}),
      text: "",
      type: "button",
      onClick: openMenu
    }, null, 8, ["aria-controls", "disabled", "loading", "style"]),
    _createVNode(_unref(Menu), {
      id: ID_PREFIX + _ctx.id,
      ref_key: "menu",
      ref: menu,
      popup: ""
    }, {
      start: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_unref(ColorPicker), {
            modelValue: localColor.value,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((localColor).value = $event)),
            baseZIndex: 51,
            format: "rgb",
            inline: "",
            "pt:panel:style": {
							background: 'var(--p-menu-background)',
							borderColor: 'var(--p-menu-border-color)'
						}
          }, null, 8, ["modelValue"]),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_unref(Button), {
              label: "Close",
              outlined: "",
              size: "small",
              type: "button",
              onClick: _cache[1] || (_cache[1] = () => menu.value?.toggle())
            }),
            _createVNode(_unref(Button), {
              label: "Apply",
              size: "small",
              type: "button",
              onClick: save
            })
          ])
        ])
      ]),
      _: 1
    }, 8, ["id"])
  ]))
}
}

})