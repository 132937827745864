import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, isRef as _isRef, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, Fragment as _Fragment } from "vue"
import _imports_0 from '../../images/mygrant.jpg'
import _imports_1 from '../../images/pilkington.png'
import _imports_2 from '../../images/pgw.jpg'


const _hoisted_1 = { class: "tw3-flex tw3-items-center tw3-justify-between tw3-py-4" }
const _hoisted_2 = {
  key: 0,
  class: "tw3-py-4"
}
const _hoisted_3 = { class: "tw3-flex tw3-items-center tw3-justify-between tw3-py-4" }
const _hoisted_4 = { class: "tw3-flex tw3-items-center tw3-justify-between tw3-py-4" }
const _hoisted_5 = {
  key: 2,
  class: "tw3-py-4"
}
const _hoisted_6 = { class: "tw3-flex tw3-pt-8 tw3-space-x-4" }

import { toTypedSchema } from '@vee-validate/zod';
import Button from 'primevue/button';
import InputMask from 'primevue/inputmask';
import InputText from 'primevue/inputtext';
import ToggleSwitch from 'primevue/toggleswitch';
import { useForm } from 'vee-validate';
import { onMounted, ref, watch } from 'vue';
import { z } from 'zod';

import { useCreateAccountStore } from '../../model/createAccount';
import {
	AccountCreationSteps,
	AccountCreationStepValues,
	ShopIntegrationsForm
} from '../../model/types';
import StepBody from '../steps/StepBody.vue';
import StepInputGroup from '../steps/StepInputGroup.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'ShopIntegrationsStep',
  setup(__props) {

const createAccountStore = useCreateAccountStore();

const mygrantChecked = ref(false);
const pilkingtonChecked = ref(false);
const pgwChecked = ref(false);

const validationSchema = toTypedSchema(
	z
		.object({
			mygrant_account_id: z.string().optional(),
			pilkington_username: z.string().optional(),
			pilkington_password: z.string().optional(),
			pgw_customer_number: z.string().optional()
		})
		.superRefine((values, ctx) => {
			if (mygrantChecked.value && !values.mygrant_account_id) {
				ctx.addIssue({
					code: z.ZodIssueCode.custom,
					message: 'Account ID is required',
					path: ['mygrant_account_id']
				});
			}
			if (pilkingtonChecked.value) {
				if (!values.pilkington_username) {
					ctx.addIssue({
						code: z.ZodIssueCode.custom,
						message: 'Username is required',
						path: ['pilkington_username']
					});
				}
				if (!values.pilkington_password) {
					ctx.addIssue({
						code: z.ZodIssueCode.custom,
						message: 'Password is required',
						path: ['pilkington_password']
					});
				}
			}
			if (pgwChecked.value && !values.pgw_customer_number) {
				ctx.addIssue({
					code: z.ZodIssueCode.custom,
					message: 'Customer number is required',
					path: ['pgw_customer_number']
				});
			}
		})
);

const { handleSubmit, errors, defineField, values } =
	useForm<ShopIntegrationsForm>({
		validationSchema,
		initialValues:
			createAccountStore.form[AccountCreationSteps.shopIntegrations]
	});

onMounted(() => {
	if (!createAccountStore.form[AccountCreationSteps.shopIntegrations]) {
		createAccountStore.form[AccountCreationSteps.shopIntegrations] =
			{} as ShopIntegrationsForm;
	} else {
		const form = createAccountStore.form[AccountCreationSteps.shopIntegrations];
		mygrantChecked.value = !!form.mygrant_account_id;
		pilkingtonChecked.value =
			!!form.pilkington_username && !!form.pilkington_password;
		pgwChecked.value = !!form.pgw_customer_number;
	}
});

watch(
	() => values,
	v => {
		createAccountStore.form[AccountCreationSteps.shopIntegrations] = v;
	},
	{ deep: true }
);

const [mygrant_account_id] = defineField('mygrant_account_id');
const [pilkington_username] = defineField('pilkington_username');
const [pilkington_password] = defineField('pilkington_password');
const [pgw_customer_number] = defineField('pgw_customer_number');

const next = handleSubmit(async () => {
	if (!mygrantChecked.value) {
		createAccountStore.form[
			AccountCreationSteps.shopIntegrations
		].mygrant_account_id = '';
		mygrant_account_id.value = '';
	}
	if (!pilkingtonChecked.value) {
		createAccountStore.form[
			AccountCreationSteps.shopIntegrations
		].pilkington_username = '';
		createAccountStore.form[
			AccountCreationSteps.shopIntegrations
		].pilkington_password = '';
		pilkington_username.value = '';
		pilkington_password.value = '';
	}
	if (!pgwChecked.value) {
		createAccountStore.form[
			AccountCreationSteps.shopIntegrations
		].pgw_customer_number = '';
		pgw_customer_number.value = '';
	}
	createAccountStore.changeStep(AccountCreationStepValues.billingPlan);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[11] || (_cache[11] = _createElementVNode("div", { class: "tw3-mb-4 tw3-mr-24 tw3-text-slate-700 tw3-text-lg" }, " Does your shop work with any of these warehouses? ", -1)),
    _createElementVNode("div", _hoisted_1, [
      _cache[8] || (_cache[8] = _createElementVNode("div", { class: "tw3-flex tw3-items-center" }, [
        _createElementVNode("img", {
          alt: "Mygrant Logo",
          class: "tw3-w-12 tw3-h-12 tw3-rounded-full tw3-mr-4",
          src: _imports_0
        }),
        _createElementVNode("div", { class: "tw3-font-semibold" }, "Mygrant Glass")
      ], -1)),
      _createVNode(_unref(ToggleSwitch), {
        modelValue: mygrantChecked.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((mygrantChecked).value = $event))
      }, null, 8, ["modelValue"])
    ]),
    (mygrantChecked.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(StepInputGroup, {
            error: _unref(errors).mygrant_account_id,
            label: "Account ID"
          }, {
            default: _withCtx(() => [
              _createVNode(_unref(InputMask), {
                modelValue: _unref(mygrant_account_id),
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_isRef(mygrant_account_id) ? (mygrant_account_id).value = $event : null)),
                fluid: "",
                invalid: !!_unref(errors).mygrant_account_id,
                mask: "*******-***",
                placeholder: "C123456-001"
              }, null, 8, ["modelValue", "invalid"])
            ]),
            _: 1
          }, 8, ["error"])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      _cache[9] || (_cache[9] = _createElementVNode("div", { class: "tw3-flex tw3-items-center" }, [
        _createElementVNode("img", {
          alt: "Pilkington Logo",
          class: "tw3-w-12 tw3-h-12 tw3-rounded-full tw3-mr-4",
          src: _imports_1
        }),
        _createElementVNode("div", { class: "tw3-font-semibold" }, "Pilkington")
      ], -1)),
      _createVNode(_unref(ToggleSwitch), {
        modelValue: pilkingtonChecked.value,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((pilkingtonChecked).value = $event))
      }, null, 8, ["modelValue"])
    ]),
    (pilkingtonChecked.value)
      ? (_openBlock(), _createBlock(StepBody, {
          key: 1,
          class: "tw3-py-4"
        }, {
          default: _withCtx(() => [
            _createVNode(StepInputGroup, {
              error: _unref(errors).pilkington_username,
              label: "Username"
            }, {
              default: _withCtx(() => [
                _createVNode(_unref(InputText), {
                  modelValue: _unref(pilkington_username),
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_isRef(pilkington_username) ? (pilkington_username).value = $event : null)),
                  fluid: "",
                  invalid: !!_unref(errors).pilkington_username,
                  placeholder: "Username"
                }, null, 8, ["modelValue", "invalid"])
              ]),
              _: 1
            }, 8, ["error"]),
            _createVNode(StepInputGroup, {
              error: _unref(errors).pilkington_password,
              label: "Password"
            }, {
              default: _withCtx(() => [
                _createVNode(_unref(InputText), {
                  modelValue: _unref(pilkington_password),
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (_isRef(pilkington_password) ? (pilkington_password).value = $event : null)),
                  fluid: "",
                  invalid: !!_unref(errors).pilkington_password,
                  placeholder: "Password"
                }, null, 8, ["modelValue", "invalid"])
              ]),
              _: 1
            }, 8, ["error"])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_4, [
      _cache[10] || (_cache[10] = _createElementVNode("div", { class: "tw3-flex tw3-items-center" }, [
        _createElementVNode("img", {
          alt: "PGW Logo",
          class: "tw3-w-12 tw3-h-12 tw3-rounded-full tw3-mr-4",
          src: _imports_2
        }),
        _createElementVNode("div", { class: "tw3-font-semibold" }, "Pittsburg Glass Works")
      ], -1)),
      _createVNode(_unref(ToggleSwitch), {
        modelValue: pgwChecked.value,
        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((pgwChecked).value = $event))
      }, null, 8, ["modelValue"])
    ]),
    (pgwChecked.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createVNode(StepInputGroup, {
            error: _unref(errors).pgw_customer_number,
            label: "Customer Number"
          }, {
            default: _withCtx(() => [
              _createVNode(_unref(InputText), {
                modelValue: _unref(pgw_customer_number),
                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => (_isRef(pgw_customer_number) ? (pgw_customer_number).value = $event : null)),
                fluid: "",
                invalid: !!_unref(errors).pgw_customer_number,
                placeholder: "12345"
              }, null, 8, ["modelValue", "invalid"])
            ]),
            _: 1
          }, 8, ["error"])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_6, [
      _createVNode(_unref(Button), {
        label: "Back",
        severity: "secondary",
        size: "small",
        onClick: _cache[7] || (_cache[7] = ($event: any) => (
				_unref(createAccountStore).changeStep(_unref(AccountCreationStepValues).shopTaxes)
			))
      }),
      _createVNode(_unref(Button), {
        label: "Next",
        size: "small",
        onClick: _unref(next)
      }, null, 8, ["onClick"])
    ])
  ], 64))
}
}

})