import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "tw3-text-center"
}
const _hoisted_2 = {
  key: 1,
  class: "tw3-flex tw3-space-x-4"
}

import Button from 'primevue/button';
import ProgressSpinner from 'primevue/progressspinner';
import { onMounted, ref, watch } from 'vue';

import { getHostedSubscriptionPage, HostedPage } from '../../api';
import { useCreateAccountStore } from '../../model/createAccount';
import {
	AccountCreationSteps,
	AccountCreationStepValues,
	BillingFinancingForm
} from '../../model/types';


export default /*@__PURE__*/_defineComponent({
  __name: 'BillingFinancingStep',
  setup(__props) {

const store = useCreateAccountStore();
const hostedpage = ref<HostedPage>();
const loading = ref(false);

onMounted(() => {
	getUrl();
});

watch(
	() => store.step,
	async val => {
		if (val === AccountCreationStepValues.billingFinancing) {
			getUrl();
		}
	}
);

const getUrl = async () => {
	loading.value = true;
	const billingPlan = store.form[AccountCreationSteps.billingPlan];
	const org = store.form[AccountCreationSteps.organization];

	const redirectUrl = `${window.location.origin}/sign-up/finish`;

	const res = await getHostedSubscriptionPage({
		redirectUrl,
		plan: billingPlan,
		organization: org
	});
	hostedpage.value = res.hostedpage;
	loading.value = false;
};

const handle = (val: boolean) => {
	const financing: BillingFinancingForm = {
		hostedpage: hostedpage.value!
	};
	if (val) {
		financing.finance = true;
	}
	store.form[AccountCreationSteps.billingFinancing] = financing;
	window.location.href = hostedpage.value!.url;
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[2] || (_cache[2] = _createElementVNode("div", { class: "tw3-text-2xl tw3-font-semibold tw3-mb-12 tw3-text-center" }, " Would you like to setup same-day funding for your insurance jobs? Our team member will contact you about financing options. ", -1)),
    (loading.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_unref(ProgressSpinner))
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_unref(Button), {
            fluid: "",
            label: "No",
            severity: "secondary",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (handle(false)))
          }),
          _createVNode(_unref(Button), {
            fluid: "",
            label: "Yes",
            severity: "success",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (handle(true)))
          })
        ]))
  ], 64))
}
}

})