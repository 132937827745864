import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { style: {"display":"flex","align-items":"center","padding":"40px 16px 0 16px","margin-bottom":"32px"} }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = ["src"]
const _hoisted_4 = { style: {"flex-grow":"1","text-align":"right"} }
const _hoisted_5 = { style: {"color":"#718096"} }

import dayjs from 'dayjs';
import { computed } from 'vue';

import { useUserStore } from '@/entities/user/store';


export default /*@__PURE__*/_defineComponent({
  __name: 'AgingHeader',
  props: {
    shopId: {}
  },
  setup(__props: any) {

const props = __props;

const userStore = useUserStore();
const date = computed(() => dayjs().format('MMMM D, YYYY'));
const shop = computed(() =>
	userStore.user.shops.find(i => i.id === props.shopId)
);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (shop.value && shop.value.logo.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("img", {
            alt: "Shop's logo",
            class: "tw3-object-contain",
            src: shop.value.logo,
            style: {"max-height":"70px","max-width":"200px","height":"auto"}
          }, null, 8, _hoisted_3)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_4, [
      _cache[0] || (_cache[0] = _createElementVNode("div", { style: {"font-size":"1.25rem","color":"#4a5568","margin-bottom":"0.25rem"} }, " Aging Report ", -1)),
      _createElementVNode("div", _hoisted_5, "as of " + _toDisplayString(date.value), 1)
    ])
  ]))
}
}

})