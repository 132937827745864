import { dateFilterMap } from '../constants';

export const reportsGetters = {
	dataIsNull: (data: any): boolean => data == null,

	emptyData: (data: any): boolean => {
		const value = data;
		if (Array.isArray(value)) {
			return value.length === 0;
		} else if (value && typeof value === 'object' && !Array.isArray(value)) {
			return Object.keys(value).length === 0;
		} else {
			return false;
		}
	},

	getDollarColumns: (data: any[]) => data.filter(item => item.dollar),

	formatNumber: (
		number: number,
		options: {
			style?: 'dollar' | 'double';
			minFractionDigits?: number;
			maxFractionDigits?: number;
		} = {}
	): string => {
		const {
			style = 'double',
			minFractionDigits = 2,
			maxFractionDigits = 2
		} = options;

		if (style === 'double') {
			return (+number).toFixed(minFractionDigits);
		}

		if (style === 'dollar') {
			return `$${number.toLocaleString('en-US', {
				minimumFractionDigits: minFractionDigits,
				maximumFractionDigits: maxFractionDigits
			})}`;
		}

		return number.toString();
	},

	getColumnsSum: (
		col: {
			prop: string;
			name: string;
		},
		data: any[]
	) => {
		const validData = data ?? [];
		const sum = validData.reduce(
			(acc, item) => acc + Number(item[col.prop || col.name] || 0),
			0
		);
		return reportsGetters.formatNumber(sum, {
			style: 'dollar'
		});
	},

	getFortyPercentSum: (data: any[]) => {
		const sum = data.reduce(
			(acc, item) => acc + (item['forty_percent'] || 0),
			0
		);
		return reportsGetters.formatNumber(sum, {
			style: 'dollar'
		});
	},

	getTotalMarginPercentageWithColor: (data: any[]) => {
		let totalRevenue = 0;
		let totalMargin = 0;

		for (let i = 0; i < data.length; i++) {
			totalRevenue += data[i]['total_subtotal'] || 0;
			totalMargin += data[i]['total_margin'] || 0;
		}

		const tempMargin = Math.round((totalMargin / totalRevenue) * 100);
		let color = '';

		if (Math.sign(tempMargin) === 1) {
			color = '#389c38';
		} else if (Math.sign(tempMargin) === -1) {
			color = 'red';
		} else {
			color = 'black';
		}

		if (Math.abs(tempMargin) === Infinity) {
			return `<span style="color: black; font-size: 12px;">N/A</span>`;
		}

		return `<span style="color: ${color}; font-size: 12px;">${tempMargin}%</span>`;
	},

	dateColumnLabel: (dateType: keyof typeof dateFilterMap): string =>
		dateFilterMap[dateType]
};

export const splitReportDataByAttr =
	(attr: string, total_key?: string) =>
	(data: any[] | null | undefined): Record<string, any[]> => {
		let prepArr = [];
		let validData = data ?? [];
		const contractorGroup = [
			'tech',
			'salesource',
			'salesrep',
			'location',
			'csr'
		];
		if (contractorGroup.includes(attr)) {
			const key = convertGroupByToVendor(attr);
			const contractorArr: any[] = [];
			for (let i = 0; i < validData.length; i++) {
				validData[i][key].map(
					(t: {
						name: string;
						total_commission: number;
						job_gb_vendor_gb_vendor_types: { commission_total: number }[];
					}) => {
						let totalJobCommission = 0;
						t.job_gb_vendor_gb_vendor_types.forEach(jgv => {
							totalJobCommission += jgv.commission_total || 0;
						});

						if (total_key) {
							contractorArr.push({
								...validData[i],
								name: t.name,
								total_commission: t.total_commission
									? t.total_commission
									: totalJobCommission,
								[total_key]: t.job_gb_vendor_gb_vendor_types[0].commission_total
							});
						} else {
							contractorArr.push({
								...validData[i],
								name: t.name
							});
						}

						// newData.push({
						// 	...data[i],
						// 	[this.type.groupBy]: t.name,
						// 	total_commission:
						// 		t.job_gb_vendor_gb_vendor_types[0].commission_total,
						// 	total_commission: totalJobCommission,
						// 	[this.type.total_key]: totalJobCommission
						// });
					}
				);
			}
			validData = [...contractorArr];
			attr = 'name';
		}

		prepArr = validData.reduce(
			(acc, item) => {
				const key = item[attr];

				if (!acc[key]) {
					acc[key] = [];
				}
				acc[key].push(item);
				return acc;
			},
			{} as Record<string, any[]>
		);
		return prepArr;
	};

export const convertGroupByToVendor = (groupBy: string) => {
	switch (groupBy) {
		case 'csr':
			return 'job_csrs';
		case 'salesource':
			return 'job_salesources';
		case 'salesrep':
			return 'job_salesreps';
		case 'tech':
			return 'job_techs';
		case 'location':
			return 'job_locations';
	}
	return groupBy;
};

export const splitByContractor = (
	data: any[] | null | undefined
): Record<string, any[]> => {
	// Define the priority order of data sources
	const sources = [
		'csr_data',
		'salesource_data',
		'salesrep_data',
		'tech_data',
		'location_data',

		'job_csr',
		'job_salesource',
		'job_salesreps',
		'job_techs',
		'job_locations'
	];
	const validData = data ?? [];

	validData.forEach(item => {
		for (const source of sources) {
			if (item[source]) {
				item.name = item[source].name;
				break;
			}
		}
	});

	return splitReportDataByAttr('name')(data);
};
