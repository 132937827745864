import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, isRef as _isRef, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "tw3-flex tw3-mb-8 tw3-items-center" }
const _hoisted_2 = { class: "tw3-flex tw3-pt-8 tw3-space-x-4" }

import { toTypedSchema } from '@vee-validate/zod';
import Button from 'primevue/button';
import IconField from 'primevue/iconfield';
import InputIcon from 'primevue/inputicon';
import InputMask from 'primevue/inputmask';
import InputText from 'primevue/inputtext';
import { useForm } from 'vee-validate';
import { watch } from 'vue';
import { z } from 'zod';

import InputAddress from '@/features/address/InputAddress.vue';

import { useCreateAccountStore } from '../../model/createAccount';
import {
	AccountCreationSteps,
	AccountCreationStepValues,
	ShopInfoForm
} from '../../model/types';
import StepBody from '../steps/StepBody.vue';
import StepInputGroup from '../steps/StepInputGroup.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'ShopInfoStep',
  setup(__props) {

const createAccountStore = useCreateAccountStore();

const validationSchema = toTypedSchema(
	z.object({
		name: z
			.string({ message: 'Shop name is required' })
			.min(1, { message: 'Shop name is required' }),
		tax_id: z.string().optional(),
		g_address: z.object({
			address: z.string(),
			city: z.string().optional(),
			extra: z.string().nullish(),
			full_address: z.string({ message: 'Mailing address is required' }),
			google_place_id: z.string(),
			lat: z.number(),
			lng: z.number(),
			po_box: z.number().optional(),
			state: z.string().optional(),
			unit: z.string().nullish(),
			zip: z.string().nullish()
		}),
		phone: z.string({ message: 'Phone is required' }).length(14, {
			message: 'Invalid phone number'
		}),
		fax: z.string().optional(),
		email: z
			.string({ message: 'Contact email is required' })
			.email({ message: 'Invalid email' }),
		website: z.string().url().optional()
	})
);

const { handleSubmit, errors, defineField, values } = useForm<ShopInfoForm>({
	validationSchema,
	initialValues: createAccountStore.form[AccountCreationSteps.shopInfo]
});

watch(
	() => values,
	v => {
		createAccountStore.form[AccountCreationSteps.shopInfo] = v;
	},
	{ deep: true }
);

const [name] = defineField('name');
const [tax_id] = defineField('tax_id');
const [g_address] = defineField('g_address');
const [phone] = defineField('phone');
const [fax] = defineField('fax');
const [email] = defineField('email');
const [website] = defineField('website');

const next = handleSubmit(async () => {
	createAccountStore.changeStep(AccountCreationStepValues.shopLogo);
});

const copyCompanyInfo = () => {
	const organization =
		createAccountStore.form[AccountCreationSteps.organization];
	name.value = organization.name;
	g_address.value = organization.g_address;
	phone.value = organization.phone;
	fax.value = organization.fax;
	email.value = organization.email;
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _cache[8] || (_cache[8] = _createElementVNode("div", { class: "tw3-flex-grow tw3-mr-24 tw3-text-slate-500" }, " Fill in main organization shop data. ", -1)),
      _createVNode(_unref(Button), {
        class: "tw3-shrink-0",
        label: "Use company info",
        outlined: "",
        severity: "info",
        size: "small",
        onClick: copyCompanyInfo
      })
    ]),
    _createVNode(StepBody, null, {
      default: _withCtx(() => [
        _createVNode(StepInputGroup, {
          error: _unref(errors).name,
          label: "Shop name"
        }, {
          default: _withCtx(() => [
            _createVNode(_unref(InputText), {
              modelValue: _unref(name),
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(name) ? (name).value = $event : null)),
              fluid: "",
              invalid: !!_unref(errors).name,
              placeholder: "Shop name"
            }, null, 8, ["modelValue", "invalid"])
          ]),
          _: 1
        }, 8, ["error"]),
        _createVNode(StepInputGroup, {
          error: _unref(errors).tax_id,
          label: "Tax ID",
          subLabel: "(EIN)"
        }, {
          default: _withCtx(() => [
            _createVNode(_unref(IconField), null, {
              default: _withCtx(() => [
                _createVNode(_unref(InputIcon), { class: "pi pi-phone" }),
                _createVNode(_unref(InputMask), {
                  modelValue: _unref(tax_id),
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_isRef(tax_id) ? (tax_id).value = $event : null)),
                  fluid: "",
                  invalid: !!_unref(errors).tax_id,
                  mask: "99-9999999",
                  placeholder: "99-9999999"
                }, null, 8, ["modelValue", "invalid"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["error"]),
        _createVNode(StepInputGroup, {
          class: "tw3-col-span-2",
          error: _unref(errors).g_address,
          label: "Address"
        }, {
          default: _withCtx(() => [
            _createVNode(InputAddress, {
              errMsg: _unref(errors).g_address,
              modelValue: _unref(g_address).full_address ? _unref(g_address) : null,
              withExtra: "",
              withPoBox: "",
              "onUpdate:modelValue": _cache[2] || (_cache[2] = v => (g_address.value = v))
            }, null, 8, ["errMsg", "modelValue"])
          ]),
          _: 1
        }, 8, ["error"]),
        _createVNode(StepInputGroup, {
          error: _unref(errors).phone,
          label: "Phone"
        }, {
          default: _withCtx(() => [
            _createVNode(_unref(IconField), null, {
              default: _withCtx(() => [
                _createVNode(_unref(InputIcon), { class: "pi pi-phone" }),
                _createVNode(_unref(InputMask), {
                  modelValue: _unref(phone),
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_isRef(phone) ? (phone).value = $event : null)),
                  fluid: "",
                  invalid: !!_unref(errors).phone,
                  mask: "(999) 999-9999",
                  placeholder: "(999) 999-9999"
                }, null, 8, ["modelValue", "invalid"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["error"]),
        _createVNode(StepInputGroup, {
          error: _unref(errors).fax,
          label: "Fax"
        }, {
          default: _withCtx(() => [
            _createVNode(_unref(IconField), null, {
              default: _withCtx(() => [
                _createVNode(_unref(InputIcon), { class: "pi pi-phone" }),
                _createVNode(_unref(InputMask), {
                  modelValue: _unref(fax),
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (_isRef(fax) ? (fax).value = $event : null)),
                  fluid: "",
                  invalid: !!_unref(errors).fax,
                  mask: "(999) 999-9999",
                  placeholder: "(999) 999-9999"
                }, null, 8, ["modelValue", "invalid"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["error"]),
        _createVNode(StepInputGroup, {
          error: _unref(errors).email,
          label: "Email"
        }, {
          default: _withCtx(() => [
            _createVNode(_unref(InputText), {
              modelValue: _unref(email),
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (_isRef(email) ? (email).value = $event : null)),
              fluid: "",
              invalid: !!_unref(errors).email,
              placeholder: "Contact email"
            }, null, 8, ["modelValue", "invalid"])
          ]),
          _: 1
        }, 8, ["error"]),
        _createVNode(StepInputGroup, {
          error: _unref(errors).website,
          label: "Website"
        }, {
          default: _withCtx(() => [
            _createVNode(_unref(InputText), {
              modelValue: _unref(website),
              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => (_isRef(website) ? (website).value = $event : null)),
              fluid: "",
              invalid: !!_unref(errors).website,
              placeholder: "Email"
            }, null, 8, ["modelValue", "invalid"])
          ]),
          _: 1
        }, 8, ["error"])
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_unref(Button), {
        label: "Back",
        severity: "secondary",
        size: "small",
        onClick: _cache[7] || (_cache[7] = ($event: any) => (
				_unref(createAccountStore).changeStep(_unref(AccountCreationStepValues).organization)
			))
      }),
      _createVNode(_unref(Button), {
        label: "Next",
        size: "small",
        onClick: _unref(next)
      }, null, 8, ["onClick"])
    ])
  ], 64))
}
}

})