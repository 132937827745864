import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import Tag from 'primevue/tag';
import { useI18n } from 'vue-i18n';

import { FaxStatus } from '../model/types';


export default /*@__PURE__*/_defineComponent({
  __name: 'FaxStatus',
  props: {
    status: {}
  },
  setup(__props: any) {

const { t } = useI18n();

const faxStatuses: { [key in FaxStatus]: string } = {
	UNREAD: t('faxDialog.status.unread'),
	READ: t('faxDialog.status.read'),
	SENT: t('faxDialog.status.sent'),
	ERROR: t('faxDialog.status.error'),
	SENDING: t('faxDialog.status.sending'),
	QUEUED: t('faxDialog.status.queued'),
	BURST: t('faxDialog.status.burst')
};

const faxStatusesFullInfo: { [key in FaxStatus]: string } = {
	UNREAD: t('faxDialog.statusResponses.unread'),
	READ: t('faxDialog.statusResponses.read'),
	SENT: t('faxDialog.statusResponses.sent'),
	ERROR: t('faxDialog.statusResponses.error'),
	SENDING: t('faxDialog.status.sending'),
	QUEUED: t('faxDialog.status.queued'),
	BURST: t('faxDialog.status.burst')
};

const mappedStatus = {
	[FaxStatus.SENT]: 'success',
	[FaxStatus.UNREAD]: 'info',
	[FaxStatus.READ]: 'success',
	[FaxStatus.ERROR]: 'danger',
	[FaxStatus.SENDING]: 'info',
	[FaxStatus.QUEUED]: 'warn',
	[FaxStatus.BURST]: 'warn'
};



return (_ctx: any,_cache: any) => {
  const _directive_tooltip = _resolveDirective("tooltip")!

  return _withDirectives((_openBlock(), _createBlock(_unref(Tag), {
    class: "tw3-capitalize",
    severity: mappedStatus[_ctx.status],
    value: faxStatuses[_ctx.status]
  }, null, 8, ["severity", "value"])), [
    [_directive_tooltip, faxStatusesFullInfo[_ctx.status]]
  ])
}
}

})