import { coreApi } from '@/shared/api';

export const getConfigDetails = async (): Promise<{
	filePickerKey: string;
}> => {
	try {
		const response = await coreApi().get(`/api/config`);

		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
};
