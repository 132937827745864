<script lang="ts" setup>
import Button from 'primevue/button';

import { User } from '@/entities/user/lib/types';

import { JobNote } from '../../model/types/note';

defineProps<{
	note: JobNote;
	user?: Pick<User, 'username' | 'color'>;
	showDelete: boolean;
}>();
const emit = defineEmits<{ (e: 'delete'): void }>();
</script>

<template>
	<div
		class="tw3-relative tw3-flex tw3-items-start tw3-space-x-3 tw3-p-3 tw3-rounded-lg tw3-shadow-md tw3-bg-white tw3-group"
	>
		<div class="tw3-flex tw3-flex-col tw3-space-y-1 tw3-w-full">
			<div class="tw3-flex tw3-flex-col tw3-space-y-1 tw3-w-full">
				<div class="tw3-flex tw3-items-center tw3-space-x-2">
					<b
						class="tw3-font-semibold tw3-truncate"
						:style="{ color: user?.color || '#333' }"
					>
						{{ user?.username || 'Unknown' }}
					</b>
					<span class="tw3-text-sm tw3-text-gray-500 tw3-whitespace-nowrap">
						{{ note.note_date }}
					</span>
				</div>
				<p class="tw3-text-gray-700 tw3-text-lg tw3-break-words">
					{{ note.note_text }}
				</p>
			</div>
		</div>
		<Button
			v-if="showDelete"
			aria-label="Delete"
			class="!tw3-absolute tw3-top-[-0.25rem] tw3-right-[-0.25rem] sm:tw3-opacity-0 group-hover:tw3-opacity-100 tw3-transition tw3-duration-400"
			icon="pi pi-times"
			iconClass="!tw3-text-[0.8rem]"
			raised
			rounded
			severity="danger"
			small
			style="height: 1.25rem; width: 1.25rem"
			text
			type="button"
			@click="emit('delete')"
		/>
	</div>
</template>
