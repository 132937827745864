import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "tw3-m-auto" }
const _hoisted_2 = { class: "tw3-flex tw3-flex-col tw3-gap-[0.5rem] tw3-min-w-[11rem] tw3-mb-[0.25rem] tw3-mx-[0.75rem] tw3-mt-[0.75rem]" }
const _hoisted_3 = { class: "tw3-flex tw3-flex-col tw3-gap-[0.5rem]" }
const _hoisted_4 = { class: "tw3-flex tw3-items-center tw3-justify-between" }

import { FilterMatchMode } from '@primevue/core';
import Button from 'primevue/button';
import Menu from 'primevue/menu';
import { ref } from 'vue';

import JobTableFilters from '../filters/JobTableFilters.vue';

const ID_PREFIX = 'overlay_menu_column_filter_';

type Filter = {
	val?: any | null;
	type: string;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'JobColumnFilterPopup',
  props: {
    id: {},
    name: {},
    icon: {},
    modelValue: {},
    loading: { type: Boolean }
  },
  emits: ["update:modelValue", "clear"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emit = __emit;

const localFilter = ref<Filter>({ val: null, type: FilterMatchMode.EQUALS });

const menu = ref<any>();

const openMenu = (e: MouseEvent) => {
	localFilter.value = { ...props.modelValue };
	menu.value?.toggle(e);
};

const clear = () => {
	emit('clear');
	menu.value?.hide();
};

const save = () => {
	menu.value?.toggle();
	emit('update:modelValue', localFilter.value);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_unref(Button), {
      "aria-controls": ID_PREFIX + _ctx.id,
      "aria-haspopup": "true",
      disabled: _ctx.loading,
      icon: _ctx.icon,
      loading: _ctx.loading,
      rounded: "",
      severity: "secondary",
      text: "",
      type: "button",
      onClick: openMenu
    }, null, 8, ["aria-controls", "disabled", "icon", "loading"]),
    _createVNode(_unref(Menu), {
      id: ID_PREFIX + _ctx.id,
      ref_key: "menu",
      ref: menu,
      "aria-modal": "true",
      popup: "",
      role: "dialog",
      onHide: () => {}
    }, {
      start: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(JobTableFilters, {
              id: _ctx.id,
              key: "JobTableFiltersModal",
              modelValue: localFilter.value.val,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((localFilter.value.val) = $event)),
              type: localFilter.value.type,
              "onUpdate:type": _cache[1] || (_cache[1] = ($event: any) => ((localFilter.value.type) = $event)),
              name: _ctx.name
            }, null, 8, ["id", "modelValue", "type", "name"])
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_unref(Button), {
              label: "Clear",
              outlined: "",
              size: "small",
              type: "button",
              onClick: clear
            }),
            _createVNode(_unref(Button), {
              label: "Apply",
              size: "small",
              type: "button",
              onClick: save
            })
          ])
        ])
      ]),
      _: 1
    }, 8, ["id"])
  ]))
}
}

})