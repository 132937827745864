export type Config = {
	isDev: boolean;

	apiSecure: boolean;
	apiHost: string;

	pusherKey: string;
	pusherCluster: string;

	cloverClientId: string;
	cloverServerUrl: string;

	googleAnalyticsId: string;

	googleMapsApiKey: string;
	googleMapsVersion: string;

	getBaseURL: () => string;
	getBaseWsURL: () => string;
};

let conf: Config;

export function setConfig(newConfig: Config): void {
	conf = newConfig;

	conf.googleMapsVersion = '3.43';
}

export function config(): Config {
	if (!conf) {
		throw new Error('Config is not set');
	}

	return conf;
}
