import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, renderList as _renderList, createSlots as _createSlots } from "vue"

const _hoisted_1 = { class: "tw3-flex tw3-justify-between" }
const _hoisted_2 = { class: "tw3-flex tw3-gap-2 tw3-items-center" }
const _hoisted_3 = { class: "!tw3-text-xl !tw3-font-bold tw3-text-black" }
const _hoisted_4 = { class: "tw3-flex tw3-items-center tw3-pr-4 tw3-opacity-50" }
const _hoisted_5 = { class: "tw3-flex tw3-justify-between tw3-gap-2" }
const _hoisted_6 = { class: "tw3-flex tw3-gap-3" }
const _hoisted_7 = { class: "tw3-flex tw3-flex-col tw3-gap-0 tw3-grow" }
const _hoisted_8 = ["for"]
const _hoisted_9 = { class: "tw3-flex tw3-flex-col tw3-gap-0 tw3-grow" }
const _hoisted_10 = ["for"]
const _hoisted_11 = { class: "tw3-flex tw3-gap-2 tw3-items-end" }
const _hoisted_12 = { class: "tw3-flex tw3-justify-end tw3-gap-2 tw3-font-bold" }
const _hoisted_13 = { class: "tw3-flex tw3-justify-end tw3-font-bold tw3-w-full" }
const _hoisted_14 = { class: "tw3-mx-3" }
const _hoisted_15 = { class: "tw3-mx-3" }
const _hoisted_16 = { class: "tw3-mx-3" }
const _hoisted_17 = {
  key: 1,
  class: "tw3-text-right"
}
const _hoisted_18 = {
  key: 1,
  class: "tw3-text-right"
}
const _hoisted_19 = {
  key: 1,
  class: "tw3-text-right"
}

import { DateTime } from 'luxon';
import { storeToRefs } from 'pinia';
import Breadcrumb from 'primevue/breadcrumb';
import Button from 'primevue/button';
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import Skeleton from 'primevue/skeleton';
import { computed, ref, watchEffect } from 'vue';

import router from '@/app/router';
import { BalanceType } from '@/entities/accounting/accounts/lib/types';
import useTransactionsStore from '@/entities/accounting/transactions/lib/typeStore';
import useOrganizationsStore from '@/entities/organization/lib/store';
import useAccountingStore from '@/features/accounting/lib/store';
import { formatDate, formatMoney } from '@/shared/helpers/formatters';
import PageCard from '@/shared/ui/container/PageCard.vue';
import DatePicker from '@/shared/ui/input/DatePicker.vue';
import TransactionDetails from '@/widgets/accounting/journalEntries/TransactionDetails.vue';

import useAccountingReportsStore from './lib/store';

const cid = 'accounting-transactions-by-account';


export default /*@__PURE__*/_defineComponent({
  __name: 'TransactionsByAccount',
  props: {
    id: {}
  },
  setup(__props: any) {

const skeletonArray = Array(30).fill({});

const props = __props;

const { organizations } = storeToRefs(useOrganizationsStore());

const accountingStore = useAccountingStore();
const { accounts, typesById, shopsById } = storeToRefs(accountingStore);

const account = computed(() => {
	return accounts.value.find((account: any) => account.id == props.id);
});
const accountType = computed(() =>
	account.value ? typesById.value[account.value.typeId] : null
);

const organization = computed(() => {
	return organizations.value.find(
		(organization: any) => organization.id == account.value?.organizationId
	)!;
});

const transactionsStore = useTransactionsStore();
const { typesById: transactionTypesById } = storeToRefs(transactionsStore);

const reportsStore = useAccountingReportsStore();
const { transactionsByAccount: report, transactionsByAccountLoading: loading } =
	storeToRefs(reportsStore);

const selectedTransactionDtFrom = ref(DateTime.now().startOf('year'));
const selectedTransactionDtTo = ref(DateTime.now().startOf('day'));

const refresh = async () => {
	await reportsStore.loadTransactionsByAccount({
		accountId: props.id,
		transactionDt: {
			start: selectedTransactionDtFrom.value,
			end: selectedTransactionDtTo.value.plus({ days: 1 })
		}
	});
};

watchEffect(async () => {
	await refresh();
});

const recordsNormalized = computed(() => {
	if (!accountType.value) {
		return [];
	}
	let balance = report.value.startBalance;
	const creditAccount = accountType.value.creditAccount;
	return report.value.record.map((transaction: any) => {
		const increase = creditAccount ? transaction.credit : transaction.debit;
		const decrease = creditAccount ? transaction.debit : transaction.credit;
		const balanceBefore = balance;
		balance += increase - decrease;
		return {
			...transaction,
			increase: increase,
			decrease: decrease,
			balanceBefore: balanceBefore,
			balance: balance
		};
	});
});

const totalIncrease = computed(() => {
	return recordsNormalized.value.reduce(
		(acc, transaction) => acc + transaction.increase,
		0
	);
});

const totalDecrease = computed(() => {
	return recordsNormalized.value.reduce(
		(acc, transaction) => acc + transaction.decrease,
		0
	);
});

const totalBalance = computed(() => {
	return recordsNormalized.value.reduce(
		(acc, transaction) => acc + transaction.balance,
		0
	);
});

const table = ref<typeof DataTable | null>(null);

const ytd = computed(() => {
	return accountType.value?.balanceType == BalanceType.BALANCE_TYPE_YTD;
});

const mainReport = computed(() => {
	return !ytd.value
		? {
				title: 'Balance Sheet',
				routeName: 'accounting-balance-sheet'
			}
		: {
				title: 'Income Statement',
				routeName: 'accounting-income-statement'
			};
});

const title = computed(() => [
	{
		label: mainReport.value.title,
		disabled: true
	},
	{
		label: `${accountType.value?.name}: ${account.value?.name}`,
		disabled: true
	}
]);

const showTransactionDetails = ref(false);
const selectedTransaction: any = ref(null);

const goToAccounts = () => {
	router.push({ name: mainReport.value.routeName });
};

return (_ctx: any,_cache: any) => {
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!!accountType.value)
      ? (_openBlock(), _createBlock(PageCard, { key: 0 }, {
          title: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                _withDirectives(_createVNode(_unref(Button), {
                  "aria-label": "Filter",
                  icon: "pi pi-arrow-left",
                  serverity: "secondary",
                  text: "",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (goToAccounts()))
                }, null, 512), [
                  [
                    _directive_tooltip,
                    `Back to ${mainReport.value.title}`,
                    void 0,
                    { top: true }
                  ]
                ]),
                _createVNode(_unref(Breadcrumb), {
                  model: title.value,
                  "pt:root:class": "!tw3-p-0"
                }, {
                  item: _withCtx(({ item }) => [
                    _createElementVNode("span", _hoisted_3, _toDisplayString(item.label), 1)
                  ]),
                  _: 1
                }, 8, ["model"])
              ]),
              _createElementVNode("div", _hoisted_4, _toDisplayString(organization.value.name), 1)
            ])
          ]),
          content: _withCtx(() => [
            _createVNode(_unref(DataTable), {
              ref_key: "table",
              ref: table,
              dataKey: "id",
              editMode: "row",
              filterDisplay: "menu",
              groupRowsBy: "someColumn",
              pt: {
					rowgroupheadercell: { colspan: '99' },
					rowgroupfootercell: { colspan: '99' }
				},
              rowHover: !_unref(loading),
              rowGroupMode: "subheader",
              scrollable: "",
              scrollHeight: "flex",
              size: "small",
              tableClass: "tw3-max-w-full",
              value: _unref(loading) ? _unref(skeletonArray) : recordsNormalized.value
            }, _createSlots({
              header: _withCtx(() => [
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("div", _hoisted_6, [
                    _createElementVNode("div", _hoisted_7, [
                      _createElementVNode("label", {
                        for: `${cid}-dt-from`
                      }, "From", 8, _hoisted_8),
                      _createVNode(DatePicker, {
                        modelValue: selectedTransactionDtFrom.value,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((selectedTransactionDtFrom).value = $event)),
                        class: "tw3-w-[250px]",
                        hourFormat: "12",
                        inputId: `${cid}-dt-from`,
                        manualInput: false,
                        showIcon: ""
                      }, null, 8, ["modelValue", "inputId"])
                    ]),
                    _createElementVNode("div", _hoisted_9, [
                      _createElementVNode("label", {
                        for: `${cid}-dt-from`
                      }, "To", 8, _hoisted_10),
                      _createVNode(DatePicker, {
                        id: `${cid}-dt-to`,
                        modelValue: selectedTransactionDtTo.value,
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((selectedTransactionDtTo).value = $event)),
                        class: "tw3-w-[250px]",
                        hourFormat: "12",
                        manualInput: false,
                        showIcon: ""
                      }, null, 8, ["id", "modelValue"])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_11, [
                    _withDirectives(_createVNode(_unref(Button), {
                      icon: "pi pi-refresh",
                      severity: "secondary",
                      onClick: _cache[3] || (_cache[3] = ($event: any) => (refresh()))
                    }, null, 512), [
                      [
                        _directive_tooltip,
                        'Refresh',
                        void 0,
                        { top: true }
                      ]
                    ])
                  ])
                ])
              ]),
              default: _withCtx(() => [
                _createVNode(_unref(Column), {
                  field: "transactionDt",
                  header: "Date",
                  style: { width: '8%' }
                }, {
                  body: _withCtx(({ data }) => [
                    (_unref(loading))
                      ? (_openBlock(), _createBlock(_unref(Skeleton), { key: 0 }))
                      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                          _createTextVNode(_toDisplayString(_unref(formatDate)(data.transactionDt)), 1)
                        ], 64))
                  ]),
                  _: 1
                }),
                _createVNode(_unref(Column), {
                  field: "type",
                  header: "Type",
                  style: { width: '17%' }
                }, {
                  body: _withCtx(({ data }) => [
                    (_unref(loading))
                      ? (_openBlock(), _createBlock(_unref(Skeleton), { key: 0 }))
                      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                          _createTextVNode(_toDisplayString(_unref(transactionTypesById)[data.typeId].name), 1)
                        ], 64))
                  ]),
                  _: 1
                }),
                _createVNode(_unref(Column), {
                  field: "description",
                  header: "Description",
                  style: { width: '19%' }
                }, {
                  body: _withCtx(({ data }) => [
                    (_unref(loading))
                      ? (_openBlock(), _createBlock(_unref(Skeleton), { key: 0 }))
                      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                          _createTextVNode(_toDisplayString(data.description), 1)
                        ], 64))
                  ]),
                  _: 1
                }),
                _createVNode(_unref(Column), {
                  field: "otherAccount",
                  header: "Other Account",
                  style: { width: '17%' }
                }, {
                  body: _withCtx(({ data }) => [
                    (_unref(loading))
                      ? (_openBlock(), _createBlock(_unref(Skeleton), { key: 0 }))
                      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                          _createTextVNode(_toDisplayString(_unref(accounts).find(account => account.id === data.otherAccountId)
									?.name) + " ", 1),
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(data.otherAccountIds, (accountId) => {
                            return (_openBlock(), _createElementBlock("div", { key: accountId }, _toDisplayString(_unref(accounts).find(account => account.id === accountId)?.name), 1))
                          }), 128))
                        ], 64))
                  ]),
                  _: 1
                }),
                _createVNode(_unref(Column), {
                  field: "shop",
                  header: "Shop",
                  style: { width: '17%' }
                }, {
                  body: _withCtx(({ data }) => [
                    (_unref(loading))
                      ? (_openBlock(), _createBlock(_unref(Skeleton), { key: 0 }))
                      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                          _createTextVNode(_toDisplayString(_unref(shopsById)[data.shopId].name), 1)
                        ], 64))
                  ]),
                  _: 1
                }),
                _createVNode(_unref(Column), {
                  field: "increase",
                  header: "Increase",
                  style: { width: '8%' }
                }, {
                  body: _withCtx(({ data }) => [
                    (_unref(loading))
                      ? (_openBlock(), _createBlock(_unref(Skeleton), { key: 0 }))
                      : (_openBlock(), _createElementBlock("div", _hoisted_17, _toDisplayString(data.increase ? _unref(formatMoney)(data.increase) : ''), 1))
                  ]),
                  _: 1
                }),
                _createVNode(_unref(Column), {
                  field: "decrease",
                  header: "Decrease",
                  style: { width: '8%' }
                }, {
                  body: _withCtx(({ data }) => [
                    (_unref(loading))
                      ? (_openBlock(), _createBlock(_unref(Skeleton), { key: 0 }))
                      : (_openBlock(), _createElementBlock("div", _hoisted_18, _toDisplayString(data.decrease ? _unref(formatMoney)(data.decrease) : ''), 1))
                  ]),
                  _: 1
                }),
                (!ytd.value)
                  ? (_openBlock(), _createBlock(_unref(Column), {
                      key: 0,
                      field: "balance",
                      header: "Balance",
                      style: { width: '8%' }
                    }, {
                      body: _withCtx(({ data }) => [
                        (_unref(loading))
                          ? (_openBlock(), _createBlock(_unref(Skeleton), { key: 0 }))
                          : (_openBlock(), _createElementBlock("div", _hoisted_19, _toDisplayString(_unref(formatMoney)(data.balance)), 1))
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
              ]),
              _: 2
            }, [
              (!_unref(loading) && !ytd.value)
                ? {
                    name: "groupheader",
                    fn: _withCtx(({ data }) => [
                      _createElementVNode("div", _hoisted_12, [
                        _cache[6] || (_cache[6] = _createElementVNode("span", { class: "tw3-mr-3" }, " Starting balance: ", -1)),
                        _createTextVNode(" " + _toDisplayString(_unref(formatMoney)(data.balanceBefore)), 1)
                      ])
                    ]),
                    key: "0"
                  }
                : undefined,
              (!_unref(loading))
                ? {
                    name: "groupfooter",
                    fn: _withCtx(({ data }) => [
                      _createElementVNode("div", _hoisted_13, [
                        (!ytd.value)
                          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                              _cache[7] || (_cache[7] = _createElementVNode("span", { class: "tw3-mr-3" }, " Ending balance: ", -1)),
                              _createElementVNode("span", null, _toDisplayString(_unref(formatMoney)(data.balance)), 1)
                            ], 64))
                          : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                              _createElementVNode("span", _hoisted_14, [
                                _cache[8] || (_cache[8] = _createElementVNode("span", { class: "tw3-mr-3" }, " Increase: ", -1)),
                                _createElementVNode("span", null, _toDisplayString(_unref(formatMoney)(totalIncrease.value)), 1)
                              ]),
                              _cache[11] || (_cache[11] = _createTextVNode(" / ")),
                              _createElementVNode("span", _hoisted_15, [
                                _cache[9] || (_cache[9] = _createElementVNode("span", { class: "tw3-mr-3" }, " Decrease: ", -1)),
                                _createElementVNode("span", null, _toDisplayString(_unref(formatMoney)(totalDecrease.value)), 1)
                              ]),
                              _cache[12] || (_cache[12] = _createTextVNode(" / ")),
                              _createElementVNode("span", _hoisted_16, [
                                _cache[10] || (_cache[10] = _createElementVNode("span", { class: "tw3-mr-3" }, " Total Change: ", -1)),
                                _createElementVNode("span", null, _toDisplayString(_unref(formatMoney)(totalBalance.value)), 1)
                              ])
                            ], 64))
                      ])
                    ]),
                    key: "1"
                  }
                : undefined,
              (!_unref(loading))
                ? {
                    name: "empty",
                    fn: _withCtx(() => [
                      _cache[13] || (_cache[13] = _createElementVNode("div", { class: "tw3-w-full tw3-flex tw3-justify-center tw3-items-center tw3-py-20" }, [
                        _createElementVNode("p", { class: "tw3-text-center tw3-text-3xl" }, "Transactions not found")
                      ], -1))
                    ]),
                    key: "2"
                  }
                : undefined
            ]), 1032, ["rowHover", "value"])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    _createVNode(TransactionDetails, {
      transaction: selectedTransaction.value,
      "onUpdate:transaction": _cache[4] || (_cache[4] = ($event: any) => ((selectedTransaction).value = $event)),
      visible: showTransactionDetails.value,
      "onUpdate:visible": _cache[5] || (_cache[5] = ($event: any) => ((showTransactionDetails).value = $event))
    }, null, 8, ["transaction", "visible"])
  ], 64))
}
}

})