<script setup lang="ts">
import Button from 'primevue/button';
import ProgressSpinner from 'primevue/progressspinner';
import { onMounted, ref, watch } from 'vue';

import { getHostedSubscriptionPage, HostedPage } from '../../api';
import { useCreateAccountStore } from '../../model/createAccount';
import {
	AccountCreationSteps,
	AccountCreationStepValues,
	BillingFinancingForm
} from '../../model/types';

const store = useCreateAccountStore();
const hostedpage = ref<HostedPage>();
const loading = ref(false);

onMounted(() => {
	getUrl();
});

watch(
	() => store.step,
	async val => {
		if (val === AccountCreationStepValues.billingFinancing) {
			getUrl();
		}
	}
);

const getUrl = async () => {
	loading.value = true;
	const billingPlan = store.form[AccountCreationSteps.billingPlan];
	const org = store.form[AccountCreationSteps.organization];

	const redirectUrl = `${window.location.origin}/sign-up/finish`;

	const res = await getHostedSubscriptionPage({
		redirectUrl,
		plan: billingPlan,
		organization: org
	});
	hostedpage.value = res.hostedpage;
	loading.value = false;
};

const handle = (val: boolean) => {
	const financing: BillingFinancingForm = {
		hostedpage: hostedpage.value!
	};
	if (val) {
		financing.finance = true;
	}
	store.form[AccountCreationSteps.billingFinancing] = financing;
	window.location.href = hostedpage.value!.url;
};
</script>

<template>
	<div class="tw3-text-2xl tw3-font-semibold tw3-mb-12 tw3-text-center">
		Would you like to setup same-day funding for your insurance jobs? Our team
		member will contact you about financing options.
	</div>

	<div v-if="loading" class="tw3-text-center">
		<ProgressSpinner />
	</div>
	<div v-else class="tw3-flex tw3-space-x-4">
		<Button fluid label="No" severity="secondary" @click="handle(false)" />
		<Button fluid label="Yes" severity="success" @click="handle(true)" />
	</div>
</template>
