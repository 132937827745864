import { coreApi } from '@/shared/api';
import { DefaultApiResponse } from '@/shared/types/default-api-response';

import {
	Csrs,
	Quote,
	QuoteRaw,
	QuoteRequestParams,
	QuoteVoidUpdate
} from './types';
import { normazileQuotes } from './utils';

export const CSRS_URL = '/unum/vendor/organization/gb_csr';
export const QUOTE_URL = '/unum/job-details/jobslist/quotes-dialog/table';
export const QUOTE_DATA_UPDATE_URL = '/unum/job-details/data';
export const QUOTE_DATA_UPDATE_BULK_URL = '/unum/job-details/data/update/bulk';

export const getCsrs = async (id: number): Promise<Csrs[]> => {
	try {
		return (await coreApi().get<Csrs[]>(`${CSRS_URL}/${id}`)).data;
	} catch (error) {
		console.error(error);
		throw error;
	}
};

export const getMany = async (
	params: QuoteRequestParams
): Promise<DefaultApiResponse<Quote>> => {
	try {
		const response = await coreApi().post<DefaultApiResponse<QuoteRaw>>(
			QUOTE_URL,
			params
		);

		return {
			numberOfRowsFound: response.data.numberOfRowsFound,
			rows: normazileQuotes(response.data.rows)
		};
	} catch (error) {
		console.error(error);
		throw error;
	}
};

export const update = async (
	id: string,
	follow_up_date: string | null
): Promise<void> => {
	try {
		await coreApi().post(`${QUOTE_DATA_UPDATE_URL}/${id}`, { follow_up_date });
	} catch (error) {
		console.error(error);
		throw error;
	}
};

export const voidMany = async (
	job_ids: Array<number>,
	update: QuoteVoidUpdate
): Promise<void> => {
	try {
		await coreApi().post(`${QUOTE_DATA_UPDATE_BULK_URL}`, { job_ids, update });
	} catch (error) {
		console.log(error);
		throw error;
	}
};
