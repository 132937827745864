import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import Button from 'primevue/button';
import { useI18n } from 'vue-i18n';

import { JobTag } from '@/entities/job/model/types';
import TagsDialog from '@/features/tagsDialog/TagsDialog.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'TagDialogWithButton',
  props: /*@__PURE__*/_mergeModels({
    jobID: { default: 0 },
    tagsList: { default: () => [] },
    currentTagsList: { default: () => [] },
    isLoading: { type: Boolean, default: false },
    openButtonLabel: { default: undefined },
    openButtonIcon: { default: undefined },
    dialogTitle: { default: undefined },
    updateLabel: { default: undefined }
  }, {
    "visible": { type: Boolean },
    "visibleModifiers": {},
  }),
  emits: /*@__PURE__*/_mergeModels(["updateTags"], ["update:visible"]),
  setup(__props: any, { emit: __emit }) {

const showModal = _useModel<boolean>(__props, 'visible');

const { t } = useI18n();



const emit = __emit;

return (_ctx: any,_cache: any) => {
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", null, [
    _withDirectives(_createVNode(_unref(Button), {
      class: "w-full md:w-14rem",
      icon: _ctx.openButtonIcon ? _ctx.openButtonIcon : 'pi pi-plus-circle',
      loading: _ctx.isLoading,
      text: "",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (showModal.value = true))
    }, null, 8, ["icon", "loading"]), [
      [
        _directive_tooltip,
        _ctx.openButtonLabel ? _ctx.openButtonLabel : _unref(t)('addNewTag'),
        void 0,
        { top: true }
      ]
    ]),
    _createVNode(TagsDialog, {
      visible: showModal.value,
      "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => ((showModal).value = $event)),
      currentTagsList: _ctx.currentTagsList,
      dialogTitle: _ctx.dialogTitle,
      isLoading: _ctx.isLoading,
      jobID: _ctx.jobID,
      tagsList: _ctx.tagsList,
      updateLabel: _ctx.updateLabel,
      onUpdateTags: _cache[2] || (_cache[2] = 
				v => {
					emit('updateTags', v);
					showModal.value = false;
				}
			)
    }, null, 8, ["visible", "currentTagsList", "dialogTitle", "isLoading", "jobID", "tagsList", "updateLabel"])
  ]))
}
}

})