import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "scheduler-report-table" }
const _hoisted_2 = { class: "table-shop-header" }
const _hoisted_3 = ["colspan"]
const _hoisted_4 = { class: "table-tech-header" }
const _hoisted_5 = ["colspan"]
const _hoisted_6 = { class: "table-date-header" }
const _hoisted_7 = ["colspan"]
const _hoisted_8 = { class: "table-head" }
const _hoisted_9 = { key: 0 }
const _hoisted_10 = ["id", "href"]
const _hoisted_11 = ["id", "innerHTML"]
const _hoisted_12 = { class: "row-spacer-date" }
const _hoisted_13 = ["colspan"]
const _hoisted_14 = { class: "row-spacer-tech" }
const _hoisted_15 = ["colspan"]
const _hoisted_16 = { class: "row-spacer-shop" }
const _hoisted_17 = ["colspan"]

import { storeToRefs } from 'pinia';
import { computed } from 'vue';

import useReportsStore from '@/widgets/reportsElements/model/store';
import {
	reportsGetters,
	splitReportDataByAttr
} from '@/widgets/reportsElements/model/utils/getters';


export default /*@__PURE__*/_defineComponent({
  __name: 'SchedulerReportTable',
  props: {
    data: {}
  },
  setup(__props: any) {

const props = __props;

const reportStore = useReportsStore();
const { currentColumns } = storeToRefs(reportStore);
const dataSplitedByShop = computed(() => {
	return splitReportDataByAttr('shop_name')(props.data);
});

const splitByTech = (data: any[]) => {
	const newData = [];
	for (let i = 0; i < data.length; i++) {
		if (data[i].job_techs?.length) {
			data[i].job_techs.map(item => {
				newData.push({ ...data[i], tech: item.name });
			});
		} else {
			newData.push({ ...data[i], tech: 'unassigned' });
		}
	}
	const val = splitReportDataByAttr('tech')(newData);
	return val;
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("table", _hoisted_1, [
    _createElementVNode("tbody", null, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(dataSplitedByShop.value, (tableData, shop) => {
        return (_openBlock(), _createElementBlock(_Fragment, { key: shop }, [
          _createElementVNode("tr", _hoisted_2, [
            _createElementVNode("td", {
              class: "cell",
              colspan: _unref(currentColumns).length + 3
            }, _toDisplayString(shop), 9, _hoisted_3)
          ]),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(splitByTech(tableData), (tech_data, tech) => {
            return (_openBlock(), _createElementBlock(_Fragment, {
              key: shop + tech
            }, [
              _createElementVNode("tr", _hoisted_4, [
                _cache[0] || (_cache[0] = _createElementVNode("td", { class: "fake-cell" }, null, -1)),
                _createElementVNode("td", {
                  class: "cell",
                  colspan: _unref(currentColumns).length + 2
                }, _toDisplayString(tech && tech !== 'null' ? tech : 'UNASSIGNED'), 9, _hoisted_5)
              ]),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(splitReportDataByAttr)('date')(
							tech_data
						), (date_data, date) => {
                return (_openBlock(), _createElementBlock(_Fragment, {
                  key: shop + tech + date
                }, [
                  _createElementVNode("tr", _hoisted_6, [
                    _cache[1] || (_cache[1] = _createElementVNode("td", { class: "fake-cell" }, null, -1)),
                    _cache[2] || (_cache[2] = _createElementVNode("td", { class: "fake-cell" }, null, -1)),
                    _createElementVNode("td", {
                      class: "cell",
                      colspan: _unref(currentColumns).length + 2
                    }, _toDisplayString(date), 9, _hoisted_7)
                  ]),
                  _createElementVNode("tr", _hoisted_8, [
                    _cache[3] || (_cache[3] = _createElementVNode("td", { class: "fake-cell" }, null, -1)),
                    _cache[4] || (_cache[4] = _createElementVNode("td", { class: "fake-cell" }, null, -1)),
                    _cache[5] || (_cache[5] = _createElementVNode("td", { class: "fake-cell" }, null, -1)),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(currentColumns), (col) => {
                      return (_openBlock(), _createElementBlock("td", {
                        key: col.prop,
                        class: "cell"
                      }, _toDisplayString(col.label), 1))
                    }), 128))
                  ]),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(date_data, (row) => {
                    return (_openBlock(), _createElementBlock("tr", {
                      key: shop + tech + date + 1 + row.id,
                      class: "table-row"
                    }, [
                      _cache[6] || (_cache[6] = _createElementVNode("td", { class: "fake-cell" }, null, -1)),
                      _cache[7] || (_cache[7] = _createElementVNode("td", { class: "fake-cell" }, null, -1)),
                      _cache[8] || (_cache[8] = _createElementVNode("td", { class: "fake-cell" }, null, -1)),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(currentColumns), (col) => {
                        return (_openBlock(), _createElementBlock("td", {
                          key: col.prop,
                          class: _normalizeClass(["cell", { wrap: col.wrap }])
                        }, [
                          (col.prop === 'vehicle')
                            ? (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(row[col.prop]) + " " + _toDisplayString(row.vin), 1))
                            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                (col.link)
                                  ? (_openBlock(), _createElementBlock("a", {
                                      key: 0,
                                      id: row.id,
                                      class: "tw3-whitespace-nowrap",
                                      href: `/jobs/${row.id}`
                                    }, _toDisplayString(row[col.prop]), 9, _hoisted_10))
                                  : (_openBlock(), _createElementBlock("div", {
                                      key: 1,
                                      id: row.id,
                                      class: _normalizeClass({
											'tw3-whitespace-nowrap': col.prop === 'time',
											'tw3-text-right': col.dollar
										}),
                                      innerHTML: 
											col.dollar
												? _unref(reportsGetters).formatNumber(row[col.prop], {
														style: 'dollar'
													})
												: row[col.prop]
										
                                    }, null, 10, _hoisted_11))
                              ], 64))
                        ], 2))
                      }), 128))
                    ]))
                  }), 128)),
                  _createElementVNode("tr", _hoisted_12, [
                    _cache[9] || (_cache[9] = _createElementVNode("td", { class: "fake-cell" }, null, -1)),
                    _cache[10] || (_cache[10] = _createElementVNode("td", { class: "fake-cell" }, null, -1)),
                    _cache[11] || (_cache[11] = _createElementVNode("td", { class: "fake-cell" }, null, -1)),
                    _createElementVNode("td", {
                      class: "cell",
                      colspan: _unref(currentColumns).length
                    }, null, 8, _hoisted_13)
                  ])
                ], 64))
              }), 128)),
              _createElementVNode("tr", _hoisted_14, [
                _cache[12] || (_cache[12] = _createElementVNode("td", { class: "fake-cell" }, null, -1)),
                _cache[13] || (_cache[13] = _createElementVNode("td", { class: "fake-cell" }, null, -1)),
                _createElementVNode("td", {
                  class: "cell",
                  colspan: _unref(currentColumns).length + 1
                }, null, 8, _hoisted_15)
              ])
            ], 64))
          }), 128)),
          _createElementVNode("tr", _hoisted_16, [
            _cache[14] || (_cache[14] = _createElementVNode("td", { class: "fake-cell" }, null, -1)),
            _createElementVNode("td", {
              class: "cell",
              colspan: _unref(currentColumns).length + 2
            }, null, 8, _hoisted_17)
          ])
        ], 64))
      }), 128))
    ])
  ]))
}
}

})