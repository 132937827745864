import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"


export default /*@__PURE__*/_defineComponent({
  __name: 'TableRowSpacer',
  props: {
    shop: { type: Boolean },
    fake: {},
    columns: {}
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("tr", {
    class: _normalizeClass(["table-row-spacer", { 'shop-spacer': _ctx.shop }])
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fake, (n) => {
      return (_openBlock(), _createElementBlock("td", {
        key: n,
        class: "fake-cell"
      }))
    }), 128)),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.columns, (col) => {
      return (_openBlock(), _createElementBlock("td", {
        key: col.prop,
        class: "empty-cell"
      }))
    }), 128))
  ], 2))
}
}

})