import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

import { FilterMatchMode } from '@primevue/core';
import dayjs from 'dayjs';
import DatePicker from 'primevue/datepicker';
import Select from 'primevue/select';
import { computed, ref } from 'vue';

type DateFilter = {
	val?: { date_1?: string | null; date_2?: string | null } | null;
	type: string;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'JobDateFilter',
  props: {
    modelValue: {},
    name: {}
  },
  emits: ["update:modelValue"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emit = __emit;

const formattedDate1 = computed(() =>
	props.modelValue.val?.date_1 ? new Date(props.modelValue.val.date_1) : null
);
const formattedDate2 = computed(() =>
	props.modelValue.val?.date_2 ? new Date(props.modelValue.val.date_2) : null
);

const options = ref([
	{ name: 'Single Value', id: FilterMatchMode.EQUALS },
	{ name: 'Value Range', id: FilterMatchMode.BETWEEN }
]);

const updateMatchMode = (v: string) => {
	emit('update:modelValue', { type: v, val: null });
};

const updateDate1 = (v: Date) => {
	emit('update:modelValue', {
		type: props.modelValue.type,
		val:
			v || props.modelValue.val?.date_2
				? {
						date_1: v ? dayjs(v).format('YYYY-MM-DD') : null,
						date_2: props.modelValue.val?.date_2
					}
				: null
	});
};

const updateDate2 = (v: Date) => {
	emit('update:modelValue', {
		type: props.modelValue.type,
		val:
			v || props.modelValue.val?.date_1
				? {
						date_1: props.modelValue.val?.date_1,
						date_2: v ? dayjs(v).format('YYYY-MM-DD') : null
					}
				: null
	});
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_unref(Select), {
      appendTo: "self",
      class: "p-column-filter",
      modelValue: _ctx.modelValue.type,
      optionLabel: "name",
      options: options.value,
      optionValue: "id",
      "onUpdate:modelValue": updateMatchMode
    }, null, 8, ["modelValue", "options"]),
    _createVNode(_unref(DatePicker), {
      appendTo: "self",
      class: "p-column-filter",
      dateFormat: "m/d/yy",
      mask: "9/9/9999",
      modelValue: formattedDate1.value,
      placeholder: 
			_ctx.modelValue.type === _unref(FilterMatchMode).BETWEEN
				? `${_ctx.name} from`
				: `Search by ${_ctx.name}`
		,
      "onUpdate:modelValue": updateDate1
    }, null, 8, ["modelValue", "placeholder"]),
    (_ctx.modelValue.type === _unref(FilterMatchMode).BETWEEN)
      ? (_openBlock(), _createBlock(_unref(DatePicker), {
          key: 0,
          appendTo: "self",
          class: "p-column-filter",
          dateFormat: "m/d/yy",
          mask: "9/9/9999",
          modelValue: formattedDate2.value,
          placeholder: `${_ctx.name} to`,
          "onUpdate:modelValue": updateDate2
        }, null, 8, ["modelValue", "placeholder"]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})