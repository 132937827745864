import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

import { storeToRefs } from 'pinia';
import InputText from 'primevue/inputtext';
import MultiSelect from 'primevue/multiselect';

import { useUserStore } from '@/entities/user/store';

import { customerTypes, ediStatuses, jobTypes } from '../../config';
import {
	JOB_DATE_COLUMNS,
	JOB_PRICE_COLUMNS
} from '../../config/jobs-table-columns';
import { useJobsStore } from '../../model/store';

import JobDateFilter from './JobDateFilter.vue';
import JobPriceFilter from './JobPriceFilter.vue';
import JobTagTaxFilter from './JobTagTaxFilter.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'JobTableFilters',
  props: {
    id: {},
    name: {},
    modelValue: {},
    type: {}
  },
  emits: ["update:modelValue", "update:type"],
  setup(__props: any, { emit: __emit }) {


const emit = __emit;

const store = useJobsStore();
const userStore = useUserStore();
const { view, jobFiltersData } = storeToRefs(store);

return (_ctx: any,_cache: any) => {
  return (_ctx.id === 'status')
    ? (_openBlock(), _createBlock(_unref(MultiSelect), {
        key: 0,
        appendTo: "self",
        class: "p-column-filter",
        maxSelectedLabels: 2,
        modelValue: _ctx.modelValue,
        optionLabel: "name",
        options: _unref(jobTypes),
        optionValue: "id",
        placeholder: `Search by ${_ctx.name}`,
        showToggleAll: false,
        showClear: "",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = v => emit('update:modelValue', v))
      }, null, 8, ["modelValue", "options", "placeholder"]))
    : (_ctx.id === 'shop.name')
      ? (_openBlock(), _createBlock(_unref(MultiSelect), {
          key: 1,
          appendTo: "self",
          class: "p-column-filter",
          maxSelectedLabels: 1,
          modelValue: _ctx.modelValue,
          optionLabel: "name",
          options: _unref(userStore).user.shops,
          optionValue: "id",
          placeholder: `Search by ${_ctx.name}`,
          showToggleAll: false,
          showClear: "",
          "onUpdate:modelValue": _cache[1] || (_cache[1] = v => emit('update:modelValue', v))
        }, null, 8, ["modelValue", "options", "placeholder"]))
      : (_ctx.id === 'shop_id')
        ? (_openBlock(), _createBlock(_unref(MultiSelect), {
            key: 2,
            appendTo: "self",
            class: "p-column-filter",
            maxSelectedLabels: 3,
            modelValue: _ctx.modelValue,
            optionLabel: "id",
            options: _unref(userStore).user.shops,
            optionValue: "id",
            placeholder: `Search by ${_ctx.name}`,
            showToggleAll: false,
            showClear: "",
            "onUpdate:modelValue": _cache[2] || (_cache[2] = v => emit('update:modelValue', v))
          }, null, 8, ["modelValue", "options", "placeholder"]))
        : (_ctx.id === 'consumer.is_commercial')
          ? (_openBlock(), _createBlock(_unref(MultiSelect), {
              key: 3,
              appendTo: "self",
              class: "p-column-filter",
              maxSelectedLabels: 3,
              modelValue: _ctx.modelValue,
              optionLabel: "name",
              options: _unref(customerTypes),
              optionValue: "id",
              placeholder: `Search by ${_ctx.name}`,
              showToggleAll: false,
              showClear: "",
              "onUpdate:modelValue": _cache[3] || (_cache[3] = v => emit('update:modelValue', v))
            }, null, 8, ["modelValue", "options", "placeholder"]))
          : (_ctx.id === 'ediqueue.status')
            ? (_openBlock(), _createBlock(_unref(MultiSelect), {
                key: 4,
                appendTo: "self",
                class: "p-column-filter",
                maxSelectedLabels: 3,
                modelValue: _ctx.modelValue,
                optionLabel: "name",
                options: _unref(ediStatuses),
                optionValue: "id",
                placeholder: `Search by ${_ctx.name}`,
                showToggleAll: false,
                showClear: "",
                "onUpdate:modelValue": _cache[4] || (_cache[4] = v => emit('update:modelValue', v))
              }, null, 8, ["modelValue", "options", "placeholder"]))
            : (_ctx.id === 'job_csr')
              ? (_openBlock(), _createBlock(_unref(MultiSelect), {
                  key: 5,
                  appendTo: "self",
                  class: "p-column-filter",
                  filter: "",
                  maxSelectedLabels: 3,
                  modelValue: _ctx.modelValue,
                  optionLabel: "name",
                  options: _unref(jobFiltersData).csrs,
                  optionValue: "id",
                  placeholder: `Search by ${_ctx.name}`,
                  showClear: "",
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = v => emit('update:modelValue', v))
                }, null, 8, ["modelValue", "options", "placeholder"]))
              : (_ctx.id === 'job_salesource')
                ? (_openBlock(), _createBlock(_unref(MultiSelect), {
                    key: 6,
                    appendTo: "self",
                    class: "p-column-filter",
                    filter: "",
                    maxSelectedLabels: 3,
                    modelValue: _ctx.modelValue,
                    optionLabel: "name",
                    options: _unref(jobFiltersData).salesources,
                    optionValue: "id",
                    placeholder: `Search by ${_ctx.name}`,
                    showClear: "",
                    "onUpdate:modelValue": _cache[6] || (_cache[6] = v => emit('update:modelValue', v))
                  }, null, 8, ["modelValue", "options", "placeholder"]))
                : (_ctx.id === 'job_salesrep')
                  ? (_openBlock(), _createBlock(_unref(MultiSelect), {
                      key: 7,
                      appendTo: "self",
                      class: "p-column-filter",
                      filter: "",
                      maxSelectedLabels: 3,
                      modelValue: _ctx.modelValue,
                      optionLabel: "name",
                      options: _unref(jobFiltersData).salesreps,
                      optionValue: "id",
                      placeholder: `Search by ${_ctx.name}`,
                      showClear: "",
                      "onUpdate:modelValue": _cache[7] || (_cache[7] = v => emit('update:modelValue', v))
                    }, null, 8, ["modelValue", "options", "placeholder"]))
                  : (_ctx.id === 'job_tech')
                    ? (_openBlock(), _createBlock(_unref(MultiSelect), {
                        key: 8,
                        appendTo: "self",
                        class: "p-column-filter",
                        filter: "",
                        maxSelectedLabels: 3,
                        modelValue: _ctx.modelValue,
                        optionLabel: "name",
                        options: _unref(jobFiltersData).techs,
                        optionValue: "id",
                        placeholder: `Search by ${_ctx.name}`,
                        showClear: "",
                        "onUpdate:modelValue": _cache[8] || (_cache[8] = v => emit('update:modelValue', v))
                      }, null, 8, ["modelValue", "options", "placeholder"]))
                    : (_ctx.id === 'job_location')
                      ? (_openBlock(), _createBlock(_unref(MultiSelect), {
                          key: 9,
                          appendTo: "self",
                          class: "p-column-filter",
                          filter: "",
                          maxSelectedLabels: 3,
                          modelValue: _ctx.modelValue,
                          optionLabel: "name",
                          options: _unref(jobFiltersData).locations,
                          optionValue: "id",
                          placeholder: `Search by ${_ctx.name}`,
                          showClear: "",
                          "onUpdate:modelValue": _cache[9] || (_cache[9] = v => emit('update:modelValue', v))
                        }, null, 8, ["modelValue", "options", "placeholder"]))
                      : (_ctx.id === 'commercialaccount_name')
                        ? (_openBlock(), _createBlock(_unref(MultiSelect), {
                            key: 10,
                            appendTo: "self",
                            class: "p-column-filter",
                            filter: "",
                            maxSelectedLabels: 3,
                            modelValue: _ctx.modelValue,
                            optionLabel: "name",
                            options: _unref(jobFiltersData).commercialaccounts,
                            optionValue: "id",
                            placeholder: `Search by ${_ctx.name}`,
                            showClear: "",
                            "onUpdate:modelValue": _cache[10] || (_cache[10] = v => emit('update:modelValue', v))
                          }, null, 8, ["modelValue", "options", "placeholder"]))
                        : (_ctx.id === 'bill_to.consumer_edi.trading_partner')
                          ? (_openBlock(), _createBlock(_unref(MultiSelect), {
                              key: 11,
                              appendTo: "self",
                              class: "p-column-filter",
                              filter: "",
                              maxSelectedLabels: 3,
                              modelValue: _ctx.modelValue,
                              optionLabel: "name",
                              options: _unref(jobFiltersData).ediTradingPartners,
                              optionValue: "id",
                              placeholder: `Search by ${_ctx.name}`,
                              showClear: "",
                              "onUpdate:modelValue": _cache[11] || (_cache[11] = v => emit('update:modelValue', v))
                            }, null, 8, ["modelValue", "options", "placeholder"]))
                          : (_ctx.id === 'tags')
                            ? (_openBlock(), _createBlock(JobTagTaxFilter, {
                                key: 12,
                                modelValue: { val: _ctx.modelValue, type: _ctx.type as string },
                                multiselectOptions: _unref(jobFiltersData).tags,
                                name: _ctx.name,
                                selectedShopsById: _unref(view).filters['shop_id'].val,
                                selectedShopsByName: _unref(view).filters['shop.name'].val,
                                "onUpdate:modelValue": _cache[12] || (_cache[12] = 
			v => {
				emit('update:modelValue', v.val);
				emit('update:type', v.type);
			}
		)
                              }, null, 8, ["modelValue", "multiselectOptions", "name", "selectedShopsById", "selectedShopsByName"]))
                            : (_ctx.id === 'taxes')
                              ? (_openBlock(), _createBlock(JobTagTaxFilter, {
                                  key: 13,
                                  modelValue: { val: _ctx.modelValue, type: _ctx.type as string },
                                  multiselectOptions: _unref(jobFiltersData).taxes,
                                  name: _ctx.name,
                                  selectedShopsById: _unref(view).filters['shop_id'].val,
                                  selectedShopsByName: _unref(view).filters['shop.name'].val,
                                  "onUpdate:modelValue": _cache[13] || (_cache[13] = 
			v => {
				emit('update:modelValue', v.val);
				emit('update:type', v.type);
			}
		)
                                }, null, 8, ["modelValue", "multiselectOptions", "name", "selectedShopsById", "selectedShopsByName"]))
                              : (_unref(JOB_DATE_COLUMNS).includes(_ctx.id))
                                ? (_openBlock(), _createBlock(JobDateFilter, {
                                    key: 14,
                                    modelValue: { val: _ctx.modelValue, type: _ctx.type as string },
                                    name: _ctx.name,
                                    "onUpdate:modelValue": _cache[14] || (_cache[14] = 
			v => {
				emit('update:modelValue', v.val);
				emit('update:type', v.type);
			}
		)
                                  }, null, 8, ["modelValue", "name"]))
                                : (_unref(JOB_PRICE_COLUMNS).includes(_ctx.id))
                                  ? (_openBlock(), _createBlock(JobPriceFilter, {
                                      key: 15,
                                      modelValue: { val: _ctx.modelValue, type: _ctx.type as string },
                                      name: _ctx.name,
                                      "onUpdate:modelValue": _cache[15] || (_cache[15] = 
			v => {
				emit('update:modelValue', v.val);
				emit('update:type', v.type);
			}
		)
                                    }, null, 8, ["modelValue", "name"]))
                                  : (_openBlock(), _createBlock(_unref(InputText), {
                                      key: 16,
                                      class: "p-column-filter",
                                      modelValue: _ctx.modelValue,
                                      placeholder: `Search by ${_ctx.name}`,
                                      type: "text",
                                      "onUpdate:modelValue": _cache[16] || (_cache[16] = v => emit('update:modelValue', v))
                                    }, null, 8, ["modelValue", "placeholder"]))
}
}

})