<script setup lang="ts">
import Button from 'primevue/button';
import Checkbox from 'primevue/checkbox';
import InputGroup from 'primevue/inputgroup';
import InputGroupAddon from 'primevue/inputgroupaddon';
import InputNumber from 'primevue/inputnumber';
import InputText from 'primevue/inputtext';
import { computed, ref } from 'vue';

import { useCreateAccountStore } from '../../model/createAccount';
import { AccountCreationStepValues, Tax } from '../../model/types';
import StepInputGroup from '../steps/StepInputGroup.vue';

import TaxesSection from './TaxesSection.vue';

const createAccountStore = useCreateAccountStore();

const defaultTaxForm = {
	name: '',
	percentage: 10,
	for_labor: false,
	for_materials: false,
	default_tax: false
};

const form = ref<Tax>({ ...defaultTaxForm });

const isFormValid = computed(() => {
	return (
		form.value.name &&
		form.value.percentage &&
		(form.value.for_labor || form.value.for_materials)
	);
});

const addTax = () => {
	createAccountStore.addShopTax(form.value);
	form.value = { ...defaultTaxForm };
};

const next = () => {
	createAccountStore.changeStep(AccountCreationStepValues.shopIntegrations);
};
</script>

<template>
	<div class="tw3-mb-8 tw3-mr-24 tw3-text-slate-500">
		Create default tax rates to apply to your invoices. You can always do this
		later.
	</div>

	<div class="tw3-grid tw3-grid-cols-1 sm:tw3-grid-cols-4 tw3-gap-4 tw3-mb-2">
		<StepInputGroup
			class="tw3-col-span-3"
			label="Tax Jurisdiction"
			subLabel="Name"
		>
			<InputText v-model="form.name" fluid placeholder="Name" />
		</StepInputGroup>

		<StepInputGroup label="Rate">
			<InputGroup>
				<InputNumber
					v-model="form.percentage"
					fluid
					:maxFractionDigits="2"
					placeholder="Rate"
				/>
				<InputGroupAddon>%</InputGroupAddon>
			</InputGroup>
		</StepInputGroup>

		<div class="tw3-flex tw3-items-center tw3-gap-2">
			<Checkbox v-model="form.for_labor" binary inputId="for_labor" />
			<label
				class="!tw3-font-bold !tw3-text-base !tw3-text-slate-700"
				for="for_labor"
			>
				Labor
			</label>
		</div>

		<div class="tw3-flex tw3-items-center tw3-gap-2">
			<Checkbox v-model="form.for_materials" binary inputId="for_materials" />
			<label
				class="!tw3-font-bold !tw3-text-base !tw3-text-slate-700"
				for="for_materials"
			>
				Material
			</label>
		</div>

		<div class="tw3-flex tw3-items-center tw3-gap-2">
			<Checkbox v-model="form.default_tax" binary inputId="default_tax" />
			<label
				class="!tw3-font-bold !tw3-text-base !tw3-text-slate-700"
				for="default_tax"
			>
				Default
			</label>
		</div>

		<Button
			:disabled="!isFormValid"
			label="Add Tax"
			severity="info"
			size="small"
			@click="addTax"
		/>
	</div>

	<TaxesSection />

	<div class="tw3-flex tw3-pt-8 tw3-space-x-4">
		<Button
			label="Back"
			severity="secondary"
			size="small"
			@click="createAccountStore.changeStep(AccountCreationStepValues.shopLogo)"
		/>
		<Button label="Next" size="small" @click="next" />
	</div>
</template>
