import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "tw3-flex tw3-items-center tw3-gap-2"
}
const _hoisted_2 = { class: "tw3-flex tw3-items-center tw3-gap-2 tw3-capitalize" }
const _hoisted_3 = { style: {"font-size":"0.7rem","white-space":"nowrap"} }
const _hoisted_4 = { class: "tw3-flex tw3-flex-wrap tw3-items-center tw3-gap-2" }
const _hoisted_5 = { class: "tw3-flex tw3-items-center tw3-gap-2 tw3-capitalize" }
const _hoisted_6 = { style: {"font-size":"0.7rem","white-space":"nowrap"} }

import Tag from 'primevue/tag';

import JobPopoverCell from './layout/JobPopoverCell.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'JobTagsCell',
  props: {
    data: {},
    name: {}
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_ctx.data && _ctx.data[0])
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_unref(Tag), {
          severity: "secondary",
          style: _normalizeStyle({
				'border-left': _ctx.data[0].color ? '10px solid black' : '',
				'border-color': _ctx.data[0].color ?? 'var(--p-tag-secondary-background)'
			})
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.data[0].text), 1)
            ])
          ]),
          _: 1
        }, 8, ["style"]),
        (_ctx.data.length > 1)
          ? (_openBlock(), _createBlock(JobPopoverCell, {
              key: 0,
              label: `+${_ctx.data.length - 1}`,
              name: _ctx.name
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_4, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data, (tag, index) => {
                    return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
                      (index !== 0)
                        ? (_openBlock(), _createBlock(_unref(Tag), {
                            key: 0,
                            severity: "secondary",
                            style: _normalizeStyle({
							'border-left': tag.color ? '10px solid black' : '',
							'border-color': tag.color ?? 'var(--p-tag-secondary-background)'
						})
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", _hoisted_5, [
                                _createElementVNode("span", _hoisted_6, _toDisplayString(tag.text), 1)
                              ])
                            ]),
                            _: 2
                          }, 1032, ["style"]))
                        : _createCommentVNode("", true)
                    ], 64))
                  }), 128))
                ])
              ]),
              _: 1
            }, 8, ["label", "name"]))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}
}

})