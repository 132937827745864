import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "customer-summary-report" }
const _hoisted_2 = { class: "table-head" }
const _hoisted_3 = { class: "customer" }
const _hoisted_4 = { class: "customer-details-head" }
const _hoisted_5 = { class: "job-totals right-cell" }
const _hoisted_6 = { class: "customer-name" }
const _hoisted_7 = { class: "customer-details" }
const _hoisted_8 = { class: "right-cell" }
const _hoisted_9 = { class: "right-cell" }
const _hoisted_10 = { class: "right-cell" }
const _hoisted_11 = { class: "total-key" }
const _hoisted_12 = { class: "right-cell" }

import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import { reportsGetters } from '@/widgets/reportsElements/model/utils/getters';


export default /*@__PURE__*/_defineComponent({
  __name: 'CustomerSummaryTable',
  props: {
    data: {}
  },
  setup(__props: any) {

const { t } = useI18n();

const props = __props;

const consumerDetails = (consumer: {
	g_address: {
		full_address: string;
	};
	phone: {
		number: string;
	};
}) => {
	let detailsString = '';
	if (consumer.g_address && consumer.g_address.full_address) {
		detailsString += `${consumer.g_address.full_address} - `;
	}
	if (consumer.phone) {
		detailsString += `${consumer.phone.number}`;
	}
	return detailsString;
};

const rangeTotal = computed(() => {
	let total = 0;
	if (props.data) {
		for (let i = 0; i < props.data.length; i++) {
			total += props.data[i].totals_sum;
		}
	}
	return total;
});

const rangeJobs = computed(() => {
	let jobs = 0;
	if (props.data) {
		for (let i = 0; i < props.data.length; i++) {
			jobs += props.data[i].jobs_count;
		}
	}
	return jobs;
});

const consumerDistribution = (rowTotal: number) => {
	const dist = ((rowTotal / rangeTotal.value) * 100).toFixed(1);
	return `${dist}%`;
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("table", null, [
      _createElementVNode("thead", null, [
        _createElementVNode("tr", _hoisted_2, [
          _createElementVNode("th", _hoisted_3, _toDisplayString(_unref(t)('reportsPage.customer')), 1),
          _createElementVNode("th", _hoisted_4, _toDisplayString(_unref(t)('details')), 1),
          _createElementVNode("th", _hoisted_5, _toDisplayString(_unref(t)('job')) + " " + _toDisplayString(_unref(t)('reportsPage.totals')), 1),
          _cache[0] || (_cache[0] = _createElementVNode("th", { class: "dist right-cell" }, [
            _createElementVNode("span", null, "Dist. %")
          ], -1))
        ])
      ]),
      _createElementVNode("tbody", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data, (row, key) => {
          return (_openBlock(), _createElementBlock("tr", { key: key }, [
            _createElementVNode("td", null, [
              _createElementVNode("span", _hoisted_6, _toDisplayString(row.consumer.company_name), 1)
            ]),
            _createElementVNode("td", null, [
              _createElementVNode("span", _hoisted_7, _toDisplayString(consumerDetails(row.consumer)), 1)
            ]),
            _createElementVNode("td", _hoisted_8, " (" + _toDisplayString(row.jobs_count) + ") " + _toDisplayString(_unref(reportsGetters).formatNumber(row.totals_sum, {
								style: 'dollar'
							})), 1),
            _createElementVNode("td", _hoisted_9, _toDisplayString(consumerDistribution(row.totals_sum)), 1)
          ]))
        }), 128)),
        _createElementVNode("tr", null, [
          _cache[1] || (_cache[1] = _createElementVNode("td", null, null, -1)),
          _createElementVNode("td", _hoisted_10, [
            _createElementVNode("span", _hoisted_11, _toDisplayString(_unref(t)('reportsPage.total')) + ":", 1)
          ]),
          _createElementVNode("td", _hoisted_12, " (" + _toDisplayString(rangeJobs.value) + ") " + _toDisplayString(_unref(reportsGetters).formatNumber(rangeTotal.value, {
								style: 'dollar'
							})), 1),
          _cache[2] || (_cache[2] = _createElementVNode("td", null, null, -1))
        ])
      ])
    ])
  ]))
}
}

})