import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "tw3-relative tw3-flex tw3-items-start tw3-space-x-3 tw3-p-3 tw3-rounded-lg tw3-shadow-md tw3-bg-white tw3-group" }
const _hoisted_2 = { class: "tw3-flex tw3-flex-col tw3-space-y-1 tw3-w-full" }
const _hoisted_3 = { class: "tw3-flex tw3-flex-col tw3-space-y-1 tw3-w-full" }
const _hoisted_4 = { class: "tw3-flex tw3-items-center tw3-space-x-2" }
const _hoisted_5 = { class: "tw3-text-sm tw3-text-gray-500 tw3-whitespace-nowrap" }
const _hoisted_6 = { class: "tw3-text-gray-700 tw3-text-lg tw3-break-words" }

import Button from 'primevue/button';

import { User } from '@/entities/user/lib/types';

import { JobNote } from '../../model/types/note';


export default /*@__PURE__*/_defineComponent({
  __name: 'JobNoteCell',
  props: {
    note: {},
    user: {},
    showDelete: { type: Boolean }
  },
  emits: ["delete"],
  setup(__props: any, { emit: __emit }) {


const emit = __emit;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("b", {
            class: "tw3-font-semibold tw3-truncate",
            style: _normalizeStyle({ color: _ctx.user?.color || '#333' })
          }, _toDisplayString(_ctx.user?.username || 'Unknown'), 5),
          _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.note.note_date), 1)
        ]),
        _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.note.note_text), 1)
      ])
    ]),
    (_ctx.showDelete)
      ? (_openBlock(), _createBlock(_unref(Button), {
          key: 0,
          "aria-label": "Delete",
          class: "!tw3-absolute tw3-top-[-0.25rem] tw3-right-[-0.25rem] sm:tw3-opacity-0 group-hover:tw3-opacity-100 tw3-transition tw3-duration-400",
          icon: "pi pi-times",
          iconClass: "!tw3-text-[0.8rem]",
          raised: "",
          rounded: "",
          severity: "danger",
          small: "",
          style: {"height":"1.25rem","width":"1.25rem"},
          text: "",
          type: "button",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (emit('delete')))
        }))
      : _createCommentVNode("", true)
  ]))
}
}

})