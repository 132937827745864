import { coreApi } from '@/shared/api';
import {
	DashboardAvgWeightData,
	DashboardLifetimeData,
	DashboardLifetimeObjectNumber,
	DashboardLifetimeObjectString,
	FilteredRequestParams
} from '@/widgets/dashboard/model/types';

import {
	FollowUpJobs,
	PurchaseMethodJobs,
	TechEntry,
	TechRequestParams,
	TechResponse
} from '../../dashboard/model/types';

const DASHBOARD_JOB_URL = '/unum/dashboard/job';

export const getFilteredCompleted = async (params: FilteredRequestParams) => {
	try {
		const response = await coreApi().post<{ items: DashboardLifetimeData[] }>(
			`${DASHBOARD_JOB_URL}/filtered-completed`,
			params
		);

		return response.data.items;
	} catch (error) {
		console.error(error);
		throw error;
	}
};

export const getFilteredWarrantyRate = async (
	params: FilteredRequestParams
) => {
	try {
		const response = await coreApi().post<{ items: DashboardAvgWeightData[] }>(
			`${DASHBOARD_JOB_URL}/filtered-warranty-rate`,
			params
		);

		return response.data.items;
	} catch (error) {
		console.error(error);
		throw error;
	}
};

export const getFilteredCommission = async (params: FilteredRequestParams) => {
	try {
		const response = await coreApi().post<{ items: DashboardLifetimeData[] }>(
			`${DASHBOARD_JOB_URL}/filtered-commission`,
			params
		);

		return response.data.items;
	} catch (error) {
		console.error(error);
		throw error;
	}
};

export const getFilteredCostRatio = async (params: FilteredRequestParams) => {
	try {
		const response = await coreApi().post<{ items: DashboardAvgWeightData[] }>(
			`${DASHBOARD_JOB_URL}/filtered-cost-ratio`,
			params
		);

		return response.data.items;
	} catch (error) {
		console.error(error);
		throw error;
	}
};

export const getTotalAndCompletedNumber = async () => {
	try {
		const response = await coreApi().get<DashboardLifetimeObjectNumber>(
			`${DASHBOARD_JOB_URL}/total-and-completed-number`
		);

		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
};

export const getAverageAndWarrantyRate = async () => {
	try {
		const response = await coreApi().get<DashboardLifetimeObjectNumber>(
			`${DASHBOARD_JOB_URL}/average-and-warranty-rate`
		);

		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
};

export const getTotalAndCommissionAmount = async () => {
	try {
		const response = await coreApi().get<DashboardLifetimeObjectString>(
			`${DASHBOARD_JOB_URL}/total-and-commission-amount`
		);

		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
};

export const getAverageAndCostRatio = async () => {
	try {
		const response = await coreApi().get<DashboardLifetimeObjectString>(
			`${DASHBOARD_JOB_URL}/average-and-cost-ratio`
		);

		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
};

export const getFollowUpJobs = async () => {
	try {
		const response = await coreApi().get<FollowUpJobs>(
			`${DASHBOARD_JOB_URL}/follow-up`
		);

		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
};

export const getPurchaseMethodJobs = async () => {
	try {
		const response = await coreApi().get<PurchaseMethodJobs>(
			`${DASHBOARD_JOB_URL}/by-install-context`
		);

		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
};

export const getTechs = async (
	params: TechRequestParams
): Promise<TechResponse<TechEntry>> => {
	try {
		const { data } = await coreApi().post<TechResponse<TechEntry>>(
			`${DASHBOARD_JOB_URL}/techs`,
			params
		);

		return { ...data, totalCount: Number(data.totalCount) };
	} catch (error) {
		console.error(error);
		throw error;
	}
};
