import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "tw3-relative tw3-mb-6 -tw3-space-y-px" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "tw3-flex-grow tw3-flex tw3-items-center tw3-gap-4" }
const _hoisted_4 = { class: "tw3-font-medium" }
const _hoisted_5 = { class: "tw3-font-medium" }
const _hoisted_6 = { class: "tw3-mb-6" }
const _hoisted_7 = { class: "tw3-mb-6 tw3-border tw3-border-gray-200 tw3-rounded-lg tw3-divide-y tw3-divide-gray-200" }
const _hoisted_8 = { class: "tw3-p-4" }
const _hoisted_9 = ["innerHTML"]
const _hoisted_10 = { class: "tw3-flex tw3-items-center tw3-gap-3 tw3-p-4" }
const _hoisted_11 = { class: "tw3-pt-6 tw3-space-x-4" }

import Button from 'primevue/button';
import Checkbox from 'primevue/checkbox';
import Message from 'primevue/message';
import RadioButton from 'primevue/radiobutton';
import ScrollPanel from 'primevue/scrollpanel';
import { onMounted, ref, watch } from 'vue';

import { getPlans, Plan } from '../../api';
import { TNC } from '../../constants/tnc';
import { useCreateAccountStore } from '../../model/createAccount';
import {
	AccountCreationSteps,
	AccountCreationStepValues
} from '../../model/types';


export default /*@__PURE__*/_defineComponent({
  __name: 'BillingPlanStep',
  setup(__props) {

const store = useCreateAccountStore();

const plans = ref<Plan[]>([]);

const selectedPlanCode = ref('');
const termsAccepted = ref(false);

onMounted(() => init());

watch(
	() => store.step,
	async val => {
		if (val === AccountCreationStepValues.billingPlan) {
			await init();
		} else {
			termsAccepted.value = false;
		}
	}
);

watch(
	selectedPlanCode,
	val => {
		if (val) {
			const plan = plans.value.find(
				({ plan_code }) => plan_code === selectedPlanCode.value
			);
			if (plan) {
				store.form[AccountCreationSteps.billingPlan] = { plan };
			}
		}
	},
	{ deep: true }
);

const init = async () => {
	plans.value = await getPlans();
	if (store.form[AccountCreationSteps.billingPlan]) {
		selectedPlanCode.value =
			store.form[AccountCreationSteps.billingPlan].plan.plan_code;
	}
};
const next = () => {
	store.changeStep(AccountCreationStepValues.billingFinancing);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[5] || (_cache[5] = _createElementVNode("div", { class: "tw3-mb-6 tw3-text-slate-500" }, " Select a subscription plan based on your shop's requirements. ", -1)),
    _cache[6] || (_cache[6] = _createElementVNode("div", { class: "tw3-text-xl tw3-font-semibold tw3-mb-6" }, " First 30 days completely FREE! ", -1)),
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(plans.value, (i) => {
        return (_openBlock(), _createElementBlock("div", {
          key: i.plan_id,
          class: _normalizeClass(["tw3-relative tw3-flex tw3-items-center tw3-p-6 tw3-cursor-pointer tw3-border tw3-border-gray-200 first:tw3-rounded-t-lg last:tw3-rounded-b-lg", {
				'tw3-border-green-200 tw3-z-10 tw3-bg-green-50':
					selectedPlanCode.value === i.plan_code
			}]),
          onClick: ($event: any) => (selectedPlanCode.value = i.plan_code)
        }, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_unref(RadioButton), {
              modelValue: selectedPlanCode.value,
              value: i.plan_code
            }, null, 8, ["modelValue", "value"]),
            _createElementVNode("div", _hoisted_4, _toDisplayString(i.description), 1)
          ]),
          _createElementVNode("div", _hoisted_5, "$" + _toDisplayString(i.recurring_price) + "/mo *", 1)
        ], 10, _hoisted_2))
      }), 128))
    ]),
    _cache[7] || (_cache[7] = _createElementVNode("div", { class: "tw3-text-orange-400 tw3-mb-6" }, " * Plus $1 per Lynx Services EDI sent ", -1)),
    _createElementVNode("div", _hoisted_6, [
      _createVNode(_unref(Message), {
        icon: "pi pi-info-circle",
        severity: "info"
      }, {
        default: _withCtx(() => _cache[2] || (_cache[2] = [
          _createTextVNode(" NAGS© and GlassMate© are registered trademarks owned by Mitchell International, Inc. The NAGS Data and All Related OEM and Aftermarket Information are provided under License from Mitchell International, Inc. ")
        ])),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_7, [
      _cache[4] || (_cache[4] = _createElementVNode("div", { class: "tw3-p-4 tw3-font-medium" }, " TERMS OF USE OF GLASSBILLER SERVICES ", -1)),
      _createElementVNode("div", _hoisted_8, [
        _createVNode(_unref(ScrollPanel), { class: "tw3-h-48 tw3-w-full" }, {
          default: _withCtx(() => [
            _createElementVNode("div", { innerHTML: _unref(TNC) }, null, 8, _hoisted_9)
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_10, [
        _createVNode(_unref(Checkbox), {
          modelValue: termsAccepted.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((termsAccepted).value = $event)),
          binary: "",
          inputId: "terms",
          size: "small"
        }, null, 8, ["modelValue"]),
        _cache[3] || (_cache[3] = _createElementVNode("label", { for: "terms" }, "Accept Terms of Use", -1))
      ])
    ]),
    _createElementVNode("div", _hoisted_11, [
      _createVNode(_unref(Button), {
        label: "Back",
        severity: "secondary",
        size: "small",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_unref(store).changeStep(_unref(AccountCreationStepValues).shopIntegrations)))
      }),
      _createVNode(_unref(Button), {
        disabled: !selectedPlanCode.value || !termsAccepted.value,
        label: "Next",
        size: "small",
        onClick: next
      }, null, 8, ["disabled"])
    ])
  ], 64))
}
}

})