import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "tw3-w-full tw3-flex tw3-flex-col tw3-col-span-1" }
const _hoisted_2 = { class: "tw3-content-end tw3-text-slate-500 tw3-font-medium tw3-text-sm" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "tw3-w-full tw3-flex tw3-flex-col tw3-col-span-1" }
const _hoisted_5 = { class: "tw3-content-end tw3-text-slate-500 tw3-font-medium tw3-text-sm" }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { class: "tw3-w-full tw3-flex tw3-flex-col tw3-col-span-1" }
const _hoisted_8 = { class: "tw3-content-end tw3-text-slate-500 tw3-font-medium tw3-text-sm" }
const _hoisted_9 = { class: "tw3-w-full tw3-flex tw3-flex-col tw3-col-span-1" }
const _hoisted_10 = { class: "tw3-content-end tw3-text-slate-500 tw3-font-medium tw3-text-sm" }
const _hoisted_11 = { class: "tw3-w-full tw3-flex tw3-flex-col tw3-col-span-1" }
const _hoisted_12 = { class: "tw3-content-end tw3-text-slate-500 tw3-font-medium tw3-text-sm" }

import { storeToRefs } from 'pinia';
import Button from 'primevue/button';
import { computed, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';

import { useWindowSizes } from '@/shared/composables';
import { capitalizeStr } from '@/shared/helpers';
import useReportsStore from '@/widgets/reportsElements/model/store';

import { allFiltersTypes } from '../../model/constants';

import ReportsSelectFilter from './ReportsSelectFilter.vue';

interface Organization {
	id: number;
	name: string;
	salesources?: Option[];
	csrs?: Option[];
	salesreps?: Option[];
	techs?: Option[];
	locations?: Option[];
}

interface Option {
	id: number;
	name: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'ReportsContractorFilter',
  setup(__props) {

const { t } = useI18n();

const reportsStore = useReportsStore();
const {
	contractorFilterTypes,
	contractorFilterOptions,
	contractorFilterValue,
	contractorFilterOptionsLoading,
	additionalFilterIsVisible,
	currentReportType
} = storeToRefs(reportsStore);

const { isPc } = useWindowSizes();

const selectedCsrs = ref<number[]>([]);
const selectedSalesources = ref<number[]>([]);
const selectedSalesreps = ref<number[]>([]);
const selectedTechs = ref<number[]>([]);
const selectedLocations = ref<number[]>([]);

const groupOptionsByOrganization = (key: keyof Organization) => {
	const groupedOptions: { label: string; items: Option[] }[] = [];
	contractorFilterOptions.value.forEach(shop => {
		const organization = shop.organization;
		const options = organization ? organization[key] : false;
		if (options && Array.isArray(options)) {
			groupedOptions.push({
				label: organization?.name || '',
				items: options
			});
		}
	});
	return groupedOptions;
};

const csrOptions = computed(() => groupOptionsByOrganization('csrs'));
const salesourcesOptions = computed(() =>
	groupOptionsByOrganization('salesources')
);
const salesrepsOptions = computed(() =>
	groupOptionsByOrganization('salesreps')
);
const techsOptions = computed(() => groupOptionsByOrganization('techs'));
const locationsOptions = computed(() =>
	groupOptionsByOrganization('locations')
);

const someFiltersIsActive = computed(() => {
	return (
		selectedCsrs.value.length !== 0 ||
		selectedSalesources.value.length !== 0 ||
		selectedSalesreps.value.length !== 0 ||
		selectedTechs.value.length !== 0 ||
		selectedLocations.value.length !== 0
	);
});

const activeFiltersList = computed<string[]>(() => {
	const preparedArr = [];
	if (selectedCsrs.value.length !== 0) {
		preparedArr.push('csrs');
	}
	if (selectedSalesources.value.length !== 0) {
		preparedArr.push('salesources');
	}
	if (selectedSalesreps.value.length !== 0) {
		preparedArr.push('salesreps');
	}
	if (selectedTechs.value.length !== 0) {
		preparedArr.push('techs');
	}
	if (selectedLocations.value.length !== 0) {
		preparedArr.push('locations');
	}
	return [];
});

watch(activeFiltersList, () => {
	contractorFilterTypes.value = activeFiltersList.value;
});

const combinedFilter = computed(() => {
	let preparedObj: { [key: string]: (number | string)[] } = {
		csrs: [],
		salesources: [],
		salesreps: [],
		techs: [],
		locations: []
	};
	if (someFiltersIsActive.value) {
		preparedObj = {
			csrs: selectedCsrs.value.length ? selectedCsrs.value : [],
			salesources: selectedSalesources.value.length
				? selectedSalesources.value
				: [],
			salesreps: selectedSalesreps.value.length ? selectedSalesreps.value : [],
			techs: selectedTechs.value.length ? selectedTechs.value : [],
			locations: selectedLocations.value.length ? selectedLocations.value : []
		};
	}
	return preparedObj;
});

const setDefaultFiltersValues = () => {
	selectedSalesources.value = [];
	selectedSalesreps.value = [];
	selectedTechs.value = [];
	selectedLocations.value = [];
	if (!reportsStore.selectedShop || !reportsStore.selectedShop.length) {
		selectedCsrs.value = [];
	}
};

const refreshOptions = () => {
	if (!reportsStore.contractorFilterOptions.length) {
		reportsStore.contractorFilterOptionsHandler.execute(
			0,
			additionalFilterIsVisible.value ? allFiltersTypes : ['csrs']
		);
	} else if (!salesourcesOptions.value.length) {
		reportsStore.contractorFilterOptionsHandler.execute(0, allFiltersTypes);
	}
	if (!additionalFilterIsVisible.value) {
		setDefaultFiltersValues();
	}
};

watch(
	() => reportsStore.selectedShop,
	() => {
		if (!reportsStore.selectedShop || !reportsStore.selectedShop.length) {
			setDefaultFiltersValues();
		}
	}
);

watch(contractorFilterValue, () => {
	if (contractorFilterValue.value.length === 0) {
		setDefaultFiltersValues();
	}
});

watch(combinedFilter, () => {
	contractorFilterValue.value = combinedFilter.value;
});

watch(additionalFilterIsVisible, refreshOptions);

return (_ctx: any,_cache: any) => {
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_unref(Button), {
      class: _normalizeClass({
			'tw3-w-full': !_unref(isPc),
			'tw3-w-fit !tw3-absolute tw3-m-auto tw3-bottom-[-27px] tw3-left-0 tw3-right-0 !tw3-bg-white !tw3-text-[0.75rem]':
				_unref(isPc)
		}),
      icon: _unref(additionalFilterIsVisible) ? 'pi pi-angle-up' : 'pi pi-angle-down',
      iconPos: "right",
      label: "Additional Filters",
      outlined: "",
      severity: "secondary",
      size: "small",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (additionalFilterIsVisible.value = !_unref(additionalFilterIsVisible)))
    }, null, 8, ["class", "icon"]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("p", _hoisted_2, _toDisplayString(_unref(capitalizeStr)(_unref(t)('reportsPage.filters.filterBy.csrs'))), 1),
      _withDirectives(_createVNode(ReportsSelectFilter, {
        modelValue: selectedCsrs.value,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((selectedCsrs).value = $event)),
        disabled: 
				!_unref(reportsStore).selectedShop || !_unref(reportsStore).selectedShop.length
			,
        dropdownOptions: csrOptions.value,
        filter: "",
        loading: _unref(contractorFilterOptionsLoading),
        maxSelectedLabels: 1,
        optionGroupChildren: "items",
        optionGroupLabel: "label",
        optionLabel: "name",
        optionValue: "id",
        placeholder: `${_unref(t)('select')} ${_unref(t)('reportsPage.filters.csrs')}...`,
        onBeforeShow: refreshOptions
      }, null, 8, ["modelValue", "disabled", "dropdownOptions", "loading", "placeholder"]), [
        [
          _directive_tooltip,
          
				!_unref(reportsStore).selectedShop || !_unref(reportsStore).selectedShop.length
					? 'Shop is required'
					: null
			,
          void 0,
          { top: true }
        ]
      ])
    ]),
    (_unref(additionalFilterIsVisible))
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          (
				_unref(currentReportType).id !== 'receipt' &&
				_unref(currentReportType).id !== 'margin' &&
				_unref(isPc)
			)
            ? (_openBlock(), _createElementBlock("span", _hoisted_3))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("p", _hoisted_5, _toDisplayString(_unref(capitalizeStr)(_unref(t)('reportsPage.filters.filterBy.salesources'))), 1),
            _withDirectives(_createVNode(ReportsSelectFilter, {
              modelValue: selectedSalesources.value,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((selectedSalesources).value = $event)),
              dataKey: "id",
              disabled: 
					!_unref(reportsStore).selectedShop || !_unref(reportsStore).selectedShop.length
				,
              dropdownOptions: salesourcesOptions.value,
              filter: "",
              loading: _unref(contractorFilterOptionsLoading),
              maxSelectedLabels: 1,
              optionGroupChildren: "items",
              optionGroupLabel: "label",
              optionLabel: "name",
              optionValue: "id",
              placeholder: `${_unref(t)('select')} ${_unref(t)('reportsPage.filters.salesources').toLocaleLowerCase()}...`
            }, null, 8, ["modelValue", "disabled", "dropdownOptions", "loading", "placeholder"]), [
              [
                _directive_tooltip,
                
					!_unref(reportsStore).selectedShop || !_unref(reportsStore).selectedShop.length
						? 'Shop is required'
						: null
				,
                void 0,
                { top: true }
              ]
            ])
          ]),
          (_unref(currentReportType).id == 'receipt' && _unref(isPc))
            ? (_openBlock(), _createElementBlock("span", _hoisted_6))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("p", _hoisted_8, _toDisplayString(_unref(capitalizeStr)(_unref(t)('reportsPage.filters.filterBy.salesreps'))), 1),
            _withDirectives(_createVNode(ReportsSelectFilter, {
              modelValue: selectedSalesreps.value,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((selectedSalesreps).value = $event)),
              dataKey: "id",
              disabled: 
					!_unref(reportsStore).selectedShop || !_unref(reportsStore).selectedShop.length
				,
              dropdownOptions: salesrepsOptions.value,
              filter: "",
              loading: _unref(contractorFilterOptionsLoading),
              maxSelectedLabels: 1,
              optionGroupChildren: "items",
              optionGroupLabel: "label",
              optionLabel: "name",
              optionValue: "id",
              placeholder: `${_unref(t)('select')} ${_unref(t)('reportsPage.filters.salesreps').toLocaleLowerCase()}...`
            }, null, 8, ["modelValue", "disabled", "dropdownOptions", "loading", "placeholder"]), [
              [
                _directive_tooltip,
                
					!_unref(reportsStore).selectedShop || !_unref(reportsStore).selectedShop.length
						? 'Shop is required'
						: null
				,
                void 0,
                { top: true }
              ]
            ])
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("p", _hoisted_10, _toDisplayString(_unref(capitalizeStr)(_unref(t)('reportsPage.filters.filterBy.techs'))), 1),
            _withDirectives(_createVNode(ReportsSelectFilter, {
              modelValue: selectedTechs.value,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((selectedTechs).value = $event)),
              dataKey: "id",
              disabled: 
					!_unref(reportsStore).selectedShop || !_unref(reportsStore).selectedShop.length
				,
              dropdownOptions: techsOptions.value,
              filter: "",
              loading: _unref(contractorFilterOptionsLoading),
              maxSelectedLabels: 1,
              optionGroupChildren: "items",
              optionGroupLabel: "label",
              optionLabel: "name",
              optionValue: "id",
              placeholder: `${_unref(t)('select')} ${_unref(t)('reportsPage.filters.techs').toLocaleLowerCase()}...`
            }, null, 8, ["modelValue", "disabled", "dropdownOptions", "loading", "placeholder"]), [
              [
                _directive_tooltip,
                
					!_unref(reportsStore).selectedShop || !_unref(reportsStore).selectedShop.length
						? 'Shop is required'
						: null
				,
                void 0,
                { top: true }
              ]
            ])
          ]),
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("p", _hoisted_12, _toDisplayString(_unref(capitalizeStr)(_unref(t)('reportsPage.filters.filterBy.locations'))), 1),
            _withDirectives(_createVNode(ReportsSelectFilter, {
              modelValue: selectedLocations.value,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((selectedLocations).value = $event)),
              dataKey: "id",
              disabled: 
					!_unref(reportsStore).selectedShop || !_unref(reportsStore).selectedShop.length
				,
              dropdownOptions: locationsOptions.value,
              filter: "",
              loading: _unref(contractorFilterOptionsLoading),
              maxSelectedLabels: 1,
              optionGroupChildren: "items",
              optionGroupLabel: "label",
              optionLabel: "name",
              optionValue: "id",
              placeholder: `${_unref(t)('select')} ${_unref(t)('reportsPage.filters.locations').toLocaleLowerCase()}...`
            }, null, 8, ["modelValue", "disabled", "dropdownOptions", "loading", "placeholder"]), [
              [
                _directive_tooltip,
                
					!_unref(reportsStore).selectedShop || !_unref(reportsStore).selectedShop.length
						? 'Shop is required'
						: null
				,
                void 0,
                { top: true }
              ]
            ])
          ])
        ], 64))
      : _createCommentVNode("", true)
  ], 64))
}
}

})