import { useStorage } from '@vueuse/core';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

import { createAccount } from '../api';

import {
	AccountCreationForm,
	AccountCreationSteps,
	AccountCreationStepValues,
	Tax
} from './types';

export const useCreateAccountStore = defineStore('createAccount', () => {
	const isSubmitting = ref(false);
	const error = ref<unknown>(null);

	const step = useStorage(
		'createAccountStep',
		AccountCreationStepValues.organization
	);

	const form = useStorage<AccountCreationForm>(
		'createAccountForm',
		{} as AccountCreationForm
	);

	const hasTaxes = computed(
		() => form.value[AccountCreationSteps.shopTaxes]?.tax.length > 0
	);

	const changeStep = (s: AccountCreationStepValues) => {
		step.value = s;
	};

	const addShopTax = (tax: Tax) => {
		const existing = form.value[AccountCreationSteps.shopTaxes]?.tax || [];
		form.value[AccountCreationSteps.shopTaxes] = {
			enabled: true,
			tax: [...existing, tax]
		};
	};

	const removeShopTax = (index: number) => {
		const existing = form.value[AccountCreationSteps.shopTaxes]?.tax || [];
		form.value[AccountCreationSteps.shopTaxes].tax = existing.filter(
			(_, i) => i !== index
		);
	};

	const reset = () => {
		step.value = AccountCreationStepValues.organization;
		form.value = {} as AccountCreationForm;
		error.value = null;
		isSubmitting.value = false;
	};

	const submit = async () => {
		isSubmitting.value = true;
		try {
			await createAccount(form.value);
			reset();
		} catch (e) {
			error.value = e;
		} finally {
			isSubmitting.value = false;
		}
	};

	return {
		isSubmitting,
		error,
		step,
		form,
		hasTaxes,
		changeStep,
		addShopTax,
		removeShopTax,
		reset,
		submit
	};
});
