import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, isRef as _isRef, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "tw3-flex tw3-items-center tw3-justify-between tw3-gap-4 tw3-flex-col lg:tw3-flex-row" }
const _hoisted_2 = { class: "tw3-flex tw3-items-center tw3-gap-4 tw3-flex-col sm:tw3-flex-row tw3-w-full lg:tw3-w-auto" }
const _hoisted_3 = { class: "tw3-flex tw3-items-center tw3-gap-4 tw3-w-full sm:tw3-w-auto" }
const _hoisted_4 = {
  key: 0,
  class: "tw3-flex tw3-justify-center tw3-w-full sm:tw3-w-auto"
}
const _hoisted_5 = { class: "tw3-relative tw3-w-full sm:tw3-w-auto" }
const _hoisted_6 = { class: "tw3-absolute tw3-text-xs tw3-left-2 tw3-bg-white tw3-top-[-7px] tw3-px-[0.25rem] tw3-rounded" }
const _hoisted_7 = { class: "tw3-w-full tw3-flex tw3-items-center tw3-justify-between tw3-gap-4" }
const _hoisted_8 = { class: "tw3-flex tw3-items-center tw3-gap-4 tw3-w-full lg:tw3-w-auto" }
const _hoisted_9 = { class: "tw3-relative tw3-w-full lg:tw3-w-56" }
const _hoisted_10 = { class: "tw3-flex tw3-justify-between tw3-items-center tw3-gap-2 tw3-w-full" }
const _hoisted_11 = { class: "tw3-max-w-36 tw3-truncate" }
const _hoisted_12 = {
  key: 0,
  class: "tw3-rounded-2xl tw3-p-1 tw3-bg-gray-200 tw3-text-xs",
  style: {"color":"var(--p-select-option-color)"}
}
const _hoisted_13 = { class: "tw3-absolute tw3-text-xs tw3-left-2 tw3-bg-white tw3-top-[-7px] tw3-px-[0.25rem] tw3-rounded" }

import { storeToRefs } from 'pinia';
import Button from 'primevue/button';
import Listbox from 'primevue/listbox';
import MultiSelect from 'primevue/multiselect';
import Popover from 'primevue/popover';
import Select from 'primevue/select';
import { useConfirm } from 'primevue/useconfirm';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';

import { useJobEditCellStore, useJobsStore } from '../model/store';


export default /*@__PURE__*/_defineComponent({
  __name: 'JobsListHeader',
  setup(__props) {

const { t } = useI18n();
const store = useJobsStore();
const editStore = useJobEditCellStore();
const {
	viewOptions,
	view,
	selectedFilters,
	selectedFiltersLocal,
	loading,
	loadingMore,
	loadingFiltersData,
	updateViewApiLoading,
	updateViewLoading,
	exportingLoading
} = storeToRefs(store);

const confirm = useConfirm();
const popupFilters = ref<any>();

const toggleFilter = (event: MouseEvent) => {
	popupFilters.value.toggle(event);
};

const exportButtonPressed = async () => {
	confirm.require({
		accept: async () => {
			await store.exportData();
		},
		acceptLabel: t('yes'),
		acceptProps: {
			severity: 'success'
		},
		header: t('export'),
		message: t('exportConfirm'),
		// exportMode.value && !exportAllRowsSelected.value
		// 	? t('exportConfirm', { n: exportRows.value.length })
		// 	: t('exportConfirm', { n: store.jobsAsync.state.numberOfRowsFound }),
		rejectLabel: t('no'),
		rejectProps: {
			severity: 'secondary'
		}
	});
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_unref(Button), {
          disabled: 
						_unref(loading) || _unref(loadingFiltersData) || _unref(updateViewLoading) || _unref(loadingMore)
					,
          label: _ctx.$t('export'),
          loading: _unref(exportingLoading),
          raised: "",
          text: "",
          onClick: exportButtonPressed
        }, null, 8, ["disabled", "label", "loading"]),
        (_unref(selectedFilters).length)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_unref(MultiSelect), {
                  modelValue: _unref(selectedFilters),
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(selectedFilters) ? (selectedFilters).value = $event : null)),
                  "aria-controls": "overlay_popover_selected_filters",
                  class: "tw3-w-full sm:tw3-w-56",
                  disabled: _unref(loading) || _unref(loadingFiltersData) || _unref(updateViewLoading),
                  display: "chip",
                  loading: _unref(loading) || _unref(loadingFiltersData) || _unref(updateViewLoading),
                  optionLabel: "name",
                  options: _unref(selectedFilters),
                  overlayClass: "!tw3-hidden",
                  onClick: toggleFilter
                }, null, 8, ["modelValue", "disabled", "loading", "options"]),
                _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t('selectedFilters')), 1)
              ]),
              _createVNode(_unref(Popover), {
                id: "overlay_popover_selected_filters",
                ref_key: "popupFilters",
                ref: popupFilters,
                class: "tw3-max-h-96 tw3-overflow-auto",
                onHide: _unref(store).onSelectedFiltersMultiselectHide
              }, {
                default: _withCtx(() => [
                  _createVNode(_unref(Listbox), {
                    modelValue: _unref(selectedFiltersLocal),
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_isRef(selectedFiltersLocal) ? (selectedFiltersLocal).value = $event : null)),
                    class: "w-full",
                    disabled: _unref(loading),
                    listStyle: "min-width: 172px",
                    multiple: "",
                    optionLabel: "name",
                    options: _unref(selectedFiltersLocal),
                    "pt:root:class": "!tw3-border-0"
                  }, {
                    option: _withCtx(({ option }) => [
                      _createElementVNode("div", _hoisted_7, [
                        _createElementVNode("span", null, _toDisplayString(option.name), 1),
                        _cache[3] || (_cache[3] = _createElementVNode("i", { class: "pi pi-times" }, null, -1))
                      ])
                    ]),
                    _: 1
                  }, 8, ["modelValue", "disabled", "options"])
                ]),
                _: 1
              }, 8, ["onHide"])
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createElementVNode("div", _hoisted_9, [
        (_openBlock(), _createBlock(_unref(Select), {
          id: "selected_view",
          key: 'change-view_' + _unref(updateViewLoading),
          class: "tw3-w-full lg:tw3-w-56",
          disabled: _unref(loading) || _unref(loadingFiltersData) || _unref(updateViewLoading),
          loading: _unref(loading) || _unref(updateViewLoading) || _unref(updateViewApiLoading),
          modelValue: _unref(view).selectedView,
          optionLabel: "name",
          options: _unref(viewOptions),
          optionValue: "id",
          "onUpdate:modelValue": _cache[2] || (_cache[2] = v => _unref(store).handleChangeView(v))
        }, {
          option: _withCtx(({ option }) => [
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("span", _hoisted_11, _toDisplayString(option.name), 1),
              (option.id === _unref(view).defaultView)
                ? (_openBlock(), _createElementBlock("span", _hoisted_12, _toDisplayString(_ctx.$t('default')), 1))
                : _createCommentVNode("", true)
            ])
          ]),
          _: 1
        }, 8, ["disabled", "loading", "modelValue", "options"])),
        _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.$t('myViews')), 1)
      ]),
      (_openBlock(), _createBlock(_unref(Button), {
        key: 'change-view_' + _unref(updateViewLoading),
        disabled: _unref(loading) || _unref(updateViewLoading) || _unref(updateViewApiLoading),
        icon: "pi pi-objects-column",
        severity: "secondary",
        size: "small",
        onClick: _unref(editStore).toggleJobViewDialog
      }, null, 8, ["disabled", "onClick"]))
    ])
  ]))
}
}

})