import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "cell-container" }
const _hoisted_2 = {
  key: 0,
  class: "tw3-flex tw3-gap-2 tw3-flex-wrap"
}
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { key: 2 }
const _hoisted_5 = {
  key: 0,
  class: "tw3-flex tw3-mb-4 tw3-gap-2"
}
const _hoisted_6 = { class: "tw3-font-bold" }
const _hoisted_7 = {
  key: 1,
  class: "tw3-flex tw3-gap-2"
}
const _hoisted_8 = { class: "tw3-font-bold" }
const _hoisted_9 = ["innerHTML"]
const _hoisted_10 = { class: "p-timeline-event-marker tw3-flex tw3-items-center" }
const _hoisted_11 = { class: "tw3-flex tw3-flex-col tw3-gap-1" }
const _hoisted_12 = { class: "tw3-text-md tw3-font-bold" }
const _hoisted_13 = { class: "tw3-text-sm" }

import dayjs from 'dayjs';
import Button from 'primevue/button';
import Column from 'primevue/column';
import DataTable, { DataTablePageEvent } from 'primevue/datatable';
import Popover from 'primevue/popover';
import Skeleton from 'primevue/skeleton';
import Timeline from 'primevue/timeline';
import { computed, nextTick, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import { openFilePreview } from '@/shared/helpers/file';
import useReportsEmailStore from '@/widgets/reportShareDialog/model/store';

import { EmailHistoryRow } from '../model/types';


export default /*@__PURE__*/_defineComponent({
  __name: 'EmailHistory',
  setup(__props) {

const { t } = useI18n();

const skeletonArray = Array(10).fill({});

const emailStore = useReportsEmailStore();
const currentHeaders = [
	{
		title: t('reportsPage.textMessagesTemplate.sent'),
		key: 'sent'
	},
	{
		title: t('reportsPage.textMessagesTemplate.destination'),
		key: 'destination'
	},
	{
		title: t('reportsPage.textMessagesTemplate.sent_documents'),
		key: 'sent_documents'
	},
	{
		title: t('reportsPage.textMessagesTemplate.status'),
		key: 'status'
	}
];

const formatData = (data: string) => dayjs(data).format('MM/DD/YYYY hh:mmA');
const formatTimestamp = (dt: number) => {
	const date = dayjs.unix(dt).format('MMM D, h:mm A');
	// const time = dayjs.unix(dt).format('h:mm A');
	return date;
};

const selectedRow = ref();
const popoverEl = ref();

const displayStatus = (event: Event, row: EmailHistoryRow) => {
	popoverEl.value.hide();

	if (selectedRow.value?.id === row.id) {
		selectedRow.value = null;
	} else {
		selectedRow.value = row;

		nextTick(() => {
			popoverEl.value.show(event);
		});
	}
};

const getStatusType = (status: string) => {
	if (status === 'delivered') return 'tw3-bg-green-500';
	if (status === 'delivery') return 'tw3-bg-green-500';
	if (status === 'open') return 'tw3-bg-sky-500';
	if (status === 'click') return 'tw3-bg-sky-500';
	if (status === 'processed') return 'tw3-bg-sky-500';
	if (status === 'bounce') return 'tw3-bg-red-500';
	if (status === 'spamreport') return 'tw3-bg-red-500';
	if (status === 'dropped') return 'tw3-bg-red-500';
};
const getStatusName = (status: string) => {
	if (status === 'spamreport') return 'spam report';
	if (status === 'group_unsubscribe') return 'group unsubscribe';
	if (status === 'group_resubscribe') return 'group resubscribe';
	return status;
};

const currentPage = ref<number>(1);
const pageSize = ref(10);
const preparedHistory = computed(() => {
	const prepHistory: EmailHistoryRow[] = emailStore.emailHistory.state;
	prepHistory.map(item => {
		return {
			...item,
			sent: formatData(item.sent)
		};
	});

	const startIndex = (currentPage.value - 1) * pageSize.value;
	const endIndex = startIndex + pageSize.value;
	return prepHistory.slice(startIndex, endIndex);
});

const handlePage = (state: DataTablePageEvent) => {
	currentPage.value = state.page + 1;
};

const expandedRows = ref({});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_unref(DataTable), {
      expandedRows: expandedRows.value,
      "onUpdate:expandedRows": _cache[0] || (_cache[0] = ($event: any) => ((expandedRows).value = $event)),
      class: "tw3-h-full",
      dataKey: "sent",
      lazy: "",
      paginator: "",
      rows: pageSize.value,
      scrollable: "",
      scrollHeight: "flex",
      tableClass: "tw3-w-full tw3-h-full tw3-max-h-full",
      totalRecords: _unref(emailStore).emailHistory.state.length,
      value: _unref(emailStore).emailHistory.isLoading ? _unref(skeletonArray) : preparedHistory.value,
      onPage: handlePage
    }, {
      expansion: _withCtx((slotProps) => [
        (slotProps.data.subject)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createElementVNode("p", _hoisted_6, _toDisplayString(_unref(t)('subject')) + ":", 1),
              _createElementVNode("p", null, _toDisplayString(slotProps.data.subject), 1)
            ]))
          : _createCommentVNode("", true),
        (slotProps.data.body)
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
              _createElementVNode("p", _hoisted_8, _toDisplayString(_unref(t)('body')) + ":", 1),
              _createElementVNode("div", {
                innerHTML: slotProps.data.body
              }, null, 8, _hoisted_9)
            ]))
          : _createCommentVNode("", true)
      ]),
      default: _withCtx(() => [
        _createVNode(_unref(Column), {
          expander: "",
          style: {"width":"3rem"}
        }),
        (_openBlock(), _createElementBlock(_Fragment, null, _renderList(currentHeaders, (column) => {
          return _createVNode(_unref(Column), {
            key: column.key,
            field: column.key,
            header: column.title
          }, {
            body: _withCtx(({ data }) => [
              _createElementVNode("div", _hoisted_1, [
                (_unref(emailStore).emailHistory.isLoading)
                  ? (_openBlock(), _createBlock(_unref(Skeleton), { key: 0 }))
                  : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                      (column.key === 'sent_documents')
                        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(data[column.key], (attachment, index) => {
                              return (_openBlock(), _createBlock(_unref(Button), {
                                key: index,
                                icon: "pi pi-file",
                                label: attachment.document.document_type,
                                severity: "secondary",
                                size: "small",
                                onClick: ($event: any) => (_unref(openFilePreview)(attachment.url, 'report'))
                              }, null, 8, ["label", "onClick"]))
                            }), 128))
                          ]))
                        : (column.key === 'status' && data.email_statuses.length)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_3, [
                              _createVNode(_unref(Button), {
                                class: "tw3-uppercase",
                                label: data.email_statuses[0].event,
                                severity: "secondary",
                                size: "small",
                                onClick: ($event: any) => (displayStatus($event, data))
                              }, null, 8, ["label", "onClick"])
                            ]))
                          : (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(data[column.key]), 1))
                    ], 64))
              ])
            ]),
            _: 2
          }, 1032, ["field", "header"])
        }), 64))
      ]),
      _: 1
    }, 8, ["expandedRows", "rows", "totalRecords", "value"]),
    _createVNode(_unref(Popover), {
      ref_key: "popoverEl",
      ref: popoverEl
    }, {
      default: _withCtx(() => [
        _createVNode(_unref(Timeline), {
          align: "left",
          "pt:event:class": "'!tw3-min-h-4'",
          value: selectedRow.value.email_statuses
        }, {
          marker: _withCtx((slotProps) => [
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("div", {
                class: _normalizeClass([
							'center tw3-rounded tw3-w-[0.375rem] tw3-h-[0.375rem] tw3-absolute',
							getStatusType(slotProps.item.event)
						])
              }, null, 2)
            ])
          ]),
          content: _withCtx((slotProps) => [
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("p", _hoisted_12, _toDisplayString(getStatusName(slotProps.item.event)), 1),
              _createElementVNode("p", _hoisted_13, _toDisplayString(formatTimestamp(slotProps.item.timestamp)), 1)
            ])
          ]),
          _: 1
        }, 8, ["value"])
      ]),
      _: 1
    }, 512)
  ], 64))
}
}

})