import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { style: {"padding-left":"1rem","padding-right":"1rem"} }
const _hoisted_2 = { style: {"width":"100%"} }
const _hoisted_3 = { style: {"border":"0","border-bottom":"1px solid","height":"2rem","font-size":"0.75rem","color":"#6b7280"} }
const _hoisted_4 = ["href"]
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { style: {"display":"flex","align-items":"center","border-radius":"9999px","background-color":"#f9fafb","border":"1px solid #d1d5db","box-shadow":"0 1px 2px rgba(0, 0, 0, 0.05)","padding-left":"0.5rem","padding-right":"0.5rem","padding-top":"2px","padding-bottom":"2px","margin-left":"0.5rem"} }
const _hoisted_7 = { style: {"padding-left":"0.5rem","color":"#4b5563"} }
const _hoisted_8 = { style: {"border":"0","border-bottom":"1px solid"} }
const _hoisted_9 = { colspan: "9" }
const _hoisted_10 = { style: {"padding-top":"0.25rem","padding-left":"3rem","background-color":"#f9fafb"} }
const _hoisted_11 = { style: {"width":"500px"} }

import dayjs from 'dayjs';
import { computed, getCurrentInstance, ref, watch } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'AgingTable',
  props: {
    invoices: {},
    shopId: {},
    expandPayments: { type: Boolean }
  },
  setup(__props: any) {

const props = __props;

const paymentDetailsOpen = ref({});
const anyPaymentsOpen = computed(() => {
	const keys = Object.keys(paymentDetailsOpen.value);
	for (let i = 0; i < keys.length; i++) {
		if (paymentDetailsOpen.value[keys[i]]) {
			return true;
		}
	}
	return false;
});

watch(
	() => props.expandPayments,
	() => {
		if (!props.expandPayments) {
			paymentDetailsOpen.value = {};
		}
	}
);

const formatDate = (date: string) => {
	const dt = new Date(date);
	const dtDateOnly = new Date(
		dt.valueOf() + dt.getTimezoneOffset() * 60 * 1000
	);
	return dayjs(dtDateOnly).format('MMM D, YYYY');
};

const getConsumerName = (c: {
	consumer_company_name?: string;
	consumer_first_name: string;
	consumer_last_name?: string;
}) => {
	if (c.consumer_company_name) {
		return c.consumer_company_name;
	}
	let name = c.consumer_first_name;
	if (c.consumer_last_name) {
		name += ' ' + c.consumer_last_name;
	}
	return name;
};

const openPayments = (index: number) => {
	paymentDetailsOpen.value[index] = !paymentDetailsOpen.value[index];
	forceReRender();
};

const instance = getCurrentInstance();

const forceReRender = () => {
	if (instance) {
		instance.proxy?.$forceUpdate(); // Force re-render
	}
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("table", _hoisted_2, [
      _createElementVNode("thead", null, [
        _createElementVNode("tr", _hoisted_3, [
          _cache[0] || (_cache[0] = _createElementVNode("td", null, "Invoice date", -1)),
          _cache[1] || (_cache[1] = _createElementVNode("td", null, "Invoice #", -1)),
          _cache[2] || (_cache[2] = _createElementVNode("td", null, "Referral #", -1)),
          _cache[3] || (_cache[3] = _createElementVNode("td", null, "Customer", -1)),
          _cache[4] || (_cache[4] = _createElementVNode("td", null, "Third party", -1)),
          _cache[5] || (_cache[5] = _createElementVNode("td", null, "Days", -1)),
          _createElementVNode("td", {
            class: _normalizeClass({ 'pdf-no-display': !anyPaymentsOpen.value })
          }, "Payments", 2),
          _cache[6] || (_cache[6] = _createElementVNode("td", null, "Total invoice", -1)),
          _cache[7] || (_cache[7] = _createElementVNode("td", null, "Balance due", -1))
        ])
      ]),
      _createElementVNode("tbody", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.invoices, (i, index) => {
          return (_openBlock(), _createElementBlock(_Fragment, {
            key: index + 'row'
          }, [
            _createElementVNode("tr", {
              style: _normalizeStyle([{"height":"2rem","font-size":"0.875rem","color":"#4b5563"}, {
							borderBottom: !paymentDetailsOpen.value[index] ? '1px solid' : 'none',
							border: '0'
						}])
            }, [
              _createElementVNode("td", null, _toDisplayString(formatDate(i.invoice_date)), 1),
              _createElementVNode("td", null, [
                _createElementVNode("a", {
                  href: `/jobs/${i.job_id}`,
                  target: "_blank"
                }, _toDisplayString(_ctx.shopId) + "-" + _toDisplayString(i.job_id), 9, _hoisted_4)
              ]),
              _createElementVNode("td", null, _toDisplayString(i.referral_number), 1),
              _createElementVNode("td", null, _toDisplayString(i.consumer_company_name || getConsumerName(i)), 1),
              _createElementVNode("td", null, _toDisplayString(i.trading_partner), 1),
              _createElementVNode("td", null, _toDisplayString(i.days_old), 1),
              _createElementVNode("td", {
                class: _normalizeClass({ 'pdf-no-display': !paymentDetailsOpen.value[index] })
              }, [
                (i.payments.length)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 0,
                      style: {"display":"flex","align-items":"center","cursor":"pointer"},
                      onClick: ($event: any) => (openPayments(index))
                    }, [
                      _createElementVNode("i", {
                        class: _normalizeClass(
										paymentDetailsOpen.value[index]
											? 'el-icon-arrow-down'
											: 'el-icon-arrow-right'
									),
                        style: {"font-weight":"bold"}
                      }, null, 2),
                      _createElementVNode("div", _hoisted_6, [
                        _cache[8] || (_cache[8] = _createElementVNode("i", { class: "pi pi-copy" }, null, -1)),
                        _createElementVNode("span", _hoisted_7, _toDisplayString(i.payments.length), 1)
                      ])
                    ], 8, _hoisted_5))
                  : _createCommentVNode("", true)
              ], 2),
              _createElementVNode("td", null, "$" + _toDisplayString(i.total_balance), 1),
              _createElementVNode("td", null, "$" + _toDisplayString(i.remaining_balance), 1)
            ], 4),
            _withDirectives(_createElementVNode("tr", _hoisted_8, [
              _createElementVNode("td", _hoisted_9, [
                _createElementVNode("div", _hoisted_10, [
                  _createElementVNode("table", _hoisted_11, [
                    _cache[9] || (_cache[9] = _createElementVNode("thead", null, [
                      _createElementVNode("tr", { style: {"height":"2rem","font-size":"0.75rem","color":"#6b7280"} }, [
                        _createElementVNode("td", null, "Date"),
                        _createElementVNode("td", null, "Type"),
                        _createElementVNode("td", null, "X-ActionType"),
                        _createElementVNode("td", null, "Amount")
                      ])
                    ], -1)),
                    _createElementVNode("tbody", null, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(i.payments, (p) => {
                        return (_openBlock(), _createElementBlock("tr", {
                          key: p.id,
                          style: {"height":"2rem","font-size":"0.875rem","color":"#4b5563","border":"0","border-top":"1px solid"}
                        }, [
                          _createElementVNode("td", null, _toDisplayString(formatDate(p.date)), 1),
                          _createElementVNode("td", null, _toDisplayString(p.type), 1),
                          _createElementVNode("td", null, _toDisplayString(p.x_action_type), 1),
                          _createElementVNode("td", null, "$" + _toDisplayString(p.amount), 1)
                        ]))
                      }), 128))
                    ])
                  ])
                ])
              ])
            ], 512), [
              [_vShow, paymentDetailsOpen.value[index] || _ctx.expandPayments]
            ])
          ], 64))
        }), 128))
      ])
    ])
  ]))
}
}

})