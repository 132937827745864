import singleSpaVue, { SingleSpaProps } from 'single-spa-vue';
import { createApp, h } from 'vue';
import './app/assets/styles/index.css';

import initApp from './app';
import App from './app/App.vue';
import { AuthService, setAuthService } from './shared/auth';
import { Config, setConfig } from './shared/config';

interface CustomSingleSpaProps extends SingleSpaProps {
	config: Config;
	authService: AuthService;
}

const vueLifecycles = singleSpaVue({
	createApp,
	appOptions: {
		render() {
			return h(App);
		}
	},
	handleInstance(app, props: CustomSingleSpaProps) {
		setConfig(props.config);
		setAuthService(props.authService);

		initApp(app);
	}
});

export const bootstrap = vueLifecycles.bootstrap;
export const mount = vueLifecycles.mount;
export const unmount = vueLifecycles.unmount;
