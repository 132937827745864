import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "tw3-flex tw3-flex-col tw3-h-full tw3-gap-2" }
const _hoisted_2 = { class: "flex items-center" }
const _hoisted_3 = { class: "tw3-flex tw3-items-center tw3-capitalize tw3-px-2 tw3-py-0.5 tw3-gap-2 tw3-text-sm" }

import Badge from 'primevue/badge';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import Listbox from 'primevue/listbox';
import { ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';

import { JobTag } from '@/entities/job/model/types';


export default /*@__PURE__*/_defineComponent({
  __name: 'TagsDialog',
  props: /*@__PURE__*/_mergeModels({
    jobID: { default: 0 },
    tagsList: { default: () => [] },
    currentTagsList: { default: () => [] },
    isLoading: { type: Boolean, default: false },
    dialogTitle: { default: undefined },
    updateLabel: { default: undefined }
  }, {
    "visible": { type: Boolean },
    "visibleModifiers": {},
  }),
  emits: /*@__PURE__*/_mergeModels(["updateTags"], ["update:visible"]),
  setup(__props: any, { emit: __emit }) {

const showModal = _useModel<boolean>(__props, 'visible');

const { t } = useI18n();

const props = __props;

const emit = __emit;

const selectedtags = ref<number[]>([]);

const handleUpdate = () => {
	if (!selectedtags.value || !selectedtags.value.length) return;

	const result: JobTag[] = [];

	selectedtags.value.forEach((item: number) => {
		const wasPreviously = props.currentTagsList.find(
			element => element.id === item
		);

		if (wasPreviously) {
			result.push({ ...wasPreviously, shop_tag_id: item });
		} else {
			result.push({
				created: -1,
				modified: -1,
				id: -1,
				job_id: props.jobID,
				shop_tag_id: item
			});
		}
	});
	emit('updateTags', result);
};

watch(
	() => showModal.value,
	() => {
		selectedtags.value = props.currentTagsList.map(tag => tag.id);
	}
);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(Dialog), {
    id: "vue3JobTagsAdding",
    visible: showModal.value,
    "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => ((showModal).value = $event)),
    appendTo: "#vue3app",
    class: "tw3-h-[450px] tw3-w-[400px]",
    draggable: false,
    header: _ctx.dialogTitle ? _ctx.dialogTitle : _unref(t)('rejectedInvoices.addTagsTitle'),
    modal: "",
    "pt:content": 'tw3-h-full tw3-flex tw3-flex-col',
    "pt:root:class": '!tw3-border-0 tw3-overflow-hidden'
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_unref(Listbox), {
          modelValue: selectedtags.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((selectedtags).value = $event)),
          checkmark: "",
          class: "w-full md:w-56 tw3-h-full",
          disabled: _ctx.isLoading,
          filter: "",
          listStyle: "max-height:280px",
          multiple: "",
          optionLabel: "text",
          options: _ctx.tagsList,
          optionValue: "id",
          "pt:root:class": "!tw3-border-0"
        }, {
          option: _withCtx((slotProps) => [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_unref(Badge), {
                  class: "!tw3-w-2.5 !tw3-h-2.5 !tw3-min-w-2.5",
                  "pt:root:style": `background-color: ${slotProps.option.color}`,
                  size: "small"
                }, null, 8, ["pt:root:style"]),
                _createTextVNode(" " + _toDisplayString(slotProps.option.text.toLowerCase()), 1)
              ])
            ])
          ]),
          _: 1
        }, 8, ["modelValue", "disabled", "options"]),
        _createVNode(_unref(Button), {
          class: "w-full md:w-14rem",
          label: _ctx.updateLabel ? _ctx.updateLabel : _unref(t)('rejectedInvoices.applyTags'),
          loading: _ctx.isLoading,
          "pt:root:class": "tw3-rounded-t-none",
          size: "small",
          onClick: handleUpdate
        }, null, 8, ["label", "loading"])
      ])
    ]),
    _: 1
  }, 8, ["visible", "header"]))
}
}

})