import { useModel as _useModel, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "cell-container" }
const _hoisted_2 = {
  key: 0,
  class: "pi pi-check"
}
const _hoisted_3 = {
  key: 1,
  class: "pi pi-times"
}
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { class: "cell-container" }
const _hoisted_6 = {
  key: 1,
  class: "tw3-flex tw3-gap-2 tw3-items-center"
}
const _hoisted_7 = { class: "tw3-flex tw3-justify-end" }

import Button from 'primevue/button';
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import Dialog from 'primevue/dialog';
import Skeleton from 'primevue/skeleton';
import { useConfirm } from 'primevue/useconfirm';
import { useToast } from 'primevue/usetoast';
import { onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import {
	deleteTextMessageTemplate,
	getEmailTemplates,
	getTextMessagesTypes
} from '@/widgets/reportsElements/api/api';

import { EmailTemplate } from '../model/types';

import TemplateEditor from './TemplateEditor.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'TextMessagesTemplates',
  props: {
    "show": { type: Boolean },
    "showModifiers": {},
  },
  emits: ["update:show"],
  setup(__props) {

const confirm = useConfirm();
const toast = useToast();
const { t } = useI18n();
const showModal = _useModel<boolean>(__props, 'show');
const templatesData = ref<EmailTemplate[]>([]);
const templateIsLoading = ref(false);
const skeletonArray = Array(10).fill({});
const currentHeaders = [
	{
		title: t('name'),
		key: 'name'
	},
	{
		title: t('shopName'),
		key: 'shop_name'
	},
	{
		title: t('reportsPage.textMessagesTemplate.typeName'),
		key: 'type_name'
	},
	{
		title: t('reportsPage.textMessagesTemplate.default'),
		key: 'default'
	}
];

onMounted(() => {
	refreshTemplates();
});

const refreshTemplates = async () => {
	templateIsLoading.value = true;

	templatesData.value = await getEmailTemplates();
	templateIsLoading.value = false;
};

const closeTemplateEditor = () => {
	showEditor.value = false;
	refreshTemplates();
};

const deleteEmailTemplate = (data: EmailTemplate) => {
	confirm.require({
		accept: async () => {
			const response = await deleteTextMessageTemplate(data.id);

			if (response.status === 200) {
				toast.add({
					life: 3000,
					severity: 'success',
					detail: t('reportsPage.textMessagesTemplate.deleted'),
					summary: t('copyTextToast.succesSummary')
				});

				const index = templatesData.value.findIndex(
					item => item.id === data.id
				);
				templatesData.value.splice(index, 1);
			} else {
				toast.add({
					life: 3000,
					severity: 'error',
					detail: t('copyTextToast.errorDetails'),
					summary: t('copyTextToast.errorSummary')
				});
			}
		},
		acceptProps: {
			label: t('yes')
		},
		header: t('warning'),
		icon: 'pi pi-info-circle',
		message: t('reportsPage.textMessagesTemplate.deleteQuestion'),
		rejectProps: {
			label: t('no'),
			outlined: true,
			severity: 'secondary'
		}
	});
};

const showEditor = ref(false);
const templatesTypes = ref([]);
const editableTemplate = ref();
const addNewTemplate = async () => {
	showEditor.value = true;
	editableTemplate.value = null;
	templatesTypes.value = await getTextMessagesTypes('email');
};

const editTemplate = async (data: EmailTemplate) => {
	showEditor.value = true;
	templatesTypes.value = await getTextMessagesTypes('email');
	editableTemplate.value = data;
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(Dialog), {
    id: "TextTemplatesListWindow",
    visible: showModal.value,
    "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => ((showModal).value = $event)),
    appendTo: "#vue3app",
    class: "tw3-max-w-[800px] tw3-h-full tw3-w-full",
    header: _unref(t)('reportsPage.textMessagesTemplate.emailTemplates'),
    modal: "",
    "pt:content": 'tw3-h-full tw3-flex tw3-flex-col'
  }, {
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_unref(Button), {
          icon: "pi pi-plus-circle",
          label: _unref(t)('reportsPage.textMessagesTemplate.addTemplates'),
          severity: "secondary",
          size: "small",
          type: "button",
          onClick: addNewTemplate
        }, null, 8, ["label"])
      ])
    ]),
    default: _withCtx(() => [
      _createVNode(_unref(DataTable), {
        class: "tw3-h-full",
        dataKey: "name",
        lazy: "",
        scrollable: "",
        scrollHeight: "flex",
        tableClass: "tw3-w-full tw3-h-full tw3-max-h-full",
        totalRecords: templatesData.value.length,
        value: templateIsLoading.value ? _unref(skeletonArray) : templatesData.value
      }, {
        default: _withCtx(() => [
          (_openBlock(), _createElementBlock(_Fragment, null, _renderList(currentHeaders, (column) => {
            return _createVNode(_unref(Column), {
              key: column.key,
              field: column.key,
              header: column.title
            }, {
              body: _withCtx(({ data }) => [
                _createElementVNode("div", _hoisted_1, [
                  (templateIsLoading.value)
                    ? (_openBlock(), _createBlock(_unref(Skeleton), { key: 0 }))
                    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                        (column.key === 'default')
                          ? (_openBlock(), _createElementBlock("div", {
                              key: 0,
                              class: _normalizeClass(["tw3-flex tw3-w-full tw3-items-center tw3-justify-center", {
									'tw3-text-green-600': data[column.key] === 1,
									'tw3-text-red-600': data[column.key] !== 1
								}])
                            }, [
                              (data[column.key] === 1)
                                ? (_openBlock(), _createElementBlock("i", _hoisted_2))
                                : (_openBlock(), _createElementBlock("i", _hoisted_3))
                            ], 2))
                          : (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(data[column.key]), 1))
                      ], 64))
                ])
              ]),
              _: 2
            }, 1032, ["field", "header"])
          }), 64)),
          _createVNode(_unref(Column), {
            header: "Actions",
            style: {"max-width":"120px"}
          }, {
            body: _withCtx(({ data }) => [
              _createElementVNode("div", _hoisted_5, [
                (templateIsLoading.value)
                  ? (_openBlock(), _createBlock(_unref(Skeleton), { key: 0 }))
                  : (_openBlock(), _createElementBlock("div", _hoisted_6, [
                      _createVNode(_unref(Button), {
                        icon: "pi pi-pencil",
                        rounded: "",
                        severity: "secondary",
                        size: "small",
                        text: "",
                        onClick: ($event: any) => (editTemplate(data))
                      }, null, 8, ["onClick"]),
                      _createVNode(_unref(Button), {
                        class: "ml-0",
                        icon: "pi pi-trash",
                        rounded: "",
                        severity: "danger",
                        size: "small",
                        text: "",
                        onClick: ($event: any) => (deleteEmailTemplate(data))
                      }, null, 8, ["onClick"])
                    ]))
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["totalRecords", "value"]),
      _createVNode(TemplateEditor, {
        visible: showEditor.value,
        "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((showEditor).value = $event)),
        template: editableTemplate.value,
        templatesTypes: templatesTypes.value,
        onUpdateTemplates: closeTemplateEditor
      }, null, 8, ["visible", "template", "templatesTypes"])
    ]),
    _: 1
  }, 8, ["visible", "header"]))
}
}

})