<script setup lang="ts">
import { Loader } from '@googlemaps/js-api-loader';
import AutoComplete, {
	AutoCompleteCompleteEvent,
	AutoCompleteOptionSelectEvent
} from 'primevue/autocomplete';
import Checkbox from 'primevue/checkbox';
import InputText from 'primevue/inputtext';
import Textarea from 'primevue/textarea';
import { computed, onMounted, ref, watch } from 'vue';

import { Address } from '@/entities/address/lib/types';
import { config } from '@/shared/config';

import {
	parseAutocompleteResults,
	serializeAddressComponents
} from './lib/helpers';
import { GoogleAddressComponents, GoogleParsedResults } from './lib/types';

const props = defineProps<{
	modelValue?: Partial<Address> | null;
	disabled?: boolean;
	withPoBox?: boolean;
	withExtra?: boolean;
	errMsg?: string;
	inline?: boolean;
}>();

const emit = defineEmits<{
	(e: 'update:modelValue', address: Partial<Address>): void;
}>();

const token = ref<string>();
const Places = ref<any>();
const suggestions = ref<GoogleParsedResults[]>([]);

const loading = ref(false);
const addExtra = ref(!!props.modelValue?.extra);
const address = ref(props.modelValue?.full_address ?? '');

const showExtra = computed(() => addExtra.value || props.modelValue?.extra);

const search = async (event: AutoCompleteCompleteEvent) => {
	loading.value = true;
	try {
		const service = new Places.value.AutocompleteService();
		const data = await service.getPlacePredictions({
			componentRestrictions: { country: ['us', 'ca'] },
			language: 'en',
			fields: [
				'address_component',
				'adr_address',
				'formatted_address',
				'geometry,place_id'
			],
			input: event.query,
			sessionToken: token.value,
			types: props.modelValue?.po_box ? ['(regions)'] : ['address']
		});
		suggestions.value = parseAutocompleteResults(data.predictions);
	} catch (error) {
		suggestions.value = [];
	}
	loading.value = false;
};

const getPlaceDetails = (placeId: string) =>
	new Promise(resolve => {
		try {
			const service = new Places.value.PlacesService(
				document.createElement('div')
			);
			service.getDetails(
				{
					fields: [
						'address_component',
						'adr_address',
						'formatted_address',
						'geometry,place_id'
					],
					placeId,
					sessionToken: token.value
				},
				(results: any) => {
					resolve(serializeAddressComponents(results));
				}
			);
		} catch (error) {
			resolve(null);
		}
	});

const handleSelect = async (result: AutoCompleteOptionSelectEvent) => {
	loading.value = true;
	const { place_id } = result.value;

	const data = (await getPlaceDetails(place_id)) as GoogleAddressComponents;
	const value = {
		...props.modelValue,
		...data
	};
	emit('update:modelValue', value);
	address.value = value.full_address ?? '';
	loading.value = false;
};

const setToken = () => {
	token.value = new Places.value.AutocompleteSessionToken();
};

const updateValue = (field: keyof Address, value: any) => {
	emit('update:modelValue', { ...props.modelValue, [field]: value });
};

const invalidateInput = () => {
	emit('update:modelValue', {
		...props.modelValue,
		address: undefined,
		city: undefined,
		full_address: undefined,
		google_place_id: undefined,
		lat: 0,
		lng: 0,
		state: undefined,
		zip: null
	});
};

const toggleExtra = () => {
	if (showExtra.value) {
		updateValue('extra', null);
		addExtra.value = false;
	} else {
		addExtra.value = true;
	}
};

onMounted(async () => {
	const loader = new Loader({
		apiKey: config().googleMapsApiKey,
		version: config().googleMapsVersion
	});
	Places.value = await loader.importLibrary('places');
	address.value = props.modelValue?.full_address || '';
});

watch(
	() => props.modelValue,
	() => {
		address.value = props.modelValue?.full_address || '';
	},
	{ deep: true }
);
</script>

<template>
	<div
		class="tw3-w-full tw3-flex tw3-gap-2"
		:class="{ 'tw3-flex-col': !inline }"
	>
		<div class="tw3-w-full tw3-flex tw3-flex-col tw3-gap-2">
			<div class="tw3-flex tw3-gap-2">
				<AutoComplete
					v-model="address"
					v-tooltip.top="{
						value: errMsg,
						pt: {
							text: {
								style: { backgroundColor: 'rgb(248 113 113)' }
							},
							arrow: {
								style: { borderTopColor: 'rgb(248 113 113)' }
							}
						}
					}"
					class="tw3-min-w-64 tw3-w-full"
					:disabled="disabled"
					inputClass="tw3-min-w-64 tw3-w-full"
					:invalid="!!errMsg"
					:loading="loading"
					placeholder="Select address"
					:suggestions="suggestions"
					@complete="search"
					@focus="setToken"
					@option-select="handleSelect"
					@update:model-value="invalidateInput"
				>
					<template #option="{ option }">
						<div>
							<div class="tw3-text-slate-600 tw3-font-medium tw3-text-sm">
								{{ option.main_text }}
							</div>
							<div class="tw3-text-slate-400 tw3-font-medium tw3-text-xs">
								{{ option.secondary_text }}
							</div>
						</div>
					</template>
				</AutoComplete>
				<InputText
					class="tw3-w-28"
					:disabled="disabled"
					:invalid="!!errMsg"
					:modelValue="modelValue?.unit"
					:placeholder="!!props.modelValue?.po_box ? 'PO BOX' : 'Unit/Apt'"
					type="text"
					@update:model-value="v => updateValue('unit', v ?? null)"
				/>
			</div>
			<div
				v-if="withPoBox || withExtra"
				class="tw3-flex tw3-items-center tw3-gap-4"
			>
				<div v-if="withPoBox" class="tw3-flex tw3-items-center">
					<Checkbox
						binary
						:modelValue="!!props.modelValue?.po_box"
						@update:model-value="v => updateValue('po_box', +v)"
					/>
					<div class="tw3-text-slate-500 tw3-font-medium tw3-text-sm tw3-ml-2">
						P.O. BOX
					</div>
				</div>
				<div
					v-if="withExtra"
					class="tw3-text-slate-500 tw3-font-medium tw3-text-sm tw3-cursor-pointer hover:tw3-underline"
					@click.prevent="toggleExtra"
				>
					<span v-if="showExtra">Remove instructions</span>
					<span v-else>Add delivery instructions</span>
				</div>
			</div>
		</div>
		<Textarea
			v-if="withExtra && showExtra"
			autoResize
			class="tw3-w-full"
			:disabled="disabled"
			inputClass="tw3-w-full"
			:modelValue="modelValue?.extra"
			@update:model-value="v => updateValue('extra', v)"
		/>
	</div>
</template>
