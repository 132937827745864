import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, unref as _unref, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "gb-flex-container tw3-grow" }

import { storeToRefs } from 'pinia';
import Card from 'primevue/card';
import { computed } from 'vue';

import { useUserStore } from '@/entities/user/store';


export default /*@__PURE__*/_defineComponent({
  __name: 'WrapperPage',
  setup(__props) {

const { user } = storeToRefs(useUserStore());

const blocked = computed(() => {
	return !user.value.user.admin;
});

return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!blocked.value)
      ? (_openBlock(), _createBlock(_component_router_view, { key: 0 }))
      : (_openBlock(), _createBlock(_unref(Card), { key: 1 }, {
          title: _withCtx(() => _cache[0] || (_cache[0] = [
            _createTextVNode("Accounting")
          ])),
          content: _withCtx(() => _cache[1] || (_cache[1] = [
            _createElementVNode("p", { class: "m-0" }, "Accounting disabled", -1)
          ])),
          _: 1
        }))
  ]))
}
}

})