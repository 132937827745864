import { useModel as _useModel, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "tw3-flex tw3-items-center tw3-text-xl" }
const _hoisted_2 = { class: "tw3-font-bold" }
const _hoisted_3 = { class: "tw3-grid tw3-grid-cols-3 tw3-gap-4 tw3-pt-2" }
const _hoisted_4 = { class: "tw3-flex tw3-justify-end tw3-pt-4" }

import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import { useConfirm } from 'primevue/useconfirm';
import { useToast } from 'primevue/usetoast';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { RouterLink } from 'vue-router';

import ClaimStatus from '@/entities/claim/ClaimStatus.vue';
import { useClaimDetailsStore } from '@/entities/claim/lib/store';
import { ClaimStatus as ClaimStatusEnum } from '@/entities/claim/lib/types';
import AdditionalInfo from '@/features/additionalInfo/AdditionalInfo.vue';
import CoverageResponse from '@/features/coverageResponse/CoverageResponse.vue';
import DamageInfo from '@/features/damageInfo/DamageInfo.vue';
import VehicleInfo from '@/features/vehicleInfo/VehicleInfo.vue';
import { AutoAccept } from '@/shared/ui';
import InsuredCustomer from '@/widgets/insurance/InsuredCustomer.vue';

import { useClaimsStore } from '../claimTable/lib/store';


export default /*@__PURE__*/_defineComponent({
  __name: 'ClaimDialog',
  props: {
    "show": { type: Boolean },
    "showModifiers": {},
  },
  emits: ["update:show"],
  setup(__props) {

const { t } = useI18n();

const confirm = useConfirm();
const toast = useToast();

const claimsStore = useClaimsStore();
const claimDetailsStore = useClaimDetailsStore();
const showModal = _useModel<boolean>(__props, 'show');

const showCoverageResponce = computed(
	() =>
		claimDetailsStore.claim.state.result.status ===
			ClaimStatusEnum.CLAIM_STATUS_COVERAGE ||
		claimDetailsStore.claim.state.result.status ===
			ClaimStatusEnum.CLAIM_STATUS_SUCCESS ||
		claimDetailsStore.claim.state.result.status ===
			ClaimStatusEnum.CLAIM_STATUS_ERROR
);

const isStatusErrorOrPending = computed(
	() =>
		claimDetailsStore.claim.state.result.status ===
			ClaimStatusEnum.CLAIM_STATUS_ERROR ||
		claimDetailsStore.claim.state.result.status ===
			ClaimStatusEnum.CLAIM_STATUS_PENDING
);

const shouldBeBlocked = computed(
	() =>
		claimDetailsStore.claim.state.result.status ===
			ClaimStatusEnum.CLAIM_STATUS_SUCCESS ||
		claimDetailsStore.claim.state.result.status ===
			ClaimStatusEnum.CLAIM_STATUS_COVERAGE ||
		claimDetailsStore.claim.state.result.status ===
			ClaimStatusEnum.CLAIM_STATUS_ERROR
);

const isEditable = computed(
	() =>
		claimDetailsStore.claim.state.result.status ===
			ClaimStatusEnum.CLAIM_STATUS_NEW ||
		claimDetailsStore.claim.state.result.status ===
			ClaimStatusEnum.CLAIM_STATUS_PENDING
);

const sendClaimIsLoading = ref(false);
const handleSendClaim = async () => {
	if (claimDetailsStore.hasEmptyFields) {
		toast.add({
			detail: 'Please fill in all required fields.',
			life: 3000,
			severity: 'warn',
			summary: 'Warn Message'
		});

		return;
	}

	confirm.require({
		accept: async () => {
			sendClaimIsLoading.value = true;
			await claimDetailsStore.createClaim({
				ackDetails: claimDetailsStore.claim.state.ackDetails,
				additionalInfo: claimDetailsStore.claim.state.additionalInfo,
				agent: claimDetailsStore.claim.state.agent,
				customer: claimDetailsStore.claim.state.customer,
				damageInfo: claimDetailsStore.claim.state.damageInfo,
				insurance: claimDetailsStore.claim.state.insurance,
				jobId: claimDetailsStore.claim.state.jobId,
				providerName: claimDetailsStore.claim.state.providerName,
				vehicle: claimDetailsStore.claim.state.vehicle
			});
			sendClaimIsLoading.value = false;
		},
		acceptLabel: t('yes'),
		acceptProps: {
			severity: 'success'
		},
		header: t('submitClaim'),
		message: t('submitClaimMsg'),
		rejectLabel: t('no'),
		rejectProps: {
			severity: 'secondary'
		}
	});
};

const claimUpdateIsLoading = computed(
	() => claimDetailsStore.claim.isLoading || sendClaimIsLoading.value
);

const refreshClaims = async () => {
	claimsStore.claims.execute(0, await claimsStore.composeGetClaimsArgs());
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(Dialog), {
    id: "vue3ClaimDialog",
    visible: showModal.value,
    "onUpdate:visible": [
      _cache[8] || (_cache[8] = ($event: any) => ((showModal).value = $event)),
      refreshClaims
    ],
    appendTo: "#vue3app",
    class: "tw3-w-full tw3-max-w-[990px]",
    loading: claimUpdateIsLoading.value,
    modal: ""
  }, {
    header: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("span", _hoisted_2, _toDisplayString(_unref(t)('claimForJob')) + " #", 1),
        _createVNode(_unref(RouterLink), {
          class: "tw3-ml-1",
          to: `/jobs/${_unref(claimDetailsStore).claim.state.jobId}`
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_unref(claimDetailsStore).claim.state.jobId), 1)
          ]),
          _: 1
        }, 8, ["to"]),
        _createVNode(ClaimStatus, {
          class: "tw3-ml-2",
          status: _unref(claimDetailsStore).claim.state.result.status
        }, null, 8, ["status"])
      ])
    ]),
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_4, [
        (!isEditable.value)
          ? (_openBlock(), _createBlock(_unref(Button), {
              key: 0,
              label: "Edit Claim",
              severity: "secondary",
              onClick: _unref(claimDetailsStore).restartClaim
            }, null, 8, ["onClick"]))
          : _createCommentVNode("", true),
        (!showCoverageResponce.value)
          ? (_openBlock(), _createBlock(_unref(Button), {
              key: 1,
              class: "tw3-ml-5",
              label: `${isStatusErrorOrPending.value ? 'Restart Claim' : 'Send Claim'}`,
              loading: claimUpdateIsLoading.value,
              onClick: handleSendClaim
            }, null, 8, ["label", "loading"]))
          : _createCommentVNode("", true)
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_3, [
        (showCoverageResponce.value)
          ? (_openBlock(), _createBlock(CoverageResponse, {
              key: 0,
              class: "tw3-col-span-3",
              deductible: 
					_unref(claimDetailsStore).claim.state.result.coverageResponseVehicle
						.deductible
				,
              errorMessage: _unref(claimDetailsStore).claim.state.result.errorMessage,
              referenceNumber: _unref(claimDetailsStore).claim.state.result.referenceNumber
            }, null, 8, ["deductible", "errorMessage", "referenceNumber"]))
          : _createCommentVNode("", true),
        _createVNode(InsuredCustomer, {
          insurance: _unref(claimDetailsStore).claim.state.insurance,
          "onUpdate:insurance": _cache[0] || (_cache[0] = ($event: any) => ((_unref(claimDetailsStore).claim.state.insurance) = $event)),
          insuredCustomer: _unref(claimDetailsStore).claim.state.customer,
          "onUpdate:insuredCustomer": _cache[1] || (_cache[1] = ($event: any) => ((_unref(claimDetailsStore).claim.state.customer) = $event)),
          class: "tw3-col-span-2",
          policyNumber: _unref(claimDetailsStore).claim.state.insurance.policyNumber,
          status: shouldBeBlocked.value
        }, null, 8, ["insurance", "insuredCustomer", "policyNumber", "status"]),
        _createVNode(VehicleInfo, {
          vehicle: _unref(claimDetailsStore).claim.state.vehicle,
          "onUpdate:vehicle": _cache[2] || (_cache[2] = ($event: any) => ((_unref(claimDetailsStore).claim.state.vehicle) = $event)),
          status: shouldBeBlocked.value
        }, null, 8, ["vehicle", "status"]),
        _createVNode(AdditionalInfo, {
          additionalInfo: _unref(claimDetailsStore).claim.state.additionalInfo,
          "onUpdate:additionalInfo": _cache[3] || (_cache[3] = ($event: any) => ((_unref(claimDetailsStore).claim.state.additionalInfo) = $event)),
          agent: _unref(claimDetailsStore).claim.state.agent,
          "onUpdate:agent": _cache[4] || (_cache[4] = ($event: any) => ((_unref(claimDetailsStore).claim.state.agent) = $event)),
          insurance: _unref(claimDetailsStore).claim.state.insurance,
          "onUpdate:insurance": _cache[5] || (_cache[5] = ($event: any) => ((_unref(claimDetailsStore).claim.state.insurance) = $event)),
          class: "tw3-col-span-2",
          collapsed: showCoverageResponce.value,
          dateOfLoss: _unref(claimDetailsStore).claim.state.insurance.dateOfLoss,
          status: shouldBeBlocked.value
        }, null, 8, ["additionalInfo", "agent", "insurance", "collapsed", "dateOfLoss", "status"]),
        _createVNode(_unref(AutoAccept), {
          ackDetails: _unref(claimDetailsStore).claim.state.ackDetails,
          "onUpdate:ackDetails": _cache[6] || (_cache[6] = ($event: any) => ((_unref(claimDetailsStore).claim.state.ackDetails) = $event)),
          collapsed: showCoverageResponce.value,
          status: shouldBeBlocked.value
        }, null, 8, ["ackDetails", "collapsed", "status"]),
        _createVNode(DamageInfo, {
          damageInfoData: _unref(claimDetailsStore).claim.state.damageInfo,
          "onUpdate:damageInfoData": _cache[7] || (_cache[7] = ($event: any) => ((_unref(claimDetailsStore).claim.state.damageInfo) = $event)),
          addClaimDamageInfo: _unref(claimDetailsStore).addClaimDamageInfo,
          class: "mb-3 tw3-col-span-3",
          collapsed: showCoverageResponce.value,
          removeClaimDamageInfo: _unref(claimDetailsStore).removeClaimDamageInfo,
          status: shouldBeBlocked.value
        }, null, 8, ["damageInfoData", "addClaimDamageInfo", "collapsed", "removeClaimDamageInfo", "status"])
      ])
    ]),
    _: 1
  }, 8, ["visible", "loading"]))
}
}

})