import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { id: "accounting-reports" }
const _hoisted_2 = { class: "section-header" }
const _hoisted_3 = { class: "co-accounts-table" }
const _hoisted_4 = { class: "column-one" }
const _hoisted_5 = {
  align: "right",
  class: "column-three"
}
const _hoisted_6 = { class: "column-two black-text bold-text" }
const _hoisted_7 = {
  align: "right",
  class: "column-three"
}
const _hoisted_8 = { class: "border-top-div black-text" }
const _hoisted_9 = { class: "section-header" }
const _hoisted_10 = { class: "sub-section-header" }
const _hoisted_11 = { class: "co-accounts-table" }
const _hoisted_12 = { class: "column-one" }
const _hoisted_13 = {
  align: "right",
  class: "column-three"
}
const _hoisted_14 = { class: "column-two" }
const _hoisted_15 = {
  align: "right",
  class: "column-three"
}
const _hoisted_16 = { class: "border-top-div black-text" }
const _hoisted_17 = { class: "sub-section-header" }
const _hoisted_18 = { class: "co-accounts-table" }
const _hoisted_19 = { class: "column-one" }
const _hoisted_20 = {
  align: "right",
  class: "column-three"
}
const _hoisted_21 = { key: 0 }
const _hoisted_22 = { class: "column-one" }
const _hoisted_23 = {
  align: "right",
  class: "column-three"
}
const _hoisted_24 = { class: "column-two" }
const _hoisted_25 = {
  align: "right",
  class: "column-three"
}
const _hoisted_26 = { class: "border-top-div black-text" }
const _hoisted_27 = { class: "column-two black-text bold-text" }
const _hoisted_28 = {
  align: "right",
  class: "column-three black-text"
}

import { useI18n } from 'vue-i18n';


export default /*@__PURE__*/_defineComponent({
  __name: 'BalanceSheetTable',
  props: {
    data: {}
  },
  setup(__props: any) {



const { t } = useI18n();

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", _hoisted_2, _toDisplayString(_unref(t)('reportsPage.table.assets')), 1),
    _createElementVNode("table", _hoisted_3, [
      _createElementVNode("tbody", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data.assetAccountDetails, (accountDetail) => {
          return (_openBlock(), _createElementBlock("tr", {
            key: accountDetail.id
          }, [
            _createElementVNode("td", _hoisted_4, _toDisplayString(accountDetail.co_account_name), 1),
            _cache[0] || (_cache[0] = _createElementVNode("td", { class: "column-two" }, null, -1)),
            _createElementVNode("td", _hoisted_5, _toDisplayString(accountDetail.value), 1)
          ]))
        }), 128)),
        _createElementVNode("tr", null, [
          _cache[1] || (_cache[1] = _createElementVNode("td", { class: "column-one" }, null, -1)),
          _createElementVNode("td", _hoisted_6, _toDisplayString(_unref(t)('reportsPage.total')) + " " + _toDisplayString(_unref(t)('reportsPage.table.assets')), 1),
          _createElementVNode("td", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.data.totalAssets), 1)
          ])
        ])
      ])
    ]),
    _createElementVNode("span", _hoisted_9, _toDisplayString(_unref(t)('reportsPage.table.liabilitiesAndEquity')), 1),
    _cache[9] || (_cache[9] = _createElementVNode("br", null, null, -1)),
    _createElementVNode("span", _hoisted_10, _toDisplayString(_unref(t)('reportsPage.table.liabilities')), 1),
    _createElementVNode("table", _hoisted_11, [
      _createElementVNode("tbody", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data.liabilitiesAccountDetails, (accountDetail) => {
          return (_openBlock(), _createElementBlock("tr", {
            key: accountDetail.id
          }, [
            _createElementVNode("td", _hoisted_12, _toDisplayString(accountDetail.co_account_name), 1),
            _cache[2] || (_cache[2] = _createElementVNode("td", { class: "column-two" }, null, -1)),
            _createElementVNode("td", _hoisted_13, _toDisplayString(accountDetail.value), 1)
          ]))
        }), 128)),
        _createElementVNode("tr", null, [
          _cache[3] || (_cache[3] = _createElementVNode("td", { class: "column-one" }, null, -1)),
          _createElementVNode("td", _hoisted_14, _toDisplayString(_unref(t)('reportsPage.total')) + " " + _toDisplayString(_unref(t)('reportsPage.table.liabilities')), 1),
          _createElementVNode("td", _hoisted_15, [
            _createElementVNode("div", _hoisted_16, _toDisplayString(_ctx.data.totalLiabilities), 1)
          ])
        ])
      ])
    ]),
    _createElementVNode("span", _hoisted_17, _toDisplayString(_unref(t)('reportsPage.table.equity')), 1),
    _createElementVNode("table", _hoisted_18, [
      _createElementVNode("tbody", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data.equityAccountDetails, (accountDetail) => {
          return (_openBlock(), _createElementBlock("tr", {
            key: accountDetail.id
          }, [
            _createElementVNode("td", _hoisted_19, _toDisplayString(accountDetail.co_account_name), 1),
            _cache[4] || (_cache[4] = _createElementVNode("td", { class: "column-two" }, null, -1)),
            _createElementVNode("td", _hoisted_20, _toDisplayString(accountDetail.value), 1)
          ]))
        }), 128)),
        (_ctx.data.showTotalNetIncome)
          ? (_openBlock(), _createElementBlock("tr", _hoisted_21, [
              _createElementVNode("td", _hoisted_22, _toDisplayString(_unref(t)('reportsPage.table.netIncome')), 1),
              _cache[5] || (_cache[5] = _createElementVNode("td", { class: "column-two" }, null, -1)),
              _createElementVNode("td", _hoisted_23, _toDisplayString(_ctx.data.totalNetIncome), 1)
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("tr", null, [
          _cache[6] || (_cache[6] = _createElementVNode("td", { class: "column-one" }, null, -1)),
          _createElementVNode("td", _hoisted_24, _toDisplayString(_unref(t)('reportsPage.total')) + " " + _toDisplayString(_unref(t)('reportsPage.table.equity')), 1),
          _createElementVNode("td", _hoisted_25, [
            _createElementVNode("div", _hoisted_26, _toDisplayString(_ctx.data.totalEquity), 1)
          ])
        ]),
        _cache[8] || (_cache[8] = _createElementVNode("tr", null, [
          _createElementVNode("td", { class: "column-one" }, [
            _createElementVNode("br")
          ]),
          _createElementVNode("td", { class: "column-two" }),
          _createElementVNode("td", { class: "column-three" })
        ], -1)),
        _createElementVNode("tr", null, [
          _cache[7] || (_cache[7] = _createElementVNode("td", { class: "column-one" }, null, -1)),
          _createElementVNode("td", _hoisted_27, _toDisplayString(_unref(t)('reportsPage.total')) + " " + _toDisplayString(_unref(t)('reportsPage.table.liabilitiesAndEquity')), 1),
          _createElementVNode("td", _hoisted_28, _toDisplayString(_ctx.data.totalLiabilitiesAndEquity), 1)
        ])
      ])
    ])
  ]))
}
}

})