import { coreApi } from '@/shared/api';
import { Exact } from '@/shared/types';

import {
	GetPaymentSettingsResponse,
	PaymentSettings,
	UpdatePaymentSettingsRequest
} from './types';

export const URL = '/unum/accounting';

export const getPaymentSettings = async (
	organizationId: number
): Promise<PaymentSettings> => {
	const response = await coreApi().get<GetPaymentSettingsResponse>(
		URL + '/payment/settings',
		{
			params: { organizationId: organizationId }
		}
	);
	return response.data.settings;
};

export const updatePaymentSettings = async <T>(
	request: Exact<T, UpdatePaymentSettingsRequest>
): Promise<void> => {
	await coreApi().patch(URL + '/payment/settings', request);
};
