import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "tw3-flex tw3-flex-col tw3-w-[500px] tw3-h-[500px]" }
const _hoisted_2 = { class: "tw3-relative tw3-flex tw3-flex-col tw3-gap-2 tw3-flex-1 tw3-overflow-y-auto documents-container" }
const _hoisted_3 = { class: "tw3-flex tw3-items-center tw3-gap-2 tw3-capitalize" }
const _hoisted_4 = { style: {"font-size":"1rem","font-weight":"600","white-space":"nowrap"} }
const _hoisted_5 = {
  key: 0,
  class: "tw3-flex tw3-flex-col tw3-gap-2 tw3-pb-[18px]"
}
const _hoisted_6 = ["href"]
const _hoisted_7 = { class: "text-gray-800 tw3-truncate" }

import { Client, PickerResponse } from 'filestack-js';
import { upperCase } from 'lodash-es';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import Panel from 'primevue/panel';
import Tag from 'primevue/tag';
import { useConfirm } from 'primevue/useconfirm';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';

import useAppStore from '@/entities/app/lib/store';

import { DocumentType } from '../../../model/enums';
import { JobDocument } from '../../../model/types';
import { getDocumentColorByType } from '../../../utils';


export default /*@__PURE__*/_defineComponent({
  __name: 'JobEditDocuments',
  props: {
    data: {},
    show: { type: Boolean },
    loading: { type: Boolean }
  },
  emits: ["on-close", "on-create", "on-delete"],
  setup(__props: any, { emit: __emit }) {

const types = Object.values(DocumentType).map(v => ({
	id: v,
	text: upperCase(v)
}));

const { t } = useI18n();
const appStore = useAppStore();

const props = __props;
const emit = __emit;

const selectedType = ref<DocumentType>();

const confirm = useConfirm();

const deletePressed = (data: JobDocument) => {
	confirm.require({
		accept: () => {
			emit('on-delete', data);
		},
		acceptLabel: t('yes'),
		acceptProps: {
			severity: 'success'
		},
		header: t('document.delete'),
		message: t('document.deleteConfirm'),
		rejectLabel: t('no'),
		rejectProps: {
			severity: 'secondary'
		}
	});
};

const openFilePicker = (v: DocumentType) => {
	selectedType.value = v;
	const client = new Client(appStore.appConfig.state.filePickerKey);
	const options = {
		maxFiles: 1,
		onUploadDone: handleUploadDone
	};

	client.picker(options).open();
};

const handleUploadDone = (result: PickerResponse) => {
	const uploadedFile = result.filesUploaded[0];

	emit('on-create', {
		id: -1,
		created: -1,
		modified: -1,
		document_type: selectedType.value,
		url: uploadedFile.url,
		document_name: uploadedFile.filename
	});
	selectedType.value = undefined;
};

const documentsByType = (v: DocumentType) => {
	return props.data.filter(d => d.document_type === v);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(Dialog), {
    id: "vue3JobEditDocumentsDialog",
    header: _ctx.$t('jobDocuments'),
    modal: "",
    visible: _ctx.show,
    "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => (emit('on-close')))
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(types), (item) => {
            return (_openBlock(), _createBlock(_unref(Panel), {
              key: item.id,
              header: item.text,
              "pt:content:class": "!tw3-pb-0"
            }, {
              header: _withCtx(() => [
                _createVNode(_unref(Tag), {
                  severity: "secondary",
                  style: _normalizeStyle({
								'border-left': '10px solid black',
								'border-color': _unref(getDocumentColorByType)(item.id)
							})
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_3, [
                      _createElementVNode("span", _hoisted_4, _toDisplayString(item.text), 1)
                    ])
                  ]),
                  _: 2
                }, 1032, ["style"])
              ]),
              icons: _withCtx(() => [
                _createVNode(_unref(Button), {
                  icon: "pi pi-plus",
                  loading: _ctx.loading,
                  rounded: "",
                  severity: "secondary",
                  text: "",
                  onClick: ($event: any) => (openFilePicker(item.id))
                }, null, 8, ["loading", "onClick"])
              ]),
              default: _withCtx(() => [
                (documentsByType(item.id).length)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(documentsByType(item.id), (document) => {
                        return (_openBlock(), _createElementBlock("a", {
                          key: document.id,
                          class: "tw3-w-full tw3-flex tw3-justify-between tw3-items-center tw3-gap-2 tw3-p-2 tw3-rounded tw3-border !tw3-no-underline tw3-transition-all hover:tw3-opacity-70",
                          href: document.url,
                          target: "_blank"
                        }, [
                          _createElementVNode("span", _hoisted_7, _toDisplayString(document.document_name), 1),
                          _createVNode(_unref(Button), {
                            disabled: _ctx.loading,
                            icon: "pi pi-trash",
                            severity: "danger",
                            size: "small",
                            text: "",
                            onClick: _withModifiers(($event: any) => (deletePressed(document)), ["prevent"])
                          }, null, 8, ["disabled", "onClick"])
                        ], 8, _hoisted_6))
                      }), 128))
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _: 2
            }, 1032, ["header"]))
          }), 128))
        ])
      ])
    ]),
    _: 1
  }, 8, ["header", "visible"]))
}
}

})