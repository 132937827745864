import { coreApi } from '@/shared/api';
import { convertToDateTime } from '@/shared/helpers/converters';
import { Exact } from '@/shared/types';

import {
	CreateExpenseRequest,
	CreateExpenseResponse,
	Expense,
	ExpenseShort,
	GetExpenseResponse,
	ListExpenseRequest,
	ListExpenseResponse,
	UpdateExpenseRequest
} from '../model/types';

export const URL = '/unum/accounting';

export const listExpense = async <T>(
	request: Exact<T, ListExpenseRequest>
): Promise<ExpenseShort[]> => {
	// * this is "GET with BODY" request
	const response = await coreApi().post<ListExpenseResponse>(
		URL + '/expense/search',
		request
	);
	return convertToDateTime(response.data.expense);
};

export const getExpense = async (id: number): Promise<Expense> => {
	// * this is "GET with BODY" request
	const response = await coreApi().get<GetExpenseResponse>(
		URL + '/expense/' + id
	);
	return convertToDateTime(response.data.expense);
};

export const createExpense = async <T>(
	request: Exact<T, CreateExpenseRequest>
): Promise<number> => {
	const response = await coreApi().post<CreateExpenseResponse>(
		URL + '/expense',
		request
	);
	return response.data.id;
};

export const updateExpense = async <T>(
	request: Exact<T, UpdateExpenseRequest>
): Promise<void> => {
	await coreApi().patch(URL + '/expense', request);
};

export const deleteExpense = async (id: number): Promise<void> => {
	await coreApi().delete(`${URL}/expense/${id}`);
};
