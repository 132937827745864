import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "tax-summary-table"
}
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = {
  key: 0,
  class: "cell"
}
const _hoisted_4 = {
  key: 1,
  class: "cell"
}
const _hoisted_5 = { class: "cell" }
const _hoisted_6 = { class: "cell" }
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = { class: "cell" }

import { storeToRefs } from 'pinia';
import { computed } from 'vue';

import { taxSummaryLastCols } from '@/widgets/reportsElements/model/constants';
import useReportsStore from '@/widgets/reportsElements/model/store';
import { reportsGetters } from '@/widgets/reportsElements/model/utils/getters';


export default /*@__PURE__*/_defineComponent({
  __name: 'TaxSummaryTable',
  props: {
    data: {}
  },
  setup(__props: any) {

const props = __props;

const reportStore = useReportsStore();

const { currentReportType, currentColumns } = storeToRefs(reportStore);

const taxExtraColumns = computed(() => {
	const allCol = [...currentColumns.value, ...taxSummaryLastCols];
	const mappedColumns = allCol.map(col => col.prop);
	if (props.data) {
		return Object.keys(props.data).filter(key => !mappedColumns.includes(key));
	}
	return [];
});

return (_ctx: any,_cache: any) => {
  return (_ctx.data)
    ? (_openBlock(), _createElementBlock("table", _hoisted_1, [
        _createElementVNode("tbody", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(currentColumns), (col) => {
            return (_openBlock(), _createElementBlock("tr", {
              key: col.prop
            }, [
              _createElementVNode("td", {
                class: "cell",
                innerHTML: col.label
              }, null, 8, _hoisted_2),
              (col.prop !== 'empty')
                ? (_openBlock(), _createElementBlock("td", _hoisted_3, _toDisplayString(_unref(reportsGetters).formatNumber(_ctx.data[col.prop], {
							style: 'dollar'
						})), 1))
                : (_openBlock(), _createElementBlock("td", _hoisted_4, " "))
            ]))
          }), 128)),
          (_unref(currentReportType).id === 'tax-summary')
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(taxExtraColumns.value, (col) => {
                  return (_openBlock(), _createElementBlock("tr", { key: col }, [
                    _createElementVNode("td", _hoisted_5, _toDisplayString(col), 1),
                    _createElementVNode("td", _hoisted_6, _toDisplayString(_unref(reportsGetters).formatNumber(_ctx.data[col], {
								style: 'dollar'
							})), 1)
                  ]))
                }), 128)),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(taxSummaryLastCols), (col) => {
                  return (_openBlock(), _createElementBlock("tr", {
                    key: col.prop
                  }, [
                    _createElementVNode("td", {
                      class: "cell",
                      innerHTML: col.label
                    }, null, 8, _hoisted_7),
                    _createElementVNode("td", _hoisted_8, _toDisplayString(_unref(reportsGetters).formatNumber(_ctx.data[col.prop], {
								style: 'dollar'
							})), 1)
                  ]))
                }), 128))
              ], 64))
            : _createCommentVNode("", true)
        ])
      ]))
    : _createCommentVNode("", true)
}
}

})