import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "tw3-flex tw3-flex-grow tw3-items-center"
}

import { storeToRefs } from 'pinia';
import ProgressSpinner from 'primevue/progressspinner';
import { computed, onMounted, onUnmounted, ref } from 'vue';

import { useUserStore } from '@/entities/user/store';

import { CreateAccount } from '../createAccount';


export default /*@__PURE__*/_defineComponent({
  __name: 'MainContainer',
  setup(__props) {

const isMob = ref(false);
const contentWidth = ref(48);

const left = computed(() => {
	return isMob.value && contentWidth.value < 110
		? contentWidth.value / 2
		: contentWidth.value;
});

const updatePosition = (entries: ResizeObserverEntry[]) => {
	contentWidth.value =
		entries[0].contentRect.width > 0 ? entries[0].contentRect.width : 48;
};

const resizeObserver = new ResizeObserver(updatePosition);
const resizeHandle = () => {
	isMob.value = window.innerWidth < 600;
};

onMounted(() => {
	const interval = setInterval(() => {
		const el = document.getElementById('main-sidebar');
		if (el === null) {
			return;
		}
		resizeObserver.observe(el!);
		clearInterval(interval);
		resizeHandle();
		window.addEventListener('resize', resizeHandle);
	}, 500);
});

onUnmounted(() => {
	resizeObserver.disconnect();
	window.removeEventListener('resize', resizeHandle);
});

const { user } = storeToRefs(useUserStore());

const blocked = computed(() => {
	return !user.value.user.id;
});

const showAccountCreate = computed(() => {
	return (
		!blocked.value &&
		!user.value.user.account_id &&
		window.location.pathname !== '/sign-up/finish'
	);
});

return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", {
    id: "vue3-main-container",
    class: "px-4 py-3",
    style: _normalizeStyle({ left: `${left.value}px` })
  }, [
    (blocked.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_unref(ProgressSpinner))
        ]))
      : (showAccountCreate.value)
        ? (_openBlock(), _createBlock(_unref(CreateAccount), { key: 1 }))
        : (_openBlock(), _createBlock(_component_router_view, { key: 2 }))
  ], 4))
}
}

})