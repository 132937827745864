import { coreApi } from '@/shared/api';

import {
	AccountCreationForm,
	BillingPlanForm,
	OrganizationForm
} from '../model/types';

export type Plan = {
	plan_code: string;
	plan_id: string;
	name: string;
	billing_mode: string;
	description: string;
	status: string;
	product_id: string;
	tax_id: string;
	tax_name: string;
	tax_percentage: number;
	tax_type: string;
	trial_period: number;
	setup_fee: number;
	setup_fee_account_id: string;
	setup_fee_account_name: string;
	account_id: string;
	account: string;
	recurring_price: number;
	pricing_scheme: string;
	pricing_scheme_formatted: string;
	price_brackets: {
		price: number;
	}[];
	unit: string;
	interval: number;
	interval_unit: string;
	billing_cycles: number;
	product_type: string;
	show_in_widget: boolean;
	store_description: string;
	store_markup_description: string;
	url: string;
	shipping_interval: number;
	shipping_interval_unit: string;
	created_time: string;
	created_time_formatted: string;
	updated_time: string;
	updated_time_formatted: string;
	addons: {
		name: string;
		addon_code: string;
	}[];
	custom_fields: any[];
};

export type GetPlansResponse = Plan[];

export const getPlans = async (): Promise<GetPlansResponse> => {
	const res = await coreApi().get('/api/zoho/plans');
	return res.data;
};

export type getHostedSubscriptionPageRequest = {
	plan: BillingPlanForm;
	organization: OrganizationForm;
	redirectUrl: string;
};

export type getHostedSubscriptionPageResponse = {
	hostedpage: HostedPage;
};

export type HostedPage = {
	hostedpage_id: string;
	decrypted_hosted_page_id: string;
	status: string;
	url: string;
	action: string;
	expiring_time: Date;
	created_time: Date;
};

export const getHostedSubscriptionPage = async (
	req: getHostedSubscriptionPageRequest
): Promise<getHostedSubscriptionPageResponse> => {
	const res = await coreApi().get('/api/zoho/hosted-subscription-page', {
		params: {
			redirect_url: req.redirectUrl,
			plan: JSON.stringify(req.plan.plan),
			customer: JSON.stringify(req.organization)
		}
	});
	return res.data;
};

export const createAccount = async (
	data: AccountCreationForm
): Promise<void> => {
	try {
		await coreApi().post('/api/account', data);
	} catch (err: any) {
		console.log(err);
		throw new Error(err.response.data.message);
	}
};

export const getSignedStorageUrl = async () => {
	const res = await coreApi().get('/api/file-storage/signed-url');
	return res.data;
};
