import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "reports-body-container tw3-flex tw3-w-full tw3-h-full tw3-justify-center" }
const _hoisted_2 = {
  key: 0,
  class: "reports-no-data tw3-min-h-[500px] tw3-m-auto tw3-flex tw3-flex-col tw3-items-center tw3-justify-center tw3-gap-3 tw3-w-fit tw3-text-4xl"
}
const _hoisted_3 = {
  key: 0,
  class: "tw3-text-center"
}
const _hoisted_4 = {
  key: 1,
  class: "tw3-text-center"
}
const _hoisted_5 = {
  key: 1,
  class: "mobile-table-wrapper tw3-pb-5 tw3-px-16 lg:tw3-pb-0 lg:tw3-px-20 tw3-box-border tw3-min-w-full tw3-w-max"
}

import { storeToRefs } from 'pinia';
import Card from 'primevue/card';
import { useI18n } from 'vue-i18n';

import useReportsStore from '@/widgets/reportsElements/model/store';
import { reportsGetters } from '@/widgets/reportsElements/model/utils/getters';

import ReportTableTitle from './reportsHelpers/ReportTableTitle.vue';
import TableGrid from './reportsHelpers/TableGrid.vue';
import AgingReportTable from './reportsTables/AgingReportTable.vue';
import BalanceSheetTable from './reportsTables/BalanceSheetTable.vue';
import CustomerSummaryTable from './reportsTables/CustomerSummaryTable.vue';
import EdiReportTable from './reportsTables/EdiReportTable.vue';
import InstallReportTable from './reportsTables/InstallReportTable.vue';
import NagsLicenseReportTable from './reportsTables/NagsLicenseReportTable.vue';
import ProfitAndLossTable from './reportsTables/ProfitAndLossTable.vue';
import RightPartReportTable from './reportsTables/RightPartReportTable.vue';
import RoyaltySummaryReportTable from './reportsTables/RoyaltySummaryReportTable.vue';
import SchedulerReportTable from './reportsTables/SchedulerReportTable.vue';
import StatementReportTable from './reportsTables/StatementReportTable.vue';
import TaxDetailReportTable from './reportsTables/TaxDetailReportTable.vue';
import TaxSummaryTable from './reportsTables/TaxSummaryTable.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'ReportsBody',
  setup(__props) {

const { t } = useI18n();

const reportsStore = useReportsStore();

const {
	currentReportType,
	reportData,
	tableMode,
	currentColumns,
	selectedShop,
	expandAllAgingPayments
} = storeToRefs(reportsStore);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (
				_unref(reportsGetters).dataIsNull(_unref(reportData)) ||
				_unref(reportsGetters).emptyData(_unref(reportData))
			)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _cache[0] || (_cache[0] = _createElementVNode("i", {
            class: "pi pi-calendar-times",
            style: {"font-size":"2.25rem","line-height":"2.5rem"}
          }, null, -1)),
          (_unref(reportsGetters).emptyData(_unref(reportData)))
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_unref(t)('reportsPage.filters.noResults')), 1))
            : (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_unref(t)('reportsPage.filters.noGenerated')), 1))
        ]))
      : (_openBlock(), _createBlock(_unref(Card), {
          key: 1,
          class: "tw3-h-fit tw3-bg-white !tw3-p-0 lg:!tw3-p-3 tw3-pt-0 tw3-rounded table-container tw3-w-full tw3-max-h-[600px] tw3-overflow-auto",
          pt: {
				body: '!tw3-p-0 lg:!tw3-p-5 tw3-w-fit lg:tw3-w-full',
				content: 'tw3-min-w-full tw3-w-max'
			}
        }, {
          content: _withCtx(() => [
            (
						_unref(currentReportType).id !== 'account-statement' &&
						_unref(currentReportType).id !== 'open-invoice' &&
						_unref(currentReportType).id !== 'aging'
					)
              ? (_openBlock(), _createBlock(ReportTableTitle, { key: 0 }))
              : _createCommentVNode("", true),
            (_unref(reportData))
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  (_unref(currentReportType).group === 'scheduler')
                    ? (_openBlock(), _createBlock(SchedulerReportTable, {
                        key: 0,
                        data: _unref(reportData)
                      }, null, 8, ["data"]))
                    : (_unref(currentReportType).id === 'customer-summary')
                      ? (_openBlock(), _createBlock(CustomerSummaryTable, {
                          key: 1,
                          data: _unref(reportData)
                        }, null, 8, ["data"]))
                      : (_unref(currentReportType).group === 'royalty')
                        ? (_openBlock(), _createBlock(RoyaltySummaryReportTable, {
                            key: 2,
                            data: _unref(reportData)
                          }, null, 8, ["data"]))
                        : (_unref(currentReportType).id == 'tax-detail')
                          ? (_openBlock(), _createBlock(TaxDetailReportTable, {
                              key: 3,
                              data: _unref(reportData)
                            }, null, 8, ["data"]))
                          : (_unref(currentReportType).id === 'tax-summary')
                            ? (_openBlock(), _createBlock(TaxSummaryTable, {
                                key: 4,
                                data: _unref(reportData)
                              }, null, 8, ["data"]))
                            : (_unref(currentReportType).group === 'install')
                              ? (_openBlock(), _createBlock(InstallReportTable, {
                                  key: 5,
                                  data: _unref(reportData)
                                }, null, 8, ["data"]))
                              : (_unref(currentReportType).id == 'summary')
                                ? (_openBlock(), _createBlock(TaxSummaryTable, {
                                    key: 6,
                                    data: _unref(reportData)
                                  }, null, 8, ["data"]))
                                : (_unref(currentReportType).group === 'statements')
                                  ? (_openBlock(true), _createElementBlock(_Fragment, { key: 7 }, _renderList(_unref(reportData), (item, index) => {
                                      return (_openBlock(), _createBlock(StatementReportTable, {
                                        key: index,
                                        data: item
                                      }, null, 8, ["data"]))
                                    }), 128))
                                  : (_unref(currentReportType).id === 'edi')
                                    ? (_openBlock(), _createBlock(EdiReportTable, {
                                        key: 8,
                                        data: _unref(reportData)
                                      }, null, 8, ["data"]))
                                    : (_unref(currentReportType).id === 'profit-and-loss')
                                      ? (_openBlock(), _createBlock(ProfitAndLossTable, {
                                          key: 9,
                                          data: _unref(reportData)
                                        }, null, 8, ["data"]))
                                      : (_unref(currentReportType).id === 'balance-sheet')
                                        ? (_openBlock(), _createBlock(BalanceSheetTable, {
                                            key: 10,
                                            data: _unref(reportData)
                                          }, null, 8, ["data"]))
                                        : (_unref(currentReportType).id === 'aging')
                                          ? (_openBlock(), _createBlock(AgingReportTable, {
                                              key: 11,
                                              data: _unref(reportData),
                                              expandPayments: _unref(expandAllAgingPayments),
                                              groupBy: _unref(tableMode) ?? 'none',
                                              shopId: _unref(selectedShop) ? _unref(selectedShop)[0] : 0
                                            }, null, 8, ["data", "expandPayments", "groupBy", "shopId"]))
                                          : (
							_unref(currentReportType).group === 'right-part' &&
							_unref(currentReportType).id === 'requests'
						)
                                            ? (_openBlock(), _createBlock(RightPartReportTable, {
                                                key: 12,
                                                data: _unref(reportData)
                                              }, null, 8, ["data"]))
                                            : (_unref(currentReportType).group === 'nags-license')
                                              ? (_openBlock(), _createBlock(NagsLicenseReportTable, {
                                                  key: 13,
                                                  data: _unref(reportData)
                                                }, null, 8, ["data"]))
                                              : (_openBlock(), _createBlock(TableGrid, {
                                                  key: 14,
                                                  class: _normalizeClass(_unref(currentReportType).group + '-' + _unref(currentReportType).id),
                                                  columns: _unref(currentColumns),
                                                  data: _unref(reportData),
                                                  reportType: _unref(currentReportType),
                                                  splitBy: _unref(tableMode) ?? 'none'
                                                }, null, 8, ["class", "columns", "data", "reportType", "splitBy"]))
                ]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }))
  ]))
}
}

})