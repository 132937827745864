import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "tw3-text-center tw3-py-20"
}
const _hoisted_2 = {
  key: 2,
  class: "tw3-max-w-[1440px] tw3-m-auto tw3-grid tw3-grid-cols-12 tw3-gap-4 md:tw3-gap-8"
}
const _hoisted_3 = { class: "tw3-col-span-12 lg:tw3-col-span-8" }
const _hoisted_4 = { class: "card widget-visitor-graph !tw3-border-0 !tw3-shadow-transparent tw3-rounded-xl tw3-p-4" }
const _hoisted_5 = { class: "tw3-font-medium tw3-flex tw3-flex-col sm:tw3-flex-row sm:tw3-justify-between sm:tw3-items-center tw3-leading-loose" }
const _hoisted_6 = { class: "tw3-flex tw3-flex-col sm:tw3-flex-row tw3-items-center tw3-gap-2" }
const _hoisted_7 = { class: "graph-content tw3-grid tw3-grid-cols-12 tw3-gap-4 tw3-mt-6" }
const _hoisted_8 = {
  key: 0,
  class: "tw3-col-span-12 md:tw3-col-span-6"
}
const _hoisted_9 = { class: "tw3-text-3xl tw3-font-semibold" }
const _hoisted_10 = { class: "tw3-font-semibold tw3-my-4" }
const _hoisted_11 = { class: "tw3-text-surface-500" }
const _hoisted_12 = { class: "graph" }
const _hoisted_13 = { class: "tw3-text-xl tw3-font-semibold tw3-my-6" }
const _hoisted_14 = {
  key: 0,
  class: "tw3-h-[590px] tw3-w-full tw3-flex tw3-justify-center tw3-items-center"
}
const _hoisted_15 = { class: "tw3-self-end tw3-mt-2 tw3-text-sm tw3-text-gray-300" }
const _hoisted_16 = { class: "tw3-col-span-12 lg:tw3-col-span-4 tw3-mb-[1.25rem]" }

import { storeToRefs } from 'pinia';
import Chart from 'primevue/chart';
import ProgressSpinner from 'primevue/progressspinner';
import Select from 'primevue/select';
import Skeleton from 'primevue/skeleton';
import TreeSelect from 'primevue/treeselect';
import { computed, nextTick, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import { permissionCheck, permissions } from '@/entities/user/lib/util';
import { useUserStore } from '@/entities/user/store';
import { timeRangeModes } from '@/widgets/dashboard/config/constants';
import {
	calculateTooltipTotals,
	formatNumber,
	formatPrice,
	getChartLifetimeData,
	getChartOptions,
	getDualMainChartData,
	getLastUpdatedDate,
	getMainChartData,
	getTimeRangePrefix,
	getTimeRangeSuffix,
	toLabelsAndData
} from '@/widgets/dashboard/lib';
import { DashboardHeaderTooltip } from '@/widgets/dashboard/model/types';
import DashboardLifetime from '@/widgets/dashboard/ui/DashboardLifetime.vue';
import NewestInvoices from '@/widgets/dashboard/ui/NewestInvoices.vue';
import PermissionRequired from '@/widgets/dashboard/ui/PermissionRequired.vue';

import { getChartTextByModeRevenue } from '../lib';
import { useDashboardStore } from '../model/store';


export default /*@__PURE__*/_defineComponent({
  __name: 'RevenueDashboard',
  setup(__props) {

const { t } = useI18n();

const store = useDashboardStore();
const userStore = useUserStore();
const { user } = storeToRefs(userStore);

const shopOptions = computed(() => [
	{
		key: 'allShops',
		label: t('allShops')
	},
	...user.value.organizations.map(v => ({
		key: 'organizations_' + v.id,
		label: v.name,
		children: user.value.shops
			.filter(s => s.organization_id === v.id)
			.map(s => ({ key: 'shops_' + s.id, label: s.name }))
	}))
]);

const selectedShop = ref({ allShops: true });
const apiSelectedShop = computed(() => {
	let shopIds: number[] = [];
	Object.keys(selectedShop.value).forEach(v => {
		if (v === 'allShops' || v === 'organizations' || v === 'shops') return;
		else {
			const data = v.split('_');
			if (data[0] === 'shops') {
				shopIds.push(Number(data[1]));
			}
			if (selectedMainChart.value !== 4) {
				shopIds = user.value.shops
					.filter(v => v.organization_id === Number(data[1]))
					.map(v => v.id);
			}
		}
	});
	return shopIds.length ? shopIds : undefined;
});
const apiSelectedOrganization = computed(() => {
	const organizationIds: number[] = [];
	Object.keys(selectedShop.value).forEach(v => {
		if (v === 'allShops' || v === 'organizations' || v === 'shops') return;
		else {
			const data = v.split('_');
			if (data[0] === 'organizations') {
				organizationIds.push(Number(data[1]));
			}
		}
	});
	return organizationIds.length ? organizationIds : undefined;
});

const mainBlockTitle = ref(t('dashboard.titles.compareRevenue'));
const chartTitle = ref(t('dashboard.titles.revenueAndNumberOfInvoices'));
const chartHeaderTooltips = ref<DashboardHeaderTooltip[]>([]);

const selectedMainChart = ref(1);

const dataActualDate = ref<string>();
const mainChart = ref();
const chartOptions = ref();
const numberOfInvoicesChart = ref();
const averageOfInvoicesChart = ref();
const revenueOfInvoicesChart = ref();
const totalCustomerCountChart = ref();

const timeMode = ref(timeRangeModes[2]);

const mainChartDescSuffix = computed(() =>
	getTimeRangeSuffix(timeMode.value.code)
);

const onUpdate = async () => {
	await nextTick();
	await getAndSetMainChartData();
};

const updateChartMode = async (v: number) => {
	selectedMainChart.value = v;
	selectedShop.value = { allShops: true };
	timeMode.value = timeRangeModes[2];
	await onUpdate();
};

const getAndSetMainChartData = async () => {
	const params = {
		time_range: timeMode.value.code,
		shop_ids: apiSelectedShop.value
	};
	const headPrefix = getTimeRangePrefix(timeMode.value.code);
	let currentLabels;

	if (selectedMainChart.value === 1) {
		await store.revenueAndInvoicesAmount.execute(0, params);

		const totalWithoutLastI = calculateTooltipTotals(
			store.revenueAndInvoicesAmount.state.invoices
		);
		const totalWithoutLastR = calculateTooltipTotals(
			store.revenueAndInvoicesAmount.state.revenue
		);

		chartHeaderTooltips.value = [
			{
				data: formatPrice(formatNumber(totalWithoutLastR)),
				title: headPrefix + ' ' + t('revenue'),
				desc: t('dashboard.titles.totalAmountOfInvoices')
			},
			{
				data: formatNumber(totalWithoutLastI),
				title: headPrefix + ' ' + t('invoiceCount'),
				desc: t('dashboard.titles.totalNumberOfInvoices')
			}
		];
		const { labels, data: revenueData } = toLabelsAndData(
			store.revenueAndInvoicesAmount.state.revenue,
			timeMode.value.code
		);
		const { data: invoiceData } = toLabelsAndData(
			store.revenueAndInvoicesAmount.state.invoices,
			timeMode.value.code
		);
		currentLabels = labels;
		mainChart.value = getDualMainChartData(invoiceData, revenueData, labels);
	} else if (selectedMainChart.value === 4) {
		await store.filteredNumberOfCustomers.execute(0, {
			...params,
			organization_ids: apiSelectedOrganization.value
		});

		const totalWithoutLast = calculateTooltipTotals(
			store.filteredNumberOfCustomers.state
		);

		chartHeaderTooltips.value = [
			{
				data: formatNumber(totalWithoutLast),
				title: headPrefix + ' ' + t('customerCount'),
				desc: t('dashboard.titles.totalNumberOfCustomers')
			}
		];
		const { labels, data } = toLabelsAndData(
			store.filteredNumberOfCustomers.state,
			timeMode.value.code
		);
		currentLabels = labels;
		mainChart.value = getMainChartData(data, labels, t('customers'), '#3B82F6');
	} else if (selectedMainChart.value === 3) {
		await store.filteredNumberOfInvoices.execute(0, params);

		const totalWithoutLast = calculateTooltipTotals(
			store.filteredNumberOfInvoices.state
		);

		chartHeaderTooltips.value = [
			{
				data: formatNumber(totalWithoutLast),
				title: headPrefix + ' ' + t('invoiceCount'),
				desc: t('dashboard.titles.totalNumberOfInvoices')
			}
		];
		const { labels, data } = toLabelsAndData(
			store.filteredNumberOfInvoices.state,
			timeMode.value.code
		);
		currentLabels = labels;
		mainChart.value = getMainChartData(data, labels, t('invoices'), '#fc6161');
	} else {
		await store.filteredAverageOfInvoices.execute(0, params);

		const totalWithoutLast = calculateTooltipTotals(
			store.filteredAverageOfInvoices.state
		);
		const yearLength = store.filteredAverageOfInvoices.state.filter(
			(v, index) =>
				v.amount !== 0 &&
				index !== store.filteredAverageOfInvoices.state.length - 1
		).length;

		chartHeaderTooltips.value = [
			{
				data: formatPrice(formatNumber(totalWithoutLast / yearLength)),
				title: t('averageInvoice'),
				desc: t('averageInvoice')
			}
		];
		const { labels, data } = toLabelsAndData(
			store.filteredAverageOfInvoices.state,
			timeMode.value.code
		);
		currentLabels = labels;
		mainChart.value = getMainChartData(data, labels, t('invoices'), '#0bd18a');
	}

	const { chartTitle: chart, mainBlockTitle: main } = getChartTextByModeRevenue(
		selectedMainChart.value
	);
	mainBlockTitle.value = main;
	chartTitle.value = chart;

	chartOptions.value = getChartOptions(
		selectedMainChart.value !== 1,
		timeMode.value.code,
		currentLabels
	);
};

onMounted(async () => {
	if (!permissionCheck(permissions.GRAPH, user.value)) return;
	const promises: Promise<any>[] = [getAndSetMainChartData()];

	if (store.amountAndRevenueOfInvoices.state.data.length === 0) {
		promises.push(store.amountAndRevenueOfInvoices.execute(0));
	}
	if (store.averageAndNumberOfInvoices.state.data.length === 0) {
		promises.push(store.averageAndNumberOfInvoices.execute(0));
	}
	if (store.totalAndNumberOfCustomers.state.data.length === 0) {
		promises.push(store.totalAndNumberOfCustomers.execute(0));
	}
	if (store.totalAndNumberOfInvoices.state.data.length === 0) {
		promises.push(store.totalAndNumberOfInvoices.execute(0));
	}
	if (store.newestInvoices.state.length === 0) {
		promises.push(store.newestInvoices.execute(0));
	}

	await Promise.all(promises);

	dataActualDate.value = getLastUpdatedDate();

	averageOfInvoicesChart.value = getChartLifetimeData(
		store.averageAndNumberOfInvoices.state.data,
		'11, 209, 138'
	);
	numberOfInvoicesChart.value = getChartLifetimeData(
		store.totalAndNumberOfInvoices.state.data,
		'252, 97, 97'
	);
	revenueOfInvoicesChart.value = getChartLifetimeData(
		store.amountAndRevenueOfInvoices.state.data,
		'0, 208, 222'
	);
	totalCustomerCountChart.value = getChartLifetimeData(
		store.totalAndNumberOfCustomers.state.data,
		'59, 130, 246'
	);
});

return (_ctx: any,_cache: any) => {
  return (
			_unref(store).amountAndRevenueOfInvoices.isLoading ||
			_unref(store).averageAndNumberOfInvoices.isLoading ||
			_unref(store).totalAndNumberOfCustomers.isLoading ||
			_unref(store).totalAndNumberOfInvoices.isLoading ||
			_unref(store).newestInvoices.isLoading
		)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_unref(ProgressSpinner))
      ]))
    : (!_unref(permissionCheck)(_unref(permissions).GRAPH, _unref(user)))
      ? (_openBlock(), _createBlock(PermissionRequired, { key: 1 }))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(DashboardLifetime, {
            cardStyle: 
				selectedMainChart.value === 1
					? {
							boxShadow: '0px 6px 20px rgba(0, 208, 222, 0.5) !important'
						}
					: undefined
			,
            chart: revenueOfInvoicesChart.value,
            differenceNumber: _unref(store).amountAndRevenueOfInvoices.state.difference,
            isRevenue: "",
            showTooltip: {
				tooltipHeaderI18: 'revenueData',
				tooltipDescI18: 'dashboardTooltipTitle'
			},
            showTooltipExplaining: "",
            title: _ctx.$t('lifetimeRevenue'),
            value: _unref(store).amountAndRevenueOfInvoices.state.count,
            valueStyle: {
				backgroundColor: '#00d0de',
				boxShadow: '0px 6px 20px rgba(0, 208, 222, 0.3)'
			},
            onClick: _cache[0] || (_cache[0] = ($event: any) => (updateChartMode(1)))
          }, null, 8, ["cardStyle", "chart", "differenceNumber", "title", "value"]),
          _createVNode(DashboardLifetime, {
            cardStyle: 
				selectedMainChart.value === 2
					? {
							boxShadow: '0px 6px 20px rgba(11, 209, 138, 0.5) !important'
						}
					: undefined
			,
            chart: averageOfInvoicesChart.value,
            differenceNumber: _unref(store).averageAndNumberOfInvoices.state.difference,
            isRevenue: "",
            title: _ctx.$t('averageInvoiceAmount'),
            value: _unref(store).averageAndNumberOfInvoices.state.count,
            valueStyle: {
				backgroundColor: '#0bd18a',
				boxShadow: '0px 6px 20px rgba(11, 209, 138, 0.3)'
			},
            onClick: _cache[1] || (_cache[1] = ($event: any) => (updateChartMode(2)))
          }, null, 8, ["cardStyle", "chart", "differenceNumber", "title", "value"]),
          _createVNode(DashboardLifetime, {
            cardStyle: 
				selectedMainChart.value === 3
					? {
							boxShadow: '0px 6px 20px rgba(252, 97, 97, 0.5) !important'
						}
					: undefined
			,
            chart: numberOfInvoicesChart.value,
            differenceNumber: _unref(store).totalAndNumberOfInvoices.state.difference,
            title: _ctx.$t('lifetimeInvoices'),
            value: _unref(store).totalAndNumberOfInvoices.state.count,
            valueStyle: {
				backgroundColor: '#fc6161',
				boxShadow: '0px 6px 20px rgba(252, 97, 97, 0.3)'
			},
            onClick: _cache[2] || (_cache[2] = ($event: any) => (updateChartMode(3)))
          }, null, 8, ["cardStyle", "chart", "differenceNumber", "title", "value"]),
          _createVNode(DashboardLifetime, {
            cardStyle: 
				selectedMainChart.value === 4
					? {
							boxShadow: '0px 6px 20px rgba(59, 130, 246, 0.5) !important'
						}
					: undefined
			,
            chart: totalCustomerCountChart.value,
            differenceNumber: _unref(store).totalAndNumberOfCustomers.state.difference,
            title: _ctx.$t('lifetimeCustomers'),
            value: _unref(store).totalAndNumberOfCustomers.state.count,
            valueStyle: {
				backgroundColor: '#3B82F6',
				boxShadow: '0px 6px 20px rgba(59, 130, 246, 0.3)'
			},
            onClick: _cache[3] || (_cache[3] = ($event: any) => (updateChartMode(4)))
          }, null, 8, ["cardStyle", "chart", "differenceNumber", "title", "value"]),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("span", null, _toDisplayString(mainBlockTitle.value), 1),
                _createElementVNode("div", _hoisted_6, [
                  _createVNode(_unref(TreeSelect), {
                    modelValue: selectedShop.value,
                    "onUpdate:modelValue": [
                      _cache[4] || (_cache[4] = ($event: any) => ((selectedShop).value = $event)),
                      onUpdate
                    ],
                    class: "tw3-w-full sm:tw3-w-auto",
                    loading: 
								_unref(store).revenueAndInvoicesAmount.isLoading ||
								_unref(store).filteredNumberOfCustomers.isLoading ||
								_unref(store).filteredNumberOfInvoices.isLoading ||
								_unref(store).filteredAverageOfInvoices.isLoading
							,
                    options: shopOptions.value,
                    placeholder: "Select an Item"
                  }, null, 8, ["modelValue", "loading", "options"]),
                  _createVNode(_unref(Select), {
                    modelValue: timeMode.value,
                    "onUpdate:modelValue": [
                      _cache[5] || (_cache[5] = ($event: any) => ((timeMode).value = $event)),
                      onUpdate
                    ],
                    class: "tw3-w-full sm:tw3-w-auto",
                    loading: 
								_unref(store).revenueAndInvoicesAmount.isLoading ||
								_unref(store).filteredNumberOfCustomers.isLoading ||
								_unref(store).filteredNumberOfInvoices.isLoading ||
								_unref(store).filteredAverageOfInvoices.isLoading
							,
                    optionLabel: "name",
                    options: _unref(timeRangeModes)
                  }, null, 8, ["modelValue", "loading", "options"])
                ])
              ]),
              _createElementVNode("div", _hoisted_7, [
                (
							!mainChart.value ||
							_unref(store).revenueAndInvoicesAmount.isLoading ||
							_unref(store).filteredNumberOfCustomers.isLoading ||
							_unref(store).filteredNumberOfInvoices.isLoading ||
							_unref(store).filteredAverageOfInvoices.isLoading
						)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                      _createVNode(_unref(Skeleton), {
                        height: "31px",
                        width: "5.5rem"
                      }),
                      _createVNode(_unref(Skeleton), {
                        class: "tw3-my-4",
                        height: "18px",
                        width: "7rem"
                      }),
                      _createVNode(_unref(Skeleton), {
                        height: "18px",
                        width: "12rem"
                      })
                    ]))
                  : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(chartHeaderTooltips.value, (item, index) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: index,
                        class: "tw3-col-span-12 md:tw3-col-span-6"
                      }, [
                        _createElementVNode("div", _hoisted_9, _toDisplayString(item.data), 1),
                        _createElementVNode("div", _hoisted_10, _toDisplayString(item.title), 1),
                        _createElementVNode("p", _hoisted_11, _toDisplayString(item.desc + mainChartDescSuffix.value), 1)
                      ]))
                    }), 128))
              ]),
              _createElementVNode("div", _hoisted_12, [
                _createElementVNode("div", _hoisted_13, _toDisplayString(chartTitle.value), 1),
                (
							!mainChart.value ||
							_unref(store).revenueAndInvoicesAmount.isLoading ||
							_unref(store).filteredNumberOfCustomers.isLoading ||
							_unref(store).filteredNumberOfInvoices.isLoading ||
							_unref(store).filteredAverageOfInvoices.isLoading
						)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                      _createVNode(_unref(ProgressSpinner))
                    ]))
                  : (_openBlock(), _createBlock(_unref(Chart), {
                      key: 1,
                      data: mainChart.value,
                      height: 590,
                      options: chartOptions.value,
                      type: "bar"
                    }, null, 8, ["data", "options"]))
              ]),
              _createElementVNode("div", _hoisted_15, _toDisplayString(_ctx.$t('dataUpdatedOn', { date: dataActualDate.value })), 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_16, [
            _createVNode(NewestInvoices, {
              items: _unref(store).newestInvoices.state,
              loading: _unref(store).newestInvoices.isLoading
            }, null, 8, ["items", "loading"])
          ])
        ]))
}
}

})