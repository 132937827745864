import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "tw3-flex tw3-items-center tw3-gap-2 tw3-capitalize" }
const _hoisted_2 = { style: {"font-size":"0.9rem"} }
const _hoisted_3 = { class: "tw3-flex tw3-items-center tw3-gap-2 tw3-capitalize" }
const _hoisted_4 = { style: {"font-size":"0.9rem"} }
const _hoisted_5 = { class: "tw3-flex tw3-items-center tw3-gap-2 tw3-capitalize" }
const _hoisted_6 = { style: {"font-size":"0.9rem"} }
const _hoisted_7 = { class: "tw3-flex tw3-items-center tw3-gap-2 tw3-capitalize" }
const _hoisted_8 = { style: {"font-size":"0.9rem"} }

import Tag from 'primevue/tag';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import { CustomerSearchItem } from '../../api/types';


export default /*@__PURE__*/_defineComponent({
  __name: 'ReportsCustomerTag',
  props: {
    item: {},
    taxExempt: { type: Boolean },
    poRequired: { type: Boolean },
    flagged: { type: Boolean }
  },
  setup(__props: any) {

const { t } = useI18n();
const props = __props;

const tagSettings = computed(() => {
	const tagProps = props.item;
	if (tagProps.status === 'disabled') {
		return {
			color: 'red',
			text: 'Disabled'
		};
	} else if (tagProps.is_insurance) {
		return {
			color: '#6366f1',
			text: 'Ins'
		};
	} else if (tagProps.is_commercial) {
		return {
			color: '#eab308',
			text: 'Com'
		};
	} else {
		return {
			color: '#06b6d4',
			text: 'Retail'
		};
	}
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_unref(Tag), {
      severity: "secondary",
      style: _normalizeStyle({
			'border-left': '10px solid black',
			'border-color': tagSettings.value.color
		})
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("span", _hoisted_2, _toDisplayString(tagSettings.value.text), 1)
        ])
      ]),
      _: 1
    }, 8, ["style"]),
    (_ctx.taxExempt)
      ? (_openBlock(), _createBlock(_unref(Tag), {
          key: 0,
          severity: "secondary"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("span", _hoisted_4, _toDisplayString(_unref(t)('reportsPage.filters.tags.exempt')), 1)
            ])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_ctx.poRequired)
      ? (_openBlock(), _createBlock(_unref(Tag), {
          key: 1,
          severity: "secondary",
          style: {
			'border-left': '10px solid black',
			'border-color': 'sky-blue'
		}
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("span", _hoisted_6, _toDisplayString(_unref(t)('reportsPage.filters.tags.poReq')), 1)
            ])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_ctx.flagged)
      ? (_openBlock(), _createBlock(_unref(Tag), {
          key: 2,
          severity: "secondary",
          style: {
			'border-left': '10px solid black',
			'border-color': 'dark'
		}
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("span", _hoisted_8, _toDisplayString(_unref(t)('reportsPage.filters.tags.flagged')), 1)
            ])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ], 64))
}
}

})