import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["href"]
const _hoisted_2 = { class: "tw3-flex tw3-items-center tw3-gap-2 tw3-capitalize" }
const _hoisted_3 = { style: {"font-size":"0.7rem","white-space":"nowrap"} }

import Tag from 'primevue/tag';

import { JobDocument } from '../../model/types';
import { getDocumentColorByType } from '../../utils';
import { getDocumentNameByType } from '../../utils/get-document-name-by-type.util';


export default /*@__PURE__*/_defineComponent({
  __name: 'JobDocumentCell',
  props: {
    data: {}
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("a", {
    class: "tw3-no-underline tw3-transition-all hover:tw3-opacity-70",
    href: _ctx.data.url,
    target: "_blank"
  }, [
    _createVNode(_unref(Tag), {
      severity: "secondary",
      style: _normalizeStyle({
				'border-left': '10px solid black',
				'border-color': _unref(getDocumentColorByType)(_ctx.data.document_type)
			})
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("span", _hoisted_3, _toDisplayString(_unref(getDocumentNameByType)(_ctx.data.document_type)), 1)
        ])
      ]),
      _: 1
    }, 8, ["style"])
  ], 8, _hoisted_1))
}
}

})