import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "tw3-grid tw3-grid-cols-1 sm:tw3-grid-cols-4 tw3-gap-4 tw3-mb-2" }
const _hoisted_2 = { class: "tw3-flex tw3-items-center tw3-gap-2" }
const _hoisted_3 = { class: "tw3-flex tw3-items-center tw3-gap-2" }
const _hoisted_4 = { class: "tw3-flex tw3-items-center tw3-gap-2" }
const _hoisted_5 = { class: "tw3-flex tw3-pt-8 tw3-space-x-4" }

import Button from 'primevue/button';
import Checkbox from 'primevue/checkbox';
import InputGroup from 'primevue/inputgroup';
import InputGroupAddon from 'primevue/inputgroupaddon';
import InputNumber from 'primevue/inputnumber';
import InputText from 'primevue/inputtext';
import { computed, ref } from 'vue';

import { useCreateAccountStore } from '../../model/createAccount';
import { AccountCreationStepValues, Tax } from '../../model/types';
import StepInputGroup from '../steps/StepInputGroup.vue';

import TaxesSection from './TaxesSection.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'ShopTaxesStep',
  setup(__props) {

const createAccountStore = useCreateAccountStore();

const defaultTaxForm = {
	name: '',
	percentage: 10,
	for_labor: false,
	for_materials: false,
	default_tax: false
};

const form = ref<Tax>({ ...defaultTaxForm });

const isFormValid = computed(() => {
	return (
		form.value.name &&
		form.value.percentage &&
		(form.value.for_labor || form.value.for_materials)
	);
});

const addTax = () => {
	createAccountStore.addShopTax(form.value);
	form.value = { ...defaultTaxForm };
};

const next = () => {
	createAccountStore.changeStep(AccountCreationStepValues.shopIntegrations);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[10] || (_cache[10] = _createElementVNode("div", { class: "tw3-mb-8 tw3-mr-24 tw3-text-slate-500" }, " Create default tax rates to apply to your invoices. You can always do this later. ", -1)),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(StepInputGroup, {
        class: "tw3-col-span-3",
        label: "Tax Jurisdiction",
        subLabel: "Name"
      }, {
        default: _withCtx(() => [
          _createVNode(_unref(InputText), {
            modelValue: form.value.name,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((form.value.name) = $event)),
            fluid: "",
            placeholder: "Name"
          }, null, 8, ["modelValue"])
        ]),
        _: 1
      }),
      _createVNode(StepInputGroup, { label: "Rate" }, {
        default: _withCtx(() => [
          _createVNode(_unref(InputGroup), null, {
            default: _withCtx(() => [
              _createVNode(_unref(InputNumber), {
                modelValue: form.value.percentage,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((form.value.percentage) = $event)),
                fluid: "",
                maxFractionDigits: 2,
                placeholder: "Rate"
              }, null, 8, ["modelValue"]),
              _createVNode(_unref(InputGroupAddon), null, {
                default: _withCtx(() => _cache[6] || (_cache[6] = [
                  _createTextVNode("%")
                ])),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_unref(Checkbox), {
          modelValue: form.value.for_labor,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((form.value.for_labor) = $event)),
          binary: "",
          inputId: "for_labor"
        }, null, 8, ["modelValue"]),
        _cache[7] || (_cache[7] = _createElementVNode("label", {
          class: "!tw3-font-bold !tw3-text-base !tw3-text-slate-700",
          for: "for_labor"
        }, " Labor ", -1))
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_unref(Checkbox), {
          modelValue: form.value.for_materials,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((form.value.for_materials) = $event)),
          binary: "",
          inputId: "for_materials"
        }, null, 8, ["modelValue"]),
        _cache[8] || (_cache[8] = _createElementVNode("label", {
          class: "!tw3-font-bold !tw3-text-base !tw3-text-slate-700",
          for: "for_materials"
        }, " Material ", -1))
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_unref(Checkbox), {
          modelValue: form.value.default_tax,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((form.value.default_tax) = $event)),
          binary: "",
          inputId: "default_tax"
        }, null, 8, ["modelValue"]),
        _cache[9] || (_cache[9] = _createElementVNode("label", {
          class: "!tw3-font-bold !tw3-text-base !tw3-text-slate-700",
          for: "default_tax"
        }, " Default ", -1))
      ]),
      _createVNode(_unref(Button), {
        disabled: !isFormValid.value,
        label: "Add Tax",
        severity: "info",
        size: "small",
        onClick: addTax
      }, null, 8, ["disabled"])
    ]),
    _createVNode(TaxesSection),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_unref(Button), {
        label: "Back",
        severity: "secondary",
        size: "small",
        onClick: _cache[5] || (_cache[5] = ($event: any) => (_unref(createAccountStore).changeStep(_unref(AccountCreationStepValues).shopLogo)))
      }),
      _createVNode(_unref(Button), {
        label: "Next",
        size: "small",
        onClick: next
      })
    ])
  ], 64))
}
}

})