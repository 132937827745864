import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createSlots as _createSlots } from "vue"

const _hoisted_1 = {
  class: "tw3-flex tw3-justify-between tw3-gap-2",
  style: {"padding-right":"2.5px"}
}
const _hoisted_2 = { class: "cell-container" }
const _hoisted_3 = { class: "tw3-w-full tw3-h-[600px] tw3-flex tw3-justify-center tw3-items-center" }
const _hoisted_4 = { class: "tw3-text-center tw3-text-3xl" }

import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import DatePicker from 'primevue/datepicker';
import InputNumber from 'primevue/inputnumber';
import InputText from 'primevue/inputtext';
import Select from 'primevue/select';
import SplitButton from 'primevue/splitbutton';
import { useConfirm } from 'primevue/useconfirm';
import { useI18n } from 'vue-i18n';
import { RouterLink } from 'vue-router';

import { Quote } from '@/entities/quote/lib/types';
import { isEmptyValue } from '@/shared/helpers';
import { RefreshButton } from '@/shared/ui';

import { header } from './lib/constants';
import { useQuotesStore } from './lib/store';

import type {
	DataTablePageEvent,
	DataTableSortEvent
} from 'primevue/datatable';


export default /*@__PURE__*/_defineComponent({
  __name: 'QuotesTable',
  setup(__props) {

const { t } = useI18n();

const confirm = useConfirm();

const quotesStore = useQuotesStore();

const bulkActions = [
	{
		label: 'Void',
		command: () => {
			handleVoid();
		}
	}
];

const handlePage = (state: DataTablePageEvent) => {
	quotesStore.pagination.page = state.page + 1;
};

const handleSort = (state: DataTableSortEvent) => {
	quotesStore.pagination.page = 1;

	quotesStore.handleSort({
		desc: state.sortOrder === -1,
		field: state.sortField as string
	});
};

const getFilterIconClass = (field: string) => {
	const filter = quotesStore.filters[field];

	return isEmptyValue(filter.value) || filter.value === 'all'
		? 'pi pi-filter'
		: 'pi pi-filter-fill';
};

const refreshQuotes = async () => {
	quotesStore.quotes.execute(0, await quotesStore.composeGetQuotesArgs());
};

const handleVoid = async () => {
	confirm.require({
		accept: async () => {
			const ids = quotesStore.selectedQuotes.map((row: Quote) => row.id);
			await quotesStore.voidQuotesAndRefetchQuotes(ids);
			quotesStore.selectedQuotes = [];
		},
		acceptLabel: t('yes'),
		acceptProps: {
			severity: 'success'
		},
		header: t('voidJobs.title'),
		message: t('voidJobs.message'),
		rejectLabel: t('no'),
		rejectProps: {
			severity: 'secondary'
		}
	});
};

const handleFollowupDate = async (id: string, newDate: Date | null) => {
	confirm.require({
		accept: async () => {
			await quotesStore.updateQuoteAndRefetchQuotes(id, newDate);
		},
		acceptLabel: t('yes'),
		acceptProps: {
			severity: 'success'
		},
		header: t('changeFollowupDate.title'),
		message: t('changeFollowupDate.message'),
		rejectLabel: t('no'),
		rejectProps: {
			severity: 'secondary'
		}
	});
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(DataTable), {
    filters: _unref(quotesStore).filters,
    "onUpdate:filters": _cache[2] || (_cache[2] = ($event: any) => ((_unref(quotesStore).filters) = $event)),
    selection: _unref(quotesStore).selectedQuotes,
    "onUpdate:selection": _cache[3] || (_cache[3] = ($event: any) => ((_unref(quotesStore).selectedQuotes) = $event)),
    dataKey: "id",
    filterDisplay: "menu",
    lazy: "",
    loading: _unref(quotesStore).quotes.isLoading,
    paginator: "",
    rows: _unref(quotesStore).pagination.pageSize,
    scrollable: "",
    sortField: _unref(quotesStore).sort.field,
    sortOrder: _unref(quotesStore).sort.desc === false ? 1 : -1,
    tableClass: "tw3-max-w-full",
    totalRecords: _unref(quotesStore).quotes.state.numberOfRowsFound,
    value: _unref(quotesStore).quotes.state.rows,
    onPage: handlePage,
    onSort: handleSort
  }, _createSlots({
    header: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", null, [
          _createVNode(_unref(Select), {
            modelValue: _unref(quotesStore).selectedShop,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(quotesStore).selectedShop) = $event)),
            optionLabel: "name",
            options: _unref(quotesStore).shops,
            placeholder: `${_unref(t)('selectShop')}`
          }, null, 8, ["modelValue", "options", "placeholder"]),
          (_unref(quotesStore).selectedQuotes.length)
            ? (_openBlock(), _createBlock(_unref(SplitButton), {
                key: 0,
                class: "customized-split-button tw3-ml-5",
                label: "Bulk Actions",
                model: bulkActions,
                outlined: ""
              }))
            : _createCommentVNode("", true)
        ]),
        _createVNode(_unref(RefreshButton), {
          onClick: _cache[1] || (_cache[1] = ($event: any) => (refreshQuotes()))
        })
      ])
    ]),
    default: _withCtx(() => [
      _createVNode(_unref(Column), {
        headerStyle: "width: 3rem",
        selectionMode: "multiple"
      }),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(header), (column) => {
        return (_openBlock(), _createBlock(_unref(Column), {
          key: column.key,
          field: column.key,
          filterField: column.key,
          header: column.title,
          headerClass: "tw3-whitespace-nowrap",
          showFilterMatchModes: false,
          showFilterMenu: column.key !== 'actions',
          sortable: column.key !== 'actions',
          style: _normalizeStyle({
				width:
					column.key === 'customerPhone'
						? '12%'
						: column.key === 'followupDate'
							? '13%'
							: column.key === 'quoteDate'
								? '8%'
								: 'auto'
			})
        }, {
          body: _withCtx(({ data }) => [
            _createElementVNode("div", _hoisted_2, [
              (column.key === 'id')
                ? (_openBlock(), _createBlock(_unref(RouterLink), {
                    key: 0,
                    target: "_blank",
                    to: `/jobs/${data[column.key]}`
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(data[column.key]), 1)
                    ]),
                    _: 2
                  }, 1032, ["to"]))
                : (column.key === 'followupDate')
                  ? (_openBlock(), _createBlock(_unref(DatePicker), {
                      key: 1,
                      appendTo: "#vue3app",
                      dateFormat: "m/d/yy",
                      mask: "9/9/9999",
                      modelValue: data[column.key] ? new Date(data[column.key]) : null,
                      placeholder: "M/D/YYYY",
                      showIcon: "",
                      "onUpdate:modelValue": 
							v => handleFollowupDate(data.id, v as Date | null)
						
                    }, null, 8, ["modelValue", "onUpdate:modelValue"]))
                  : (_openBlock(), _createElementBlock("div", {
                      key: 2,
                      class: _normalizeClass({
							'tw3-text-center': column.key === 'quoteDate',
							'tw3-text-right': column.key === 'amount'
						})
                    }, _toDisplayString(data[column.key]), 3))
            ])
          ]),
          filtericon: _withCtx(() => [
            _createElementVNode("i", {
              class: _normalizeClass(getFilterIconClass(column.key))
            }, null, 2)
          ]),
          filter: _withCtx(({ filterModel }) => [
            (column.key === 'quoteDate' || column.key === 'followupDate')
              ? (_openBlock(), _createBlock(_unref(DatePicker), {
                  key: 0,
                  modelValue: filterModel.value,
                  "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
                  appendTo: "#vue3app",
                  dateFormat: "m/d/yy",
                  mask: "9/9/9999",
                  placeholder: "M/D/YYYY"
                }, null, 8, ["modelValue", "onUpdate:modelValue"]))
              : (column.key === 'customerPhone' || column.key === 'id')
                ? (_openBlock(), _createBlock(_unref(InputNumber), {
                    key: 1,
                    allowEmpty: "",
                    class: "p-column-filter",
                    modelValue: filterModel.value,
                    placeholder: `Search by ${column.title}`,
                    type: "number",
                    useGrouping: false,
                    "onUpdate:modelValue": v => (filterModel.value = v ? String(v) : v)
                  }, null, 8, ["modelValue", "placeholder", "onUpdate:modelValue"]))
                : (column.key === 'amount')
                  ? (_openBlock(), _createBlock(_unref(InputNumber), {
                      key: 2,
                      allowEmpty: "",
                      class: "p-column-filter",
                      currency: "USD",
                      locale: "en-US",
                      mode: "currency",
                      modelValue: filterModel.value,
                      placeholder: `Search by ${column.title}`,
                      "onUpdate:modelValue": v => (filterModel.value = v ? String(v) : v)
                    }, null, 8, ["modelValue", "placeholder", "onUpdate:modelValue"]))
                  : (column.key === 'gbCsrId')
                    ? (_openBlock(), _createBlock(_unref(Select), {
                        key: 3,
                        modelValue: filterModel.value,
                        "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
                        class: "p-column-filter",
                        loading: _unref(quotesStore).csrs.isLoading,
                        optionLabel: "name",
                        options: _unref(quotesStore).csrs.state,
                        optionValue: "id",
                        placeholder: `Search by ${column.title}`
                      }, null, 8, ["modelValue", "onUpdate:modelValue", "loading", "options", "placeholder"]))
                    : (_openBlock(), _createBlock(_unref(InputText), {
                        key: 4,
                        modelValue: filterModel.value,
                        "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
                        class: "p-column-filter",
                        placeholder: `Search by ${column.title}`,
                        type: "text"
                      }, null, 8, ["modelValue", "onUpdate:modelValue", "placeholder"]))
          ]),
          _: 2
        }, 1032, ["field", "filterField", "header", "showFilterMenu", "sortable", "style"]))
      }), 128))
    ]),
    _: 2
  }, [
    (!_unref(quotesStore).quotes.isLoading)
      ? {
          name: "empty",
          fn: _withCtx(() => [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("p", _hoisted_4, _toDisplayString(_unref(t)('noData')), 1)
            ])
          ]),
          key: "0"
        }
      : undefined
  ]), 1032, ["filters", "selection", "loading", "rows", "sortField", "sortOrder", "totalRecords", "value"]))
}
}

})