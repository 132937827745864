import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "tw3-flex tw3-items-center tw3-gap-2" }
const _hoisted_2 = { class: "p-dialog-title" }
const _hoisted_3 = { class: "tw3-relative tw3-flex tw3-flex-col tw3-w-[250px] tw3-h-[313px] tw3-gap-[0.75rem] tw3-overflow-hidden" }
const _hoisted_4 = {
  key: 0,
  class: "tw3-absolute tw3-right-0 tw3-top-0 tw3-z-10 tw3-bg-white tw3-w-full tw3-h-full tw3-overflow-auto tw3-flex tw3-flex-col tw3-gap-4 history-container"
}
const _hoisted_5 = {
  key: 0,
  class: "tw3-mb-2 tw3-font-semibold tw3-text-gray-800"
}
const _hoisted_6 = { class: "tw3-text-gray-500 tw3-text-sm" }
const _hoisted_7 = { class: "flex items-center" }
const _hoisted_8 = { class: "tw3-flex tw3-items-center tw3-capitalize tw3-px-2 tw3-py-0.5 tw3-gap-2 tw3-text-sm" }
const _hoisted_9 = { class: "tw3-relative tw3-w-full" }
const _hoisted_10 = { class: "tw3-absolute tw3-text-xs tw3-left-2 tw3-bg-white tw3-top-[-7px] tw3-px-[0.25rem]" }

import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import InputText from 'primevue/inputtext';
import Listbox from 'primevue/listbox';
import ToggleButton from 'primevue/togglebutton';
import { ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';

import { EdiStatus } from '../../../model/enums';
import { JobEdi, JobEdiUpdateRequest } from '../../../model/types';


export default /*@__PURE__*/_defineComponent({
  __name: 'JobEditEdi',
  props: {
    data: {},
    show: { type: Boolean },
    loading: { type: Boolean }
  },
  emits: ["on-close", "on-update"],
  setup(__props: any, { emit: __emit }) {

const { t } = useI18n();

const ediOptions = Object.values(EdiStatus).map(v => ({
	id: v,
	text: t(`edi.${v}`)
}));

const props = __props;
const emit = __emit;

const historyPopup = ref(false);
const localValue = ref<EdiStatus>(EdiStatus.Unsubmitted);
const msg = ref('');

const history = ref<{ serverMsg: string; userMsg?: string }[]>([]);

const save = async () => {
	if (!props.data) return;
	emit('on-update', {
		status: localValue.value ? localValue.value : EdiStatus.Unsubmitted,
		msg: msg.value
	});
};

const parseMessages = (input: string) => {
	return input.split(/<br><br>/).map(entry => {
		const parts = entry.split(/<br>/);
		return {
			serverMsg: parts[0].trim(),
			userMsg: parts[1] ? parts[1].trim() : undefined
		};
	});
};

watch(
	() => props.show,
	async () => {
		historyPopup.value = false;
		if (props.show) {
			localValue.value = props.data.status
				? props.data.status
				: EdiStatus.Unsubmitted;
			msg.value = '';
			history.value = props.data.job_edi_message
				? parseMessages(props.data.job_edi_message)
				: [];
		}
	}
);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(Dialog), {
    id: "vue3JobEditEdiDialog",
    header: _ctx.$t('edi.status'),
    modal: "",
    visible: _ctx.show,
    "onUpdate:visible": _cache[3] || (_cache[3] = ($event: any) => (emit('on-close')))
  }, {
    header: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t('edi.status')), 1),
        (history.value.length)
          ? (_openBlock(), _createBlock(_unref(ToggleButton), {
              key: 0,
              modelValue: historyPopup.value,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((historyPopup).value = $event)),
              binary: "",
              disabled: _ctx.loading,
              offLabel: _ctx.$t('history'),
              onLabel: _ctx.$t('history'),
              size: "small"
            }, null, 8, ["modelValue", "disabled", "offLabel", "onLabel"]))
          : _createCommentVNode("", true)
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_Transition, { name: "slide-menu" }, {
          default: _withCtx(() => [
            (historyPopup.value)
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(history.value, (item, index) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: index,
                      class: "tw3-bg-white tw3-rounded-2xl tw3-p-4 tw3-border tw3-border-gray-200"
                    }, [
                      (item.userMsg)
                        ? (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString(_ctx.$t('message')) + ": " + _toDisplayString(item.userMsg), 1))
                        : _createCommentVNode("", true),
                      _createElementVNode("p", _hoisted_6, _toDisplayString(item.serverMsg), 1)
                    ]))
                  }), 128))
                ]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }),
        _createVNode(_unref(Listbox), {
          modelValue: localValue.value,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((localValue).value = $event)),
          checkmark: "",
          class: "w-full md:w-56",
          disabled: _ctx.loading,
          listStyle: "max-height:250px",
          optionLabel: "text",
          options: _unref(ediOptions),
          optionValue: "id",
          "pt:root:class": "!tw3-border-0"
        }, {
          option: _withCtx((slotProps) => [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, _toDisplayString(slotProps.option.text), 1)
            ])
          ]),
          _: 1
        }, 8, ["modelValue", "disabled", "options"]),
        _createElementVNode("div", _hoisted_9, [
          _createVNode(_unref(InputText), {
            modelValue: msg.value,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((msg).value = $event)),
            class: "tw3-w-full",
            loading: _ctx.loading
          }, null, 8, ["modelValue", "loading"]),
          _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.$t('message')) + " (" + _toDisplayString(_ctx.$t('optional')) + ") ", 1)
        ]),
        _createVNode(_unref(Button), {
          class: "w-full md:w-14rem",
          label: _unref(t)('save'),
          loading: _ctx.loading,
          "pt:root:class": "tw3-rounded-t-none",
          size: "small",
          onClick: save
        }, null, 8, ["label", "loading"])
      ])
    ]),
    _: 1
  }, 8, ["header", "visible"]))
}
}

})