<script setup lang="ts">
import Card from 'primevue/card';
import IconField from 'primevue/iconfield';
import InputIcon from 'primevue/inputicon';
import InputText from 'primevue/inputtext';
import Tag from 'primevue/tag';
import { computed, onBeforeMount, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';

import { useUserStore } from '@/entities/user/store';
import { useMessages } from '@/shared/composables';
import { capitalizeStr } from '@/shared/helpers';

import { reportsMenuConfig } from './lib/constants';

const router = useRouter();
const messages = useMessages();
const i18n = useI18n();
const breadcrumbs = [
	{ label: i18n.t('reports'), path: '' },
	{ label: `${i18n.t('all')} ${i18n.t('reports')}`, path: '' }
];

const userStore = useUserStore();
const searchQuery = ref('');

const config = computed(() => {
	const configArray = [];
	for (let i = 0; i < reportsMenuConfig.length; i++) {
		if (reportsMenuConfig[i].id === 'accounting-reports') {
			if (
				userStore.accountPackagesState.state &&
				userStore.accountPackagesState.state.length > 0
			) {
				configArray.push(reportsMenuConfig[i]);
			}
		} else {
			const filterRportsByPermission = reportsMenuConfig[i].items.filter(
				item => !item.permissionId || userStore.checkUserCan(item.permissionId)
			);
			reportsMenuConfig[i].items = filterRportsByPermission;
			if (reportsMenuConfig[i].items.length) {
				configArray.push(reportsMenuConfig[i]);
			}
		}
	}

	return configArray;
});

const filteredConfig = computed(() => {
	if (!searchQuery.value.trim()) return config.value;
	return config.value
		.map(group => {
			const filteredItems = group.items.filter(item => {
				const byTitle = item.label
					.toLowerCase()
					.includes(searchQuery.value.toLowerCase());
				const byDescription = item.desc
					.toLowerCase()
					.includes(searchQuery.value.toLowerCase());
				return byTitle || byDescription;
			});
			return { ...group, items: filteredItems };
		})
		.filter(group => group.items.length > 0);
});

const highlightSearchingText = (text: string, searchQuery: string): string => {
	if (!searchQuery.trim()) return text;
	const regex = new RegExp(`(${searchQuery.trim()})`, 'gi');
	return text.replace(regex, '<span class="highlight">$1</span>');
};

const showToast = (text: string) => {
	messages.showInfo(text);
};

onBeforeMount(() => {
	if (!userStore.checkUserCan('reports')) {
		router.push('/');
	}
});
</script>

<template>
	<div
		class="tw3-flex tw3-flex-col tw3-max-w-[1440px] tw3-w-full tw3-mx-auto tw3-h-full"
	>
		<div
			class="tw3-flex tw3-flex-col tw3-gap-4 md:tw3-flex-row md:tw3-items-start md:tw3-justify-between tw3-pb-[1rem]"
		>
			<nav class="layout-breadcrumb tw3-text-gray-500 tw3-mr-4 tw3-">
				<ol>
					<li>
						<RouterLink class="tw3-cursor-pointer tw3-transition-all" to="/">
							<i class="pi pi-home"></i>
						</RouterLink>
					</li>
					<li class="layout-breadcrumb-chevron">/</li>
					<template
						v-for="(breadcrumbRoute, i) in breadcrumbs"
						:key="breadcrumbRoute"
					>
						<li>
							<RouterLink
								v-if="breadcrumbRoute.path"
								class="tw3-cursor-pointer tw3-transition-all"
								:to="breadcrumbRoute.path"
							>
								{{ capitalizeStr(breadcrumbRoute.label) }}
							</RouterLink>
							<template v-else>
								{{ capitalizeStr(breadcrumbRoute.label) }}
							</template>
						</li>
						<li
							v-if="i !== breadcrumbs.length - 1"
							class="layout-breadcrumb-chevron"
						>
							/
						</li>
					</template>
				</ol>
			</nav>

			<IconField class="tw3-w-full sm:tw3-w-4/12 md:tw3-mt-[-8px]">
				<InputIcon class="pi pi-search" />
				<InputText
					v-model="searchQuery"
					class="tw3-w-full"
					placeholder="Search reports..."
					type="text"
				/>
			</IconField>
		</div>
		<Card class="tw3-h-full tw3-overflow-auto">
			<template #content>
				<div class="tw3-flex tw3-flex-col tw3-flex-1">
					<h2
						class="tw3-text-xl tw3-font-extrabold tw3-bg-white tw3-px-4 tw3-py-6"
					>
						{{ $t('allReports') }}
					</h2>
					<div
						v-if="!filteredConfig.length"
						class="tw3-w-full tw3-h-full tw3-flex tw3-justify-center tw3-items-center tw3-border-t"
					>
						<p class="tw3-text-xl tw3-text-center tw3-py-7">
							{{ $t('noData') }}
						</p>
					</div>
					<div
						v-for="group in filteredConfig"
						v-else
						:id="group.id"
						:key="group.id"
					>
						<div
							class="tw3-w-full tw3-flex tw3-items-center tw3-gap-4 tw3-p-4 tw3-border-t tw3-border-b"
						>
							<div class="icon-component">
								<component :is="group.img" />
							</div>
							<div class="tw3-w-full tw3-flex tw3-flex-col tw3-gap-2">
								<div class="tw3-flex tw3-items-center tw3-gap-2">
									<h2 class="tw3-text-xl tw3-font-bold">
										{{ group.label }}
									</h2>
									<Tag>Reports: {{ group.items.length }}</Tag>
								</div>
								<div class="tw3-text-gray-500 tw3-text-sm">
									{{ group.desc }}
								</div>
							</div>
						</div>

						<div class="tw3-flex tw3-flex-col">
							<template v-for="item in group.items" :key="item.id">
								<component
									:is="item.commingSoon ? 'div' : 'router-link'"
									class="tw3-flex tw3-flex-col tw3-gap-2 tw3-px-8 tw3-py-4 tw3-border-b tw3-border-slate-200 last:tw3-border-b-0 tw3-bg-white hover:tw3-bg-[#E2E8F0] !tw3-no-underline tw3-cursor-pointer tw3-transition-all"
									:to="item.commingSoon ? null : `/reports/${item.id}`"
									@click="
										item.commingSoon ? showToast(item.command as string) : null
									"
								>
									<div class="tw3-flex tw3-items-center tw3-gap-2">
										<div
											class="tw3-text-blue-500 tw3-font-semibold tw3-text-lg"
											v-html="highlightSearchingText(item.label, searchQuery)"
										></div>
										<Tag v-if="item.commingSoon" severity="warn"
											>Comming soon</Tag
										>
										<Tag v-else severity="info"
											>Filters: {{ item.filters }}</Tag
										>
									</div>
									<div
										class="tw3-text-gray-500 tw3-text-sm"
										v-html="highlightSearchingText(item.desc, searchQuery)"
									></div>
								</component>
							</template>
						</div>
					</div>
				</div>
			</template>
		</Card>
	</div>
</template>

<style lang="scss" scoped>
:deep(.highlight) {
	display: inline-block;
	background-color: #fb923c !important;
}

.gb-header-cover {
	position: fixed;
	top: 56px;
	width: 100%;
	height: 1rem;

	z-index: 10;
}

.gb-default-bg {
	background-color: #f2f2f2;
}

.layout-breadcrumb {
	ol {
		display: flex;
		align-items: center;
		margin: 0;
		padding: 0;
		list-style: none;
		gap: 1rem;
		flex-wrap: wrap;

		li {
			font-weight: 600;
		}
	}
}

.gb-categories-block {
	scrollbar-width: none;
	-ms-overflow-style: none;
	overflow: auto;

	&::-webkit-scrollbar {
		width: 0;
		height: 0;
	}
}

.icon-component {
	display: flex;
	width: 2rem;
	height: 2rem;
	transition: 0.2s ease;

	svg {
		height: 100%;
		width: 100%;
	}
}

.category-hover:hover {
	.icon-component {
		color: #60a5fa;
	}
}
</style>
