import {
	ApolloClient,
	ApolloLink,
	createHttpLink,
	InMemoryCache
} from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';

import { authService } from '../auth';
import { config } from '../config';

let apollo: ApolloClient<any>;

export function apolloClient(): ApolloClient<any> {
	if (apollo) {
		return apollo;
	}

	apollo = new ApolloClient({
		cache: new InMemoryCache(),
		connectToDevTools: config().isDev,
		link: ApolloLink.from([
			onError(({ graphQLErrors, operation }) => {
				if (graphQLErrors) {
					graphQLErrors.forEach(error => {
						console.error(`${operation.operationName}: ${error.message}`);
					});
				}
			}),
			setContext(async (_, { headers }) => {
				return {
					headers: {
						...headers,
						Authorization: authService().getAuthorizationHeader(),
						'Visitor-Fingerprint-Id': authService().getVisitorFingerprintId()
					}
				};
			}),
			createHttpLink({
				uri: `${config().getBaseURL()}/apollo/job-details`
			})
		])
	});

	return apollo;
}
