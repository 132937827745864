import { Address } from '@/entities/address/lib/types';

import { HostedPage, Plan } from '../api';

export enum AccountCreationSteps {
	organization = 'organization',
	shopInfo = 'shop/info',
	shopLogo = 'shop/logo',
	shopTaxes = 'shop/taxes',
	shopIntegrations = 'shop/integrations',
	billingPlan = 'billing/plan',
	billingFinancing = 'billing/financing',
	billingPayment = 'billing/payment'
}

export enum AccountCreationStepValues {
	organization = '1',
	shopInfo = '2',
	shopLogo = '3',
	shopTaxes = '4',
	shopIntegrations = '5',
	billingPlan = '6',
	billingFinancing = '7',
	billingPayment = '8'
}

export type OrganizationForm = {
	name: string;
	contact: string;
	email: string;
	phone: string;
	fax: string;
	g_address: Partial<Address>;
};

export type ShopInfoForm = {
	name: string;
	tax_id: string;
	g_address: Partial<Address>;
	phone: string;
	fax: string;
	email: string;
	website: string;
};

export type ShopLogoForm = {
	logo: string;
};

export type ShopTaxesForm = {
	enabled: boolean;
	tax: Tax[];
};

export type Tax = {
	default_tax: boolean;
	for_labor: boolean;
	for_materials: boolean;
	name: string;
	percentage: number;
};

export type ShopIntegrationsForm = {
	mygrant_account_id: string;
	pilkington_username: string;
	pilkington_password: string;
	pgw_customer_number: string;
};

export type BillingPlanForm = {
	plan: Plan;
};

export type BillingFinancingForm = {
	finance?: boolean;
	hostedpage: HostedPage;
};

export type AccountCreationForm = {
	[AccountCreationSteps.organization]: OrganizationForm;
	[AccountCreationSteps.shopInfo]: ShopInfoForm;
	[AccountCreationSteps.shopLogo]: ShopLogoForm;
	[AccountCreationSteps.shopTaxes]: ShopTaxesForm;
	[AccountCreationSteps.shopIntegrations]: ShopIntegrationsForm;
	[AccountCreationSteps.billingPlan]: BillingPlanForm;
	[AccountCreationSteps.billingFinancing]: BillingFinancingForm;
};
