import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, renderSlot as _renderSlot, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "tw3-flex tw3-justify-center" }

import Button from 'primevue/button';
import Popover from 'primevue/popover';
import { ref } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'JobPopoverCell',
  props: {
    name: {},
    label: {}
  },
  setup(__props: any) {



const popup = ref<any>();

const toggle = (event: MouseEvent) => {
	popup.value.toggle(event);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_unref(Button), {
      "aria-controls": `overlay_popover_${_ctx.name}`,
      label: _ctx.label,
      rounded: "",
      severity: "secondary",
      small: "",
      style: {"padding":"0.25rem 0.5rem","font-size":"0.8rem","white-space":"nowrap"},
      text: "",
      onClick: toggle
    }, null, 8, ["aria-controls", "label"]),
    _createVNode(_unref(Popover), {
      id: `overlay_popover_${_ctx.name}`,
      ref_key: "popup",
      ref: popup,
      class: "tw3-max-w-72 tw3-max-h-96 tw3-overflow-auto"
    }, {
      default: _withCtx(() => [
        _renderSlot(_ctx.$slots, "default")
      ]),
      _: 3
    }, 8, ["id"])
  ]))
}
}

})