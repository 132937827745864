import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock, createVNode as _createVNode } from "vue"

const _hoisted_1 = { style: {"margin-bottom":"1rem","display":"flex","padding-left":"3rem","padding-right":"3rem"} }
const _hoisted_2 = { style: {"font-size":"1rem","flex-grow":"1"} }
const _hoisted_3 = { style: {"margin-right":"0.5rem"} }
const _hoisted_4 = { style: {"font-size":"0.75rem","color":"#4b5563"} }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = {
  key: 0,
  style: {"text-align":"right","margin-top":"1rem","padding-left":"3rem","padding-right":"3rem"}
}
const _hoisted_7 = { style: {"padding-left":"3rem","padding-right":"3rem","margin-top":"1.5rem"} }

import Tag from 'primevue/tag';

import AgingTable from './AgingTable.vue';
import OutstandingBalances from './OutstandingBalances.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'InvoicesSection',
  props: {
    data: {},
    name: {},
    shopId: {},
    groupBy: {},
    expandPayments: { type: Boolean }
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("span", {
          style: _normalizeStyle({
						'font-style': !_ctx.name ? 'italic' : 'normal',
						'margin-right': '0.5rem'
					})
        }, [
          _createTextVNode(_toDisplayString(_ctx.name || 'No name') + " ", 1),
          _createElementVNode("span", _hoisted_3, [
            (_ctx.data.consumer_type === 'insurance')
              ? (_openBlock(), _createBlock(_unref(Tag), {
                  key: 0,
                  severity: "info",
                  size: "small",
                  value: "Ins"
                }))
              : (_ctx.data.consumer_type === 'commercial')
                ? (_openBlock(), _createBlock(_unref(Tag), {
                    key: 1,
                    severity: "danger",
                    size: "small",
                    value: "Com"
                  }))
                : (_ctx.data.consumer_type === 'cash')
                  ? (_openBlock(), _createBlock(_unref(Tag), {
                      key: 2,
                      severity: "success",
                      size: "small",
                      value: "Cash"
                    }))
                  : _createCommentVNode("", true)
          ]),
          _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.data.invoices.length) + " invoice" + _toDisplayString(_ctx.data.invoices.length > 1 ? 's' : ''), 1)
        ], 4)
      ]),
      (_ctx.groupBy === 'age')
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _cache[0] || (_cache[0] = _createElementVNode("span", { style: {"font-size":"0.875rem","margin-right":"0.5rem"} }, "Total:", -1)),
            _createTextVNode(" $" + _toDisplayString(_ctx.data.balance), 1)
          ]))
        : _createCommentVNode("", true)
    ]),
    _createVNode(AgingTable, {
      expandPayments: _ctx.expandPayments,
      invoices: _ctx.data.invoices,
      shopId: _ctx.shopId
    }, null, 8, ["expandPayments", "invoices", "shopId"]),
    (_ctx.groupBy === 'age')
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _cache[1] || (_cache[1] = _createElementVNode("span", { style: {"font-size":"0.875rem","margin-right":"0.5rem"} }, "Total:", -1)),
          _createTextVNode(" $" + _toDisplayString(_ctx.data.balance), 1)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_7, [
      (_ctx.groupBy === 'consumer')
        ? (_openBlock(), _createBlock(OutstandingBalances, {
            key: 0,
            groupBy: _ctx.groupBy,
            ninetyPlusBalance: _ctx.data.ninety_plus_balance,
            sixtyNinetyBalance: _ctx.data.sixty_ninety_balance,
            thirtySixtyBalance: _ctx.data.thirty_sixty_balance,
            totalBalance: _ctx.data.balance,
            zeroThirtyBalance: _ctx.data.zero_thirty_balance
          }, null, 8, ["groupBy", "ninetyPlusBalance", "sixtyNinetyBalance", "thirtySixtyBalance", "totalBalance", "zeroThirtyBalance"]))
        : _createCommentVNode("", true)
    ]),
    _cache[2] || (_cache[2] = _createElementVNode("hr", null, null, -1))
  ]))
}
}

})