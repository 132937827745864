import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "tw3-flex tw3-flex-col tw3-w-full tw3-col-span-1"
}
const _hoisted_2 = { class: "tw3-content-end tw3-text-slate-500 tw3-font-medium tw3-text-sm tw3-relative" }
const _hoisted_3 = { class: "tw3-flex tw3-flex-col tw3-w-full tw3-col-span-1" }
const _hoisted_4 = { class: "tw3-content-end tw3-text-slate-500 tw3-font-medium tw3-text-sm tw3-relative" }

import dayjs from 'dayjs';
import { storeToRefs } from 'pinia';
import Badge from 'primevue/badge';
import DatePicker from 'primevue/datepicker';
import Select from 'primevue/select';
import { computed, onMounted, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';

import { capitalizeStr } from '@/shared/helpers';
import useReportsStore from '@/widgets/reportsElements/model/store';

import { defaultDateTypes } from '../../model/constants';


export default /*@__PURE__*/_defineComponent({
  __name: 'ReportsDateFilter',
  setup(__props) {

const { t } = useI18n();

const reportsStore = useReportsStore();

const { currentReportType } = storeToRefs(reportsStore);

const dateTypes = computed(() => {
	if (currentReportType.value.group === 'sales') {
		const dateTypes = [...defaultDateTypes];

		return dateTypes;
	} else {
		const { group, id } = currentReportType.value;
		if (group === 'install') {
			const baseDates = [
				{ name: t('reportsPage.filters.invoice_date'), id: 'invoice_date' },
				{ name: t('reportsPage.filters.install_date'), id: 'install_date' }
			];

			if (id === 'tech-collections') {
				return baseDates;
			}

			return [
				...baseDates,
				{ name: 'Payment Date', id: 'payment_date' },
				{ name: 'Last Status Date', id: 'status_date' }
			];
		}

		if (group === 'scheduler' && id === 'schedule') {
			return [
				{ name: t('reportsPage.filters.scheduled_date'), id: 'scheduled_date' },
				{ name: t('reportsPage.filters.unscheduled'), id: 'unscheduled' }
			];
		}

		return [
			{ name: t('reportsPage.filters.invoice_date'), id: 'invoice_date' },
			{ name: t('reportsPage.filters.install_date'), id: 'install_date' }
		];
	}
});
const isDateRange = computed(
	() => currentReportType.value.id !== 'balance-sheet'
);
const dateTypeFilter = ref(dateTypes.value[0]);
const datePickerValue = ref();

watch(dateTypeFilter, () => {
	reportsStore.dateType = dateTypeFilter.value.id;
});
watch(dateTypes, () => {
	dateTypeFilter.value = dateTypes.value[0];
});
watch(currentReportType, () => {
	reportsStore.dateType = currentReportType.value.default_date_type
		? currentReportType.value.default_date_type
		: dateTypeFilter.value.id;
});
watch(
	() => reportsStore.dateFilterValue,
	() => {
		if (!reportsStore.dateFilterValue) {
			datePickerValue.value = null;
		}
	}
);
onMounted(() => {
	reportsStore.dateType = currentReportType.value.default_date_type
		? currentReportType.value.default_date_type
		: dateTypeFilter.value.id;
});

function handleDateChange(event: any) {
	let formattedValue;
	if (isDateRange.value) {
		const [startDate, endDate] = event;
		const formattedStartDate = startDate
			? dayjs(startDate).format('YYYY-MM-DD')
			: null;
		const formattedEndDate = endDate
			? dayjs(endDate).format('YYYY-MM-DD')
			: null;
		formattedValue = {
			startDate: formattedStartDate,
			endDate: formattedEndDate
		};
	} else {
		formattedValue = dayjs(event).format('YYYY-MM-DD');
	}

	reportsStore.dateFilterValue = formattedValue;
}

return (_ctx: any,_cache: any) => {
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([
			'tw3-flex tw3-flex-col tw3-gap-5 tw3-w-full',
			{
				'tw3-col-span-1 md:tw3-col-span-2 md:tw3-grid md:tw3-grid-cols-2 ':
					!_unref(currentReportType).date
			}
		])
  }, [
    (!_unref(currentReportType).date)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("p", _hoisted_2, [
            _createTextVNode(_toDisplayString(_unref(capitalizeStr)(_unref(t)('reportsPage.filters.dateType'))) + " ", 1),
            _withDirectives(_createVNode(_unref(Badge), {
              class: "tw3-absolute !tw3-h-[0.3rem] !tw3-min-w-[0.3rem] !tw3-w-[0.3rem]",
              severity: "danger"
            }, null, 512), [
              [
                _directive_tooltip,
                _unref(t)('required'),
                void 0,
                { top: true }
              ]
            ])
          ]),
          _createVNode(_unref(Select), {
            modelValue: dateTypeFilter.value,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((dateTypeFilter).value = $event)),
            filter: "",
            optionLabel: "name",
            options: dateTypes.value,
            placeholder: `${_unref(t)('select')} ${_unref(t)('reportsPage.filters.dateType')}...`
          }, null, 8, ["modelValue", "options", "placeholder"])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("p", _hoisted_4, [
        _createTextVNode(_toDisplayString(_unref(capitalizeStr)(_unref(currentReportType)?.date || dateTypeFilter.value.name)) + " ", 1),
        (_unref(reportsStore).isRequired('date'))
          ? _withDirectives((_openBlock(), _createBlock(_unref(Badge), {
              key: 0,
              class: "tw3-absolute !tw3-h-[0.3rem] !tw3-min-w-[0.3rem] !tw3-w-[0.3rem]",
              severity: "danger"
            }, null, 512)), [
              [
                _directive_tooltip,
                _unref(t)('required'),
                void 0,
                { top: true }
              ]
            ])
          : _createCommentVNode("", true)
      ]),
      _createVNode(_unref(DatePicker), {
        modelValue: datePickerValue.value,
        "onUpdate:modelValue": [
          _cache[1] || (_cache[1] = ($event: any) => ((datePickerValue).value = $event)),
          handleDateChange
        ],
        dateFormat: "mm/dd/yy",
        disabled: dateTypeFilter.value.id === 'unscheduled',
        hideOnDateTimeSelect: "",
        hideOnRangeSelection: "",
        manualInput: false,
        placeholder: `${isDateRange.value ? _unref(t)('pickDates') : _unref(t)('pickDate')}...`,
        selectionMode: isDateRange.value ? 'range' : 'single',
        showIcon: ""
      }, null, 8, ["modelValue", "disabled", "placeholder", "selectionMode"])
    ])
  ], 2))
}
}

})