import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "tw3-grid tw3-basis-2/3 tw3-gap-2" }
const _hoisted_2 = { class: "tw3-h-8 tw3-relative tw3-content-end tw3-text-slate-500 tw3-font-medium tw3-text-sm" }
const _hoisted_3 = { class: "tw3-h-8 tw3-relative tw3-content-end tw3-text-slate-500 tw3-font-medium tw3-text-sm" }
const _hoisted_4 = { class: "tw3-flex tw3-gap-4 tw3-justify-between" }
const _hoisted_5 = { class: "tw3-h-8 tw3-relative tw3-content-end tw3-text-slate-500 tw3-font-medium tw3-text-sm" }
const _hoisted_6 = { style: {"min-width":"3.5rem"} }
const _hoisted_7 = { class: "tw3-h-8 tw3-relative tw3-content-end tw3-text-slate-500 tw3-font-medium tw3-text-sm" }

import Badge from 'primevue/badge';
import DatePicker from 'primevue/datepicker';
import Fieldset from 'primevue/fieldset';
import InputText from 'primevue/inputtext';
import ToggleButton from 'primevue/togglebutton';

import { useI18n } from 'vue-i18n';

import { isEmptyValue } from '@/shared/helpers';


export default /*@__PURE__*/_defineComponent({
  __name: 'AutoAccept',
  props: /*@__PURE__*/_mergeModels({
    status: { type: Boolean }
  }, {
    "ackDetails": { required: true },
    "ackDetailsModifiers": {},
  }),
  emits: ["update:ackDetails"],
  setup(__props: any) {

const { t } = useI18n();

const ackDetails = _useModel<{
	mobileIndicator: boolean;
	requestedAppointmentDt: string;
	workLocationPostalCode: string;
	acceptanceContact: string;
}>(__props, 'ackDetails');



return (_ctx: any,_cache: any) => {
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createBlock(_unref(Fieldset), {
    legend: _unref(t)('autoAccept'),
    toggleable: ""
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", null, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("span", null, _toDisplayString(_unref(t)('acceptanceContact')), 1),
            (_unref(isEmptyValue)(ackDetails.value.acceptanceContact))
              ? _withDirectives((_openBlock(), _createBlock(_unref(Badge), {
                  key: 0,
                  class: "tw3-absolute !tw3-h-[0.3rem] !tw3-min-w-[0.3rem] !tw3-w-[0.3rem]",
                  severity: "danger"
                }, null, 512)), [
                  [
                    _directive_tooltip,
                    _unref(t)('required'),
                    void 0,
                    { top: true }
                  ]
                ])
              : _createCommentVNode("", true)
          ]),
          _createVNode(_unref(InputText), {
            modelValue: ackDetails.value.acceptanceContact,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((ackDetails.value.acceptanceContact) = $event)),
            class: "tw3-w-full",
            disabled: _ctx.status,
            type: "text"
          }, null, 8, ["modelValue", "disabled"])
        ]),
        _createElementVNode("div", null, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("span", null, _toDisplayString(_unref(t)('workLocation')), 1),
            (_unref(isEmptyValue)(ackDetails.value.workLocationPostalCode))
              ? _withDirectives((_openBlock(), _createBlock(_unref(Badge), {
                  key: 0,
                  class: "tw3-absolute !tw3-h-[0.3rem] !tw3-min-w-[0.3rem] !tw3-w-[0.3rem]",
                  severity: "danger"
                }, null, 512)), [
                  [
                    _directive_tooltip,
                    _unref(t)('required'),
                    void 0,
                    { top: true }
                  ]
                ])
              : _createCommentVNode("", true)
          ]),
          _createVNode(_unref(InputText), {
            modelValue: ackDetails.value.workLocationPostalCode,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((ackDetails.value.workLocationPostalCode) = $event)),
            class: "tw3-w-full",
            disabled: _ctx.status,
            type: "text"
          }, null, 8, ["modelValue", "disabled"])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", null, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("span", null, _toDisplayString(_unref(t)('appointmentDate')), 1),
              (_unref(isEmptyValue)(ackDetails.value.requestedAppointmentDt))
                ? _withDirectives((_openBlock(), _createBlock(_unref(Badge), {
                    key: 0,
                    class: "tw3-absolute !tw3-h-[0.3rem] !tw3-min-w-[0.3rem] !tw3-w-[0.3rem]",
                    severity: "danger"
                  }, null, 512)), [
                    [
                      _directive_tooltip,
                      _unref(t)('required'),
                      void 0,
                      { top: true }
                    ]
                  ])
                : _createCommentVNode("", true)
            ]),
            _createVNode(_unref(DatePicker), {
              modelValue: ackDetails.value.requestedAppointmentDt,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((ackDetails.value.requestedAppointmentDt) = $event)),
              class: "tw3-w-full",
              dateFormat: "yy/mm/dd",
              disabled: _ctx.status,
              hourFormat: "12",
              showTime: ""
            }, null, 8, ["modelValue", "disabled"])
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("span", null, _toDisplayString(_unref(t)('mobileIndicator')), 1),
              (_unref(isEmptyValue)(ackDetails.value.mobileIndicator))
                ? _withDirectives((_openBlock(), _createBlock(_unref(Badge), {
                    key: 0,
                    class: "tw3-absolute !tw3-h-[0.3rem] !tw3-min-w-[0.4rem] !tw3-w-[0.3rem]",
                    severity: "danger"
                  }, null, 512)), [
                    [
                      _directive_tooltip,
                      _unref(t)('required'),
                      void 0,
                      { top: true }
                    ]
                  ])
                : _createCommentVNode("", true)
            ]),
            _createVNode(_unref(ToggleButton), {
              modelValue: ackDetails.value.mobileIndicator,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((ackDetails.value.mobileIndicator) = $event)),
              disabled: _ctx.status,
              offLabel: _unref(t)('no'),
              onLabel: _unref(t)('yes')
            }, null, 8, ["modelValue", "disabled", "offLabel", "onLabel"])
          ])
        ])
      ])
    ]),
    _: 1
  }, 8, ["legend"]))
}
}

})