import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "tw3-w-full tw3-h-full tw3-max-w-[1440px] tw3-flex tw3-flex-col tw3-gap-[1rem] tw3-overflow-hidden tw3-mx-auto" }

import Card from 'primevue/card';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';

import { Address } from '@/entities/address/lib/types';
import { getAddress } from '@/entities/customer/lib/api';
import { useCustomerEditStore } from '@/entities/customer/lib/store';
import GbDefaultBreadcrumb from '@/shared/ui/GbDefaultBreadcrumb.vue';
import AddressDialog from '@/widgets/addressDialog/AddressDialog.vue';
import CustomersList from '@/widgets/customers/CustomersList.vue';
import EditCustomerDialog from '@/widgets/editCustomerDialog/editCustomerDialog.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'CustomersPage',
  setup(__props) {

const { t } = useI18n();

const tabs = [{ label: t('data') }, { label: t('customers') }];

const editStore = useCustomerEditStore();

const address = ref<Address>();
const showAddress = ref(false);

const showEditDialog = ref(false);

const toggleAddress = async (id: number, addressId: number) => {
	if (address.value && address.value.id === addressId) return;
	address.value = await getAddress(id, addressId);
	showAddress.value = true;
};

const toggleEdit = async (id: string) => {
	await editStore.customer.execute(0, { id });
	showEditDialog.value = true;
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(GbDefaultBreadcrumb, { tabs: tabs }),
    _createVNode(_unref(Card), {
      "pt:body:class": "tw3-overflow-auto tw3-h-full",
      "pt:content:class": "tw3-overflow-auto tw3-h-full",
      "pt:root:class": "tw3-w-full tw3-h-full tw3-overflow-auto"
    }, {
      content: _withCtx(() => [
        _createVNode(EditCustomerDialog, {
          show: showEditDialog.value,
          "onUpdate:show": _cache[0] || (_cache[0] = ($event: any) => ((showEditDialog).value = $event))
        }, null, 8, ["show"]),
        (address.value)
          ? (_openBlock(), _createBlock(AddressDialog, {
              key: 0,
              show: showAddress.value,
              "onUpdate:show": _cache[1] || (_cache[1] = ($event: any) => ((showAddress).value = $event)),
              fullAddress: address.value.full_address,
              lat: address.value.lat,
              lng: address.value.lng
            }, null, 8, ["show", "fullAddress", "lat", "lng"]))
          : _createCommentVNode("", true),
        _createVNode(CustomersList, {
          onOnEdit: toggleEdit,
          onOnOpenAddress: toggleAddress
        })
      ]),
      _: 1
    })
  ]))
}
}

})