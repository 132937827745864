import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import Step from 'primevue/step';
import StepItem from 'primevue/stepitem';
import StepPanel from 'primevue/steppanel';
import Stepper from 'primevue/stepper';

import { useCreateAccountStore } from '../../model/createAccount';
import { AccountCreationStepValues } from '../../model/types';
import BillingFinancingStep from '../billingFinancing/BillingFinancingStep.vue';
import BillingPlanStep from '../billingPlan/BillingPlanStep.vue';
import OrganizationStep from '../organization/OrganizationStep.vue';
import ShopInfoStep from '../shopInfo/ShopInfoStep.vue';
import ShopIntegrationsStep from '../shopIntegrations/ShopIntegrationsStep.vue';
import ShopLogoStep from '../shopLogo/ShopLogoStep.vue';
import ShopTaxesStep from '../shopTaxes/ShopTaxesStep.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'StepsLayout',
  setup(__props) {

const createAccountStore = useCreateAccountStore();

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(Stepper), {
    linear: "",
    value: _unref(createAccountStore).step
  }, {
    default: _withCtx(() => [
      _createVNode(_unref(StepItem), {
        value: _unref(AccountCreationStepValues).organization
      }, {
        default: _withCtx(() => [
          _createVNode(_unref(Step), null, {
            default: _withCtx(() => _cache[0] || (_cache[0] = [
              _createTextVNode("Company info")
            ])),
            _: 1
          }),
          _createVNode(_unref(StepPanel), { class: "tw3-py-2" }, {
            default: _withCtx(() => [
              _createVNode(OrganizationStep)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["value"]),
      _createVNode(_unref(StepItem), {
        value: _unref(AccountCreationStepValues).shopInfo
      }, {
        default: _withCtx(() => [
          _createVNode(_unref(Step), null, {
            default: _withCtx(() => _cache[1] || (_cache[1] = [
              _createTextVNode("Shop details")
            ])),
            _: 1
          }),
          _createVNode(_unref(StepPanel), { class: "tw3-py-2" }, {
            default: _withCtx(() => [
              _createVNode(ShopInfoStep)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["value"]),
      _createVNode(_unref(StepItem), {
        value: _unref(AccountCreationStepValues).shopLogo
      }, {
        default: _withCtx(() => [
          _createVNode(_unref(Step), null, {
            default: _withCtx(() => _cache[2] || (_cache[2] = [
              _createTextVNode("Shop logo")
            ])),
            _: 1
          }),
          _createVNode(_unref(StepPanel), { class: "tw3-py-2" }, {
            default: _withCtx(() => [
              _createVNode(ShopLogoStep)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["value"]),
      _createVNode(_unref(StepItem), {
        value: _unref(AccountCreationStepValues).shopTaxes
      }, {
        default: _withCtx(() => [
          _createVNode(_unref(Step), null, {
            default: _withCtx(() => _cache[3] || (_cache[3] = [
              _createTextVNode("Sales taxes")
            ])),
            _: 1
          }),
          _createVNode(_unref(StepPanel), { class: "tw3-py-2" }, {
            default: _withCtx(() => [
              _createVNode(ShopTaxesStep)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["value"]),
      _createVNode(_unref(StepItem), {
        value: _unref(AccountCreationStepValues).shopIntegrations
      }, {
        default: _withCtx(() => [
          _createVNode(_unref(Step), null, {
            default: _withCtx(() => _cache[4] || (_cache[4] = [
              _createTextVNode("Integrations")
            ])),
            _: 1
          }),
          _createVNode(_unref(StepPanel), { class: "tw3-py-2" }, {
            default: _withCtx(() => [
              _createVNode(ShopIntegrationsStep)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["value"]),
      _createVNode(_unref(StepItem), {
        value: _unref(AccountCreationStepValues).billingPlan
      }, {
        default: _withCtx(() => [
          _createVNode(_unref(Step), null, {
            default: _withCtx(() => _cache[5] || (_cache[5] = [
              _createTextVNode("Pricing plan")
            ])),
            _: 1
          }),
          _createVNode(_unref(StepPanel), { class: "tw3-py-2" }, {
            default: _withCtx(() => [
              _createVNode(BillingPlanStep)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["value"]),
      _createVNode(_unref(StepItem), {
        value: _unref(AccountCreationStepValues).billingFinancing
      }, {
        default: _withCtx(() => [
          _createVNode(_unref(Step), null, {
            default: _withCtx(() => _cache[6] || (_cache[6] = [
              _createTextVNode("Financing")
            ])),
            _: 1
          }),
          _createVNode(_unref(StepPanel), { class: "tw3-py-2" }, {
            default: _withCtx(() => [
              _createVNode(BillingFinancingStep)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["value"])
    ]),
    _: 1
  }, 8, ["value"]))
}
}

})