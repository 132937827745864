import { coreApi } from '@/shared/api';
import { DefaultApiResponse } from '@/shared/types/default-api-response';

import type {
	RejectedEdisRequest,
	RejectedInvoice,
	RejectedInvoiceAPI,
	RejectedInvoicesData,
	RejectedInvoicesRequest
} from '../model/types';

const queEdis_URL = '/api/status/queuedEdis';

const getEdiQueueStatusCounts = async () => {
	const response = await coreApi().get(`/api/ediQueueStatusCounts`);

	return response.data;
};

const getInvoicesByStatus = async (
	params: RejectedInvoicesRequest
): Promise<RejectedInvoicesData<RejectedInvoice>> => {
	const response = await coreApi().get<DefaultApiResponse<RejectedInvoiceAPI>>(
		queEdis_URL,
		{
			params: params
		}
	);

	return {
		data: response.data.rows,
		totalCount: response.data.numberOfRowsFound
	};
};

const getRejectedInvoices = async (
	params: RejectedEdisRequest
): Promise<RejectedInvoicesData<RejectedInvoice>> => {
	const response = await coreApi().post<DefaultApiResponse<RejectedInvoiceAPI>>(
		`unum/job-details/jobslist/rejected-dialog/rejected`,
		params
	);

	return {
		data: response.data.rows,
		totalCount: response.data.numberOfRowsFound
	};
};

const getQueuedInvoices = async (
	params: RejectedEdisRequest
): Promise<RejectedInvoicesData<RejectedInvoice>> => {
	const response = await coreApi().post<DefaultApiResponse<RejectedInvoiceAPI>>(
		`unum/job-details/jobslist/rejected-dialog/queued`,
		params
	);

	return {
		data: response.data.rows,
		totalCount: response.data.numberOfRowsFound
	};
};

const getUnsubmittedInvoices = async (
	params: RejectedEdisRequest
): Promise<RejectedInvoicesData<RejectedInvoice>> => {
	const response = await coreApi().post<DefaultApiResponse<RejectedInvoiceAPI>>(
		`unum/job-details/jobslist/rejected-dialog-unsubmitted`,
		params
	);

	return {
		data: response.data.rows,
		totalCount: response.data.numberOfRowsFound
	};
};

export {
	getEdiQueueStatusCounts,
	getInvoicesByStatus,
	getRejectedInvoices,
	getQueuedInvoices,
	getUnsubmittedInvoices
};
