import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "table-shop-head" }
const _hoisted_2 = ["colspan"]
const _hoisted_3 = { class: "table-date-head" }
const _hoisted_4 = ["colspan"]
const _hoisted_5 = { class: "table-header" }
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { key: 1 }
const _hoisted_9 = { key: 2 }
const _hoisted_10 = { key: 3 }
const _hoisted_11 = ["innerHTML"]
const _hoisted_12 = { key: 0 }
const _hoisted_13 = ["href"]

import { storeToRefs } from 'pinia';
import { computed, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';

import { taxDetailLastCols } from '@/widgets/reportsElements/model/constants';
import useReportsStore from '@/widgets/reportsElements/model/store';
import {
	reportsGetters,
	splitReportDataByAttr
} from '@/widgets/reportsElements/model/utils/getters';

import TableTotals from '../reportsHelpers/TableTotals.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'TaxDetailReportTable',
  props: {
    data: {}
  },
  setup(__props: any) {

const { t } = useI18n();

const props = __props;

const reportStore = useReportsStore();

const {
	customShopDataFilterValue,
	customShopDataFilterOptions,
	currentColumns
} = storeToRefs(reportStore);

const dataSplitedByShop = computed(() => {
	return splitReportDataByAttr('shop_name')(props.data);
});

const colCount = (shop: string) => {
	return (
		shopTaxColumns(shop).length +
		currentColumns.value.length +
		taxDetailLastCols.length
	);
};

const staticTaxFilter = ref(customShopDataFilterValue.value.value);

watch(
	() => props.data,
	() => {
		staticTaxFilter.value = customShopDataFilterValue.value.value;
	}
);

const shopTaxColumns = (shop: string) => {
	const foundShop = customShopDataFilterOptions.value.find(
		(item: any) => item.name === shop
	);

	if (!foundShop) return [];

	const taxes = foundShop.taxes || [];

	const filteredTaxes = taxes.filter((tax: any) =>
		staticTaxFilter.value ? staticTaxFilter.value.includes(tax.id) : tax
	);

	return filteredTaxes.map((tax: any) => ({
		...tax,
		dollar: true
	}));
};

const roundedNumbers = (data: number) => {
	return (
		data &&
		reportsGetters.formatNumber(data, {
			style: 'dollar'
		})
	);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(dataSplitedByShop.value, (shop_data, shop) => {
      return (_openBlock(), _createElementBlock("table", {
        key: shop,
        class: "tax-detail-report-table"
      }, [
        _createElementVNode("thead", null, [
          _createElementVNode("tr", _hoisted_1, [
            _createElementVNode("td", {
              class: "cell",
              colspan: colCount(shop) + 2
            }, _toDisplayString(shop), 9, _hoisted_2)
          ])
        ]),
        _createElementVNode("tbody", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(splitReportDataByAttr)('date')(shop_data), (date_data, date) => {
            return (_openBlock(), _createElementBlock(_Fragment, {
              key: shop + date
            }, [
              _createElementVNode("tr", _hoisted_3, [
                _cache[0] || (_cache[0] = _createElementVNode("td", { class: "fake-cell" }, null, -1)),
                _createElementVNode("td", {
                  class: "cell",
                  colspan: colCount(shop) + 1
                }, _toDisplayString(date), 9, _hoisted_4)
              ]),
              _createElementVNode("tr", _hoisted_5, [
                _cache[2] || (_cache[2] = _createElementVNode("td", { class: "fake-cell" }, null, -1)),
                _cache[3] || (_cache[3] = _createElementVNode("td", { class: "fake-cell" }, null, -1)),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(currentColumns), (col) => {
                  return (_openBlock(), _createElementBlock("td", {
                    key: col.prop,
                    class: "cell",
                    innerHTML: col.label
                  }, null, 8, _hoisted_6))
                }), 128)),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(shopTaxColumns(shop), (col) => {
                  return (_openBlock(), _createElementBlock("td", {
                    key: col.id,
                    class: "cell dollar strong"
                  }, [
                    _createTextVNode(_toDisplayString(col.name) + " ", 1),
                    _cache[1] || (_cache[1] = _createElementVNode("br", null, null, -1)),
                    (col.for_labor && col.for_materials)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_7, "(" + _toDisplayString(_unref(t)('both')) + " )", 1))
                      : (col.for_labor)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_8, "(" + _toDisplayString(_unref(t)('labor')) + ")", 1))
                        : (col.for_materials)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_9, "(" + _toDisplayString(_unref(t)('materials')) + ")", 1))
                          : (_openBlock(), _createElementBlock("span", _hoisted_10, "(" + _toDisplayString(_unref(t)('flat')) + ")", 1))
                  ]))
                }), 128)),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(taxDetailLastCols), (col) => {
                  return (_openBlock(), _createElementBlock("td", {
                    key: col.prop,
                    class: "cell dollar",
                    innerHTML: col.label
                  }, null, 8, _hoisted_11))
                }), 128))
              ]),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(date_data, (row) => {
                return (_openBlock(), _createElementBlock("tr", {
                  key: row.id,
                  class: "table-row"
                }, [
                  _cache[4] || (_cache[4] = _createElementVNode("td", { class: "fake-cell" }, null, -1)),
                  _cache[5] || (_cache[5] = _createElementVNode("td", { class: "fake-cell" }, null, -1)),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(currentColumns), (col) => {
                    return (_openBlock(), _createElementBlock("td", {
                      key: col.prop,
                      class: _normalizeClass(["cell", { dollar: col.dollar, strong: col.strong }])
                    }, [
                      (col.prop !== 'invoice_num')
                        ? (_openBlock(), _createElementBlock("span", _hoisted_12, _toDisplayString(col.dollar ? roundedNumbers(row[col.prop]) : row[col.prop]), 1))
                        : _createCommentVNode("", true),
                      (col.prop === 'invoice_num')
                        ? (_openBlock(), _createElementBlock("a", {
                            key: 1,
                            href: `/jobs/${row.id}`,
                            target: "_blank"
                          }, _toDisplayString(row.id), 9, _hoisted_13))
                        : _createCommentVNode("", true)
                    ], 2))
                  }), 128)),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(shopTaxColumns(shop), (col) => {
                    return (_openBlock(), _createElementBlock("td", {
                      key: col.id,
                      class: "cell dollar strong"
                    }, _toDisplayString(roundedNumbers(row[col.name])), 1))
                  }), 128)),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(taxDetailLastCols), (col) => {
                    return (_openBlock(), _createElementBlock("td", {
                      key: col.prop,
                      class: _normalizeClass(["cell", [col.class, { dollar: col.dollar, strong: col.strong }]])
                    }, _toDisplayString(col.dollar ? roundedNumbers(row[col.prop]) : row[col.prop]), 3))
                  }), 128))
                ]))
              }), 128)),
              _createVNode(TableTotals, {
                columns: [
							..._unref(currentColumns),
							...shopTaxColumns(shop),
							..._unref(taxDetailLastCols)
						],
                data: date_data,
                fake: 2,
                isDateMode: "",
                label: "Daily"
              }, null, 8, ["columns", "data"]),
              _cache[6] || (_cache[6] = _createElementVNode("tr", { class: "row-spacer-date" }, [
                _createElementVNode("td", { class: "fake-cell" }),
                _createElementVNode("td", { class: "fake-cell" }),
                _createElementVNode("td", { class: "fake-cell" })
              ], -1))
            ], 64))
          }), 128)),
          _createVNode(TableTotals, {
            columns: [
						..._unref(currentColumns),
						...shopTaxColumns(shop),
						..._unref(taxDetailLastCols)
					],
            data: shop_data,
            fake: 2,
            isShopMode: "",
            label: "Shop"
          }, null, 8, ["columns", "data"]),
          (_openBlock(), _createElementBlock("tr", {
            key: shop + 'spacer'
          }, _cache[7] || (_cache[7] = [
            _createElementVNode("td", { class: "fake-cell" }, null, -1),
            _createElementVNode("td", { class: "fake-cell" }, null, -1),
            _createElementVNode("td", { class: "fake-cell" }, null, -1)
          ])))
        ])
      ]))
    }), 128))
  ]))
}
}

})