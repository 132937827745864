import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, isRef as _isRef, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "tw3-flex tw3-mx-auto tw3-w-full tw3-items-start tw3-flex-col tw3-justify-between tw3-max-w-[1440px] tw3-mb-[1rem] tw3-gap-4 md:tw3-flex-row" }
const _hoisted_2 = { class: "layout-breadcrumb tw3-text-gray-500 tw3-flex tw3-items-center tw3-justify-between" }
const _hoisted_3 = {
  key: 0,
  class: "layout-breadcrumb-chevron"
}
const _hoisted_4 = { class: "tw3-flex tw3-flex-col tw3-gap-6 tw3-h-full" }
const _hoisted_5 = {
  key: 0,
  class: "tw3-w-full tw3-flex tw3-flex-col tw3-col-span-1"
}
const _hoisted_6 = { class: "tw3-content-end tw3-text-slate-500 tw3-font-medium tw3-text-sm tw3-relative" }
const _hoisted_7 = { key: 2 }
const _hoisted_8 = {
  key: 3,
  class: "tw3-w-full tw3-flex tw3-flex-col tw3-col-span-1"
}
const _hoisted_9 = { class: "tw3-content-end tw3-text-slate-500 tw3-font-medium tw3-text-sm tw3-relative" }
const _hoisted_10 = {
  key: 5,
  class: "tw3-flex tw3-flex-col"
}
const _hoisted_11 = {
  key: 6,
  class: "tw3-w-full tw3-flex tw3-flex-col tw3-col-span-1"
}
const _hoisted_12 = { class: "tw3-content-end tw3-text-slate-500 tw3-font-medium tw3-text-sm tw3-relative" }
const _hoisted_13 = {
  key: 7,
  class: "tw3-w-full tw3-flex tw3-flex-col tw3-col-span-1"
}
const _hoisted_14 = { class: "tw3-content-end tw3-text-slate-500 tw3-font-medium tw3-text-sm" }
const _hoisted_15 = {
  key: 8,
  class: "tw3-w-full tw3-flex tw3-flex-col tw3-col-span-1"
}
const _hoisted_16 = { class: "tw3-content-end tw3-text-slate-500 tw3-font-medium tw3-text-sm" }
const _hoisted_17 = {
  key: 9,
  class: "tw3-w-full tw3-flex tw3-flex-col tw3-col-span-1"
}
const _hoisted_18 = { class: "tw3-content-end tw3-text-slate-500 tw3-font-medium tw3-text-sm tw3-relative" }
const _hoisted_19 = {
  key: 10,
  class: "tw3-w-full tw3-flex tw3-flex-col tw3-col-span-1"
}
const _hoisted_20 = { class: "tw3-content-end tw3-text-slate-500 tw3-font-medium tw3-text-sm" }
const _hoisted_21 = {
  key: 11,
  class: "tw3-w-full tw3-flex tw3-flex-col tw3-col-span-1"
}
const _hoisted_22 = { class: "tw3-content-end tw3-text-slate-500 tw3-font-medium tw3-text-sm tw3-relative" }
const _hoisted_23 = { key: 12 }
const _hoisted_24 = {
  key: 14,
  class: "tw3-w-full tw3-flex tw3-flex-col tw3-col-span-1"
}
const _hoisted_25 = { class: "tw3-content-end tw3-text-slate-500 tw3-font-medium tw3-text-sm" }
const _hoisted_26 = {
  key: 15,
  class: "tw3-flex tw3-flex-col"
}
const _hoisted_27 = {
  key: 0,
  class: "tw3-flex tw3-flex-col tw3-w-4/6"
}
const _hoisted_28 = { class: "tw3-content-end tw3-text-slate-500 tw3-font-medium tw3-text-sm" }
const _hoisted_29 = {
  key: 1,
  class: "tw3-w-1/6 tw3-h-[51px] tw3-flex tw3-flex-col tw3-justify-end"
}
const _hoisted_30 = {
  key: 2,
  class: "tw3-w-1/6 tw3-h-[51px] tw3-flex tw3-flex-col tw3-justify-end"
}
const _hoisted_31 = { class: "tw3-flex tw3-items-end tw3-gap-2 tw3-w-full" }
const _hoisted_32 = { class: "tw3-flex tw3-flex-col tw3-h-[51px] tw3-items-end tw3-justify-end" }
const _hoisted_33 = { class: "tw3-w-fit" }
const _hoisted_34 = { class: "tw3-content-end tw3-text-slate-500 tw3-font-medium tw3-text-sm" }
const _hoisted_35 = { class: "tw3-flex tw3-gap-2 lg:tw3-justify-end" }
const _hoisted_36 = {
  key: 2,
  class: "tw3-w-full tw3-h-full tw3-flex tw3-flex-col tw3-gap-4 tw3-items-center tw3-justify-center"
}
const _hoisted_37 = { class: "tw3-mt-1 tw3-mx-auto tw3-text-xl tw3-block" }

import { storeToRefs } from 'pinia';
import Badge from 'primevue/badge';
import Button from 'primevue/button';
import Card from 'primevue/card';
import Divider from 'primevue/divider';
import Select from 'primevue/select';
import Tag from 'primevue/tag';
import ToggleButton from 'primevue/togglebutton';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import { useUserStore } from '@/entities/user/store';
import { useWindowSizes } from '@/shared/composables';
import { capitalizeStr } from '@/shared/helpers';

import {
	acrualCashOptions,
	customerTypes,
	licenseTypes,
	paidStatus,
	workTypeOptions,
	xActionTypeOptions
} from '../model/constants';
import useReportsStore from '../model/store';
import { reportsGetters } from '../model/utils/getters';

import ReportsContractorFilter from './reportsFilters/ReportsContractorFilter.vue';
import ReportsCustomerFilter from './reportsFilters/ReportsCustomerFilter.vue';
import ReportsDateFilter from './reportsFilters/ReportsDateFilter.vue';
import ReportsSelectFilter from './reportsFilters/ReportsSelectFilter.vue';
import ReportsShopDataFilter from './reportsFilters/ReportsShopDataFilter.vue';
import ReportsShareButtons from './ReportsShareButtons.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'ReportsHeader',
  props: {
    hasPermission: { type: Boolean }
  },
  setup(__props: any) {



const reportsStore = useReportsStore();

const userStore = useUserStore();
const { user } = storeToRefs(userStore);
const {
	currentReportType,
	selectedShop,
	jobTypesList,
	selectedJobType,
	showJobFilter,
	selectedCustomerType,
	showCustomerTypeFilter,
	selectedLicenseTypes,
	showLicenseTypesFilter,
	selectedPaidStatus,
	showPaidStatusFilter,
	showDateFilter,
	tableMode,
	tableModeOptions,
	showTableMode,
	showShopDataFilter,
	showPaymentsFilter,
	paymentsFilterValue,
	showContractorFilter,
	acrualCashFilterValue,
	showAcrualCashFilter,
	showAccountingFilter,
	accountingFilterValue,
	accountingPackages,
	accountingPackagesLoading,
	showCustomerFilter,
	selectedWorkType,
	showWorkTypeFilter,
	reportDataIsLoading,
	reportData,
	expandAllAgingPayments,
	allowedToGenerateReport,
	includeAdjustments
} = storeToRefs(reportsStore);

const { isTablet, isMobile, isPc } = useWindowSizes();

function getReport(rightPartAdmin: boolean) {
	reportsStore.getReportHandler.execute(0, rightPartAdmin);
}
const { t } = useI18n();

const breadcrumbs = computed(() => {
	const initialBreadcrumbs = [
		{
			path: '/reports',
			label: t('reports')
		},
		{
			label: currentReportType.value.label
		}
	];

	return initialBreadcrumbs;
});

const showPlaceholder = computed(() => {
	if (currentReportType.value.group === 'tax') {
		return !showShopDataFilter || !currentReportType.value.date;
	} else if (showCustomerFilter) {
		return !currentReportType.value.date;
	}
	return false;
});

return (_ctx: any,_cache: any) => {
  const _component_RouterLink = _resolveComponent("RouterLink")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["tw3-flex tw3-flex-col tw3-w-full", {
			'tw3-h-full': !_ctx.hasPermission
		}])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("nav", _hoisted_2, [
        _createElementVNode("ol", null, [
          _createElementVNode("li", null, [
            _createVNode(_component_RouterLink, {
              class: "tw3-cursor-pointer tw3-transition-all",
              to: "/"
            }, {
              default: _withCtx(() => _cache[15] || (_cache[15] = [
                _createElementVNode("i", { class: "pi pi-home" }, null, -1)
              ])),
              _: 1
            })
          ]),
          _cache[16] || (_cache[16] = _createElementVNode("li", { class: "layout-breadcrumb-chevron" }, "/", -1)),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(breadcrumbs.value, (breadcrumbRoute, i) => {
            return (_openBlock(), _createElementBlock(_Fragment, { key: breadcrumbRoute }, [
              _createElementVNode("li", null, [
                (breadcrumbRoute.path)
                  ? (_openBlock(), _createBlock(_component_RouterLink, {
                      key: 0,
                      class: "tw3-cursor-pointer tw3-transition-all",
                      to: breadcrumbRoute.path
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_unref(capitalizeStr)(breadcrumbRoute.label)), 1)
                      ]),
                      _: 2
                    }, 1032, ["to"]))
                  : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                      _createTextVNode(_toDisplayString(_unref(capitalizeStr)(breadcrumbRoute.label)), 1)
                    ], 64))
              ]),
              (i !== breadcrumbs.value.length - 1)
                ? (_openBlock(), _createElementBlock("li", _hoisted_3, " / "))
                : _createCommentVNode("", true)
            ], 64))
          }), 128))
        ])
      ])
    ]),
    _createVNode(_unref(Card), {
      class: _normalizeClass(["tw3-w-full tw3-relative", {
				'tw3-h-full': !_ctx.hasPermission
			}]),
      pt: {
				body: {
					class: '!tw3-p-4 tw3-h-full tw3-w-full'
				},
				content: {
					class: 'tw3-h-full tw3-w-full'
				}
			}
    }, {
      content: _withCtx(() => [
        _createElementVNode("div", _hoisted_4, [
          (_ctx.hasPermission)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                (
								_unref(currentReportType).label === 'Profit and Loss' ||
								_unref(currentReportType).label === 'Balance Sheet'
							)
                  ? (_openBlock(), _createBlock(_unref(Tag), {
                      key: 0,
                      class: "tw3-absolute tw3-right-[-5px] tw3-top-[-10px]",
                      severity: "info",
                      value: "Beta!"
                    }))
                  : _createCommentVNode("", true)
              ], 64))
            : _createCommentVNode("", true),
          (_ctx.hasPermission)
            ? (_openBlock(), _createElementBlock("div", {
                key: 1,
                class: _normalizeClass(["filter-container tw3-flex tw3-flex-col lg:tw3-flex-row tw3-w-full", {
								'tw3-items-end': !_unref(showTableMode)
							}])
              }, [
                _createElementVNode("div", {
                  class: _normalizeClass(["tw3-flex tw3-flex-col tw3-w-full lg:tw3-w-19/12 md:tw3-grid md:tw3-grid-cols-2 lg:tw3-grid-cols-4 tw3-gap-5 tw3-h-full", {
									'tw3-relative tw3-pb-4': _unref(showContractorFilter) && _unref(isPc)
								}])
                }, [
                  (_unref(currentReportType).hideShopSelect !== false)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                        _createElementVNode("p", _hoisted_6, [
                          _createTextVNode(_toDisplayString(_unref(capitalizeStr)(_unref(t)('shops'))) + " ", 1),
                          (_unref(reportsStore).isRequired('shop'))
                            ? _withDirectives((_openBlock(), _createBlock(_unref(Badge), {
                                key: 0,
                                class: "tw3-absolute !tw3-h-[0.3rem] !tw3-min-w-[0.3rem] !tw3-w-[0.3rem]",
                                severity: "danger"
                              }, null, 512)), [
                                [
                                  _directive_tooltip,
                                  _unref(t)('required'),
                                  void 0,
                                  { top: true }
                                ]
                              ])
                            : _createCommentVNode("", true)
                        ]),
                        _createVNode(ReportsSelectFilter, {
                          modelValue: _unref(selectedShop),
                          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(selectedShop) ? (selectedShop).value = $event : null)),
                          dropdownOptions: _unref(user).shops,
                          optionLabel: "name",
                          optionValue: "id",
                          placeholder: `${_unref(t)('select')} shop...`,
                          required: _unref(reportsStore).isRequired('shop'),
                          singleSelection: 
											_unref(currentReportType).group === 'royalty' ||
											_unref(currentReportType).id === 'aging'
										
                        }, null, 8, ["modelValue", "dropdownOptions", "placeholder", "required", "singleSelection"])
                      ]))
                    : _createCommentVNode("", true),
                  (_unref(showDateFilter))
                    ? (_openBlock(), _createBlock(ReportsDateFilter, { key: 1 }))
                    : _createCommentVNode("", true),
                  (showPlaceholder.value && _unref(currentReportType).id !== 'aging')
                    ? (_openBlock(), _createElementBlock("span", _hoisted_7))
                    : _createCommentVNode("", true),
                  (_unref(showAccountingFilter))
                    ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                        _createElementVNode("p", _hoisted_9, [
                          _createTextVNode(_toDisplayString(_unref(capitalizeStr)(_unref(t)('reportsPage.filters.accountingPackage'))) + " ", 1),
                          (_unref(reportsStore).isRequired('accounting'))
                            ? _withDirectives((_openBlock(), _createBlock(_unref(Badge), {
                                key: 0,
                                class: "tw3-absolute !tw3-h-[0.3rem] !tw3-min-w-[0.3rem] !tw3-w-[0.3rem]",
                                severity: "danger"
                              }, null, 512)), [
                                [
                                  _directive_tooltip,
                                  _unref(t)('required'),
                                  void 0,
                                  { top: true }
                                ]
                              ])
                            : _createCommentVNode("", true)
                        ]),
                        _createVNode(ReportsSelectFilter, {
                          modelValue: _unref(accountingFilterValue),
                          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_isRef(accountingFilterValue) ? (accountingFilterValue).value = $event : null)),
                          dropdownOptions: _unref(accountingPackages),
                          loading: _unref(accountingPackagesLoading),
                          optionLabel: "name",
                          placeholder: `${_unref(t)('select')} ${_unref(t)('reportsPage.filters.accountingPackage').toLocaleLowerCase()}...`
                        }, null, 8, ["modelValue", "dropdownOptions", "loading", "placeholder"])
                      ]))
                    : _createCommentVNode("", true),
                  (_unref(showShopDataFilter))
                    ? (_openBlock(), _createBlock(ReportsShopDataFilter, { key: 4 }))
                    : _createCommentVNode("", true),
                  (_unref(currentReportType).id === 'margin')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                        _cache[17] || (_cache[17] = _createElementVNode("p", { class: "tw3-content-end tw3-text-slate-500 tw3-font-medium tw3-text-sm" }, " Include Adjustments ", -1)),
                        _createVNode(_unref(ToggleButton), {
                          modelValue: _unref(includeAdjustments),
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_isRef(includeAdjustments) ? (includeAdjustments).value = $event : null)),
                          class: "tw3-w-fit"
                        }, null, 8, ["modelValue"])
                      ]))
                    : _createCommentVNode("", true),
                  (_unref(showJobFilter))
                    ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                        _createElementVNode("p", _hoisted_12, [
                          _createTextVNode(_toDisplayString(_unref(capitalizeStr)(_unref(t)('reportsPage.filters.jobTypes.jobTypes'))) + " ", 1),
                          (_unref(reportsStore).isRequired('jobTypes'))
                            ? _withDirectives((_openBlock(), _createBlock(_unref(Badge), {
                                key: 0,
                                class: "tw3-absolute !tw3-h-[0.3rem] !tw3-min-w-[0.3rem] !tw3-w-[0.3rem]",
                                severity: "danger"
                              }, null, 512)), [
                                [
                                  _directive_tooltip,
                                  _unref(t)('required'),
                                  void 0,
                                  { top: true }
                                ]
                              ])
                            : _createCommentVNode("", true)
                        ]),
                        (_unref(showJobFilter))
                          ? (_openBlock(), _createBlock(ReportsSelectFilter, {
                              key: 0,
                              modelValue: _unref(selectedJobType),
                              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_isRef(selectedJobType) ? (selectedJobType).value = $event : null)),
                              dropdownOptions: _unref(jobTypesList),
                              optionLabel: "name",
                              optionValue: "id",
                              placeholder: `${_unref(t)('select')} ${_unref(t)('reportsPage.filters.jobTypes.jobTypes').toLocaleLowerCase()}...`
                            }, null, 8, ["modelValue", "dropdownOptions", "placeholder"]))
                          : _createCommentVNode("", true)
                      ]))
                    : _createCommentVNode("", true),
                  (_unref(showWorkTypeFilter))
                    ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                        _createElementVNode("p", _hoisted_14, _toDisplayString(_unref(capitalizeStr)(_unref(t)('reportsPage.filters.workType'))), 1),
                        _createVNode(_unref(Select), {
                          modelValue: _unref(selectedWorkType),
                          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (_isRef(selectedWorkType) ? (selectedWorkType).value = $event : null)),
                          optionLabel: "label",
                          options: _unref(workTypeOptions),
                          optionValue: "value",
                          placeholder: `${_unref(t)('select')} ${_unref(t)('type').toLocaleLowerCase()}...`,
                          singleSelection: ""
                        }, null, 8, ["modelValue", "options", "placeholder"])
                      ]))
                    : _createCommentVNode("", true),
                  (_unref(showCustomerTypeFilter))
                    ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                        _createElementVNode("p", _hoisted_16, _toDisplayString(_unref(capitalizeStr)(_unref(t)('reportsPage.filters.billToCustomer'))), 1),
                        _createVNode(ReportsSelectFilter, {
                          modelValue: _unref(selectedCustomerType),
                          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (_isRef(selectedCustomerType) ? (selectedCustomerType).value = $event : null)),
                          dropdownOptions: _unref(customerTypes),
                          optionLabel: "name",
                          optionValue: "id",
                          placeholder: `${_unref(t)('select')} ${_unref(t)('type').toLocaleLowerCase()}...`
                        }, null, 8, ["modelValue", "dropdownOptions", "placeholder"])
                      ]))
                    : _createCommentVNode("", true),
                  (_unref(showLicenseTypesFilter))
                    ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                        _createElementVNode("p", _hoisted_18, [
                          _createTextVNode(_toDisplayString(_unref(capitalizeStr)(_unref(t)('reportsPage.filters.licenseTypes'))) + " ", 1),
                          (_unref(reportsStore).isRequired('license'))
                            ? _withDirectives((_openBlock(), _createBlock(_unref(Badge), {
                                key: 0,
                                class: "tw3-absolute !tw3-h-[0.3rem] !tw3-min-w-[0.3rem] !tw3-w-[0.3rem]",
                                severity: "danger"
                              }, null, 512)), [
                                [
                                  _directive_tooltip,
                                  _unref(t)('required'),
                                  void 0,
                                  { top: true }
                                ]
                              ])
                            : _createCommentVNode("", true)
                        ]),
                        _createVNode(ReportsSelectFilter, {
                          modelValue: _unref(selectedLicenseTypes),
                          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => (_isRef(selectedLicenseTypes) ? (selectedLicenseTypes).value = $event : null)),
                          dropdownOptions: _unref(licenseTypes),
                          optionLabel: "name",
                          optionValue: "id",
                          placeholder: `${_unref(t)('select')} ${_unref(t)('type').toLocaleLowerCase()}...`
                        }, null, 8, ["modelValue", "dropdownOptions", "placeholder"])
                      ]))
                    : _createCommentVNode("", true),
                  (_unref(showPaidStatusFilter))
                    ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                        _createElementVNode("p", _hoisted_20, _toDisplayString(_unref(capitalizeStr)(_unref(t)('reportsPage.filters.paidStatus'))), 1),
                        _createVNode(ReportsSelectFilter, {
                          modelValue: _unref(selectedPaidStatus),
                          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => (_isRef(selectedPaidStatus) ? (selectedPaidStatus).value = $event : null)),
                          dropdownOptions: _unref(paidStatus),
                          optionLabel: "name",
                          optionValue: "id",
                          placeholder: `${_unref(t)('select')} ${_unref(t)('type').toLocaleLowerCase()}...`
                        }, null, 8, ["modelValue", "dropdownOptions", "placeholder"])
                      ]))
                    : _createCommentVNode("", true),
                  (_unref(showPaymentsFilter))
                    ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                        _createElementVNode("p", _hoisted_22, [
                          _createTextVNode(_toDisplayString(_unref(capitalizeStr)(_unref(t)('reportsPage.xType'))) + " ", 1),
                          (_unref(reportsStore).isRequired('x_action_types'))
                            ? _withDirectives((_openBlock(), _createBlock(_unref(Badge), {
                                key: 0,
                                class: "tw3-absolute !tw3-h-[0.3rem] !tw3-min-w-[0.3rem] !tw3-w-[0.3rem]",
                                severity: "danger"
                              }, null, 512)), [
                                [
                                  _directive_tooltip,
                                  _unref(t)('required'),
                                  void 0,
                                  { top: true }
                                ]
                              ])
                            : _createCommentVNode("", true)
                        ]),
                        _createVNode(ReportsSelectFilter, {
                          modelValue: _unref(paymentsFilterValue),
                          "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => (_isRef(paymentsFilterValue) ? (paymentsFilterValue).value = $event : null)),
                          dropdownOptions: _unref(xActionTypeOptions),
                          placeholder: `${_unref(t)('select')} ${_unref(t)('type').toLocaleLowerCase()}...`
                        }, null, 8, ["modelValue", "dropdownOptions", "placeholder"])
                      ]))
                    : _createCommentVNode("", true),
                  (
										_unref(showCustomerFilter) &&
										_unref(currentReportType).group !== 'statements'
									)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_23))
                    : _createCommentVNode("", true),
                  (_unref(showCustomerFilter))
                    ? (_openBlock(), _createBlock(ReportsCustomerFilter, { key: 13 }))
                    : _createCommentVNode("", true),
                  (_unref(showAcrualCashFilter))
                    ? (_openBlock(), _createElementBlock("div", _hoisted_24, [
                        _createElementVNode("p", _hoisted_25, _toDisplayString(_unref(capitalizeStr)(_unref(t)('reportsPage.filters.accrual'))), 1),
                        _createVNode(ReportsSelectFilter, {
                          modelValue: _unref(acrualCashFilterValue),
                          "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => (_isRef(acrualCashFilterValue) ? (acrualCashFilterValue).value = $event : null)),
                          dropdownOptions: _unref(acrualCashOptions),
                          optionValue: "value",
                          placeholder: `${_unref(t)('select')} ${_unref(t)('type').toLocaleLowerCase()}...`
                        }, null, 8, ["modelValue", "dropdownOptions", "placeholder"])
                      ]))
                    : _createCommentVNode("", true),
                  (_unref(currentReportType).id === 'aging')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_26, [
                        _cache[18] || (_cache[18] = _createElementVNode("p", { class: "tw3-content-end tw3-text-slate-500 tw3-font-medium tw3-text-sm" }, " Expand All Payments ", -1)),
                        _createVNode(_unref(ToggleButton), {
                          modelValue: _unref(expandAllAgingPayments),
                          "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => (_isRef(expandAllAgingPayments) ? (expandAllAgingPayments).value = $event : null)),
                          class: "tw3-w-fit"
                        }, null, 8, ["modelValue"])
                      ]))
                    : _createCommentVNode("", true),
                  (_unref(showContractorFilter))
                    ? (_openBlock(), _createBlock(ReportsContractorFilter, { key: 16 }))
                    : _createCommentVNode("", true)
                ], 2),
                _createVNode(_unref(Divider), {
                  layout: _unref(isMobile) || _unref(isTablet) ? 'horizontal' : 'vertical'
                }, null, 8, ["layout"]),
                _createElementVNode("div", {
                  class: _normalizeClass(["tw3-w-full lg:tw3-min-w-3/12 lg:tw3-w-3/12 tw3-gap-2 tw3-gap-y-4 tw3-flex tw3-flex-wrap tw3-items-end tw3-h-fit", {
									'tw3-justify-end': _unref(currentReportType).group == 'right-part',
									'tw3-relative tw3-pb-4': _unref(showContractorFilter) && _unref(isPc)
								}])
                }, [
                  (
										_unref(currentReportType).group !== 'right-part' && _unref(showTableMode)
									)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_27, [
                        _createElementVNode("p", _hoisted_28, _toDisplayString(_unref(capitalizeStr)(_unref(t)('reportsPage.filters.groupBy.groupBy'))), 1),
                        _createVNode(_unref(Select), {
                          modelValue: _unref(tableMode),
                          "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => (_isRef(tableMode) ? (tableMode).value = $event : null)),
                          optionLabel: "label",
                          options: _unref(tableModeOptions),
                          optionValue: "id",
                          placeholder: `${_unref(t)('select')} ${_unref(t)('type').toLocaleLowerCase()}...`,
                          singleSelection: ""
                        }, null, 8, ["modelValue", "options", "placeholder"])
                      ]))
                    : _createCommentVNode("", true),
                  (_unref(currentReportType).group == 'right-part')
                    ? (_openBlock(), _createElementBlock("span", _hoisted_29))
                    : _createCommentVNode("", true),
                  (_unref(showTableMode))
                    ? (_openBlock(), _createElementBlock("span", _hoisted_30))
                    : _createCommentVNode("", true),
                  (
										_unref(userStore).isAdmin &&
										_unref(currentReportType).group === 'right-part'
									)
                    ? _withDirectives((_openBlock(), _createBlock(_unref(Button), {
                        key: 3,
                        class: "tw3-w-4/6",
                        disabled: !_unref(allowedToGenerateReport),
                        icon: "pi pi-play-circle",
                        label: "All Shops",
                        loading: _unref(reportDataIsLoading),
                        severity: "secondary",
                        onClick: _cache[12] || (_cache[12] = ($event: any) => (getReport(true)))
                      }, null, 8, ["disabled", "loading"])), [
                        [
                          _directive_tooltip,
                          {
										value: !_unref(allowedToGenerateReport)
											? 'You need to fill required fields first'
											: null,
										pt: {
											text: 'tw3-text-center'
										}
									},
                          void 0,
                          { top: true }
                        ]
                      ])
                    : _createCommentVNode("", true),
                  _createElementVNode("div", _hoisted_31, [
                    _createElementVNode("div", _hoisted_32, [
                      _createElementVNode("div", _hoisted_33, [
                        _createElementVNode("p", _hoisted_34, _toDisplayString(_unref(capitalizeStr)('Actions')), 1),
                        _createElementVNode("div", _hoisted_35, [
                          _createVNode(ReportsShareButtons, {
                            disabled: 
														!(
															_unref(reportData) &&
															!_unref(reportDataIsLoading) &&
															!_unref(reportsGetters).emptyData(_unref(reportData))
														)
													
                          }, null, 8, ["disabled"]),
                          _withDirectives(_createVNode(_unref(Button), {
                            disabled: !_unref(reportsStore).isSomeFilterActive,
                            icon: "pi pi-eraser",
                            severity: "secondary",
                            onClick: _cache[13] || (_cache[13] = ($event: any) => (_unref(reportsStore).setDefaultFilterValue()))
                          }, null, 8, ["disabled"]), [
                            [
                              _directive_tooltip,
                              'Clear all filters',
                              void 0,
                              { top: true }
                            ]
                          ])
                        ])
                      ])
                    ]),
                    _withDirectives(_createVNode(_unref(Button), {
                      class: "tw3-w-full tw3-whitespace-nowrap",
                      disabled: !_unref(allowedToGenerateReport),
                      icon: "pi pi-play-circle",
                      label: "Run Report",
                      loading: _unref(reportDataIsLoading),
                      severity: "info",
                      onClick: _cache[14] || (_cache[14] = ($event: any) => (getReport(false)))
                    }, null, 8, ["disabled", "loading"]), [
                      [
                        _directive_tooltip,
                        {
											value: !_unref(allowedToGenerateReport)
												? 'You need to fill required fields first'
												: null,
											pt: {
												text: 'tw3-text-center'
											}
										},
                        void 0,
                        { top: true }
                      ]
                    ])
                  ])
                ], 2)
              ], 2))
            : (_openBlock(), _createElementBlock("div", _hoisted_36, [
                _createElementVNode("h5", _hoisted_37, _toDisplayString(_unref(t)('reportsPage.filters.permissionDenied')), 1),
                _createVNode(_unref(Button), {
                  as: "router-link",
                  label: "Go back",
                  severity: "contrast",
                  size: "large",
                  to: "/reports"
                })
              ]))
        ])
      ]),
      _: 1
    }, 8, ["class"])
  ], 2))
}
}

})