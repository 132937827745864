<template>
	<table class="install-report-table">
		<tbody>
			<template v-for="(singleData, shop) in dataSplitedByShop" :key="shop">
				<TableSubhead
					:columns="currentColumns"
					:data="splitByShopForTotals(singleData)"
					:label="shop"
					:reportType="currentReportType"
					type="shop"
				/>
				<template
					v-for="(group_data, group) in splitReportDataByAttr(
						currentReportType.groupBy ?? '',
						currentReportType.total_key
					)(singleData)"
					:key="group + 'title'"
				>
					<tr class="table-group-subhead">
						<td class="fake-cell" />
						<td class="cell" :colspan="currentColumns.length + 1">
							{{ group }}
						</td>
					</tr>
					<tr class="table-head">
						<td class="fake-cell" />
						<td class="fake-cell" />
						<td
							v-for="col in currentColumns"
							:key="col.prop"
							class="cell"
							:class="{ dollar: col.dollar }"
						>
							{{ col.label }}
						</td>
					</tr>

					<tr v-for="row in group_data" :key="group + row.id" class="table-row">
						<td class="fake-cell" />
						<td class="fake-cell" />
						<td
							v-for="col in currentColumns"
							:key="col.prop"
							class="cell"
							:class="{ dollar: col.dollar, strong: col.strong }"
						>
							<a
								v-if="col.link"
								:id="row.id"
								class="tw3-whitespace-nowrap"
								:href="`/jobs/${row.id}`"
							>
								{{ row[col.prop] }}
							</a>
							<div
								v-else-if="col.dollar"
								class="tw3-text-right"
								v-html="
									reportsGetters.formatNumber(
										row[col.prop] !== null ? row[col.prop] : 0,
										{
											style: 'dollar'
										}
									)
								"
							></div>
							<div
								v-else
								:id="row.id"
								:class="{
									'tw3-whitespace-nowrap': col.prop === 'time'
								}"
								v-html="row[col.prop]"
							></div>
							<span
								v-if="col.prop === 'invoice_num'"
								class="greyItalics tw3-ml-2"
							>
								{{ paidStatus(row.paid) }}</span
							>
						</td>
					</tr>

					<TableTotals :columns="currentColumns" :data="group_data" :fake="2" />
					<TableRowSpacer :columns="currentColumns" :fake="2" />
				</template>
				<TableTotals
					:columns="currentColumns"
					:data="splitByShopForTotals(singleData)"
					:fake="2"
					label="Shop"
				/>
				<TableRowSpacer :columns="currentColumns" :fake="2" shop />
			</template>
			<TableTotals
				:columns="currentColumns"
				:data="curatedData"
				:fake="2"
				label="Grand"
			/>
		</tbody>
	</table>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import useReportsStore from '@/widgets/reportsElements/model/store';
import {
	convertGroupByToVendor,
	reportsGetters,
	splitReportDataByAttr
} from '@/widgets/reportsElements/model/utils/getters';

import TableRowSpacer from '../reportsHelpers/TableRowSpacer.vue';
import TableSubhead from '../reportsHelpers/TableSubhead.vue';
import TableTotals from '../reportsHelpers/TableTotals.vue';

const { t } = useI18n();

const props = defineProps<{
	data: object[] | null;
}>();

const reportStore = useReportsStore();

const { currentColumns, currentReportType } = storeToRefs(reportStore);

const dataSplitedByShop = computed(() => {
	return splitReportDataByAttr('shop_name')(props.data);
});

const paidStatus = (status: string) => {
	if (status === 'PP') {
		return t('reportsPage.table.pp');
	} else if (status === 'FP') {
		return t('reportsPage.table.fp');
	} else {
		return t('reportsPage.table.unpaid');
	}
};

const splitByShopForTotals = (data: any) => {
	const typeName = convertGroupByToVendor(
		currentReportType.value.groupBy ?? ''
	);
	const returnArray: any = [];
	data?.map((d: any) => {
		d[typeName].forEach((jt: any) => {
			let totalJobCommission = 0;
			jt.job_gb_vendor_gb_vendor_types.forEach((jgv: any) => {
				totalJobCommission += jgv.commission_total || 0;
			});
			returnArray.push({
				...d,
				total_commission: totalJobCommission,
				[currentReportType.value.total_key as string]: totalJobCommission
			});
		});
	}) || [];
	return returnArray;
};

const curatedData = computed(() => {
	const newData: any = [];
	if (!props.data || !props.data?.length) return newData;
	const typeName = convertGroupByToVendor(
		currentReportType.value.groupBy ?? ''
	);
	for (let i = 0; i < props.data.length; i++) {
		if (props.data[i][typeName]?.length) {
			props.data[i][typeName].map((t: any) => {
				let totalJobCommission = 0;
				t.job_gb_vendor_gb_vendor_types.forEach((jgv: any) => {
					totalJobCommission += jgv.commission_total || 0;
				});
				newData.push({
					...props.data[i],
					[currentReportType.value.groupBy as string]: t.name,
					total_commission: totalJobCommission,
					[currentReportType.value.total_key as string]: totalJobCommission
				});
			});
		} else {
			newData.push({
				...props.data[i],
				[currentReportType.value.groupBy as string]: 'unassigned'
			});
		}
	}
	return newData;
});
</script>

<style scoped>
.install-report-table {
	display: table;
	width: 100%;
}

.install-report-table .table-group-subhead > .cell {
	background: #d0e5ff;
	color: #3e94ff;
	font-weight: 600;
}

.install-report-table .table-group-subhead > .cell:nth-child(2) {
	border-left: 2px solid #00a736;
}

.install-report-table .table-row,
.install-report-table .table-head {
	.fake-cell:nth-child(2) {
		border-left: 2px solid #00a736;
	}
}

.install-report-table .table-row .cell:nth-child(3) {
	border-left: 2px solid #3e94ff;
}

.install-report-table .table-row.payments-row .payment-cell {
	border-bottom: 1px solid #3e94ff;
}

.install-report-table .table-row.payments-row .cell:nth-child(3) {
	border-top: 1px solid #3e94ff;
	border-right: 1px solid #3e94ff;
}

.install-report-table .greyItalics {
	font-style: italic;
	color: grey;
}
</style>
