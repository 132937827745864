import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, renderList as _renderList } from "vue"

const _hoisted_1 = { style: {"padding-left":"3rem","padding-right":"3rem"} }

import { computed } from 'vue';

import AgingHeader from './AgingReport/AgingHeader.vue';
import InvoicesSection from './AgingReport/InvoicesSection.vue';
import OutstandingBalances from './AgingReport/OutstandingBalances.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'AgingReportTable',
  props: {
    data: {},
    shopId: {},
    groupBy: {},
    expandPayments: { type: Boolean }
  },
  setup(__props: any) {

const props = __props;

const ninetyPlus = computed(() => props.data?.categories.ninety_plus);
const sixtyNinety = computed(() => props.data?.categories.sixty_ninety);
const thirtySixty = computed(() => props.data?.categories.thirty_sixty);
const zeroThirty = computed(() => props.data?.categories.zero_thirty);
const totalBalance = computed(() => props.data?.balance);
const ninetyPlusBalance = computed(
	() => props.data?.categories.ninety_plus.balance
);
const sixtyNinetyBalance = computed(
	() => props.data?.categories.sixty_ninety.balance
);
const thirtySixtyBalance = computed(
	() => props.data?.categories.thirty_sixty.balance
);
const zeroThirtyBalance = computed(
	() => props.data?.categories.zero_thirty.balance
);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(AgingHeader, { shopId: _ctx.shopId }, null, 8, ["shopId"]),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(OutstandingBalances, {
        grandTotals: "",
        groupBy: _ctx.groupBy,
        ninetyPlusBalance: ninetyPlusBalance.value,
        sixtyNinetyBalance: sixtyNinetyBalance.value,
        thirtySixtyBalance: thirtySixtyBalance.value,
        totalBalance: totalBalance.value,
        zeroThirtyBalance: zeroThirtyBalance.value
      }, null, 8, ["groupBy", "ninetyPlusBalance", "sixtyNinetyBalance", "thirtySixtyBalance", "totalBalance", "zeroThirtyBalance"])
    ]),
    (_ctx.groupBy === 'age')
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          (ninetyPlus.value && ninetyPlus.value.invoices.length)
            ? (_openBlock(), _createBlock(InvoicesSection, {
                key: 0,
                data: ninetyPlus.value,
                expandPayments: _ctx.expandPayments,
                groupBy: _ctx.groupBy,
                name: "91+ days",
                shopId: _ctx.shopId
              }, null, 8, ["data", "expandPayments", "groupBy", "shopId"]))
            : _createCommentVNode("", true),
          (sixtyNinety.value && sixtyNinety.value.invoices.length)
            ? (_openBlock(), _createBlock(InvoicesSection, {
                key: 1,
                data: sixtyNinety.value,
                expandPayments: _ctx.expandPayments,
                groupBy: _ctx.groupBy,
                name: "61 - 90 days",
                shopId: _ctx.shopId
              }, null, 8, ["data", "expandPayments", "groupBy", "shopId"]))
            : _createCommentVNode("", true),
          (thirtySixty.value && thirtySixty.value.invoices.length)
            ? (_openBlock(), _createBlock(InvoicesSection, {
                key: 2,
                data: thirtySixty.value,
                expandPayments: _ctx.expandPayments,
                groupBy: _ctx.groupBy,
                name: "31 - 60 days",
                shopId: _ctx.shopId
              }, null, 8, ["data", "expandPayments", "groupBy", "shopId"]))
            : _createCommentVNode("", true),
          (zeroThirty.value && zeroThirty.value.invoices.length)
            ? (_openBlock(), _createBlock(InvoicesSection, {
                key: 3,
                data: zeroThirty.value,
                expandPayments: _ctx.expandPayments,
                groupBy: _ctx.groupBy,
                name: "Under 31 days",
                shopId: _ctx.shopId
              }, null, 8, ["data", "expandPayments", "groupBy", "shopId"]))
            : _createCommentVNode("", true)
        ], 64))
      : _createCommentVNode("", true),
    (_ctx.groupBy === 'consumer')
      ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.data?.consumers, (item, key) => {
          return (_openBlock(), _createBlock(InvoicesSection, {
            key: key,
            data: item,
            expandPayments: _ctx.expandPayments,
            groupBy: _ctx.groupBy,
            name: item.name,
            shopId: _ctx.shopId
          }, null, 8, ["data", "expandPayments", "groupBy", "name", "shopId"]))
        }), 128))
      : _createCommentVNode("", true)
  ], 64))
}
}

})